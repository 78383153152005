import { AxiosRequestConfig } from 'axios';

/* eslint-disable no-undef */
const X_FUNCTIONS_KEY = process.env.REACT_APP_X_FUNCTIONS_KEY;
const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
const PMD_API_URL = process.env.REACT_APP_PUBLIC_API_URL2;
const LinkZapURL = process.env.REACT_APP_LinkZapURL;

export const globalURLS = {
    baseURL: PUBLIC_API_URL,
    baseURL2: PMD_API_URL,
    baseURL3: LinkZapURL,

    adminData: {
        accountid: 'a237aec7-5fe0-4177-99b2-d0b590af26db',
        count: 1000,
        orderby: 'date',
    },

    countriesJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/countries.json',
    indianCitiesJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/INDcities.json',
    past60DaysMedflixNewsJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/past60DaysMedflixNews.json',
    quizOnlyDiscussionsJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/QuizOnlyDiscussions.json',
    discussionsJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/discussions.json',
    allFeedTagsJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/allFeedShownTags.json',
    feedShownTagsJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/feedShownTags.json',
    clubsJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/topClubs.json',
    specialtiesJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/specialtiesV2.json',
    speakersJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/speakers.json',
    internalHostsJSONURL:
        'https://storageaccountchata97e9.blob.core.windows.net/datafiles/internalHosts.json',
    config: {
        // onUploadProgress: progressEvent => {
        //     const percentCompleted = Math.round(
        //         (progressEvent.loaded * 100) / progressEvent.total,
        //     );
        //     setProgress(percentCompleted);
        // },
        headers: {
            'x-functions-key': X_FUNCTIONS_KEY,
            'Content-Type': 'application/json',
            'Cache-Control': 'max-age=0',
        },
    },
};
