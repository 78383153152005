/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';

import { webBanner } from '../interface';
import CustomButton from '../../Common/CustomButton/CustomButton';
import { useBannerContext } from '../../../Context/index';
import CustomInput from '../../Common/CustomInput/CustomInput';

import SuccessToast from '../../Common/Toast/SuccessToast';
import FailureToast from '../../Common/Toast/FailureToast';
import { zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import LoadingToast from '../../Common/Toast/LoadingToast';

const AddWebBannerForum: React.FC = () => {
    const { setAddBannerModal } = useBannerContext();
    const [validated, setValidated] = useState(false);
    const [forumData, setForumData] = useState<webBanner>();

    const [name, setName] = useState(forumData?.name || '');
    const [priority, setPriority] = useState(forumData?.priority || '');
    const [imageURL, setImageURL] = useState(forumData?.imageurl || '');
    const [startDate, setStartDate] = useState(forumData?.startdate || '');
    const [startTime, setStartTime] = useState(forumData?.starttime || '');

    const [endDate, setEndDate] = useState(forumData?.enddate || '');
    const [endTime, setEndTime] = useState(forumData?.endtime || '');

    const [openWebviewUrl, setOpenWebviewUrl] = useState(
        forumData?.openWebviewUrl || '',
    );

    const [platform, setPlatform] = useState(forumData?.platform || 'desktop');
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showFailureToast, setShowFailureToast] = useState(false);
    const [showLoadingToast, setShowLoadingToast] = useState(false);

    const handleName = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, name: value });
        setName(value);
    };

    const handleImageURL = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, imageurl: value });
        setImageURL(value);
    };

    const handlePriority = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, priority: value });
        setPriority(value);
    };

    const timeHandler = (handleName: string, e: any) => {
        const { value } = e.target;

        switch (handleName) {
            case 'startDate':
                setForumData({ ...forumData, startdate: value });
                setStartDate(value);
                break;
            case 'startTime':
                setForumData({ ...forumData, starttime: value });
                setStartTime(value);
                break;
            default:
                break;
            case 'endDate':
                setForumData({ ...forumData, enddate: value });
                setEndDate(value);
                break;
            case 'endTime':
                setForumData({ ...forumData, endtime: value });
                setEndTime(value);
                break;
        }
    };

    const handleOpenWebViewURL = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, openWebviewUrl: value });
        setOpenWebviewUrl(value);
    };

    const handlePlatform = (e: any) => {
        setForumData({ ...forumData, platform: e });
        setPlatform(e);
    };

    const handleSuccessToast = () => {
        setShowSuccessToast(false);
        window.location.reload();
    };

    const handleFailureToast = () => {
        setShowFailureToast(false);
    };

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        setShowLoadingToast(true);

        // console.log('forumData', forumData);

        try {
            if (forumData.startDateUTC && forumData.endDateUTC) {
                forumData.startdate = forumData.startDateUTC;
                forumData.enddate = forumData.endDateUTC;
            }
            const respData = await axios.post(
                `${globalURLS.baseURL}ADaddWebBanner`,
                forumData,
                globalURLS.config,
            );

            setShowLoadingToast(true);

            // console.log('respData', respData);
            if (respData?.data?.success === true) {
                setShowLoadingToast(false);
                setShowSuccessToast(true);
                setAddBannerModal(false);
            }

            if (respData?.data?.success === false) {
                setShowLoadingToast(false);
                setShowFailureToast(true);
            }
        } catch (e) {
            setShowLoadingToast(false);
            setShowFailureToast(true);
        }
    };

    useEffect(() => {
        const values = forumData;
        if (startDate && startDate.length && startTime && startTime.length) {
            const startDateUTC = zonedTimeToUtc(
                `${startDate + ' ' + startTime}`,
                'Asia/Calcutta',
            );
            values.startDateUTC = String(startDateUTC);
            setForumData(values);
        }
        if (endDate && endDate.length && endTime && endTime.length) {
            const endDateUTC = zonedTimeToUtc(
                `${endDate + ' ' + endTime}`,
                'Asia/Calcutta',
            );
            values.endDateUTC = String(endDateUTC);
            setForumData(values);
        }
    }, [startDate, startTime, endDate, endTime]);

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title className="mb-0">Add Web Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <InputGroup className={'row mt-1'}>
                            {/* Name */}
                            <CustomInput
                                label={'Banner Name'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                placeHolder={'Enter a Banner Name'}
                                value={name}
                                onChange={handleName}
                                errorMessage={'Please Enter Banner Name'}
                            />
                            {/* Image URL */}
                            <CustomInput
                                label={'Image URL'}
                                textArea={'short'}
                                type={'textImage'}
                                required={true}
                                placeHolder={'Enter a  Image URL'}
                                value={imageURL}
                                onChange={handleImageURL}
                                errorMessage={'Please Enter  Image URL'}
                            />

                            {/* Priority */}
                            <CustomInput
                                label={'Priority (Number from 0-9)'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                placeHolder={'Enter a  Priority'}
                                value={priority}
                                onChange={handlePriority}
                                errorMessage={'Please Enter  Priority'}
                            />
                            {/* Start Date & Time */}
                            <InputGroup className={'row mt-1'}>
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    Start Date & Time
                                </Form.Label>
                                <CustomInput
                                    textArea="short"
                                    type={'date'}
                                    required={true}
                                    errorMessage="Please provide a start date."
                                    value={startDate}
                                    onChange={(e: any) => {
                                        timeHandler('startDate', e);
                                    }}
                                />
                                <CustomInput
                                    textArea="short"
                                    type={'time'}
                                    required={true}
                                    errorMessage="Please provide a start time."
                                    value={startTime}
                                    onChange={(e: any) => {
                                        timeHandler('startTime', e);
                                    }}
                                />
                            </InputGroup>
                            {/* End Date & Time */}
                            <InputGroup className={'row mt-1'}>
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    End Date & Time
                                </Form.Label>
                                <CustomInput
                                    textArea="short"
                                    type={'date'}
                                    required={true}
                                    errorMessage="Please provide a end date."
                                    value={endDate}
                                    onChange={(e: any) => {
                                        timeHandler('endDate', e);
                                    }}
                                />
                                <CustomInput
                                    textArea="short"
                                    type={'time'}
                                    required={true}
                                    errorMessage="Please provide a end time."
                                    value={endTime}
                                    onChange={(e: any) => {
                                        timeHandler('endTime', e);
                                    }}
                                />
                            </InputGroup>
                            {/* openWebviewUrl */}
                            <CustomInput
                                label={'Open Web View URL'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                placeHolder={'Enter a  Open Web View URL'}
                                value={openWebviewUrl}
                                onChange={handleOpenWebViewURL}
                                errorMessage={'Please Enter  Open Web View URL'}
                            />
                            <CustomInput
                                label={'Select Platform'}
                                textArea={'short'}
                                type={'dropdown'}
                                required={true}
                                value={screen}
                                dropDown={{
                                    title: 'Select Platform ',
                                    dropDownItems: ['mobile', 'desktop'],
                                }}
                                onSelect={handlePlatform}
                                errorMessage={'Please Select'}
                            />
                        </InputGroup>
                    </>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                    }}
                >
                    <CustomButton
                        variant="secondary"
                        onClick={() => {
                            setAddBannerModal(false), setForumData(null);
                        }}
                    >
                        Close
                    </CustomButton>

                    <CustomButton
                        variant="primary"
                        type="submit"
                        disabled={showLoadingToast ? true : false}
                        onClick={handleSubmit}
                    >
                        Create
                    </CustomButton>
                </Modal.Footer>
            </Form>

            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={handleSuccessToast}
                    headerTitle="Success"
                />
            )}

            {showFailureToast && (
                <FailureToast
                    showModal={showFailureToast}
                    handleClose={handleFailureToast}
                    headerTitle="Failure"
                />
            )}

            {showLoadingToast && (
                <LoadingToast
                    showModal={showLoadingToast}
                    headerTitle="Processing..."
                />
            )}
        </>
    );
};

export default AddWebBannerForum;
