import React from 'react';
import { Button } from 'react-bootstrap';
import PageTitle from '../../Components/PageTitle/PageTitle';

import './Notification.css';
import { useNotificationContext, useGlobalContext } from '../../Context';
import FailureToast from '../../Components/Common/Toast/FailureToast';
import SuccessToast from '../../Components/Common/Toast/SuccessToast';
import SendBN from '../../Components/Notification/SendBN/SendBN';
import InAppBell from '../../Components/Notification/InAppBell/InAppBell';
import InAppBellTable from '../../Components/Notification/InAppBellTable/InAppBellTable';

const Notification: React.FC = () => {
    const { setSendBNModal, setSendInAppModal } = useNotificationContext();
    const {
        showSuccessToast,
        showErrorToast,
        setShowSuccessToast,
        setShowErrorToast,
    } = useGlobalContext();
    return (
        <div className="notification-container">
            <div className="notification-title">
                <PageTitle>Notification</PageTitle>
                <Button
                    className="btn-add ml-4"
                    variant="primary"
                    onClick={() => setSendBNModal(true)}
                >
                    Send BN
                </Button>
                <Button
                    className="btn-add ml-4"
                    variant="primary"
                    onClick={() => setSendInAppModal(true)}
                >
                    Send InApp
                </Button>
                <SendBN />
                <InAppBell />
            </div>
            <InAppBellTable />
            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={() => setShowSuccessToast(false)}
                    headerTitle="Success!"
                />
            )}
            {showErrorToast && (
                <FailureToast
                    showModal={showErrorToast}
                    handleClose={() => setShowErrorToast(false)}
                    headerTitle="Something went wrong, Please try again"
                />
            )}
        </div>
    );
};

export default Notification;
