import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai/index.esm.js';
import { Button, Container, Row, Stack, Col, Nav } from 'react-bootstrap';

// Components imports
import PageTitle from '../../Components/PageTitle/PageTitle.js';
import './Discussion.css';
import UpcomingDiscussion from '../../Components/Discussion/UpcomingDiscussion/UpcomingDiscussion.js';
import PastDiscussion from '../../Components/Discussion/PastDiscussion/PastDiscussion.js';
import VideoAds from '../../Components/Discussion/VideoAds/VideoAds.tsx';

import AddDiscussion from '../../Components/Discussion/AddDiscussion/AddDiscussion.js';
import AddFiles from '../../Components/Discussion/AddFiles/AddFiles.js';
import AddDocument from '../../Components/Discussion/AddDocument/AddDocument.js';
import AddVideoAds from '../../Components/Discussion/AddVideoAds/AddVideoAds.tsx';

import { DiscussionContext, useGlobalContext } from '../../Context/index.tsx';
import DiscussionFiles from '../../Components/Discussion/DiscussionFiles/DiscussionFiles.tsx';
import SuccessToast from '../../Components/Common/Toast/SuccessToast.tsx';
import FailureToast from '../../Components/Common/Toast/FailureToast.tsx';
import AddDiscussionPoll from '../../Components/Discussion/AddDiscussionPoll/AddDiscussionPoll.tsx';
import CertificateModal from '../../Components/Discussion/AddCertificate/AddCertificate.tsx';

import axios from 'axios';
// import AddDiscussionPoll from '../../Components/Discussion/AddDiscussionPoll/AddDiscussionPoll.tsx';
import { globalURLS } from '../../Global/urls.js';
import HistoricalChart from '../../Components/Common/Charts/HistoricalChart.tsx';
import useDiscussionsClubsHostsInternalHosts from '../../hooks/useDiscussionsClubsHostsInternalHosts.tsx';

import Select from 'react-select';
import LiveDiscussionCard from '../../Components/Discussion/LiveDiscussionCard/LiveDiscussionCard.tsx';
import TransferUsersModal from '../../Components/Discussion/TransferUsers/TransferUsers.tsx';
import RecordingLiveNotify from '../../Components/Discussion/RecordingLiveNotify/index.tsx';
import EndLive from '../../Components/Discussion/EndLive/index.tsx';
import AddVideoExt from '../../Components/Discussion/AddVideoExt/AddVideoExt.js';
import AddAdSpots from '../../Components/Discussion/AddAdSpots/index.tsx';
import AddInRoomRating from '../../Components/Discussion/AddInRoomRating/index.tsx';
import ManageAdSpot from '../../Components/Discussion/ManageAdSpot/index.tsx';
import ManageAudioExt from '../../Components/Discussion/ManageAudioExt/index.tsx';

const buttonStyle = {
    display: 'flex',
    width: '10em',
    fontSize: '1em',
    margin: '10px 10px',
    height: '3.5em',
    alignItems: 'center',
    justifyContent: 'start',
};
// const buttonStyle = {
//     width: 'auto',
//     fontSize: '1em',
//     height: '40px',
//     minWidth: '150px',
//     margin: '5px',
//     whiteSpace: 'nowrap',
// };

function Discussion() {
    const navs = Object.freeze({
        UPCOMING: 'Upcoming',
        PAST: 'Past',
        FILES: 'Files',
        VIDEOADS: 'VideoAds',
    });

    const {
        showSuccessToast,
        showErrorToast,
        setShowSuccessToast,
        setShowErrorToast,
    } = useGlobalContext();
    const [showModal, setShowModal] = useState(false);
    const [openAddFilesModal, setAddFilesModal] = useState(false);
    const [openAddVideoFilesModal, setAddVideoFilesModal] = useState(false);
    const [openAddDocumentModal, setAddDocumentModal] = useState(false);
    const [openAddVideoAdsModal, setAddVideoAdsModal] = useState(false);
    const [openAddPollsModal, setAddPollsModal] = useState(false);
    const [openAddCertificateModal, setAddCertificateModal] = useState(false);
    const [recordingLiveNotifyModal, setRecordingLiveNotifyModal] =
        useState(false);
    const [endLiveDiscussionModal, setEndLiveDiscussionModal] = useState(false);
    const [transferUserseModal, setTransferUsersModal] = useState(false);
    const [addAdSpotsModal, setAddAdSpotsModal] = useState(false);
    const [addInRoomRatingsModal, setAddInRoomRatingsModal] = useState(false);
    const [audioExtModel, setAudioExtModel] = useState(false);
    const [updateAdSpotUsersModal, setUpdateAdSpotUsersModal] = useState(false);

    const [selectedNav, setSelectedNav] = useState(navs.UPCOMING);

    const [historicalDataWithTimestamps, setHistoricalDataWithTimestamps] =
        useState([{ timestamp: 0, value: 0 }]);
    const [selectedDiscussion, setSelectedDiscussion] = useState('');

    useDiscussionsClubsHostsInternalHosts;
    const getSelectDiscussions = true;

    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const btnopen = () => {
        setShowModal(true);
    };
    const btnFilesAddModel = () => {
        setAddFilesModal(true);
    };
    const btnVideoFilesAddModel = () => {
        setAddVideoFilesModal(true);
    };
    const btnDocumentAddModel = () => {
        setAddDocumentModal(true);
    };

    const btnVideoAdsAddModel = () => {
        setAddVideoAdsModal(true);
    };

    const btnPollsAddModel = () => {
        setAddPollsModal(true);
    };

    const btnCertificateAddModel = () => {
        setAddCertificateModal(openAddCertificateModal === true ? false : true);
    };

    const btnTransferUsersModel = () => {
        setTransferUsersModal(transferUserseModal === true ? false : true);
    };

    const btnAddAdSpotsModel = () => {
        setAddAdSpotsModal(addAdSpotsModal === true ? false : true);
    };

    const btnAddInRoomRatingsModel = () => {
        setAddInRoomRatingsModal(addInRoomRatingsModal === true ? false : true);
    };

    const btnRecordingLiveNotifyModel = () => {
        setRecordingLiveNotifyModal(
            recordingLiveNotifyModal === true ? false : true,
        );
    };

    const btnEndLiveDiscussionModel = () => {
        setEndLiveDiscussionModal(
            endLiveDiscussionModal === true ? false : true,
        );
    };

    const btnAudioExtModel = () => {
        setAudioExtModel(prevState => !prevState);
    };

    const btnUpdateAdSpotUsersModel = () => {
        setUpdateAdSpotUsersModal(prevState => !prevState);
    };

    const selectNavsHandler = name => {
        setSelectedNav(name);
    };

    React.useEffect(() => {
        const fetchHistoricalData = async () => {
            try {
                const response = await axios.post(
                    `${globalURLS.baseURL}ADgetHistoricalDiscussionPeaks`,
                    { discussionid: selectedDiscussion },
                    globalURLS.config,
                );

                const data = response?.data?.data;

                setHistoricalDataWithTimestamps(data);
            } catch (error) {
                console.error('Error fetching historical data:', error);
            }
        };

        if (selectedDiscussion) fetchHistoricalData();
    }, [selectedDiscussion]);

    const buttons = [
        {
            label: 'Discussion',
            icon: AiOutlinePlus,
            onClick: btnopen,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'PDF Files',
            icon: AiOutlinePlus,
            onClick: btnFilesAddModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'In Room Video',
            icon: AiOutlinePlus,
            onClick: btnVideoFilesAddModel,
            iconSize: 10,
            style: buttonStyle,
        },
        {
            label: 'Document',
            icon: AiOutlinePlus,
            onClick: btnDocumentAddModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'Join Videos',
            icon: AiOutlinePlus,
            onClick: btnVideoAdsAddModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'Polls',
            icon: AiOutlinePlus,
            onClick: btnPollsAddModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'Certificate',
            icon: AiOutlinePlus,
            onClick: btnCertificateAddModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'Transfer',
            icon: AiOutlinePlus,
            onClick: btnTransferUsersModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'Notify',
            icon: AiOutlinePlus,
            onClick: btnRecordingLiveNotifyModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'End Live',
            icon: AiOutlinePlus,
            onClick: btnEndLiveDiscussionModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'Ad Spots',
            icon: AiOutlinePlus,
            onClick: btnAddAdSpotsModel,
            iconSize: 14,
            style: buttonStyle,
        },
        {
            label: 'In Room Rating',
            icon: AiOutlinePlus,
            onClick: btnAddInRoomRatingsModel,
            iconSize: 10,
            style: buttonStyle,
        },
        {
            label: 'In Room Audio',
            icon: AiOutlinePlus,
            onClick: btnAudioExtModel,
            iconSize: 10,
            style: buttonStyle,
        },
        {
            label: 'Hide Ad Spot',
            icon: AiOutlinePlus,
            onClick: btnUpdateAdSpotUsersModel,
            iconSize: 10,
            style: buttonStyle,
        },
    ];

    const DiscussionButtons = ({ buttons }) => (
        <Col style={{ display: 'flex', flexFlow: 'wrap' }}>
            {buttons.map((button, index) => (
                <Button
                    key={index}
                    className="btn-add"
                    variant="primary"
                    style={button.style}
                    onClick={button.onClick}
                >
                    {button.icon && <button.icon fontSize={button.iconSize} />}
                    {button.label}
                </Button>
            ))}
        </Col>
    );

    const HistoricalPeakSection = () => {
        return (
            <div>
                <div
                    style={{ width: '50vw', marginBottom: '50px' }}
                    className=" p-3 border rounded"
                >
                    <h3 style={{ width: '70vw' }}>Historical Peak</h3>
                    <div>
                        <Select
                            className="w-100"
                            closeMenuOnSelect={true}
                            onChange={val => setSelectedDiscussion(val.value)}
                            isSearchable
                            options={selectDiscussions || []}
                        />
                    </div>
                    <HistoricalChart
                        historicalData={historicalDataWithTimestamps}
                    />
                </div>
            </div>
        );
    };
    return (
        <Container className="discussion-container">
            <Col>
                <Row>
                    <div className="discussion-title">
                        <PageTitle>Discussion</PageTitle>
                        <DiscussionButtons buttons={buttons} />
                    </div>

                    <HistoricalPeakSection />
                    <Nav
                        fill
                        variant="tabs"
                        defaultActiveKey="/upcomingdiscussion"
                    >
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => selectNavsHandler(navs.UPCOMING)}
                                eventKey="upcoming"
                            >
                                Upcoming
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => selectNavsHandler(navs.PAST)}
                                eventKey="past"
                            >
                                Past
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => selectNavsHandler(navs.FILES)}
                                eventKey="file"
                            >
                                Files
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => selectNavsHandler(navs.VIDEOADS)}
                                eventKey="videoads"
                            >
                                Join Videos
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {/* Upcoming Discussions */}
                    {selectedNav === navs.UPCOMING && <UpcomingDiscussion />}
                    {/* <UpcomingDiscussion /> */}
                    {/* Past Discussions */}
                    {selectedNav === navs.PAST && <PastDiscussion />}
                    {selectedNav === navs.FILES && <DiscussionFiles />}
                    {selectedNav === navs.VIDEOADS && <VideoAds />}
                    {/* <AddDiscussionPoll /> */}
                    {/* Add Discussion Modal */}
                    <DiscussionContext.AddDiscussionContext.Provider
                        value={{ showModal, setShowModal }}
                    >
                        <AddDiscussion />
                    </DiscussionContext.AddDiscussionContext.Provider>
                    {/* Add Discussion Files */}
                    <DiscussionContext.AddDiscussionFilesContext.Provider
                        value={{ openAddFilesModal, setAddFilesModal }}
                    >
                        <AddFiles />
                    </DiscussionContext.AddDiscussionFilesContext.Provider>

                    {/* Add Discussion Video Files */}
                    <DiscussionContext.AddVideoExContext.Provider
                        value={{
                            openAddVideoFilesModal,
                            setAddVideoFilesModal,
                        }}
                    >
                        <AddVideoExt />
                    </DiscussionContext.AddVideoExContext.Provider>
                    {/* Add Discussion Documents */}
                    <DiscussionContext.AddDiscussionDocumentContext.Provider
                        value={{ openAddDocumentModal, setAddDocumentModal }}
                    >
                        <AddDocument />
                    </DiscussionContext.AddDiscussionDocumentContext.Provider>
                    {/* Add Video Ads */}
                    <DiscussionContext.AddVideoAdsContext.Provider
                        value={{ openAddVideoAdsModal, setAddVideoAdsModal }}
                    >
                        <AddVideoAds />
                    </DiscussionContext.AddVideoAdsContext.Provider>
                    {/* Update Video Ads
                    <DiscussionContext.UpdateVideoAdsContext.Provider
                        value={{
                            openUpdateVideoAdsModal,
                            setUpdateVideoAdsModal,
                        }}
                    >
                        <UpdateVideoAds />
                    </DiscussionContext.UpdateVideoAdsContext.Provider> */}
                    {/* Add Polls */}
                    <DiscussionContext.AddPollsContext.Provider
                        value={{ openAddPollsModal, setAddPollsModal }}
                    >
                        <AddDiscussionPoll />
                    </DiscussionContext.AddPollsContext.Provider>
                    <CertificateModal
                        show={openAddCertificateModal}
                        onHide={btnCertificateAddModel}
                    />

                    <TransferUsersModal
                        show={transferUserseModal}
                        onHide={btnTransferUsersModel}
                    />

                    <RecordingLiveNotify
                        show={recordingLiveNotifyModal}
                        onHide={btnRecordingLiveNotifyModel}
                    />

                    <EndLive
                        show={endLiveDiscussionModal}
                        onHide={btnEndLiveDiscussionModel}
                    />
                    <AddAdSpots
                        show={addAdSpotsModal}
                        onHide={btnAddAdSpotsModel}
                    />

                    <AddInRoomRating
                        show={addInRoomRatingsModal}
                        onHide={btnAddInRoomRatingsModel}
                    />

                    <ManageAdSpot
                        show={updateAdSpotUsersModal}
                        onHide={btnUpdateAdSpotUsersModel}
                    />

                    <ManageAudioExt
                        show={audioExtModel}
                        onHide={btnAudioExtModel}
                    />
                </Row>
            </Col>
            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={() => setShowSuccessToast(false)}
                    headerTitle="Success!"
                />
            )}
            {showErrorToast && (
                <FailureToast
                    showModal={showErrorToast}
                    handleClose={() => setShowErrorToast(false)}
                    headerTitle="Something went wrong, Please try again"
                />
            )}
        </Container>
    );
}

export default Discussion;
