import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable/dist/react-select-creatable.esm.js';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls.js';
import { useFeedContext } from '../../../Context/index.tsx';

function NewsFeedsTags() {
    const { step, feedTags, setFeedTags } = useFeedContext();
    const [isLoading, setIsLoading] = useState(false);
    const [allFeedTags, setAllFeedTags] = useState([]);
    const [value, setValue] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const AllFeedTagsData = await axios.get(
                    globalURLS.allFeedTagsJSONURL,
                );

                if (AllFeedTagsData?.data) {
                    setAllFeedTags(
                        AllFeedTagsData.data.map(el => ({
                            value: el.id,
                            label: el.title,
                        })),
                    );
                }
            } catch (e) {
                console.log('Error', e);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (value) setFeedTags(value);
    }, [value]);

    const handleCreate = async inputValue => {
        setIsLoading(true);
        const respData = await axios.post(
            `${globalURLS.baseURL}ADFeedTag`,
            { title: inputValue },
            globalURLS.config,
        );

        if (respData?.data?.success === true) {
            const newOption = {
                value: respData?.data?.data?.id,
                label: respData?.data?.data?.title,
            };
            setIsLoading(false);
            setAllFeedTags(prev => [...prev, newOption]);
            setValue(newOption);
        }
    };

    return (
        <>
            <CreatableSelect
                isClearable
                isDisabled={step === 3 ? true : isLoading}
                isLoading={isLoading}
                onChange={newValue => setValue(newValue)}
                onCreateOption={handleCreate}
                options={allFeedTags}
                value={step === 3 ? feedTags : value}
                isMulti
            />
        </>
    );
}

export default NewsFeedsTags;
