import { Button, ToastContainer, Toast } from 'react-bootstrap';
import { useReportContext } from '../../../Context/index';
import React from 'react';
import ReactJson from 'react-json-view';

function ReportDataModal() {
    const {
        toastShow,
        setToastShow,
        errorToastShow,
        selectedReport,
        setErrorToastShow,
        axiosRespData,
    } = useReportContext();

    return (
        <>
            {toastShow && selectedReport.isDownloadable === true && (
                <ToastContainer
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '15em',
                    }}
                    position={'middle-center'}
                >
                    <Toast onClose={() => setToastShow(false)} show={toastShow}>
                        <Toast.Header>
                            <strong
                                style={{
                                    fontSize: '1em',
                                    padding: '0.5em',
                                    color: 'green',
                                }}
                            >
                                Report is ready to download
                            </strong>
                        </Toast.Header>
                        <Toast.Body>
                            {axiosRespData.url && (
                                <Button
                                    href={axiosRespData.url}
                                    variant="success"
                                    target="_blank"
                                    onClick={() => setToastShow(false)}
                                >
                                    Download
                                </Button>
                            )}
                            {axiosRespData.url === undefined &&
                                Object.entries(axiosRespData).map(
                                    ([name, url]) => (
                                        <>
                                            <Button
                                                href={url}
                                                variant="success"
                                                style={{
                                                    margin: '1rem',
                                                }}
                                            >
                                                {`${name}\n Download`}
                                            </Button>
                                        </>
                                    ),
                                )}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            )}

            {toastShow && selectedReport.isDownloadable === false && (
                <ToastContainer
                    style={{
                        width: '80vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '2em',
                    }}
                    position={'top-start'}
                >
                    <Toast onClose={() => setToastShow(false)} show={toastShow}>
                        <Toast.Header
                            style={{
                                width: '50vw',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <strong
                                style={{
                                    fontSize: '1em',
                                    padding: '0.5em',
                                    color: 'green',
                                }}
                            >
                                Here is your data!
                            </strong>
                        </Toast.Header>
                        <Toast.Body
                            style={{
                                width: '50vw',
                            }}
                        >
                            <ReactJson
                                src={axiosRespData}
                                theme="monokai"
                                displayObjectSize={false}
                                displayDataTypes={false}
                            />
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            )}

            {errorToastShow && (
                <ToastContainer className="p-3" position={'middle-center'}>
                    <Toast
                        onClose={() => setErrorToastShow(false)}
                        show={errorToastShow}
                    >
                        <Toast.Header>
                            <strong
                                style={{
                                    fontSize: '1em',
                                    padding: '0.5em',
                                    color: 'red',
                                }}
                            >
                                Error
                            </strong>
                        </Toast.Header>
                        <Toast.Body>
                            {selectedReport?.error
                                ? selectedReport?.error
                                : `Unexpected error, please try again.`}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            )}
        </>
    );
}

export default ReportDataModal;
