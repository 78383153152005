import React from 'react';
import { Container, Col } from 'react-bootstrap';

import PageTitle from '../../Components/PageTitle/PageTitle.js';
import CustomButton from '../../Components/Common/CustomButton/CustomButton';
import NavTabs from '../../Components/Common/NavTabs/NavTabsWithTables/NavTabsWithTables';
import { navTabsData } from '../../Components/Common/Table/TableData';
import { useBannerContext } from '../../Context/index';
import './Banner.css';
import BannerAddModal from '../../Components/Banner/BannerAddModal';

const Banner: React.FC = () => {
    const { setAddBannerModal } = useBannerContext();

    const bannerNavTabsData = navTabsData.filter(
        navTab => navTab.page === 'banner',
    );
    return (
        <Container className="banner-container">
            <Col>
                <Col className="banner-title">
                    <PageTitle>Banner</PageTitle>
                    <CustomButton onClick={() => setAddBannerModal(true)}>
                        + Banner
                    </CustomButton>
                </Col>
                <NavTabs
                    defaultActiveKey={'Mobile Banners'}
                    data={bannerNavTabsData}
                />
                <BannerAddModal />
            </Col>
        </Container>
    );
};

export default Banner;
