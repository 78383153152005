import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, utcToZonedTime } from 'date-fns-tz';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';

function Quiz() {
    const [quizData, setQuizData] = useState([]);
    const [columnDefs] = useState([
        { field: 'quizid', sortable: true, resizable: true },
        { field: 'header', sortable: true, resizable: true },
        { field: 'title', sortable: true, resizable: true },
        { field: 'summary', sortable: true, resizable: true },
        { field: 'viewCount', sortable: true, resizable: true },
        { field: 'deadline', sortable: true, resizable: true },
        { field: 'publishTs', sortable: true, resizable: true },
    ]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                `${globalURLS.baseURL}ADQuiz`,
                globalURLS.config,
            );
            setQuizData(response.data.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (quizData && quizData.length > 0) {
            // console.log("quizData", quizData)
            const finalquizData = quizData.map(el => {
                const quiz = {};
                const datePublishTs = new Date(el.publishTs);
                const ISTDateTimedatePublishTs = utcToZonedTime(
                    datePublishTs,
                    'Asia/Calcutta',
                );
                const finalISTDateTimedatePublishTs = format(
                    ISTDateTimedatePublishTs,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );

                const dateDeadline = new Date(el.deadline);
                const ISTDateTimeDeadline = utcToZonedTime(
                    dateDeadline,
                    'Asia/Calcutta',
                );
                const finalISTDateTimeDeadline = format(
                    ISTDateTimeDeadline,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                (quiz.quizid = el.id),
                    (quiz.header = el.header),
                    (quiz.title = el.title),
                    (quiz.summary = el.summary),
                    (quiz.deadline = finalISTDateTimeDeadline),
                    (quiz.publishTs = finalISTDateTimedatePublishTs),
                    (quiz.likeCount = el.likeCount),
                    (quiz.viewCount = el.viewCount);
                return quiz;
            });

            // console.log("finalquizData", finalquizData)
            setRowData(finalquizData ? finalquizData : []);
        }
    }, [quizData]);

    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Quiz'}
            ></Table>
        </>
    );
}

export default Quiz;
