import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Button, Modal, Form, Image, Col, Row, Stack } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.cjs';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import { useGlobalContext } from '../../../Context';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import { BiEdit } from 'react-icons/bi';

interface Option {
    rank: number;
    text: string;
    iscorrect?: number;
    isEliminateOption?: number;
}

interface Slide {
    rank: number;
    type: 'question' | 'image' | 'roundscorecard' | 'award' | 'scorecard_other';
    subType?: 'purplecap' | 'orangecap';
    images: string[];
    pdfurl?: string;
    question_text?: string;
    questionNumber?: number;
    maxseconds?: number;
    correctpts?: number;
    inncorrectpts?: number;
    options?: Option[];
}

interface Round {
    rank: number;
    title: string;
    slides: Slide[];
}

interface Quiz {
    discussionid: string;
    discussionName?: string;
    masteraccountid?: string;
    masterAccountName?: string;
    scoreCard?: string;
    type: 1 | 2;
    hideResponseCount: boolean;
    disableLifelines: string[];
    participantids: string[];
    rounds: Round[];
    doctorsonly: boolean;
}

const initialQuizValues: Quiz = {
    discussionid: '',
    masteraccountid: '',
    masterAccountName: '',
    hideResponseCount: true,
    doctorsonly: false,
    type: 1,
    scoreCard: null,
    participantids: [''],
    disableLifelines: [],
    rounds: [
        {
            rank: 1,
            title: '',
            slides: [
                {
                    rank: 1,
                    type: 'question',
                    images: [],
                    pdfurl: '',
                    question_text: '',
                    questionNumber: 0,
                    maxseconds: 30,
                    correctpts: 4,
                    inncorrectpts: 1,
                    options: [],
                },
            ],
        },
    ],
};

interface cellData {
    isUpdate?: boolean;
    discussionid?: string;
    isDraft?: boolean;
}

const animatedComponents = makeAnimated();

const AddQuiz: React.FC<{
    show: boolean;
    onClose: () => void;
    cellData?: cellData;
}> = ({
    show,
    onClose,
    cellData = { isUpdate: false, discussionid: '', isDraft: false },
}) => {
    const [discussion, setDiscussion] = useState<Quiz>(initialQuizValues);
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();
    const [errors, setErrors] = useState<any>({});

    const { isUpdate, discussionid, isDraft } = cellData;
    const [editDisable, setEditDisable] = useState(false);

    const getSelectDiscussions = true;
    const getSelectSpeakers = true;

    const { selectDiscussions, selectSpeakers } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectDiscussions,
            getSelectSpeakers,
        });
    const handleInputChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;
        const checkBoxForLifeLines = ['mindreader', 'magicwand'];
        if (checkBoxForLifeLines.includes(name)) {
            const copyOfDiscussion = { ...discussion };

            if (discussion.disableLifelines.includes(name)) {
                const indexOfLifeLines =
                    copyOfDiscussion.disableLifelines.indexOf(name);
                copyOfDiscussion.disableLifelines.splice(indexOfLifeLines, 1);
                setDiscussion(copyOfDiscussion);
            } else {
                setDiscussion(prevDiscussion => ({
                    ...prevDiscussion,
                    disableLifelines: [
                        ...prevDiscussion.disableLifelines,
                        name,
                    ],
                }));
            }
        } else if (name === 'hide_response_count') {
            setDiscussion(prevDiscussion => ({
                ...prevDiscussion,
                hideResponseCount: !prevDiscussion?.hideResponseCount,
            }));
        } else if (name === 'doctorsonly') {
            setDiscussion(prevDiscussion => ({
                ...prevDiscussion,
                doctorsonly: !prevDiscussion?.doctorsonly,
            }));
        } else {
            setDiscussion(prevDiscussion => ({
                ...prevDiscussion,
                [name]: value,
            }));
        }
    };

    const handleParticipantIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        index: number,
    ) => {
        const { value } = event.target;
        const updatedParticipantIds = [...discussion.participantids];
        updatedParticipantIds[index] = value;
        setDiscussion(prevDiscussion => ({
            ...prevDiscussion,
            participantids: updatedParticipantIds,
        }));
    };

    const editEnable = () => setEditDisable(editDisable ? false : true);

    useEffect(() => {
        const getDataFunc = async () => {
            try {
                setEditDisable(true);

                const inputUpdateData = {
                    discussionid: discussionid,
                    jSONFile: false,
                };

                const inputDraftData = {
                    discussionid: discussionid,
                    jSONFile: false,
                    draft: true,
                };

                let finalInputData = {};
                if (isUpdate) finalInputData = inputUpdateData;
                if (isDraft) finalInputData = inputDraftData;

                const res = await axios.post(
                    `${globalURLS.baseURL}ADDgetQuizJSON`,
                    finalInputData,
                    globalURLS.config,
                );

                if (res?.data?.success === true) {
                    isDraft
                        ? setDiscussion(res?.data?.data?.data)
                        : setDiscussion({
                              ...res?.data?.data,
                              hideResponseCount:
                                  !!res?.data?.data?.hideResponseCount,
                          });
                } else {
                    setShowErrorToast(true);
                }
            } catch (error) {
                console.log('--Err', error);
                setShowErrorToast(true);
            }
        };

        if (
            (cellData?.discussionid && cellData?.isUpdate === true) ||
            cellData.isDraft === true
        ) {
            getDataFunc();
        }
    }, [cellData?.discussionid]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            // Calculate questnumber and rank for each slide
            const updatedRounds = discussion.rounds.map(round => {
                let questionCounter = 1; // Move this line outside the inner map function
                const updatedSlides = round.slides.map((slide, slideIndex) => {
                    if (slide.type === 'question') {
                        slide.questionNumber = questionCounter; // Assign the current value of questionCounter to slide.questionNumber
                        questionCounter++; // Increment questionCounter
                    } else {
                        slide.questionNumber = null; // For non-question slides, set questionNumber to null
                    }

                    return {
                        ...slide,
                        options: slide.options.map((option, optionIndex) => ({
                            ...option,
                            rank: optionIndex + 1,
                        })),
                    };
                });

                return {
                    ...round,
                    slides: updatedSlides,
                };
            });

            // Update formData with the computed values
            setDiscussion({
                ...discussion,
                rounds: updatedRounds,
            });

            // console.log('discussionSubmit', discussion);

            const res = await axios.post(
                `${globalURLS.baseURL}ADaddNewQuiz`,
                discussion,
                globalURLS.config,
            );

            if (res?.data?.success === true) {
                setDiscussion(initialQuizValues);
                onClose();
                setShowSuccessToast(true);
            } else {
                setShowErrorToast(true);
            }
        } catch (e) {
            setShowErrorToast(true);
        }
    };

    const handleDraftSubmit = async (event: any) => {
        event.preventDefault();

        try {
            // Calculate questnumber and rank for each slide
            const updatedRounds = discussion.rounds.map(round => {
                let questionCounter = 1; // Move this line outside the inner map function
                const updatedSlides = round.slides.map((slide, slideIndex) => {
                    if (slide.type === 'question') {
                        slide.questionNumber = questionCounter; // Assign the current value of questionCounter to slide.questionNumber
                        questionCounter++; // Increment questionCounter
                    } else {
                        slide.questionNumber = null; // For non-question slides, set questionNumber to null
                    }

                    return {
                        ...slide,
                        options: slide.options.map((option, optionIndex) => ({
                            ...option,
                            rank: optionIndex + 1,
                        })),
                    };
                });

                return {
                    ...round,
                    slides: updatedSlides,
                };
            });

            // Update formData with the computed values
            setDiscussion({
                ...discussion,
                rounds: updatedRounds,
            });

            // console.log('discussionSubmit', discussion);

            const data = {
                data: discussion,
                discussionid: discussion?.discussionid,
            };

            let finalAPI = '';

            if (isDraft) {
                finalAPI = `ADUpdateQuizJSON`;
            } else {
                finalAPI = `ADaddQuizJSON`;
            }

            const res = await axios.post(
                `${globalURLS.baseURL}${finalAPI}`,
                data,
                globalURLS.config,
            );

            if (res?.data?.success === true) {
                setDiscussion(initialQuizValues);
                onClose();
                setShowSuccessToast(true);
            } else {
                setShowErrorToast(true);
            }
        } catch (e) {
            setShowErrorToast(true);
        }
    };

    const handleRoundChange = (
        roundIndex: number,
        field: string,
        value: string | number,
    ) => {
        const updatedDiscussion = { ...discussion };
        updatedDiscussion.rounds[roundIndex][field] = value;
        setDiscussion(updatedDiscussion);
    };

    const handleRoundSlidesChange = (
        roundIndex: number,
        slideIndex: number,
        field: string,
        value: string | number,
    ) => {
        const updatedDiscussion = { ...discussion };
        field === 'images'
            ? (updatedDiscussion.rounds[roundIndex].slides[slideIndex][field] =
                  [value])
            : (updatedDiscussion.rounds[roundIndex].slides[slideIndex][field] =
                  value);

        setDiscussion(updatedDiscussion);
    };

    const handleOptionChange = (
        roundIndex: number,
        slideIndex: number,
        optionIndex: number,
        field: string,
        value: string | number,
    ) => {
        const updatedDiscussion = { ...discussion };

        updatedDiscussion.rounds[roundIndex].slides[slideIndex].options[
            optionIndex
        ][field] = value;
        setDiscussion(updatedDiscussion);
    };

    const handleAddRound = () => {
        const updatedDiscussion = { ...discussion };
        updatedDiscussion.rounds.push({
            rank: updatedDiscussion.rounds.length + 1,
            title: '',
            slides: [],
        });
        setDiscussion(updatedDiscussion);
    };

    const handleRemoveRound = (roundIndex: number) => {
        const updatedDiscussion = { ...discussion };
        updatedDiscussion.rounds.splice(roundIndex, 1);
        setDiscussion(updatedDiscussion);
    };

    const handleAddSlide = (roundIndex: number, targetRank: number) => {
        const updatedDiscussion = { ...discussion };
        const slideType:
            | 'question'
            | 'roundscorecard'
            | 'quizscorecard'
            | 'image' = 'question';

        // Calculate the questionNumber for the new slide
        const slides = updatedDiscussion.rounds[roundIndex].slides;
        let newQuestionNumber = 1;

        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i];
            if (slide.type === 'question' && slide.rank < targetRank) {
                newQuestionNumber++;
            }
        }

        const newSlide: Slide = {
            rank: targetRank, // Set the rank to the clicked index
            type: slideType,
            images: [],
            pdfurl: '',
            question_text: '',
            questionNumber: newQuestionNumber,
            maxseconds: 0,
            correctpts: 0,
            inncorrectpts: 0,
            options: [],
            // Set your initial state values here
        };

        // Insert the new slide at the specified index (targetRank)
        slides.splice(targetRank, 0, newSlide);

        // Recalculate ranks
        slides.forEach((slide, index) => {
            slide.rank = index + 1;
        });

        setDiscussion(updatedDiscussion);
    };

    const handleRemoveSlide = (roundIndex: number, slideIndex: number) => {
        const updatedDiscussion = { ...discussion };
        updatedDiscussion.rounds[roundIndex].slides.splice(slideIndex, 1);
        setDiscussion(updatedDiscussion);
    };

    const handleAddOption = (roundIndex: number, slideIndex: number) => {
        const updatedDiscussion = { ...discussion };
        updatedDiscussion.rounds[roundIndex].slides[slideIndex].options.push({
            rank:
                updatedDiscussion.rounds[roundIndex].slides[slideIndex].options
                    .length + 1,
            text: '',
            iscorrect: 0,
        });
        setDiscussion(updatedDiscussion);
    };

    const handleRemoveOption = (
        roundIndex: number,
        slideIndex: number,
        optionIndex: number,
    ) => {
        const updatedDiscussion = { ...discussion };
        updatedDiscussion.rounds[roundIndex].slides[slideIndex].options.splice(
            optionIndex,
            1,
        );
        setDiscussion(updatedDiscussion);
    };

    // useEffect(() => {
    //     if (discussion?.disableLifelines?.includes('magicwand')) {
    //         const copyDiscussion = { ...discussion };

    //         copyDiscussion.rounds.map(round => {});
    //         setDiscussion();
    //     }
    // }, [discussion?.disableLifelines]);

    return (
        <Modal show={show} onHide={onClose} size="lg" className="p-5">
            <Modal.Header closeButton>
                <Modal.Title className="mr-2">
                    {' '}
                    {!isUpdate && !isDraft && 'Add Quiz'}
                    {isUpdate && 'Updates Quiz'}
                    {isDraft && 'Draft Quiz'}
                </Modal.Title>
                {isUpdate && <BiEdit onClick={editEnable} />}
                {isDraft && <BiEdit onClick={editEnable} />}
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="discussionid">
                        <Form.Label>Discussion *</Form.Label>
                        <Select
                            isDisabled={editDisable}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            value={{
                                value: discussion?.discussionid,
                                label: discussion?.discussionName,
                            }}
                            onChange={(val: any) =>
                                setDiscussion({
                                    ...discussion,
                                    discussionid: val.value,
                                    discussionName: val.label,
                                })
                            }
                            isSearchable
                            options={JSON.parse(
                                JSON.stringify(
                                    selectDiscussions ? selectDiscussions : [],
                                ),
                            )}
                        />
                    </Form.Group>
                    <Form.Group controlId="masteraccountid">
                        <Form.Label>Master Account *</Form.Label>

                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            onChange={(val: any) =>
                                setDiscussion({
                                    ...discussion,
                                    masteraccountid: val.value,
                                    masterAccountName: val.label,
                                })
                            }
                            value={{
                                value: discussion.masteraccountid,
                                label: discussion.masterAccountName,
                            }}
                            isSearchable
                            options={JSON.parse(
                                JSON.stringify(
                                    selectSpeakers ? selectSpeakers : [],
                                ),
                            )}
                            isDisabled={editDisable}
                        />
                    </Form.Group>
                    <Form.Group controlId="type">
                        <Form.Label>Type *</Form.Label>
                        <Form.Select
                            name="type"
                            required
                            value={discussion.type}
                            onChange={handleInputChange}
                            disabled={editDisable}
                        >
                            <option value={1}>Type 1</option>
                            <option value={2}>Type 2</option>
                        </Form.Select>
                    </Form.Group>

                    {/* <Form.Group controlId={`scoreCard`}>
                        <Form.Label>Score Card</Form.Label>
                        <Form.Control
                            type="text"
                            name={`scoreCard`}
                            value={discussion.scoreCard}
                            required={true}
                            onChange={handleInputChange}
                            disabled={editDisable}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please Enter Title
                        </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group controlId={`disabledLifelines`}>
                        <Form.Label>Enabled Lifelines</Form.Label>
                        <Row>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    id={`magicwand`}
                                    label="Magicwand"
                                    name="magicwand"
                                    checked={
                                        !discussion?.disableLifelines?.includes(
                                            'magicwand',
                                        )
                                    }
                                    onChange={handleInputChange}
                                    disabled={editDisable}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    id={`mindreader`}
                                    label="Mindreader"
                                    name="mindreader"
                                    checked={
                                        !discussion?.disableLifelines?.includes(
                                            'mindreader',
                                        )
                                    }
                                    onChange={handleInputChange}
                                    disabled={editDisable}
                                />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group controlId={`otherconfig`}>
                        <Form.Label>Configure</Form.Label>
                        <Row>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    id={`hide_response_count`}
                                    label="Hide Response Count"
                                    name="hide_response_count"
                                    checked={discussion?.hideResponseCount}
                                    onChange={handleInputChange}
                                    disabled={editDisable}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    id={`doctorsonly`}
                                    label="Doctors Only"
                                    name="doctorsonly"
                                    checked={!!discussion?.doctorsonly}
                                    onChange={handleInputChange}
                                    disabled={editDisable}
                                />
                            </Col>                            
                        </Row>
                    </Form.Group>

                    {discussion.type == '2' && (
                        <Form.Group controlId="participantids">
                            <Form.Label>Participant IDs</Form.Label>
                            {discussion?.participantids?.map(
                                (participantId, index) => (
                                    <Form.Control
                                        key={index}
                                        type="text"
                                        name={`participantids.${index}`}
                                        disabled={editDisable}
                                        value={participantId}
                                        onChange={event =>
                                            handleParticipantIdChange(
                                                event,
                                                index,
                                            )
                                        }
                                    />
                                ),
                            )}
                        </Form.Group>
                    )}

                    {discussion?.rounds?.map((round, roundIndex) => (
                        <div
                            key={roundIndex}
                            className="bg-light text-dark w-80 p-3 m-3 border border-primary rounded"
                        >
                            <h3>Round {round.rank}</h3>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleRemoveRound(roundIndex)}
                                className="mb-3"
                                disabled={editDisable}
                            >
                                Remove Round
                            </Button>
                            <Form.Group
                                controlId={`rounds.${roundIndex}.title`}
                            >
                                <Form.Label>Title *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`rounds.${roundIndex}.title`}
                                    value={round.title}
                                    required={true}
                                    onChange={event =>
                                        handleRoundChange(
                                            roundIndex,
                                            'title',
                                            event.target.value,
                                        )
                                    }
                                    disabled={editDisable}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Title
                                </Form.Control.Feedback>
                            </Form.Group>
                            {round.slides.map((slide, slideIndex) => (
                                <div key={slideIndex}>
                                    <hr />
                                    <h5>Slide {slide.rank}</h5>
                                    <Stack direction="horizontal" gap={3}>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() =>
                                                handleRemoveSlide(
                                                    roundIndex,
                                                    slideIndex,
                                                )
                                            }
                                            disabled={editDisable}
                                        >
                                            Remove Slide
                                        </Button>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            disabled={editDisable}
                                            onClick={() =>
                                                handleAddSlide(
                                                    roundIndex,
                                                    slideIndex + 1,
                                                )
                                            }
                                        >
                                            Add Slide
                                        </Button>
                                    </Stack>
                                    <Form.Group
                                        controlId={`rounds.${roundIndex}.slides.${slideIndex}.type`}
                                    >
                                        <Form.Label>Type</Form.Label>
                                        <Form.Select
                                            name={`rounds.${roundIndex}.slides.${slideIndex}.type`}
                                            value={slide.type}
                                            onChange={event =>
                                                handleRoundSlidesChange(
                                                    roundIndex,
                                                    slideIndex,
                                                    'type',
                                                    event.target.value,
                                                )
                                            }
                                            disabled={editDisable}
                                        >
                                            <option value="question">
                                                Question
                                            </option>
                                            <option value="image">Image</option>
                                            <option value="roundscorecard">
                                                Round Score Card
                                            </option>
                                            <option value="quizscorecard">
                                                Quiz Score Card
                                            </option>
                                            <option value="award">Award</option>
                                            <option value="scorecard_other">
                                                Combined Scorecard
                                            </option>

                                            <option value="cityscorecard">
                                                City Score Card
                                            </option>
                                            <option value="selfscorecard">
                                                Self Score Card
                                            </option>
                                        </Form.Select>
                                    </Form.Group>

                                    {/* sub Type */}
                                    {slide?.type === 'award' && (
                                        <Form.Group
                                            controlId={`rounds.${roundIndex}.slides.${slideIndex}.subType`}
                                        >
                                            <Form.Label>Sub Type</Form.Label>
                                            <Form.Select
                                                name={`rounds.${roundIndex}.slides.${slideIndex}.subType`}
                                                value={slide?.subType}
                                                onChange={event =>
                                                    handleRoundSlidesChange(
                                                        roundIndex,
                                                        slideIndex,
                                                        'subType',
                                                        event.target.value,
                                                    )
                                                }
                                                defaultValue={''}
                                                disabled={editDisable}
                                            >
                                                <option value="">
                                                    Please Select
                                                </option>
                                                <option value="purplecap">
                                                    Purple Cap
                                                </option>
                                                <option value="orangecap">
                                                    Orange Cap
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    )}

                                    {slide?.type === 'scorecard_other' && (
                                        <Form.Group
                                            controlId={`rounds.${roundIndex}.slides.${slideIndex}.subType`}
                                        >
                                            <Form.Label>Sub Type</Form.Label>
                                            <Form.Select
                                                name={`rounds.${roundIndex}.slides.${slideIndex}.subType`}
                                                value={slide?.subType}
                                                onChange={event =>
                                                    handleRoundSlidesChange(
                                                        roundIndex,
                                                        slideIndex,
                                                        'subType',
                                                        event.target.value,
                                                    )
                                                }
                                                defaultValue={''}
                                                disabled={editDisable}
                                            >
                                                <option value="">
                                                    Please Select
                                                </option>
                                                <option value="cmb1_purplecap">
                                                    Purple Cap
                                                </option>
                                                <option value="cmb1_orangecap">
                                                    Orange Cap
                                                </option>
                                                <option value="cmb1_champion">
                                                    Champions
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    )}

                                    {/* {slide?.type === 'roundscorecard' && (
                                        <Form.Group
                                            controlId={`rounds.${roundIndex}.slides.${slideIndex}.roundscorecard`}
                                        >
                                            <Form.Label>
                                                Round Score Card URL
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`rounds.${roundIndex}.slides.${slideIndex}.roundscorecard`}
                                                value={slide.roundscorecard}
                                                disabled={editDisable}
                                                onChange={event =>
                                                    handleRoundSlidesChange(
                                                        roundIndex,
                                                        slideIndex,
                                                        'roundscorecard',
                                                        event.target.value,
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    )} */}
                                    {slide?.type === 'image' && (
                                        <Form.Group
                                            controlId={`rounds.${roundIndex}.slides.${slideIndex}.images`}
                                        >
                                            <Form.Label>Image URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`rounds.${roundIndex}.slides.${slideIndex}.images`}
                                                value={slide.images[0]}
                                                disabled={editDisable}
                                                onChange={event =>
                                                    handleRoundSlidesChange(
                                                        roundIndex,
                                                        slideIndex,
                                                        'images',
                                                        event.target.value,
                                                    )
                                                }
                                            />
                                            {slide?.images[0] && (
                                                <Image
                                                    className="mt-1"
                                                    src={
                                                        slide.images[0]
                                                            ? slide.images[0]
                                                            : ''
                                                    }
                                                    fluid={true}
                                                    style={{
                                                        width: '70vw',
                                                        height: '45vh',
                                                    }}
                                                />
                                            )}
                                        </Form.Group>
                                    )}

                                    {slide?.type === 'question' && (
                                        <Form.Group
                                            controlId={`rounds.${roundIndex}.slides.${slideIndex}.images`}
                                        >
                                            <Form.Label>Image URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`rounds.${roundIndex}.slides.${slideIndex}.images`}
                                                value={slide.images[0]}
                                                disabled={editDisable}
                                                onChange={event =>
                                                    handleRoundSlidesChange(
                                                        roundIndex,
                                                        slideIndex,
                                                        'images',
                                                        event.target.value,
                                                    )
                                                }
                                            />
                                            {slide?.images[0] && (
                                                <Image
                                                    className="mt-1"
                                                    src={
                                                        slide.images[0]
                                                            ? slide.images[0]
                                                            : ''
                                                    }
                                                    fluid={true}
                                                    style={{
                                                        width: '70vw',
                                                        height: '45vh',
                                                    }}
                                                />
                                            )}
                                        </Form.Group>
                                    )}

                                    {slide?.type === 'question' && (
                                        <>
                                            {' '}
                                            <Form.Group
                                                controlId={`rounds.${roundIndex}.slides.${slideIndex}.question_text`}
                                            >
                                                <Form.Label>
                                                    Question Text
                                                </Form.Label>
                                                <Form.Control
                                                    type="textarea"
                                                    disabled={editDisable}
                                                    name={`rounds.${roundIndex}.slides.${slideIndex}.question_text`}
                                                    value={slide.question_text}
                                                    onChange={event =>
                                                        handleRoundSlidesChange(
                                                            roundIndex,
                                                            slideIndex,
                                                            'question_text',
                                                            event.target.value,
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Group
                                                        controlId={`rounds.${roundIndex}.slides.${slideIndex}.maxseconds`}
                                                    >
                                                        <Form.Label>
                                                            Max Seconds
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={`rounds.${roundIndex}.slides.${slideIndex}.maxseconds`}
                                                            value={
                                                                slide.maxseconds
                                                            }
                                                            disabled={
                                                                editDisable
                                                            }
                                                            defaultValue={30}
                                                            onChange={event =>
                                                                handleRoundSlidesChange(
                                                                    roundIndex,
                                                                    slideIndex,
                                                                    'maxseconds',
                                                                    event.target
                                                                        .value,
                                                                )
                                                            }
                                                            min="5"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4}>
                                                    <Form.Group
                                                        controlId={`rounds.${roundIndex}.slides.${slideIndex}.correctpts`}
                                                    >
                                                        <Form.Label>
                                                            Correct Points
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={`rounds.${roundIndex}.slides.${slideIndex}.correctpts`}
                                                            value={
                                                                slide.correctpts
                                                            }
                                                            disabled={
                                                                editDisable
                                                            }
                                                            onChange={event =>
                                                                handleRoundSlidesChange(
                                                                    roundIndex,
                                                                    slideIndex,
                                                                    'correctpts',
                                                                    event.target
                                                                        .value,
                                                                )
                                                            }
                                                            min="0"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4}>
                                                    <Form.Group
                                                        controlId={`rounds.${roundIndex}.slides.${slideIndex}.inncorrectpts`}
                                                    >
                                                        <Form.Label>
                                                            Incorrect Points
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={`rounds.${roundIndex}.slides.${slideIndex}.inncorrectpts`}
                                                            value={
                                                                slide.inncorrectpts
                                                            }
                                                            disabled={
                                                                editDisable
                                                            }
                                                            onChange={event =>
                                                                handleRoundSlidesChange(
                                                                    roundIndex,
                                                                    slideIndex,
                                                                    'inncorrectpts',
                                                                    event.target
                                                                        .value,
                                                                )
                                                            }
                                                            min="0"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                    {/* {slide.type === 'question' && (
                                        <Form.Group
                                            controlId={`rounds.${roundIndex}.slides.${slideIndex}.questionNumber`}
                                        >
                                            <Form.Label>
                                                Question Number
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={`rounds.${roundIndex}.slides.${slideIndex}.questionNumber`}
                                                value={slide.questionNumber}
                                                onChange={event =>
                                                    handleRoundSlidesChange(
                                                        roundIndex,
                                                        slideIndex,
                                                        'questionNumber',
                                                        event.target.value,
                                                    )
                                                }
                                                min="1"
                                            />
                                        </Form.Group>
                                    )} */}
                                    {/* Additional input fields for Slide */}
                                    {/* <hr /> */}
                                    {slide?.type === 'question' && (
                                        <>
                                            {' '}
                                            <h5 className="mt-4">Options</h5>
                                            {slide.options.map(
                                                (option, optionIndex) => (
                                                    <div key={optionIndex}>
                                                        <Row>
                                                            <Col xs={7}>
                                                                <Form.Group
                                                                    controlId={`rounds.${roundIndex}.slides.${slideIndex}.options.${optionIndex}.text`}
                                                                >
                                                                    <Form.Label>
                                                                        Text
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`rounds.${roundIndex}.slides.${slideIndex}.options.${optionIndex}.text`}
                                                                        value={
                                                                            option?.text
                                                                        }
                                                                        disabled={
                                                                            editDisable
                                                                        }
                                                                        onChange={event =>
                                                                            handleOptionChange(
                                                                                roundIndex,
                                                                                slideIndex,
                                                                                optionIndex,
                                                                                'text',
                                                                                event
                                                                                    .target
                                                                                    .value,
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col
                                                                className="mt-3"
                                                                xs={3}
                                                            >
                                                                <Form.Group
                                                                    controlId={`rounds.${roundIndex}.slides.${slideIndex}.options.${optionIndex}.iscorrect`}
                                                                >
                                                                    <Form.Label></Form.Label>
                                                                    <div>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            name={`rounds.${roundIndex}.slides.${slideIndex}.options.${optionIndex}.iscorrect`}
                                                                            label="Correct"
                                                                            checked={
                                                                                option?.iscorrect ===
                                                                                1
                                                                            }
                                                                            disabled={
                                                                                editDisable
                                                                            }
                                                                            onChange={event => {
                                                                                handleOptionChange(
                                                                                    roundIndex,
                                                                                    slideIndex,
                                                                                    optionIndex,
                                                                                    'iscorrect',
                                                                                    event
                                                                                        .target
                                                                                        .checked
                                                                                        ? 1
                                                                                        : 0,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    {!discussion?.disableLifelines?.includes(
                                                                        'magicwand',
                                                                    ) && (
                                                                        <div>
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                name={`rounds.${roundIndex}.slides.${slideIndex}.options.${optionIndex}.eliminate`}
                                                                                label="Eliminate"
                                                                                checked={
                                                                                    option?.isEliminateOption ===
                                                                                    1
                                                                                }
                                                                                disabled={
                                                                                    editDisable
                                                                                }
                                                                                onChange={event => {
                                                                                    handleOptionChange(
                                                                                        roundIndex,
                                                                                        slideIndex,
                                                                                        optionIndex,
                                                                                        'isEliminateOption',
                                                                                        event
                                                                                            .target
                                                                                            .checked
                                                                                            ? 1
                                                                                            : 0,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col
                                                                className="mt-4"
                                                                xs={2}
                                                            >
                                                                {' '}
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        handleRemoveOption(
                                                                            roundIndex,
                                                                            slideIndex,
                                                                            optionIndex,
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        editDisable
                                                                    }
                                                                    className="mb-3, m-2"
                                                                >
                                                                    -
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        {/* <hr /> */}
                                                    </div>
                                                ),
                                            )}
                                            <Button
                                                variant="success"
                                                size="sm"
                                                className="m-2"
                                                onClick={() =>
                                                    handleAddOption(
                                                        roundIndex,
                                                        slideIndex,
                                                    )
                                                }
                                                disabled={editDisable}
                                            >
                                                Add Option
                                            </Button>
                                        </>
                                    )}
                                </div>
                            ))}
                            <Button
                                variant="success"
                                size="sm"
                                className="m-2"
                                disabled={editDisable}
                                onClick={() =>
                                    handleAddSlide(
                                        roundIndex,
                                        round.slides.length + 1,
                                    )
                                }
                            >
                                Add Slide
                            </Button>
                        </div>
                    ))}
                    <Button
                        variant="success"
                        size="sm"
                        disabled={editDisable}
                        onClick={handleAddRound}
                    >
                        Add Round
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                {!isUpdate && (
                    <Button
                        variant="warning"
                        disabled={editDisable}
                        onClick={handleDraftSubmit}
                    >
                        Save As Draft
                    </Button>
                )}

                <Button
                    variant="primary"
                    disabled={editDisable}
                    type="submit"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddQuiz;
