import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, InputGroup, Pagination } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css'; // Include your CSS file
import { globalURLS } from '../../Global/urls.js';


const convertToCSV = (data: any[]) => {
  const headers = ['id', 'short_url', 'long_url', 'clickcount', 'createdon'];
  const csvRows = [
    headers.join(','),  // Add the headers row
    ...data.map(row =>
      [
        row.id,
        JSON.stringify(row.short_url),
        JSON.stringify(row.long_url),
        row.clickcount,
        String(new Date(row.createdon).toLocaleString()),
      ].join(',')  // Join values of each row by comma
    ),
  ];
  
  return csvRows.join('\n');  // Join all rows by newline character
};



interface Link {
  id: number;
  short_url: string;
  long_url: string;
  createdon: string;
  clickcount: number;
}

interface LinkStats {
  clickcount: number;
  stats: {
    title: string;
    data: { label: string; count: number }[];
  }[];
}

const Dashboard: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);
  const [filteredLinks, setFilteredLinks] = useState<Link[]>([]);
  const [fromDate, setFromDate] = useState<string>(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
  const [toDate, setToDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [selectedLink, setSelectedLink] = useState<Link | null>(null);
  const [linkStats, setLinkStats] = useState<LinkStats | null>(null);
  const [editLongUrl, setEditLongUrl] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [statsloading, setstatsLoading] = useState<boolean>(false);
  const itemsPerPage = 50;

  useEffect(() => {
    fetchLinks();
  }, []);
  
  const fetchLinks = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${globalURLS.baseURL3}ad_get_links`, { startdate: fromDate, enddate: toDate });
      if (response.data?.success && response.data?.data?.length) {
        setLinks(response.data.data);
        setFilteredLinks(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching links:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLinkStats = async (linkId: number) => {
    setstatsLoading(true);
    try {
      const response = await axios.post(`${globalURLS.baseURL3}ad_get_linkstats`, { link_id: linkId });
      if (response.data?.success && response.data?.data) {
        setLinkStats(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching link stats:', error);
    } finally {
      setstatsLoading(false);
    }
  };

  const updateLink = async (linkId: number, longUrl: string) => {
    try {
      const response = await axios.post(`${globalURLS.baseURL3}ad_update_link`, { link_id: linkId, long_url: longUrl });
      if (response.data?.success) fetchLinks();
    } catch (error) {
      console.error('Error updating link:', error);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = links.filter(link =>
      link.short_url.toLowerCase().includes(value) || link.long_url.toLowerCase().includes(value)
    );
    setFilteredLinks(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const downloadCSV = (data: any[]) => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'links_data.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  

  const paginatedLinks = filteredLinks.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="lz_dashboard">
      {loading ? (
        <div className="lz_loader">Loading...</div> // Simple text loader, replace with spinner if needed
      ) : (
        <>
          <div className="lz_filters">
            <div>
              <InputGroup>
                <Form.Control type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                <Form.Control type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                <Button className="lz_btn" onClick={fetchLinks}>Get Links</Button> {/* New "Get Links" button */}
              </InputGroup>
            </div>
            <div style={{display:'flex', flexDirection: 'row'}}>
              <Form.Control type="text" placeholder="Filter Results by URL" value={searchTerm} onChange={handleSearch} />
              <Button style={{width: '100%', marginLeft: 20}} onClick={() => downloadCSV(links)}>Download Results</Button>
            </div>
          </div>

          <Table striped bordered hover className="table">
            <thead>
              <tr>
                <th style={{ width: '8%' }}>ID</th>
                <th style={{ width: '15%' }}>Short URL</th>
                <th className="long-url-column">Long URL</th>
                <th style={{ width: '15%' }}>Created On</th>
                <th style={{ width: '5%' }}>Click Count</th>
                <th style={{ width: '10%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedLinks.map(link => (
                <tr key={link.id}>
                  <td>{link.id}</td>
                  <td>{link.short_url}</td>
                  <td className="long-url-column">
                    {link.long_url}{' '}
                    <Button variant="link" onClick={() => { setSelectedLink(link); setEditLongUrl(link.long_url); }}>
                      ✏️
                    </Button>
                  </td>
                  <td>{new Date(link.createdon).toLocaleString()}</td>
                  <td>{link.clickcount}</td>
                  <td>
                    <Button onClick={() => { setSelectedLink(link); fetchLinkStats(link.id); }}>
                      View Stats
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="pagination-container">
            <Pagination>
              {Array.from({ length: Math.ceil(filteredLinks.length / itemsPerPage) }, (_, i) => (
                  <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
                    {i + 1}
                  </Pagination.Item>
                ))}
            </Pagination>
          </div>

          {/* View Stats Modal */}
          <Modal show={!!selectedLink && !!linkStats} onHide={() => setSelectedLink(null)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedLink?.short_url} - Total Clicks: {linkStats?.clickcount}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Long URL: {selectedLink?.long_url}</p>
              {statsloading && <div className="lz_loader">Loading...</div>}
              {!statsloading && linkStats?.stats.map(stat => (
                <div key={stat.title}>
                  <h5>{stat.title}</h5>
                  <Line
                    data={{
                      labels: stat.data.map(d => d.label),
                      datasets: [{
                        label: stat.title,
                        data: stat.data.map(d => d.count),
                        borderColor: 'rgba(75,192,192,1)',
                        fill: false,
                      }]
                    }}
                  />
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setSelectedLink(null)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Edit Long URL Modal */}
          <Modal show={!!selectedLink && !linkStats} onHide={() => setSelectedLink(null)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Long URL for {selectedLink?.short_url}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                value={editLongUrl}
                onChange={(e) => setEditLongUrl(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setSelectedLink(null)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (selectedLink) {
                    updateLink(selectedLink.id, editLongUrl);
                    setSelectedLink(null);
                  }
                }}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>        
        </>
      )}
    </div>
  );
};

export default Dashboard;
