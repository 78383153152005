/* eslint-disable react/prop-types */
import React from 'react';
import './PageTitle.css';

function PageTitle({ children }) {
    return (
        <div className="page-title">
            <h5>Page</h5>
            <h1>{children}</h1>
        </div>
    );
}

export default PageTitle;
