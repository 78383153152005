/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import MarkdownIt from 'markdown-it';
import { htmlToText } from 'html-to-text';

import {
    Form as BootstrapForm,
    FormGroup,
    FormLabel,
    FormControl,
    Button,
    Col,
    Row,
    Container,
    Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';
import Select from 'react-select';

import Options from '../../Data/pmdFeed.json';
import { usePmdContext } from '../../Context/index.tsx';

const convertMarkdownToPlainText = async markdown => {
    const markdownIt = new MarkdownIt();
    const html = markdownIt.render(markdown);

    return htmlToText(html, {
        wordwrap: 180, // Adjust this value to set the desired width for text wrapping
    });
};

const fetchNewsData = async () => {
    try {
        const newsDataResp = await axios.get(
            globalURLS.past60DaysMedflixNewsJSONURL,
        );

        if (newsDataResp?.data) {
            return newsDataResp?.data;
        }
    } catch (error) {
        console.log('--err', error);
    }
};

const MySelect = ({ field, form, options, placeholder }) => {
    const handleChange = async selectedOptions => {
        const values = [];
        const newsData = await fetchNewsData();

        if (Array.isArray(selectedOptions)) {
            selectedOptions &&
                selectedOptions.map(opt => values.push(opt.value));

            form.setFieldValue(field.name, values);
        }
        form.setFieldValue(field.name, values);

        if (!Array.isArray(selectedOptions)) {
            const [selectedNews] = newsData.filter(
                el => el.id === selectedOptions.value,
            );

            const finalTransformedDescription =
                await convertMarkdownToPlainText(selectedNews?.summary || '');

            form.setFieldValue('title', selectedNews?.title || '');
            form.setFieldValue('description', finalTransformedDescription);
            form.setFieldValue('imageUrls', [selectedNews?.src || '']);
        }
    };

    const handleBlur = () => {
        form.setFieldTouched(field.name, true);
    };

    return (
        <Select
            id={field.name}
            name={field.name}
            value={options.find(option => option.value === field.value)}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            isMulti={field.name === 'medflixNews' ? false : true}
            placeholder={placeholder}
        />
    );
};

// eslint-disable-next-line react/prop-types
const AddPmdFeed = ({ showForm, closeForm }) => {
    const { updateStory, setUpdateStory, updateStoryModal } = usePmdContext();
    const [arrayNewsSelect, setArrayNewsSelect] = useState([]);
    const [options, setOptions] = useState({
        specialty: [],
        genre: [],
        partner: [],
    });

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        imageUrls: Yup.array()
            .of(
                Yup.string()
                    .url('Invalid URL')
                    .required('Image URL is required'),
            )
            .min(1, 'Please add at least one Image URL'),
        // pulsecheck: Yup.object().shape({
        //     question: Yup.string().optional('Please add pulse check question'),
        //     options: Yup.array()
        //         .min(2, 'Please add both the options')
        //         .max(2)
        //         .required('Both the options are required'),
        // }),
        publisher: Yup.string().required('Publisher Name is required'),
        newspublishdate: Yup.date().required('Publish Date is required'),
        specialty: Yup.array().min(1, 'At least one specialty is required'),
        genre: Yup.array().min(1, 'At least one genre is required'),
        partner: Yup.array().min(1, 'At least one parnter is required'),
        // importance: Yup.number()
        //     .min(0, 'Importance must be between 0 and 5')
        //     .max(5, 'Importance must be between 0 and 5')
        //     .required('Importance is required'),
    });

    const getNames = type => {
        if (type == 'specialty') {
            const names = updateStory?.specialties
                ?.map(s => s.specialtyName)
                .join(', ');
            return names;
        } else if (type == 'genre') {
            const names = updateStory?.genres?.map(g => g.genreName).join(', ');
            return names;
        } else if (type == 'partner') {
            const names = updateStory?.partners?.map(p => p.name).join(', ');
            return names;
        } else if (type == 'image') {
            const names = updateStory?.images?.map(i => i.fullImageUrl);
            return names;
        }
    };

    const currentDate = new Date().toISOString().split('T')[0];

    const getIds = type => {
        // console.log('Update: ', updateStory);
        if (type == 'specialty') {
            const ids = updateStory?.specialties?.map(s => s.specialtyId);
            return ids;
        } else if (type == 'genre') {
            const ids = updateStory?.genres?.map(g => g.genreId);
            return ids;
        } else if (type == 'partner') {
            const ids = updateStory?.partners?.map(p => p.partnerid);
            return ids;
        }
    };

    const getDate = () => {
        const newDate = new Date(updateStory?.newspublishdate);
        const finalDate = newDate.toISOString().slice(0, 10);
        return finalDate;
    };

    const convertToIST = utcDateString => {
        const utcDate = new Date(utcDateString);
        const istDate = new Date(utcDate.getTime()); // Convert offset to milliseconds
        return istDate.toISOString().slice(0, 16); // Return IST date string with minute precision
    };

    const convertToIST2 = utcDateString => {
        const utcDate = new Date(utcDateString);
        const istDate = new Date(utcDate.getTime() + 660 * 60000); // Convert offset to milliseconds
        return istDate.toISOString().slice(0, 16); // Return IST date string with minute precision
    };

    const initialValues = {
        specialty:
            updateStory && updateStory.specialties ? getIds('specialty') : [],
        genre: updateStory && updateStory.genres ? getIds('genre') : [],
        partner: updateStory && updateStory.partners ? getIds('partner') : [],
        imageUrls: updateStory ? getNames('image') : [''],
        importance: updateStory ? updateStory.partners[0].importance : '',
        isbn: updateStory && updateStory.partners[0].isbn === 1 ? true : false,
        title: updateStory?.title || '',
        description: updateStory ? updateStory.description : '',
        publisher: updateStory ? updateStory.publisher : '',
        isScheduled: updateStory ? updateStory.isScheduled : false,
        scheduleDate:
            updateStory && updateStory?.scheduledDate
                ? convertToIST2(updateStory?.scheduledDate.slice(0, 16))
                : '',
        newspublishdate: updateStory ? getDate() : '',
        previewtitle: updateStory ? updateStory?.previewdata?.title : '',
        previewshortdescription: updateStory
            ? updateStory?.previewdata?.description
            : '',
        previewurl: updateStory ? updateStory?.previewdata?.url : '',
        previewimageurl: updateStory ? updateStory?.previewdata?.image : '',
        pulsecheck: updateStory ? updateStory?.pulsecheck : {},
    };
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        setFormValues(formValues);
    }, [updateStory]);

    const mappedPartnersIDs = updateStory?.partners?.map(
        partner => partner?.partnerid,
    );

    const checkSubmitDisabled = (isSubmitting, touched, errors) => {
        // console.log(`isSubmitting: `, isSubmitting);
        // console.log(`Touched: `, Object.keys(touched));
        // console.log(`errors: `, Object.keys(errors));
        if (
            isSubmitting ||
            Object.keys(touched).length == 0 ||
            Object.keys(errors).length > 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const fetchOptions = async () => {
            let isValid = false;
            try {
                const res = await Promise.all([
                    axios.get(
                        `https://plexusmd.blob.core.windows.net/datasets/specialties.json`,
                        {},
                    ),
                    axios.get(
                        `https://plexusmd.blob.core.windows.net/datasets/genre.json`,
                        {},
                    ),
                    axios.get(
                        `https://plexusmd.blob.core.windows.net/datasets/partners.json`,
                        {},
                    ),
                ]);
                const [allSpecs, allGenres, allPartners] = await Promise.all([
                    res[0].data ? res[0].data : [],
                    res[1].data ? res[1].data : [],
                    res[2].data ? res[2].data : [],
                ]);

                const specOpt = [];
                const genreOpt = [];
                const partnerOpt = [];

                allSpecs &&
                    allSpecs.map(spec =>
                        specOpt.push({
                            label: spec.name,
                            value: spec.sid,
                        }),
                    );

                allGenres &&
                    allGenres.map(genre =>
                        genreOpt.push({
                            label: genre.name,
                            value: genre.genreid,
                        }),
                    );

                allPartners &&
                    allPartners.map(partner =>
                        partnerOpt.push({
                            label: partner.name,
                            value: partner.partnerid,
                        }),
                    );

                setOptions({
                    specialty: specOpt,
                    genre: genreOpt,
                    partner: partnerOpt,
                });
            } catch (error) {
                if (!isValid) {
                    const specOpt = [];
                    const genreOpt = [];
                    const partnerOpt = [];

                    Options.specialty.map(spec =>
                        specOpt.push({
                            label: spec.name,
                            value: spec.sid,
                        }),
                    );

                    Options.genre.map(genre =>
                        genreOpt.push({
                            label: genre.name,
                            value: genre.genreid,
                        }),
                    );

                    Options.partner.map(partner =>
                        partnerOpt.push({
                            label: partner.name,
                            value: partner.partnerid,
                        }),
                    );

                    setOptions({
                        specialty: specOpt,
                        genre: genreOpt,
                        partner: partnerOpt,
                    });
                    isValid = true;
                }
                //console.error(`Failed to Fetch Dropdown Options: `, error);
            }
        };
        (showForm || updateStoryModal) && fetchOptions();
    }, [showForm, updateStoryModal]);

    const X_FUNCTIONS_KEY = process.env.REACT_APP_X_FUNCTIONS_KEY_UPLOADER;
    useEffect(() => {
        const postStory = async () => {
            try {
                const partnerInfo = [];
                const images = [];

                let postUrl = '';

                if (updateStoryModal && !formValues?.isScheduled) {
                    postUrl = `${globalURLS.baseURL2}ADupdatePost`;
                } else if (!updateStoryModal && !formValues?.isScheduled) {
                    postUrl = `${globalURLS.baseURL2}ADcreatePost`;
                } else if (
                    (!updateStoryModal && formValues?.isScheduled) ||
                    (updateStoryModal && formValues?.isScheduled)
                ) {
                    postUrl = `${globalURLS.baseURL2}ADSchedulePost`;
                }

                formValues.partner.map(p =>
                    partnerInfo.push({
                        partnerid: p,
                        isbn: formValues?.isbn ? 1 : 0,
                        importance: formValues.importance,
                    }),
                );

                formValues.imageUrls.map(i =>
                    images.push({ fullImageUrl: i, thumbnailUrl: i }),
                );

                const body = {
                    title: formValues.title,
                    description: formValues.description,
                    images: images,
                    publisher: formValues.publisher,
                    newspublishdate: formValues.newspublishdate,
                    specialties: formValues.specialty,
                    genres: formValues.genre,
                    partners: partnerInfo,
                    pulsecheck:
                        formValues?.pulsecheck?.question &&
                        formValues?.pulsecheck?.options?.length
                            ? formValues?.pulsecheck
                            : {},
                    previewtitle: formValues.previewtitle,
                    previewshortdescription: formValues.previewshortdescription,
                    previewimageurl: formValues.previewimageurl,
                    previewurl: formValues.previewurl,
                };

                updateStoryModal && (body.postid = updateStory.postid);

                let postRes;
                if (formValues?.isScheduled) {
                    postRes = await axios.post(
                        `${postUrl}`,
                        {
                            type: !updateStoryModal ? 'add' : 'update',
                            scheduled_at: convertToIST(
                                formValues?.scheduleDate,
                            ),
                            data: body,
                            id: Number(updateStory?.postid),
                        },
                        {
                            headers: {
                                'x-functions-key': X_FUNCTIONS_KEY,
                                'Access-Control-Allow-Origin': '*', // Replace '*' with the specific origin you want to allow
                            },
                        },
                    );
                } else {
                    postRes = axios.post(`${postUrl}`, body, {
                        headers: {
                            'x-functions-key': X_FUNCTIONS_KEY,
                            'Access-Control-Allow-Origin': '*', // Replace '*' with the specific origin you want to allow
                        },
                    });
                }

                const postResMsg = (await postRes).data.message;
                const postResSuccess = (await postRes).data.success;
                if (postResSuccess) {
                    closeForm();
                    window.location.reload();
                    alert(postResMsg);
                }
            } catch (error) {
                alert(`Failed to post story: ${error}`);
            }
        };

        formValues?.title?.length > 0 && postStory();
    }, [formValues]);
    // }, []);

    const handleDelete = () => {
        const body = {
            type: 'delete',
            id: updateStory?.postid,
        };

        const resp = axios.post(`${globalURLS.baseURL2}ADSchedulePost`, body, {
            headers: {
                'x-functions-key': X_FUNCTIONS_KEY,
                'Access-Control-Allow-Origin': '*', // Replace '*' with the specific origin you want to allow
            },
        });

        if (resp) {
            window.location.reload();
            alert('Deleted!');
        }
    };
    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const newsDataResp = await axios.get(
                    globalURLS.past60DaysMedflixNewsJSONURL,
                );

                if (newsDataResp?.data) {
                    const mutData = newsDataResp?.data?.map(el => ({
                        value: el.id,
                        label: el.title,
                    }));

                    setArrayNewsSelect(mutData);
                }
            } catch (error) {
                console.log('--err', error);
            }
        };

        fetchNewsData();
    }, []);

    return (
        <Container className="discussion-container">
            <Col>
                <Modal
                    show={showForm || updateStoryModal}
                    animation={true}
                    size="lg"
                >
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting, errors, touched, values }) => (
                            <Form
                                onSubmit={e => {
                                    e.preventDefault();
                                    setFormValues(values);
                                }}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {updateStoryModal
                                            ? 'Update Story'
                                            : `Add New Story`}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* Title */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Medflix News
                                                </BootstrapForm.Label>

                                                <Field
                                                    name="medflixNews"
                                                    component={MySelect}
                                                    options={JSON.parse(
                                                        JSON.stringify(
                                                            !!arrayNewsSelect &&
                                                                !!arrayNewsSelect?.length
                                                                ? arrayNewsSelect
                                                                : [],
                                                        ),
                                                    )}
                                                    placeholder={
                                                        'Select Medflix News (Optional)'
                                                    }
                                                />
                                            </BootstrapForm.Group>
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Title:
                                                </BootstrapForm.Label>
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                />

                                                <ErrorMessage
                                                    name="title"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    <Col>
                                        <BootstrapForm.Group>
                                            <Field
                                                disabled={
                                                    updateStoryModal &&
                                                    values?.isScheduled
                                                }
                                                type="checkbox"
                                                name="isScheduled"
                                                className="form-check-input"
                                                label=" isScheduled:"
                                                style={{
                                                    margin: '0 0.5em',
                                                }}
                                            />
                                            <BootstrapForm.Label
                                                style={{
                                                    marginLeft: '2em',
                                                }}
                                            >
                                                Schedule:
                                            </BootstrapForm.Label>

                                            <ErrorMessage
                                                name="isScheduled"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </BootstrapForm.Group>

                                        {values?.isScheduled && (
                                            <>
                                                <FormLabel>
                                                    Schedule Time:
                                                </FormLabel>
                                                <Field name="scheduleDate">
                                                    {({ field, meta }) => (
                                                        <FormControl
                                                            type="datetime-local"
                                                            name="scheduleDate"
                                                            {...field}
                                                            min={currentDate}
                                                            isInvalid={
                                                                meta.touched &&
                                                                meta.error
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                                <FormControl.Feedback type="invalid">
                                                    {`Schedule Date is required`}
                                                </FormControl.Feedback>
                                            </>
                                        )}
                                    </Col>
                                    {/* Description */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Description:
                                                </BootstrapForm.Label>
                                                <Field
                                                    as="textarea"
                                                    name="description"
                                                    className="form-control"
                                                    style={{
                                                        resize: 'vertical',
                                                    }}
                                                    rows={18}
                                                />
                                                <ErrorMessage
                                                    name="description"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* ImageUrl */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    ImageUrl:
                                                </BootstrapForm.Label>
                                                <FieldArray name="imageUrls">
                                                    {({ remove, push }) => (
                                                        <div>
                                                            {values.imageUrls
                                                                .length > 0 &&
                                                                values.imageUrls.map(
                                                                    (
                                                                        imageUrl,
                                                                        index,
                                                                    ) => (
                                                                        <Row
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="mb-2"
                                                                        >
                                                                            <Col
                                                                                sm={
                                                                                    9
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    name={`imageUrls.${index}`}
                                                                                    placeholder="Enter image URL"
                                                                                    className="form-control"
                                                                                />
                                                                                <ErrorMessage
                                                                                    component="div"
                                                                                    name={`imageUrls.${index}`}
                                                                                    className="text-danger"
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                sm={
                                                                                    3
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="danger"
                                                                                    className="w-100"
                                                                                    onClick={() =>
                                                                                        remove(
                                                                                            index,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    -
                                                                                    Remove
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    ),
                                                                )}
                                                            <Button
                                                                type="button"
                                                                variant="secondary"
                                                                className="mb-3"
                                                                onClick={() =>
                                                                    push('')
                                                                }
                                                            >
                                                                + Add Image
                                                            </Button>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* Publisher */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Publisher:
                                                </BootstrapForm.Label>
                                                <Field
                                                    type="text"
                                                    name="publisher"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="publisher"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* Publish Date Time */}
                                    <Row className="mb-2">
                                        <FormGroup controlId="formPublishDate">
                                            <FormLabel>Publish Date</FormLabel>
                                            <Field name="newspublishdate">
                                                {({ field, meta }) => (
                                                    <FormControl
                                                        type="date"
                                                        {...field}
                                                        max={currentDate}
                                                        isInvalid={
                                                            meta.touched &&
                                                            meta.error
                                                        }
                                                    />
                                                )}
                                            </Field>
                                            <FormControl.Feedback type="invalid">
                                                {`Publish Date is required`}
                                            </FormControl.Feedback>
                                        </FormGroup>
                                    </Row>

                                    {/* Specialty dropdown */}

                                    {options.specialty &&
                                        options.specialty.length > 0 && (
                                            <Row className="mb-2">
                                                <Col className="mb-1">
                                                    <BootstrapForm.Group>
                                                        <BootstrapForm.Label>
                                                            Specialty:
                                                        </BootstrapForm.Label>
                                                        <Field
                                                            name="specialty"
                                                            component={MySelect}
                                                            options={
                                                                options.specialty
                                                            }
                                                            placeholder={
                                                                updateStoryModal &&
                                                                updateStory.specialties
                                                                    ? getNames(
                                                                          'specialty',
                                                                      )
                                                                    : 'Select speciality'
                                                            }
                                                        />

                                                        <ErrorMessage
                                                            name="specialty"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </BootstrapForm.Group>
                                                </Col>
                                            </Row>
                                        )}

                                    {/* Genre dropdown */}
                                    {options.genre &&
                                        options.genre.length > 0 && (
                                            <Row className="mb-2">
                                                <Col className="mb-1">
                                                    <BootstrapForm.Group>
                                                        <BootstrapForm.Label>
                                                            Genres:
                                                        </BootstrapForm.Label>
                                                        <Field
                                                            name="genre"
                                                            component={MySelect}
                                                            options={
                                                                options.genre
                                                            }
                                                            placeholder={
                                                                updateStoryModal &&
                                                                updateStory.genres
                                                                    ? getNames(
                                                                          'genre',
                                                                      )
                                                                    : 'Select genres'
                                                            }
                                                        />

                                                        <ErrorMessage
                                                            name="genre"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </BootstrapForm.Group>
                                                </Col>
                                            </Row>
                                        )}

                                    {/* Partner dropdown */}
                                    {options.partner &&
                                        options.partner.length > 0 && (
                                            <Row className="mb-2">
                                                <Col className="mb-1">
                                                    <BootstrapForm.Group>
                                                        <BootstrapForm.Label>
                                                            Partners:
                                                        </BootstrapForm.Label>
                                                        <Field
                                                            name="partner"
                                                            component={MySelect}
                                                            value={
                                                                updateStoryModal &&
                                                                updateStory.partners
                                                                    ? getNames(
                                                                          'partner',
                                                                      )
                                                                    : 'Select partners'
                                                            }
                                                            options={
                                                                options.partner
                                                            }
                                                            placeholder={
                                                                updateStoryModal &&
                                                                updateStory.partners
                                                                    ? getNames(
                                                                          'partner',
                                                                      )
                                                                    : 'Select partners'
                                                            }
                                                        />

                                                        <ErrorMessage
                                                            name="partner"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </BootstrapForm.Group>
                                                </Col>
                                            </Row>
                                        )}

                                    {/* Cipla Pulse Check */}
                                    {(values?.partner?.includes(
                                        '6' || 6 || '7' || 7,
                                    ) ||
                                        mappedPartnersIDs?.includes(
                                            '6' || 6 || '7' || 7,
                                        )) && (
                                        <Col
                                            style={{
                                                backgroundColor: '#E6E5E5',
                                                padding: '1em',
                                                borderRadius: '1em',
                                            }}
                                        >
                                            <Row className="mb-2">
                                                <Col className="mb-1">
                                                    <BootstrapForm.Group>
                                                        <BootstrapForm.Label>
                                                            Cipla Pulse Check
                                                            Question:
                                                        </BootstrapForm.Label>
                                                        <Field
                                                            type="text"
                                                            name="pulsecheck.question"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage
                                                            name="pulsecheck.question"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </BootstrapForm.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col className="mb-1">
                                                    <BootstrapForm.Group>
                                                        <BootstrapForm.Label>
                                                            Option 1:
                                                        </BootstrapForm.Label>
                                                        <Field
                                                            type="text"
                                                            name="pulsecheck.options[0]"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage
                                                            name="pulsecheck.options[0]"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </BootstrapForm.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col className="mb-1">
                                                    <BootstrapForm.Group>
                                                        <BootstrapForm.Label>
                                                            Option 2:
                                                        </BootstrapForm.Label>
                                                        <Field
                                                            type="text"
                                                            name="pulsecheck.options[1]"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage
                                                            name="pulsecheck.options[1]"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </BootstrapForm.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}

                                    {/* Preview Title */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Preview Title:
                                                </BootstrapForm.Label>
                                                <Field
                                                    type="text"
                                                    name="previewtitle"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="previewtitle"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* Preview Desc */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Preview Description:
                                                </BootstrapForm.Label>
                                                <Field
                                                    as="textarea"
                                                    name="previewshortdescription"
                                                    className="form-control"
                                                    style={{
                                                        resize: 'vertical',
                                                    }}
                                                    rows={8}
                                                />
                                                <ErrorMessage
                                                    name="previewshortdescription"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* Preview URL */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Preview URL:
                                                </BootstrapForm.Label>
                                                <Field
                                                    type="text"
                                                    name="previewurl"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="previewurl"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* Preview Image URL */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Preview ImageUrl:
                                                </BootstrapForm.Label>
                                                <Field
                                                    type="text"
                                                    name="previewimageurl"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="previewimageurl"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* Post Importance */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>
                                                    Importance:
                                                </BootstrapForm.Label>
                                                <Field
                                                    type="number"
                                                    name="importance"
                                                    min="0"
                                                    max="5"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="importance"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>

                                    {/* Should BN */}
                                    <Row className="mb-2">
                                        <Col className="mb-1">
                                            <BootstrapForm.Group>
                                                <Field name="isbn">
                                                    {({ field }) => (
                                                        <BootstrapForm.Check
                                                            type="checkbox"
                                                            name="isbn"
                                                            onClick={() => {
                                                                setUpdateStory({
                                                                    ...updateStory,
                                                                    partners: [
                                                                        {
                                                                            ...updateStory
                                                                                .partners[0],
                                                                            isbn: !updateStory
                                                                                .partners[0]
                                                                                .isbn,
                                                                        },
                                                                    ],
                                                                });
                                                            }}
                                                            checked={
                                                                updateStory
                                                                    ?.partners[0]
                                                                    ?.isbn
                                                            }
                                                            label="Should Partner BN"
                                                            {...field}
                                                        />
                                                    )}
                                                </Field>
                                            </BootstrapForm.Group>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={closeForm}
                                    >
                                        Close
                                    </Button>
                                    {updateStoryModal &&
                                        values?.isScheduled === true && (
                                            <>
                                                <Button
                                                    variant="danger"
                                                    type="submit"
                                                    onClick={() =>
                                                        handleDelete()
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={checkSubmitDisabled(
                                                        isSubmitting,
                                                        touched,
                                                        errors,
                                                    )}
                                                >
                                                    Update Scheduled Post
                                                </Button>
                                            </>
                                        )}
                                    {updateStoryModal &&
                                        !values?.isScheduled && (
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                disabled={checkSubmitDisabled(
                                                    isSubmitting,
                                                    touched,
                                                    errors,
                                                )}
                                            >
                                                Update Story
                                            </Button>
                                        )}

                                    {!updateStoryModal &&
                                        !formValues?.isScheduled && (
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                disabled={checkSubmitDisabled(
                                                    isSubmitting,
                                                    touched,
                                                    errors,
                                                )}
                                            >
                                                Add Story
                                            </Button>
                                        )}
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Col>
        </Container>
    );
};

export default AddPmdFeed;
