/* eslint-disable no-case-declarations */
import React from 'react';
import { CustomInput } from '../../../../Common/index.js';
import { useFeedContext } from '../../../../../Context/index.tsx';

const SPPreview = () => {
    const { shortPollData, setShortPollData, step, notToEditFeed } =
        useFeedContext();

    const changeHandler = (e, type) => {
        const { value } = e.target;
        switch (type) {
            case 'question':
                setShortPollData({
                    ...shortPollData,
                    question: value,
                });

                break;
            case 'option1':
                let values1 = { ...shortPollData };
                values1.pollOptions[0].text = value;
                setShortPollData(values1);
                break;
            case 'option2':
                let values2 = { ...shortPollData };
                values2.pollOptions[1].text = value;
                setShortPollData(values2);

                break;

            default:
                break;
        }
    };
    return (
        <>
            <CustomInput
                textArea="short"
                type={'text'}
                required={true}
                errorMessage="Please provide a question."
                label="Question"
                placeHolder="Question"
                value={shortPollData?.question}
                onChange={e => changeHandler(e, 'question')}
                disabled={step === 3 ? notToEditFeed : false}
            />

            {shortPollData?.options && (
                <>
                    <CustomInput
                        textArea="short"
                        type={'text'}
                        required={true}
                        errorMessage="Please provide option 1."
                        label="Option 1"
                        placeHolder="Option 1"
                        onChange={e => changeHandler(e, 'option1')}
                        value={shortPollData?.options[0].text}
                        disabled={step === 3 ? notToEditFeed : false}
                    />

                    <CustomInput
                        textArea="short"
                        type={'text'}
                        required={true}
                        errorMessage="Please provide option 2."
                        label="Option 2"
                        placeHolder="Option 2"
                        onChange={e => changeHandler(e, 'option2')}
                        value={shortPollData?.options[1].text}
                        disabled={step === 3 ? notToEditFeed : false}
                    />
                </>
            )}

            {shortPollData?.pollOptions && (
                <>
                    <CustomInput
                        textArea="short"
                        type={'text'}
                        required={true}
                        errorMessage="Please provide option 1."
                        label="Option 1"
                        placeHolder="Option 1"
                        onChange={e => changeHandler(e, 'option1')}
                        value={shortPollData?.pollOptions[0]?.text}
                        disabled={step === 3 ? notToEditFeed : false}
                    />

                    <CustomInput
                        textArea="short"
                        type={'text'}
                        required={true}
                        errorMessage="Please provide option 2."
                        label="Option 2"
                        placeHolder="Option 2"
                        onChange={e => changeHandler(e, 'option2')}
                        value={shortPollData?.pollOptions[1].text}
                        disabled={step === 3 ? notToEditFeed : false}
                    />
                </>
            )}
        </>
    );
};

export default SPPreview;
