import { Button } from 'react-bootstrap';

import React from 'react';
import './CustomButton.css';

interface IProp {
    className?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    style?: React.CSSProperties;
    variant?: string;
    type?: 'button' | 'submit' | 'reset';
}

const CustomButton: React.FC<IProp> = ({
    className,
    disabled,
    children,
    onClick,
    style,
    type,
    variant,
}) => {
    return (
        <Button
            className={className || 'primary-btn'}
            variant={variant || 'primary'}
            onClick={onClick}
            style={style}
            type={type || 'button'}
            disabled={disabled || false}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
