/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

export const FeedContext = React.createContext({
    showModal: false,
    setShowModal: () => {},

    alertModal: false,
    setAlertModal: () => {},

    step: 1,
    setStep: () => {},

    feedType: '',
    setFeedType: () => {},

    selectTable: '',
    setSelectTable: () => {},

    newsData: {},
    setNewsData: () => {},

    quizData: {},
    setQuizData: () => {},

    quesData: {},
    setQuesData: () => {},

    shortPollData: {},
    setShortPollData: () => {},

    longPollsData: {},
    setLongPollsData: () => {},

    feedData: {},
    setFeedData: () => {},

    feedTags: [],
    setFeedTags: () => {},

    shownFeedTags: [],
    setShownFeedTags: () => {},

    notToEditFeed: true,
    setNotToEditFeed: () => {},
});

export const FeedContextProvider = props => {
    const feedInfo = {
        title: '',
        summary: '',
        bannersMedia: [{ cta: '', src: '', type: 'Image' }],
        isTest: 0,
        publishTs: '',
        deadline: '',
        header: '',
        headerIcon: '',
        showResult: true,
    };

    const shortPollInfo = {
        pollId: '',
        parentId: '',
        parentType: 'news',
        pollType: 'short',
        question: '',
        options: [
            {
                text: '',
                rank: 1,
            },
            {
                text: '',
                rank: 2,
            },
        ],
        rank: 1,
    };

    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(1);
    const [feedType, setFeedType] = useState('');
    const [newsData, setNewsData] = useState({});
    const [shortPollData, setShortPollData] = useState(shortPollInfo);
    const [longPollsData, setLongPollsData] = useState(undefined);
    const [selectTable, setSelectTable] = useState('news');
    const [feedData, setFeedData] = useState(feedInfo);
    const [quizData, setQuizData] = useState({});
    const [quesData, setQuesData] = useState({});
    const [alertModal, setAlertModal] = useState(false);
    const [feedTags, setFeedTags] = useState(['']);
    const [showFeedTags, setShownFeedTags] = useState(['']);
    const [notToEditFeed, setNotToEditFeed] = useState(true);

    // eslint-disable-next-line react/prop-types
    const { children } = props;

    return (
        <FeedContext.Provider
            value={{
                showModal,
                setShowModal,
                step,
                setStep,
                feedType,
                setFeedType,
                newsData,
                setNewsData,
                shortPollData,
                setShortPollData,
                selectTable,
                setSelectTable,
                quizData,
                setQuizData,
                quesData,
                setQuesData,
                longPollsData,
                setLongPollsData,
                alertModal,
                setAlertModal,
                feedData,
                setFeedData,
                feedTags,
                setFeedTags,
                showFeedTags,
                setShownFeedTags,
                notToEditFeed,
                setNotToEditFeed,
            }}
        >
            {children}
        </FeedContext.Provider>
    );
};
