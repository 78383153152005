/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { appBanner, webBanner } from '../Components/Banner/interface';

interface BannerContextProps {
    //Modal State
    addBannerModal?: boolean;
    setAddBannerModal?: (addBannerModal: boolean) => void;
    updateAppBannerModal?: boolean;
    setUpdateAppBannerModal?: (updateAppBannerModal: boolean) => void;
    updateWebBannerModal?: boolean;
    setUpdateWebBannerModal?: (updateWebBannerModal: boolean) => void;

    //App Banners
    appBannerData?: appBanner;
    setAppBannerData?: (appBannerData: appBanner) => void;
    appBanners?: appBanner[];
    setAppBanners?: (appBanners: appBanner[]) => void;

    //Web Banners
    webBannerData?: webBanner;
    setWebBannerData?: (webBannerData: webBanner) => void;
    webBanners?: webBanner[];
    setWebBanners?: (webBanners: webBanner[]) => void;
}

export const BannerContext = React.createContext<BannerContextProps>({
    //Modal State
    addBannerModal: false,
    setAddBannerModal: () => {},

    updateAppBannerModal: false,
    setUpdateAppBannerModal: () => {},

    updateWebBannerModal: false,
    setUpdateWebBannerModal: () => {},

    //App Banners
    appBannerData: {
        bannerid: '',
        isactive: 1,
        name: '',
        imageurl: '',
        priority: 0,
        startdate: '',
        enddate: '',
        action: 'deeplink',
        type: 'image',
        openWebviewUrl: '',
        openDeeplink: '',
        createdon: '',
        modifiedon: '',
        minappversion: '',
        maxappversion: '',
        platform_openWebviewUrl: '',
    },
    setAppBannerData: () => {},
    appBanners: [],
    setAppBanners: () => {
        [];
    },

    //Web Banners
    webBannerData: {
        webbannerid: '',
        name: '',
        description: '',
        imageurl: '',
        openWebviewUrl: '',
        platform: '',
        startdate: '',
        enddate: '',
        isactive: 1,
    },
    setWebBannerData: () => {},
    webBanners: [],
    setWebBanners: () => {},
});

export const BannerContextProvider = (props: any) => {
    const [addBannerModal, setAddBannerModal] = useState<boolean>();
    const [updateAppBannerModal, setUpdateAppBannerModal] = useState<boolean>();
    const [appBannerData, setAppBannerData] = useState<appBanner>();
    const [appBanners, setAppBanners] = useState<appBanner[]>();
    const [webBannerData, setWebBannerData] = useState<webBanner>();
    const [webBanners, setWebBanners] = useState<webBanner[]>();
    const [updateWebBannerModal, setUpdateWebBannerModal] = useState<boolean>();

    return (
        <BannerContext.Provider
            value={{
                appBannerData,
                setAppBannerData,
                updateAppBannerModal,
                setUpdateAppBannerModal,
                appBanners,
                setAppBanners,
                addBannerModal,
                setAddBannerModal,
                webBannerData,
                setWebBannerData,
                webBanners,
                setWebBanners,
                updateWebBannerModal,
                setUpdateWebBannerModal,
            }}
        >
            {props.children}
        </BannerContext.Provider>
    );
};
