import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import PageTitle from '../../Components/PageTitle/PageTitle.js';
import Report from '../../Components/Report/Report';
import './Reports.css';
import ReportDataModal from '../../Components/Report/ReportModal/ReportDataModal';

const Reports: React.FC = () => {
    return (
        <Container className="reports-container">
            <Col>
                <Row>
                    <div className="reports-title">
                        <PageTitle>Reports</PageTitle>
                    </div>
                    <Report />
                </Row>
            </Col>
            <ReportDataModal />
        </Container>
    );
};

export default Reports;
