import React, { useState } from 'react';

import { Button, Container, Row, Col, Nav } from 'react-bootstrap';

// Components imports
import PageTitle from '../../Components/PageTitle/PageTitle.js';
import './NewsFeed.css';
import AddNews from '../../Components/NewsFeed/AddNews/AddNews.js';
import { useFeedContext, useGlobalContext } from '../../Context/index.tsx';
import AdQuiz from '../../Components/NewsFeed/AdQuiz/AdQuiz.js';
import AdQues from '../../Components/NewsFeed/AdQues/AdQues.js';
import News from '../../Components/NewsFeed/News/News.js';
import Quiz from '../../Components/NewsFeed/Quiz/Quiz.js';
import Questionnaire from '../../Components/NewsFeed/Questionnaire/Questionnaire.js';
import FeedTags from '../../Components/NewsFeed/FeedTags/FeedTags.js';
import SuccessToast from '../../Components/Common/Toast/SuccessToast.tsx';
import FailureToast from '../../Components/Common/Toast/FailureToast.tsx';

function NewsFeed() {
    const { showModal, setShowModal, feedType, setFeedType } = useFeedContext();
    const [selectTable, setSelectTable] = useState('news');

    const {
        showSuccessToast,
        showErrorToast,
        setShowSuccessToast,
        setShowErrorToast,
    } = useGlobalContext();

    const selecFeed = (type = '') => {
        if (type.length > 0) {
            setShowModal(true);
        }
        setFeedType(type);
    };

    return (
        <Container className="feed-container">
            <Col>
                <Row>
                    <div className="feed-title">
                        <PageTitle>News Feed</PageTitle>
                        <Button
                            className="btn-add"
                            variant="primary"
                            onClick={() => selecFeed('news')}
                            style={{ cursor: 'pointer' }}
                        >
                            + News
                        </Button>
                        <Button
                            className="btn-add"
                            variant="primary"
                            onClick={() => selecFeed('quiz')}
                            style={{ cursor: 'pointer' }}
                        >
                            + Quiz
                        </Button>
                        <Button
                            className="btn-add"
                            variant="primary"
                            onClick={() => selecFeed('ques')}
                            style={{ cursor: 'pointer' }}
                        >
                            + Questionnaire
                        </Button>
                    </div>
                    <>
                        <FeedTags />
                    </>
                    <Nav fill variant="tabs" defaultActiveKey="/news">
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => setSelectTable('news')}
                                eventKey="news"
                            >
                                News
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => setSelectTable('quiz')}
                                eventKey="quiz"
                            >
                                Quiz
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => setSelectTable('ques')}
                                eventKey="ques"
                            >
                                Questionnaire
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {/* Add News Modal */}
                    {showModal && feedType == 'news' && <AddNews />}
                    {/* Add Quiz Modal */}
                    {showModal && feedType == 'quiz' && <AdQuiz />}
                    {/* Add Ques Modal */}
                    {showModal && feedType == 'ques' && <AdQues />}
                    {/* News */}
                    {selectTable === 'news' ? <News /> : ''}
                    {/* Quiz */}
                    {selectTable === 'quiz' ? <Quiz /> : ''}
                    {/* Questionnaire */}
                    {selectTable === 'ques' ? <Questionnaire /> : ''}
                </Row>
            </Col>
            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={() => setShowSuccessToast(false)}
                    headerTitle="Success!"
                />
            )}
            {showErrorToast && (
                <FailureToast
                    showModal={showErrorToast}
                    handleClose={() => setShowErrorToast(false)}
                    headerTitle="Something went wrong, Please try again"
                />
            )}
        </Container>
    );
}

export default NewsFeed;
