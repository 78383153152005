/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
interface authDetails {
    email: string;
    firstName?: string;
    lastName?: string;
    picture?: string;
    token: string;
}

interface GlobalContextProps {
    authDetails: authDetails;
    setAuthDetails?: (authDetails: authDetails) => void;
    selectedTable?: selectedTable;
    setSelectedTable?: (selectedTable: selectedTable) => void;
    showSuccessToast?: boolean;
    setShowSuccessToast?: (showSuccessToast: boolean) => void;
    showErrorToast?: boolean;
    setShowErrorToast?: (showErrorToast: boolean) => void;
    showUploaderModal?: boolean;
    setShowUploaderModal?: (showUploaderModal: boolean) => void;
    showMessengerModal?: boolean;
    setShowMessengerModal?: (showMessengerModal: boolean) => void;
}

interface selectedTable {
    name?: string;
    component?: React.ReactNode;
}

export const GlobalContext = React.createContext<GlobalContextProps>({
    authDetails: {
        email: '',
        firstName: '',
        lastName: '',
        token: '',
        picture: '',
    },
    setAuthDetails: () => {},
    selectedTable: { name: '', component: '' },
    setSelectedTable: () => {},
    showSuccessToast: false,
    setShowSuccessToast: () => {},
    showErrorToast: false,
    setShowErrorToast: () => {},
    showUploaderModal: false,
    setShowUploaderModal: () => {},
    showMessengerModal: false,
    setShowMessengerModal: () => {},
});

export const GlobalContextProvider = (props: any) => {
    const [authDetails, setAuthDetails] = useState<authDetails>();
    const [selectedTable, setSelectedTable] = useState<selectedTable>();
    const [showSuccessToast, setShowSuccessToast] = useState<boolean>();
    const [showErrorToast, setShowErrorToast] = useState<boolean>();
    const [showUploaderModal, setShowUploaderModal] = useState<boolean>();
    const [showMessengerModal, setShowMessengerModal] = useState<boolean>();

    return (
        <GlobalContext.Provider
            value={{
                authDetails,
                setAuthDetails,
                selectedTable,
                setSelectedTable,
                showSuccessToast,
                setShowSuccessToast,
                showErrorToast,
                setShowErrorToast,
                showUploaderModal,
                setShowUploaderModal,
                showMessengerModal,
                setShowMessengerModal,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};
