import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';

import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import { globalURLS } from '../../Global/urls.js';
import { useGlobalContext } from '../../Context/index.tsx';
function Login() {
    const [credential, setCredential] = useState('');
    const { authDetails, setAuthDetails } = useGlobalContext();
    // console.log('authDetails1', authDetails);
    // const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            axios
                .post(
                    `${globalURLS.baseURL}ADloginAdmin`,
                    { credential },
                    globalURLS.config,
                )
                .then(response => {
                    if (response?.data?.data?.user) {
                        const { email, firstName, lastName, picture, token } =
                            response.data.data.user;
                        setAuthDetails({
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            picture: picture,
                            token: token?.token,
                        });
                        window.location.reload();
                    }
                });
            // .then(navigate('discussion'));
            // window.location.reload();
        };
        credential.length > 0 && fetchData();
    }, [credential]);

    useEffect(() => {
        authDetails &&
            localStorage.setItem('user', JSON.stringify(authDetails));
    }, [authDetails]);

    return (
        <Container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100vw',
            }}
        >
            <Card style={{ width: '25rem', padding: '2em' }}>
                <Card.Img
                    variant="top"
                    src="https://www.medflix.app/_next/image?url=%2Fassets%2Fimages%2Fmedflix_logo.webp&w=1080&q=75"
                />
                <Card.Body>
                    <Card.Title>Welcome</Card.Title>
                    <Card.Text>This is a Medflix Captain Portal.</Card.Text>
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            // console.log(credentialResponse);
                            setCredential(credentialResponse?.credential);
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Login;
