import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import NavTabsList from './NavTabsWithTablesList';
import { useGlobalContext } from '../../../../Context/index';
import { navTabsData } from '../../Table/TableData';

interface navItemProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    eventKey: string;
    children?: React.ReactNode;
}

interface IProps {
    defaultActiveKey: string;
    data: navItemProps[];
}

const NavTabsWithTables: React.FC<IProps> = ({ defaultActiveKey, data }) => {
    const { selectedTable, setSelectedTable } = useGlobalContext();
    let showTable;

    useEffect(() => {
        showTable = navTabsData.find(
            navTab => navTab?.children === defaultActiveKey,
        );
        setSelectedTable({ name: showTable.eventKey });
    }, [defaultActiveKey]);

    if (navTabsData && selectedTable) {
        showTable = navTabsData.find(
            navTab => navTab?.children === selectedTable.name,
        );
    }

    return (
        <>
            <Nav fill variant="tabs" defaultActiveKey={defaultActiveKey}>
                <NavTabsList data={data} />
            </Nav>
            {selectedTable ? showTable?.table : ''}
        </>
    );
};

export default NavTabsWithTables;
