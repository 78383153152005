import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';

function VideoAds() {
    const [columnDefs] = useState([
        { field: 'id', sortable: true, resizable: true },
        { field: 'discussionName', sortable: true, resizable: true },
        { field: 'discussionid', sortable: true, resizable: true },
        { field: 'videoExt', sortable: true, resizable: true },
        { field: 'position', sortable: true, resizable: true },
        { field: 'skipUserType', sortable: true, resizable: true },
        { field: 'skipAfterSeconds', sortable: true, resizable: true },
        { field: 'markSeenAfterSeconds', sortable: true, resizable: true },
    ]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${globalURLS.baseURL}ADgetVideosAds`,
                globalURLS.config,
            );
            setRowData(response.data.data);
        };
        fetchData();
    }, []);

    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Join Videos'}
            ></Table>
        </>
    );
}

export default VideoAds;
