import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, utcToZonedTime } from 'date-fns-tz';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';

function UpcomingDiscussion() {
    const [upcomingDiscussionData, setUpcomingDiscussionData] = useState([]);
    const [columnDefs] = useState([
        { field: 'discussionid', sortable: true, resizable: true },
        { field: 'title', sortable: true, resizable: true },
        { field: 'club', sortable: true, resizable: true },
        { field: 'status', sortable: true, resizable: true },
        { field: 'startdate', sortable: true, resizable: true },
        { field: 'attendeecount', sortable: true, resizable: true },
        { field: 'hosts', sortable: true, resizable: true },
    ]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${globalURLS.baseURL}getWebUpcomingDiscussions`,
                globalURLS.adminData,
            );

            setUpcomingDiscussionData(response.data.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (upcomingDiscussionData && upcomingDiscussionData.length > 0) {
            const finalUcomingDiscussionData = upcomingDiscussionData.map(
                el => {
                    const upcoming = {};
                    const date = new Date(el.istdate);
                    const ISTDateTime = utcToZonedTime(date, 'Asia/Calcutta');
                    const finalISTDateTime = format(
                        ISTDateTime,
                        'yyyy-MM-dd HH:mm:ss',
                        { timeZone: 'Asia/Calcutta' },
                    );
                    // const finalISTDateTime = format(ISTDateTime, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'Asia/Calcutta'});
                    const finalHosts = el.hosts
                        .map(host => {
                            return `${host.firstname} ${host.lastname}`;
                        })
                        .join('| ');
                    (upcoming.discussionid = el.discussionid),
                        (upcoming.title = el.title),
                        (upcoming.club = el.clubinfo.name),
                        (upcoming.status = el.status),
                        (upcoming.startdate = finalISTDateTime),
                        (upcoming.attendeecount = el.attendeecount),
                        (upcoming.hosts = finalHosts);
                    return upcoming;
                },
            );

            setRowData(
                finalUcomingDiscussionData ? finalUcomingDiscussionData : [],
            );
        }
    }, [upcomingDiscussionData]);
    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Upcoming Discussions'}
            ></Table>
        </>
    );
}

export default UpcomingDiscussion;
