import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import Select from 'react-select';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import { globalURLS } from '../../../Global/urls';
import axios from 'axios';
import { useGlobalContext } from '../../../Context';

interface TransferUsersModalProps {
    show: boolean;
    onHide: () => void;
}

const dropzoneStyle = {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

const dropzoneActiveStyle = {
    borderColor: '#007bff',
};

const TransferUsersModal: React.FC<TransferUsersModalProps> = ({
    show,
    onHide,
}) => {
    const [discussionId, setDiscussionId] = useState('');
    const [csvData, setCsvData] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const getSelectDiscussions = true;
    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const onDrop = (acceptedFiles: File[]) => {
        setIsDragging(false);

        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];
            setFile(selectedFile);

            const reader = new FileReader();

            reader.onload = e => {
                const content = e.target?.result as string;

                Papa.parse(content, {
                    complete: result => {
                        if (result.data.length > 0) {
                            let accountIds = result.data.map(
                                user => user?.accountid,
                            );

                            accountIds = accountIds.filter(item => item !== '');
                            setCsvData(accountIds);
                        }
                    },
                    header: true,
                });
            };

            reader.readAsText(selectedFile);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
        multiple: false,
        accept: '.csv',
    });

    const handleTransfer = () => {
        const data = {
            discussionid: discussionId,
            accountIds: csvData,
        };

        async function postFunc() {
            try {
                setIsLoading(true);
                const response = await axios.post(
                    `${globalURLS.baseURL}ADtransferUserForDiscussion`,
                    data,
                    globalURLS.config,
                );

                if (response?.data?.success === true) {
                    setShowSuccessToast(true);
                    setCsvData([]);
                    setDiscussionId('');
                    onHide();
                }
                setIsLoading(false);
            } catch (error) {
                setShowErrorToast(true);
                console.log('-err', error);
            }
        }

        postFunc();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Transfer Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="discussionId">
                        <Form.Label> Transfer to Discussion</Form.Label>
                        <Select
                            className="w-100"
                            closeMenuOnSelect={true}
                            onChange={val => setDiscussionId(val?.value)}
                            isSearchable
                            options={selectDiscussions || []}
                        />
                    </Form.Group>
                    <Form.Group controlId="csv" className="mt-4">
                        <Form.Label>Upload CSV File</Form.Label>
                        <div
                            {...getRootProps()}
                            style={{
                                ...dropzoneStyle,
                                ...(isDragging ? dropzoneActiveStyle : {}),
                            }}
                        >
                            <input {...getInputProps()} />
                            {file ? (
                                <p>Uploaded file: {file.name}</p>
                            ) : isDragging ? (
                                <p>Drop the CSV file here</p>
                            ) : (
                                <p>
                                    Drag & drop a CSV file here, or click to
                                    select one
                                </p>
                            )}
                        </div>
                    </Form.Group>
                    <div>
                        <p>Account IDs extracted from CSV:</p>
                        <ul>
                            {csvData.map((accountId, index) => (
                                <li key={index}>{accountId}</li>
                            ))}
                        </ul>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    disabled={isLoading}
                    onClick={onHide}
                >
                    Close
                </Button>
                <Button
                    disabled={isLoading}
                    variant={isLoading ? 'warning' : 'primary'}
                    onClick={handleTransfer}
                >
                    {isLoading ? 'Transfering...' : 'Transfer'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TransferUsersModal;
