import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../Common/Table/Table.js';

interface Club {
    clubid: string;
    name: string;
    membercount: string;
    isPrivate: number;
    handle: string;
    admins: string;
    isTest: number;
}

const columnDefs = [
    { field: 'clubid', sortable: true, resizable: true },
    { field: 'name', sortable: true, resizable: true },
    { field: 'membercount', sortable: true, resizable: true },
    { field: 'isPrivate', sortable: true, resizable: true },
    { field: 'handle', sortable: true, resizable: true },
    { field: 'admins', sortable: true, resizable: true },
    { field: 'isTest', sortable: true, resizable: true },
];

const adminData = {
    accountid: 'a237aec7-5fe0-4177-99b2-d0b590af26db',
    count: 1000,
    orderby: 'date',
};

function Clubs() {
    const [clubs, setClubs] = useState<Club[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${globalURLS.baseURL}ADgetClubs`,
                    adminData,
                );
                setClubs(response?.data?.data || []);
            } catch (err) {
                console.error('Failed to fetch clubs: ', err);
            }
        };
        fetchData();
    }, []);

    return <Table rowData={clubs} columnDefs={columnDefs} title={'Clubs'} />;
}

export default Clubs;
