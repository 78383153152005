import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { format, utcToZonedTime } from 'date-fns-tz';

import { PostInfo } from '../../Context/PmdContext';

import PageTitle from '../../Components/PageTitle/PageTitle.js';
import AddPmdFeed from '../../Components/PlexusMD/AddPmdFeed.js';
import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';

import Table from '../../Components/Common/Table/Table.js';
import { usePmdContext } from '../../Context/index';
import { Specialty, Partner } from '../../Context/PmdContext';

const FEEDPASS = 'feed1234';

function PlexusMD() {
    const {
        setAllStories,
        allStories,
        updateStoryId,
        setUpdateStoryModal,
        setUpdateStoryId,
        setUpdateStory,
        setIsVerified,
    } = usePmdContext();

    const checkVerification = () => {
        let verifiedString: string | null;
        if (typeof window != 'undefined') {
            verifiedString = window.localStorage.getItem('verifiedPMD');
            if (verifiedString == 'valid') {
                setIsVerified(true);
                return false;
            } else {
                return true;
            }
        }
    };

    const [showModal, setShowModal] = useState(false);
    const [showPass, setShowPass] = useState(checkVerification());
    const [password, setPassword] = useState('');
    const [stories, setStories] = useState([]);
    const [columnDefs] = useState([
        { field: 'Postid', sortable: true, resizable: true },
        { field: 'Scheduled', sortable: true, resizable: true },
        { field: 'ScheduleDate', sortable: true, resizable: true },
        { field: 'PostedOn', sortable: true, resizable: true },
        { field: 'Title', sortable: true, resizable: true },
        { field: 'Specialty', sortable: true, resizable: true },
        { field: 'Partners', sortable: true, resizable: true },
        { field: 'Publisher', sortable: true, resizable: true },
        { field: 'PublishDate', sortable: true, resizable: true },
    ]);
    const [rowData, setRowData] = useState([]);

    const btnopen = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setUpdateStory(undefined);
        setUpdateStoryId('');
        setUpdateStoryModal(false);
        setShowModal(false);
    };

    //const handleClose = () => setShowPass(false);
    //const handleShow = () => setShowPass(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleVerify = (e: any) => {
        e.preventDefault();
        setPassword('');
        if (password == FEEDPASS) {
            setShowPass(false);
            setIsVerified(true);
            window.localStorage.setItem('verifiedPMD', 'valid');
        } else {
            alert('Incorrect Password, please try again!');
        }
    };

    // eslint-disable-next-line no-undef
    const X_FUNCTIONS_KEY = process.env.REACT_APP_X_FUNCTIONS_KEY_UPLOADER;

    useEffect(() => {
        const fetchStories = async () => {
            const response = await axios.post(
                `${globalURLS.baseURL2}ADgetListing`,
                {
                    count: 200,
                },
                {
                    headers: {
                        'x-functions-key': X_FUNCTIONS_KEY,
                    },
                },
            );

            const response1 = await axios.post(
                `${globalURLS.baseURL2}ADgetScheduledListing`,
                {
                    count: 200,
                },
                {
                    headers: {
                        'x-functions-key': X_FUNCTIONS_KEY,
                    },
                },
            );

            const storiesMerge = [
                ...response1.data.data,
                ...response.data.data,
            ];

            setStories(storiesMerge);
            setAllStories(storiesMerge);
            // console.log(`All stories: `, storiesMerge);
        };
        fetchStories();
    }, []);

    useEffect(() => {
        if (stories && stories.length > 0) {
            //console.log('Stories: ', stories);

            const newStories =
                stories &&
                stories.map(story => {
                    const specialtyNames =
                        story.specialties &&
                        story.specialties
                            .map((s: Specialty) => s.specialtyName)
                            .join(', ');
                    const partnerNames =
                        story.partners &&
                        story.partners.map((p: Partner) => p.name).join(', ');
                    let date = new Date(
                        story?.lastmodifiedon || story?.lastModifiedOn,
                    );
                    let ISTDateTime = utcToZonedTime(date, 'Asia/Calcutta');
                    const postingDate = format(
                        ISTDateTime,
                        'yyyy-MM-dd HH:mm:ss',
                        {
                            timeZone: 'Asia/Calcutta',
                        },
                    );
                    date = new Date(story.newspublishdate);
                    ISTDateTime = utcToZonedTime(date, 'Asia/Calcutta');
                    const publishingDate = format(
                        ISTDateTime,
                        'yyyy-MM-dd HH:mm:ss',
                        { timeZone: 'Asia/Calcutta' },
                    );

                    let scheduledDate;

                    if (story?.scheduledDate) {
                        const sDate = new Date(story?.scheduledDate);
                        const sISTDateTime = utcToZonedTime(
                            sDate,
                            'Asia/Calcutta',
                        );
                        scheduledDate = format(
                            sISTDateTime,
                            'yyyy-MM-dd HH:mm',
                            { timeZone: 'Asia/Calcutta' },
                        );
                    }

                    const storyData = {
                        Postid: story.postid,
                        ScheduleDate: scheduledDate
                            ? scheduledDate.slice(0, 16)
                            : '',
                        Scheduled: story?.isScheduled || false,
                        PostedOn: postingDate,
                        Title: story.title,
                        Specialty: specialtyNames,
                        Partners: partnerNames,
                        Publisher: story.publisher,
                        PublishDate: publishingDate,
                    };
                    return storyData;
                });
            newStories && newStories.length > 0 && setRowData(newStories);
        }
    }, [stories]);

    useEffect(() => {
        if (
            updateStoryId &&
            updateStoryId.length > 0 &&
            allStories.length > 0
        ) {
            const storyInfo: PostInfo[] = allStories.filter(
                (story: PostInfo) => story.postid == updateStoryId,
            );
            if (storyInfo && storyInfo[0].postid) {
                setUpdateStory(storyInfo[0]);
                setUpdateStoryModal(true);
            }
        }
    }, [updateStoryId]);

    return (
        <Container className="plexusmd-container">
            {showPass ? (
                <Modal show={showPass}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleVerify}>
                            <Form.Group controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="primary"
                                type="submit"
                            >
                                Verify
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            ) : (
                <Col>
                    <Row>
                        <div className="plexusmd-title">
                            <PageTitle>PlexusMD</PageTitle>
                            <Button
                                className="btn-add"
                                variant="primary"
                                onClick={btnopen}
                            >
                                + Add Story
                            </Button>
                        </div>
                        <>
                            <Table
                                rowData={rowData}
                                columnDefs={columnDefs}
                                title={'PlexusMD Stories'}
                            ></Table>
                        </>

                        <AddPmdFeed
                            showForm={showModal}
                            closeForm={() => closeModal()}
                        />
                    </Row>
                </Col>
            )}
        </Container>
    );
}

export default PlexusMD;
