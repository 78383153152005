import React, { useState } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { Button, Form, Image, Modal } from 'react-bootstrap';
import { useFeedContext } from '../../../../../Context/index.tsx';

const QuestionnaireForm = ({ feedtype }) => {
    const [showModal, setShowModal] = useState(true);
    const [correctOptionIndex, setCorrectOptionIndex] = useState(true);
    const { setStep, feedType, longPollsData, setLongPollsData } =
        useFeedContext();

    const defQues = {
        parentId: '',
        parentType: feedType === 'ques' ? 'questionnaire' : feedType,
        pollType: 'long',
        question: '',
        options: [{ text: '', isCorrect: false, rank: 1 }],
        bannersMedia: [{ src: '', cta: '', type: '' }],
        positiveWeight: feedtype == 'quiz' ? 4 : 0,
        negativeWeight: feedtype == 'quiz' ? -1 : 0,
        rank: 1,
        isCorrect: null,
        isCorrectOption: null,
    };
    const [questions, setQuestions] = useState(
        longPollsData == undefined ? [defQues] : longPollsData,
    );

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        setLongPollsData(questions);
        // Reset the form
        setStep(3);
    };

    const prevStep = () => {
        setStep(1);
    };

    const btnclose = () => {
        setShowModal(false);
        window.location.reload(true);
    };

    // const handleSubmit = () => {
    //     // Handle form submission here

    //     console.log('Submit Quiz: ', questions);
    //     setLongPollsData(questions);
    //     // Reset the form
    //     setQuestions([defQues]);
    // };

    const handleQuestionChange = (index, fieldName, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = {
            ...updatedQuestions[index],
            [fieldName]: value,
        };
        setQuestions(updatedQuestions);
    };

    const handleScoreChange = (questionIndex, fieldName, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex] = {
            ...updatedQuestions[questionIndex],
            [fieldName]: value,
        };
        setQuestions(updatedQuestions);
    };

    const handleImageChange = (questionIndex, imageIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].bannersMedia[imageIndex].src = value;
        if (
            value.includes('png') ||
            value.includes('webp') ||
            value.includes('jpg') ||
            value.includes('avif')
        ) {
            updatedQuestions[questionIndex].bannersMedia[imageIndex].type =
                'image';
        } else {
            updatedQuestions[questionIndex].bannersMedia[imageIndex].type =
                'video';
        }
        setQuestions(updatedQuestions);
    };

    const handleOptionChange = (
        questionIndex,
        optionIndex,
        fieldName,
        value,
    ) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex] = {
            ...updatedQuestions[questionIndex].options[optionIndex],
            [fieldName]: value,
        };
        if (fieldName === 'isCorrect' && value) {
            setCorrectOptionIndex(optionIndex);
            updatedQuestions[questionIndex].isCorrect = value;
            updatedQuestions[questionIndex].isCorrectOption = optionIndex;
        } else if (
            fieldName === 'isCorrect' &&
            correctOptionIndex === optionIndex
        ) {
            updatedQuestions[questionIndex].isCorrect = null;
            updatedQuestions[questionIndex].isCorrectOption = null;
            setCorrectOptionIndex(null);
        }
        setQuestions(updatedQuestions);
    };

    const handleAddQuestion = () => {
        const newQues = defQues;
        newQues.rank = questions.length + 1;
        console.log(`New Ques: `, newQues);
        setQuestions([...questions, newQues]);
    };

    const handleRemoveQuestion = index => {
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
    };

    const handleAddImage = questionIndex => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].bannersMedia.push({
            src: '',
            cta: '',
            type: '',
        });
        setQuestions(updatedQuestions);
    };

    const handleRemoveImage = (questionIndex, imageIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].bannersMedia.splice(imageIndex, 1);
        setQuestions(updatedQuestions);
    };

    const handleAddOption = questionIndex => {
        const updatedQuestions = [...questions];
        const optionCount = updatedQuestions[questionIndex].options.length;
        updatedQuestions[questionIndex].options.push({
            text: '',
            isCorrect: false,
            rank: optionCount + 1,
        });
        setQuestions(updatedQuestions);
    };

    const handleRemoveOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options.splice(optionIndex, 1);
        setQuestions(updatedQuestions);
    };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Questionnaire Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik initialValues={{ questions }}>
                    {values => (
                        <Form
                            onSubmit={e => {
                                e.preventDefault();
                                handleSubmit(values);
                            }}
                        >
                            <FieldArray name="questions">
                                {() => (
                                    <div>
                                        {questions.map((question, index) => (
                                            <div key={index} className="mb-4">
                                                <h4>Question {index + 1}</h4>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Question:
                                                    </Form.Label>
                                                    <Field
                                                        name={`questions[${index}].question`}
                                                        as={Form.Control}
                                                        type="text"
                                                        value={
                                                            question.question
                                                        }
                                                        onChange={e =>
                                                            handleQuestionChange(
                                                                index,
                                                                'question',
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </Form.Group>

                                                <FieldArray
                                                    name={`questions[${index}].bannersMedia`}
                                                >
                                                    {() => (
                                                        <div className="mb-4">
                                                            <Form.Label>
                                                                Images:
                                                            </Form.Label>
                                                            {question.bannersMedia.map(
                                                                (
                                                                    image,
                                                                    imageIndex,
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            imageIndex
                                                                        }
                                                                        className="d-flex align-items-center mb-2"
                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={
                                                                                image.src
                                                                            }
                                                                            onChange={e =>
                                                                                handleImageChange(
                                                                                    index,
                                                                                    imageIndex,
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                            className="mr-2"
                                                                        />
                                                                        {image && (
                                                                            <Image
                                                                                src={
                                                                                    image.src
                                                                                }
                                                                                alt="Invalid"
                                                                                height="100"
                                                                            />
                                                                        )}

                                                                        {imageIndex >
                                                                            0 && (
                                                                            <Button
                                                                                variant="danger"
                                                                                onClick={() =>
                                                                                    handleRemoveImage(
                                                                                        index,
                                                                                        imageIndex,
                                                                                    )
                                                                                }
                                                                                className="ml-2"
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                ),
                                                            )}
                                                            <Button
                                                                variant="primary"
                                                                onClick={() =>
                                                                    handleAddImage(
                                                                        index,
                                                                    )
                                                                }
                                                                className="mt-2"
                                                            >
                                                                Add Image
                                                            </Button>
                                                        </div>
                                                    )}
                                                </FieldArray>

                                                <FieldArray
                                                    name={`questions[${index}].options`}
                                                >
                                                    {() => (
                                                        <div className="mb-4">
                                                            <Form.Label>
                                                                Options:
                                                            </Form.Label>
                                                            {question.options.map(
                                                                (
                                                                    option,
                                                                    optionIndex,
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            optionIndex
                                                                        }
                                                                        className="d-flex align-items-center gap-2 mb-2"
                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={
                                                                                option.text
                                                                            }
                                                                            onChange={e =>
                                                                                handleOptionChange(
                                                                                    index,
                                                                                    optionIndex,
                                                                                    'text',
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                            className="mr-2"
                                                                        />
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            checked={
                                                                                option.isCorrect
                                                                            }
                                                                            onChange={e =>
                                                                                handleOptionChange(
                                                                                    index,
                                                                                    optionIndex,
                                                                                    'isCorrect',
                                                                                    e
                                                                                        .target
                                                                                        .checked,
                                                                                )
                                                                            }
                                                                            size={
                                                                                '10'
                                                                            }
                                                                            className="ml-2 mb-4"
                                                                        />
                                                                        {optionIndex >
                                                                            0 && (
                                                                            <Button
                                                                                variant="danger"
                                                                                onClick={() =>
                                                                                    handleRemoveOption(
                                                                                        index,
                                                                                        optionIndex,
                                                                                    )
                                                                                }
                                                                                className="ml-2"
                                                                            >
                                                                                -
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                ),
                                                            )}

                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="mr-2">
                                                                    <Form.Label>
                                                                        +ve
                                                                        Score:
                                                                    </Form.Label>
                                                                    <Field
                                                                        name={`questions[${index}].positiveWeight`}
                                                                        as={
                                                                            Form.Control
                                                                        }
                                                                        type="number"
                                                                        value={
                                                                            question.positiveWeight
                                                                        }
                                                                        onChange={e =>
                                                                            handleScoreChange(
                                                                                index,
                                                                                'positiveWeight',
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            )
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className="mr-2">
                                                                    <Form.Label>
                                                                        -ve
                                                                        Score:
                                                                    </Form.Label>
                                                                    <Field
                                                                        name={`questions[${index}].negativeWeight`}
                                                                        as={
                                                                            Form.Control
                                                                        }
                                                                        type="number"
                                                                        value={
                                                                            question.negativeWeight
                                                                        }
                                                                        onChange={e =>
                                                                            handleScoreChange(
                                                                                index,
                                                                                'negativeWeight',
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <Button
                                                                variant="primary"
                                                                onClick={() =>
                                                                    handleAddOption(
                                                                        index,
                                                                    )
                                                                }
                                                                className="mt-2"
                                                            >
                                                                Add Option
                                                            </Button>
                                                        </div>
                                                    )}
                                                </FieldArray>

                                                {index > 0 && (
                                                    <Button
                                                        variant="danger"
                                                        onClick={() =>
                                                            handleRemoveQuestion(
                                                                index,
                                                            )
                                                        }
                                                        className="mb-2"
                                                    >
                                                        Remove Question
                                                    </Button>
                                                )}
                                                {
                                                    <hr className="question-divider" />
                                                }
                                            </div>
                                        ))}
                                        <Button
                                            variant="primary"
                                            onClick={handleAddQuestion}
                                            className="mb-2"
                                        >
                                            Add Question
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>
                            <Modal.Footer
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Button variant="secondary" onClick={btnclose}>
                                    Close
                                </Button>

                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => prevStep()}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={e => handleSubmit(e)}
                                >
                                    Preview
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default QuestionnaireForm;
