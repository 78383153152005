/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import axios from 'axios';

import { globalURLS } from '../Global/urls';

interface SelectProps {
    value: string;
    label: string;
}

interface CountrySelectProps extends SelectProps {
    countryISO: string;
    name: string;
}

interface UserDropDownProps {
    designation: SelectProps[];
    userType: SelectProps[];
    institute: SelectProps[];
    pursuing: SelectProps[];
    degree: SelectProps[]
}

interface CityProps {
    id: string;
    name: string;
    state: string;
}

interface CountryProps {
    numberLength: number;
    name: string;
    iso2: string;
    dialCode: string;
    flag: string;
}

function useUserDropDown(
    getSelectCities?: boolean,
    getUserDropDown?: boolean,
    getSelectCountries?: boolean,
): any {
    const [selectCities, setSelectCities] = useState<SelectProps[]>();
    const [selectCountries, setSelectCountries] =
        useState<CountrySelectProps[]>();

    const [selectUserDropDown, setUserDropDown] = useState<UserDropDownProps>();

    useEffect(() => {
        const fetchData = async () => {
            if (getSelectCities) {
                const citiesData = await axios.get(
                    globalURLS.indianCitiesJSONURL,
                );

                if (citiesData?.data) {
                    setSelectCities(
                        citiesData?.data?.map((el: CityProps) => ({
                            value: el.id,
                            label: el.name,
                        })),
                    );
                }
            }

            if (getSelectCountries) {
                const countries = await axios.get(globalURLS.countriesJSONURL);

                if (countries?.data) {
                    setSelectCountries(
                        countries?.data?.map((el: CountryProps) => ({
                            value: el.dialCode,
                            label: `${el.iso2.toUpperCase()}-${el.dialCode}`,
                            name: el.name,
                            countryISO: el.iso2,
                        })),
                    );
                }
            }
            if (getUserDropDown) {
                const UsersDropDownData = await axios.post(
                    `${globalURLS.baseURL}getUserCreatDropdownData`,
                );

                if (UsersDropDownData?.data) {
                    setUserDropDown(UsersDropDownData.data.data);
                }
            }
        };
        fetchData();
    }, [getSelectCities, getSelectCountries, getUserDropDown]);

    return {
        selectCities,
        selectCountries,
        selectUserDropDown,
    };
}

export default useUserDropDown;
