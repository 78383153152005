/* eslint-disable no-prototype-builtins */
import axios, { AxiosRequestConfig } from 'axios';
import React, { useState, useEffect } from 'react';
import { Form, Button, ProgressBar, ToastContainer, Toast } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { globalURLS } from '../../../Global/urls';
import { useGlobalContext } from '../../../Context/index';
import { axiosRespData } from '../../../Components/Report/interface';

interface User {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    profilepic?: string;
    profilepicthumb?: string;
    callingcode?: number;
    cityid: string;
    isspeaker?: boolean;
    isselectspeaker?: boolean;
    specialtyid: string;
    yearOfPassing?: number;
    isstudent?: boolean;
    isTest?: boolean;
    isdummy?: boolean;
    handle?: string;
    createdvia?: string;
    acquiredfor?: string;
    toVerify?: boolean;
    deviceType?: string;
    systemVersion?: string;
    useragent?: string;
    appversion?: string;
    summary?: string;
    disableIntercom?: boolean;
    countryid?: string;
    designationid?: string;
    yob?: number;
    instituteid?: string;
    pursuingid?: string;
    usertypeid?: string;
    tempaccountids?: string;
    discussions?: string[];
    isprofilecompleted?: boolean;
}

const AddUsersForm: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setshowSuccess] = useState<axiosRespData | boolean>(false);

    const { setShowErrorToast } = useGlobalContext();

    const removeFile = () => {
        setFile(null);
        setUsers([]);
        setProgress(0);
    };

    useEffect(() => {
        removeFile()
    }, [])

    const handleFileDrop = async (acceptedFiles: File[]) => {
        const text = await acceptedFiles[0].text();
        const rows1 = text.split('\n');

        setFile(acceptedFiles[0]);

        if (rows1[0] !== null) {
            const headers = rows1[0].split(',').map(header => header.trim());
            const rows = rows1.slice(1);

            const result = rows.map(row => {
                const values = row.split(',');
                const obj = {};
                for (let i = 0; i < headers.length; i++) {
                    obj[headers[i]] =
                        values[i] !== undefined
                            ? values[i].trim().replace(/"/g, '')
                            : '';
                }
                return obj;
            });

            const requiredFields = [
                'firstname',
                'lastname',
                'callingcode',
                'email',
                'phone',
                'cityid',
                'specialtyid',
            ];

            const filteredData = result.filter(obj =>
                requiredFields.every(field => obj[field] !== ''),
            );

            setUsers(filteredData);
        } else {
            console.log('No users found');
        }
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!file) {
            console.log('No file selected');
            return;
        }
        // onSubmit(users);
        if (users) {
            setIsLoading(true);
            try {
                const resp = await axios.post(
                    `${globalURLS.baseURL}bulkCreateAccountAndAddToDiscussion`,
                    { users },
                    {
                        ...globalURLS.config,
                        onUploadProgress: progressEvent => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total,
                            );
                            setProgress(percentCompleted);
                        },
                    },
                );
    
                if (resp?.data?.success === true) {
                    setshowSuccess(resp.data.data);
                } else {
                    setShowErrorToast(true);
                }
            }
            catch (err) {
                setShowErrorToast(true);
            }
            setIsLoading(false);
            removeFile()
        }
    };

    // console.log('users', users);
    return (
        <div>
            <Form
                style={{
                    border: '1px solid grey',
                    background: '#FFFFFF',
                    padding: '1em',
                    borderRadius: '0.6em',
                    width: '30vw',
                }}
                onSubmit={handleSubmit}
            >
                <Form.Label>Create Bulk Users, by droping csv file.</Form.Label>
                <Form.Group
                    style={{
                        border: '1px solid grey',
                        background: '#E9ECEF',
                        padding: '1em',
                        borderRadius: '0.6em',
                    }}
                >
                    <Dropzone onDrop={handleFileDrop} accept=".csv">
                        {({ getRootProps, getInputProps }) => (
                            <>
                                <Button
                                    className="mt-3 me-2"
                                    variant={file ? 'danger' : 'secondary'}
                                    onClick={removeFile}
                                    disabled={!file}
                                >
                                    Remove File
                                </Button>

                                {file && (
                                    <div className="mt-3 mb-2">
                                        <div>
                                            <strong>Selected file:</strong>
                                        </div>
                                        <div>{file.name}</div>
                                    </div>
                                )}
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {!file
                                        ? (
                                            <div>
                                                <Form.Label className="mt-2">
                                                    {`Drag and drop a CSV file or click here to select a file.`}
                                                </Form.Label>
                                                Column Options:
                                                <Form.Label className="mt-2">
                                                    {`*firstname, lastname, *phone, callingcode, profilepic, cityid, specialtyid, summary, usertypeid, degreeid, yearOfPassing`}
                                                </Form.Label>
                                                *only star marked are mandatory
                                            </div>
                                        )
                                        :
                                        (<Form.Label className="mt-2"></Form.Label>)
                                    }
                                </div>
                                <div className="mb-3">
                                    <ProgressBar
                                        now={progress}
                                        label={`${progress}%`}
                                    />
                                </div>
                            </>
                        )}
                    </Dropzone>
                </Form.Group>
                <Button
                    className="mt-3"
                    variant={isLoading ? 'warning' : 'primary'}
                    type="submit"
                >
                    {isLoading ? 'Processing' : 'Submit'}
                </Button>
            </Form>

            {!!showSuccess && (
                <ToastContainer
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '15em',
                    }}
                    position={'middle-center'}
                >
                    <Toast onClose={() => setshowSuccess(false)} show={!!showSuccess}>
                        <Toast.Header>
                            <strong
                                style={{
                                    fontSize: '1em',
                                    padding: '0.5em',
                                    color: 'green',
                                }}
                            >
                                Report is ready to download
                            </strong>
                        </Toast.Header>
                        {console.log('-setshowSuccess', setshowSuccess)}
                        <Toast.Body>
                            {!!showSuccess.url && (
                                <Button
                                    href={showSuccess.url}
                                    variant="success"
                                    target="_blank"
                                    onClick={() => setshowSuccess(false)}
                                >
                                    Download
                                </Button>
                            )}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            )}
        </div>
    );
};

export default AddUsersForm;
