import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components imports
import { globalURLS } from '../../../Global/urls';
import Table from '../../../Components/Common/Table/Table';

interface Speaker {
    accountid: string;
    name: string;
    phone: string;
    isstudent: boolean;
    isverified: boolean;
}

function SpeakersTable(): JSX.Element {
    const [speakersData, setSpeakersData] = useState<Speaker>();

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${globalURLS.speakersJSONURL}`);
            setSpeakersData(
                response.data.sort((a, b) => a.name.localeCompare(b.name)),
            );
        };
        fetchData();
    }, []);

    const [columnDefs] = useState([
        { field: 'accountid', sortable: true, resizable: true },
        { field: 'name', sortable: true, resizable: true },
        { field: 'phone', sortable: true, resizable: true },
        { field: 'isstudent', sortable: true, resizable: true },
        { field: 'isverified', sortable: true, resizable: true },
    ]);

    return (
        <>
            <Table
                rowData={speakersData || []}
                columnDefs={columnDefs}
                title={'Speakers'}
            ></Table>
        </>
    );
}

export default SpeakersTable;
