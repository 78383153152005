/* eslint-disable react/prop-types */
import React from 'react';

import './AddNews.css';
import { useFeedContext } from '../../../Context/index.tsx';
import StepOne from '../Steps/Step1/StepOne.js';
import ShortPoll from '../Steps/Step2/ShortPoll/ShortPoll.js';
import Final from '../Steps/Step3/Final.js';

function AddNews() {
    const { step } = useFeedContext();

    switch (step) {
        case 1:
            return (
                <>
                    <StepOne />
                </>
            );
        case 2:
            return (
                <>
                    <ShortPoll />
                </>
            );
        case 3:
            return (
                <>
                    <Final />
                </>
            );
        default:
            return <></>;
    }
}

export default AddNews;
