/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';

import { appBanner } from '../interface';
import CustomButton from '../../Common/CustomButton/CustomButton';
import { useBannerContext } from '../../../Context/index';
import CustomInput from '../../Common/CustomInput/CustomInput';

import SuccessToast from '../../Common/Toast/SuccessToast';
import FailureToast from '../../Common/Toast/FailureToast';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import LoadingToast from '../../Common/Toast/LoadingToast';

const UpdateAppBannerForum: React.FC = () => {
    const { appBannerData, updateAppBannerModal, setUpdateAppBannerModal } =
        useBannerContext();

    const [validated, setValidated] = useState(false);
    const [forumData, setForumData] = useState<appBanner>(appBannerData);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showFailureToast, setShowFailureToast] = useState(false);
    const [showLoadingToast, setShowLoadingToast] = useState(false);

    const handleName = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, name: value });
    };

    const handleImageURL = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, imageurl: value });
    };

    const handlePriority = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, priority: value });
    };

    // console.log('forumData', forumData);
    const timeHandler = (handleName: string, e: any) => {
        const { value } = e.target;

        switch (handleName) {
            case 'startDate':
                setForumData({ ...forumData, startdate: value });
                break;
            case 'startTime':
                setForumData({ ...forumData, starttime: value });
                break;
            default:
                break;
            case 'endDate':
                setForumData({ ...forumData, enddate: value });

                break;
            case 'endTime':
                setForumData({ ...forumData, endtime: value });
                break;
        }
    };

    const handleIsActive = () => {
        setForumData({ ...forumData, isactive: forumData?.isactive ? 0 : 1 });
    };

    const handleSuccessToast = () => {
        setShowSuccessToast(false);
        window.location.reload();
    };

    const handleFailureToast = () => {
        setShowFailureToast(false);
    };

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        setShowLoadingToast(true);

        // console.log('forumData', forumData);

        try {
            const data = forumData;
            data.startdate = data.startDateUTC;
            data.enddate = data.endDateUTC;

            const respData = await axios.post(
                `${globalURLS.baseURL}updateBanners`,
                { banners: [forumData] },
                globalURLS.config,
            );

            // console.log('forumData', forumData);

            // console.log('respData?.data', respData?.data);
            setShowLoadingToast(true);

            if (respData?.data?.success === true) {
                setShowLoadingToast(false);
                setShowSuccessToast(true);
                setUpdateAppBannerModal(false);
            }

            if (respData?.data?.success === false) {
                setShowLoadingToast(false);
                setShowFailureToast(true);
            }
        } catch (e) {
            setShowLoadingToast(false);
            setShowFailureToast(true);
        }
    };

    useEffect(() => {
        const values = forumData;
        if (forumData?.startdate && forumData?.starttime) {
            const startDateUTC = zonedTimeToUtc(
                `${forumData?.startdate + ' ' + forumData?.starttime}`,
                'Asia/Calcutta',
            );

            values.startDateUTC = String(startDateUTC);
            setForumData(values);
        }
        if (forumData?.enddate && forumData?.endtime) {
            const endDateUTC = zonedTimeToUtc(
                `${forumData?.enddate + ' ' + forumData?.endtime}`,
                'Asia/Calcutta',
            );
            values.endDateUTC = String(endDateUTC);
            setForumData(values);
        }
    }, [
        forumData?.startdate,
        forumData?.starttime,
        forumData?.enddate,
        forumData?.endtime,
    ]);

    useEffect(() => {
        const value = forumData;

        const finalStartDate = format(
            new Date(forumData?.startdate),
            'yyyy-MM-dd',
            { timeZone: 'Asia/Calcutta' },
        );

        const finalStartTime = format(new Date(forumData?.startdate), 'HH:mm', {
            timeZone: 'Asia/Calcutta',
        });

        // setForumData({
        //     ...value,
        //     startDateUTC: `$${finalStartDate} ${finalStartTime}`,
        //     startdate: finalStartDate,
        //     starttime: finalStartTime,
        // });

        const finalEndDate = format(
            new Date(forumData?.enddate),
            'yyyy-MM-dd',
            { timeZone: 'Asia/Calcutta' },
        );

        const finalEndTime = format(new Date(forumData?.enddate), 'HH:mm', {
            timeZone: 'Asia/Calcutta',
        });

        setForumData({
            ...value,
            startDateUTC: `$${finalStartDate} ${finalStartTime}`,
            startdate: finalStartDate,
            starttime: finalStartTime,
            endDateUTC: `$${finalEndDate} ${finalEndTime}`,
            enddate: finalEndDate,
            endtime: finalEndTime,
        });
    }, [appBannerData?.bannerid]);

    return (
        <Modal show={updateAppBannerModal} animation={false}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title className="mb-0">
                        Update App Banner
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <InputGroup className={'row mt-1'}>
                            {/* Banner ID */}
                            <CustomInput
                                label={'Banner ID'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                disabled={true}
                                value={forumData?.bannerid}
                                errorMessage={'Banner ID is Empty'}
                            />
                            {/* Name */}
                            <CustomInput
                                label={'Banner Name'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                placeHolder={'Enter a Banner Name'}
                                value={forumData?.name}
                                onChange={handleName}
                                errorMessage={'Please Enter Banner Name'}
                            />
                            {/* Image URL */}
                            <CustomInput
                                label={'Image URL'}
                                textArea={'short'}
                                type={'textImage'}
                                required={true}
                                placeHolder={'Enter a  Image URL'}
                                value={forumData?.imageurl}
                                onChange={handleImageURL}
                                errorMessage={'Please Enter  Image URL'}
                            />

                            {/* Priority */}
                            <CustomInput
                                label={'Priority (Number from 0-9)'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                placeHolder={'Enter a  Priority'}
                                value={forumData?.priority}
                                onChange={handlePriority}
                                errorMessage={'Please Enter  Priority'}
                            />

                            {/* Start Date & Time */}
                            <InputGroup className={'row mt-1'}>
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    Start Date & Time
                                </Form.Label>
                                <CustomInput
                                    textArea="short"
                                    type={'date'}
                                    required={true}
                                    errorMessage="Please provide a start date."
                                    value={forumData?.startdate}
                                    onChange={(e: any) => {
                                        timeHandler('startDate', e);
                                    }}
                                />
                                <CustomInput
                                    textArea="short"
                                    type={'time'}
                                    required={true}
                                    errorMessage="Please provide a start time."
                                    value={forumData?.starttime}
                                    onChange={(e: any) => {
                                        timeHandler('startTime', e);
                                    }}
                                />
                            </InputGroup>

                            {/* End Date & Time */}
                            <InputGroup className={'row mt-1'}>
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    End Date & Time
                                </Form.Label>
                                <CustomInput
                                    textArea="short"
                                    type={'date'}
                                    required={true}
                                    errorMessage="Please provide a end date."
                                    value={forumData?.enddate}
                                    onChange={(e: any) => {
                                        timeHandler('endDate', e);
                                    }}
                                />
                                <CustomInput
                                    textArea="short"
                                    type={'time'}
                                    required={true}
                                    errorMessage="Please provide a end time."
                                    value={forumData?.endtime}
                                    onChange={(e: any) => {
                                        timeHandler('endTime', e);
                                    }}
                                />
                            </InputGroup>

                            {/* IsActive */}
                            <InputGroup className="mt-3 ml-5">
                                <CustomInput
                                    textArea="short"
                                    type="switches"
                                    label="Active"
                                    value={forumData?.isactive}
                                    onChange={handleIsActive}
                                />
                            </InputGroup>
                        </InputGroup>
                    </>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                    }}
                >
                    <CustomButton
                        variant="secondary"
                        onClick={() => setUpdateAppBannerModal(false)}
                    >
                        Close
                    </CustomButton>

                    <CustomButton
                        variant="primary"
                        type="submit"
                        disabled={showLoadingToast ? true : false}
                        onClick={handleSubmit}
                    >
                        Update
                    </CustomButton>
                </Modal.Footer>
            </Form>

            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={handleSuccessToast}
                    headerTitle="Success"
                />
            )}

            {showFailureToast && (
                <FailureToast
                    showModal={showFailureToast}
                    handleClose={handleFailureToast}
                    headerTitle="Failure"
                />
            )}

            {showLoadingToast && (
                <LoadingToast
                    showModal={showLoadingToast}
                    headerTitle="Processing..."
                />
            )}
        </Modal>
    );
};

export default UpdateAppBannerForum;
