import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { useGlobalContext } from '../../../Context';

import './index.css';
import { BiCloudUpload, BiMessageRoundedDetail } from 'react-icons/bi';

const FloatingAddButton = () => {
    const {
        showUploaderModal,
        setShowUploaderModal,
        showMessengerModal,
        setShowMessengerModal,
    } = useGlobalContext();

    return (
        <Container className="floating-button-container">
            <Button
                className="floating-add-button-1 "
                onClick={() =>
                    setShowUploaderModal(showUploaderModal ? false : true)
                }
            >
                <BiCloudUpload size={'200px'} />
            </Button>
            <Button
                className="floating-add-button-2"
                onClick={() =>
                    setShowMessengerModal(showMessengerModal ? false : true)
                }
            >
                <BiMessageRoundedDetail size={'200px'} />
            </Button>
        </Container>
    );
};

export default FloatingAddButton;
