import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { globalURLS } from '../../../Global/urls.js';

interface RemoveQuizButtonProps {
    discussionid: string;
}

async function removeQuiz(props: RemoveQuizButtonProps) {
    const { discussionid } = props;
    if (!discussionid) {
        console.error('discussionid is undefined or empty');
        return;
    }
    try {
        await axios.post(
            `${globalURLS.baseURL}ADdeleteQuiz`,
            {
                discussionid: discussionid,
            },
            globalURLS.config,
        );
        // console.log(`Quiz with ID ${discussionid} deleted successfully.`);
    } catch (error) {
        console.error('Error deleting the quiz:', error);
    }
}

function RemoveQuizButton(props: RemoveQuizButtonProps) {
    return (
        <Button variant="danger" size="sm" onClick={() => removeQuiz(props)}>
            Delete Quiz
        </Button>
    );
}

export default RemoveQuizButton;
