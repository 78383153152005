import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row } from 'react-bootstrap';
import Select from 'react-select';

import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import { useGlobalContext } from '../../../Context';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';

const ManageAdSpot: React.FC<{
    show: boolean;
    onHide(): void;
}> = ({ show, onHide }) => {
    const initialValue: any = [];

    const initialDiscussionid = {
        value: '',
        label: '',
    };

    const [discussionid, setDiscussionID] = useState<{
        value: string;
        label: string;
    }>(initialDiscussionid);
    const [defaultValues, setDefaultValues] = useState(true);

    const [arrayInternalHosts, setArrayInternalHosts] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getSelectDiscussions = true;
    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const reqBody = {
                    discussionid: discussionid?.value,
                };

                const resp = await axios.post(
                    `${globalURLS.baseURL}ADgetHiddenDiscussionAdSpotUsers`,
                    reqBody,
                    globalURLS.config,
                );

                if (resp?.data?.success === true) {
                    Array.isArray(resp?.data?.data)
                        ? setDefaultValues(false)
                        : setDefaultValues(true);
                    const finalData = Array.isArray(resp?.data?.data)
                        ? resp?.data?.data
                        : initialValue;
                    setArrayInternalHosts(finalData);
                } else {
                    setArrayInternalHosts(initialValue);
                    setDefaultValues(true);
                    setShowErrorToast(true);
                }
            } catch (error) {
                console.log('--err', error);
            }
        };

        fetchData();
    }, [discussionid]);

    const { selectSpeakers } = useDiscussionsClubsHostsInternalHosts({
        getSelectSpeakers: true,
        getSelectSpecialties: true,
        isDisabled: true,
    });

    const handleInternalHosts = (val: any) => {
        setArrayInternalHosts(val);
    };

    const renderUsers = () => {
        return (
            <>
                <Form.Label className="mt-3" htmlFor="basic-url">
                    Select User To Hide from Ad Spots
                </Form.Label>
                <Select
                    closeMenuOnSelect={false}
                    isMulti
                    value={arrayInternalHosts}
                    onChange={val => handleInternalHosts(val)}
                    options={JSON.parse(JSON.stringify(selectSpeakers || []))}
                />
            </>
        );
    };

    const handleSubmit = async () => {
        try {
            setValidated(true);

            setIsLoading(true);

            const mappedAccontIds = arrayInternalHosts?.map(
                accountid => accountid.value,
            );
            const reqBody = {
                discussionid: discussionid?.value,
                accountIds: mappedAccontIds,
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADhiddenDiscussionAdSpotUsers`,
                reqBody,
                globalURLS.config,
            );

            setIsLoading(false);

            if (resp?.data?.success === true) {
                setShowSuccessToast(true);
                resetAllStates();
                onHide();
            } else {
                setShowErrorToast(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log('--error', error);
            setShowErrorToast(true);
        }
    };

    const resetAllStates = () => {
        setDiscussionID(initialDiscussionid);
        setArrayInternalHosts(initialValue);
    };
    const handleHide = async () => {
        resetAllStates();
        onHide();
    };

    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Row>
                    <Modal.Title>Manage Ad Spot Users</Modal.Title>
                    <Modal.Title style={{ fontSize: '0.8em' }}>
                        {defaultValues
                            ? 'Note: These are Default Values, Please do fill these'
                            : 'Note: These are Prefilled Values from the Actual Data'}
                    </Modal.Title>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Form.Group controlId="formSpecialties">
                        <Form.Label>Select Discussion</Form.Label>
                        <Select
                            name="discussionid"
                            options={selectDiscussions}
                            isMulti={false}
                            isSearchable
                            value={discussionid}
                            onChange={(e: any) => setDiscussionID(e)}
                        />
                    </Form.Group>

                    {discussionid?.value && renderUsers()}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    Close
                </Button>
                <Button
                    variant={isLoading ? 'warning' : 'success'}
                    type="submit"
                    onClick={handleSubmit}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManageAdSpot;
