import React, { useState } from 'react';

// Components imports
import adminAPIdData from './ReportsData';
import { ReportsData } from './interface.js';
import './Report.css';
import { Container, Button } from 'react-bootstrap';
import { useReportContext } from '../../Context/index';
import ReportModal from './ReportModal/ReportModal';

const Report: React.FC = () => {
    const [rowData] = useState<ReportsData[]>(adminAPIdData);
    const { setReportData, setReportModal } = useReportContext();

    const onClickButton = (props: ReportsData): void => {
        setReportData(props);
        setReportModal(true);
    };
    return (
        // <Container className="bg1">
        //     <Container className="bg2">
        <>
            <Container className="row row-cols-4">
                {rowData
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((el, idx) => {
                        return (
                            <div key={idx}>
                                <Button
                                    style={{ width: '15vw', height: '8vh' }}
                                    className="btn-add"
                                    variant="primary"
                                    onClick={() => onClickButton(el)}
                                >
                                    {el.name}
                                </Button>
                            </div>
                        );
                    })}
            </Container>
            <ReportModal />
        </>
    );
};

export default Report;
