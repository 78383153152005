import React, { useEffect, useState } from 'react';
import {
    Modal,
    Form,
    Button,
    Dropdown,
    Row,
    Col,
    FormCheck,
} from 'react-bootstrap';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';

import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import { useGlobalContext } from '../../../Context';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';

interface InRoomRating {
    id: string;
    data: {
        text1?: string;
        text2?: string;
        title?: string;
        iconurl?: string;
    };
}

const AddInRoomRating: React.FC<{
    show: boolean;
    onHide(): void;
}> = ({ show, onHide }) => {
    const initialValue = [
        {
            id: uuid(),
            data: {
                text1: 'Please rate your experience attending the session',
                text2: '',
                title: '',
                iconurl: 'https://plexusmd.gumlet.io/stars1_1701177450429.png',
            },
        },
    ];

    const initialDiscussionid = {
        value: '',
        label: '',
    };

    const [discussionid, setDiscussionID] = useState<{
        value: string;
        label: string;
    }>(initialDiscussionid);
    const [defaultValues, setDefaultValues] = useState(true);

    const [roomRating, setRoomRating] = useState<InRoomRating[]>(initialValue);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getSelectDiscussions = true;
    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const reqBody = {
                    discussionid: discussionid?.value,
                };

                const resp = await axios.post(
                    `${globalURLS.baseURL}ADgetInRoomRating`,
                    reqBody,
                    globalURLS.config,
                );

                if (resp?.data?.success === true) {
                    Array.isArray(resp?.data?.data)
                        ? setDefaultValues(false)
                        : setDefaultValues(true);
                    const finalData = Array.isArray(resp?.data?.data)
                        ? resp?.data?.data
                        : initialValue;
                    setRoomRating(finalData);
                } else {
                    setRoomRating(initialValue);
                    setDefaultValues(true);
                    setShowErrorToast(true);
                }
            } catch (error) {
                console.log('--err', error);
            }
        };
        if (discussionid) {
            setRoomRating(prevRoomRating =>
                prevRoomRating?.map(item => ({
                    ...item,
                    data: {
                        ...item.data,
                        text2: discussionid?.label || '',
                        title: discussionid?.label || '',
                    },
                })),
            );
        }
        fetchData();
    }, [discussionid]);

    const handleAdd = (index: number) => {
        const updatedRoomRating = [...roomRating];
        updatedRoomRating.splice(index + 1, 0, { ...initialValue[0] });
        setRoomRating(updatedRoomRating);
    };

    const handleRemove = (index: number) => {
        if (roomRating.length === 1 && defaultValues === false) {
            setShowDeleteConfirmation(true);
        } else {
            const updatedRoomRating = [...roomRating];
            updatedRoomRating.splice(index, 1);
            setRoomRating(updatedRoomRating);
        }
    };

    const BGStyle = {
        backgroundColor: '#BFBFBF',
        height: 'auto',
        margin: '4px',
        padding: '10px',
        borderRadius: '10px',
    };

    const renderRoomRating = () => {
        return (
            <>
                {roomRating &&
                    roomRating.length &&
                    roomRating?.map((room: InRoomRating, index: number) => (
                        <Row key={room.id} className="mt-3" style={BGStyle}>
                            <Col>
                                {/* Title */}
                                <Form.Group
                                    controlId={`roomRating[${index}].data.title`}
                                >
                                    <Form.Label>Rating Identifier</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Rating Identifier"
                                        value={room?.data?.title}
                                        onChange={e => {
                                            const value = e.target.value;
                                            roomRating[index].data.title =
                                                value;
                                            setRoomRating([...roomRating]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Text 1 */}
                                <Form.Group
                                    controlId={`roomRating[${index}].data.text1`}
                                >
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Title"
                                        value={room?.data?.text1}
                                        onChange={e => {
                                            const value = e.target.value;
                                            roomRating[index].data.text1 =
                                                value;
                                            setRoomRating([...roomRating]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Text 2 */}
                                <Form.Group
                                    controlId={`roomRating[${index}].data.text2`}
                                >
                                    <Form.Label>Subtitle</Form.Label>
                                    <Form.Control
                                        type="text"
                                        // required
                                        placeholder="Enter Subtitle"
                                        value={room?.data?.text2}
                                        onChange={e => {
                                            const value = e.target.value;
                                            roomRating[index].data.text2 =
                                                value;
                                            setRoomRating([...roomRating]);
                                        }}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback> */}
                                </Form.Group>

                                {/* Icon URL */}
                                <Form.Group
                                    controlId={`roomRating[${index}].data.iconurl`}
                                >
                                    <Form.Label>Icon URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Icon URL"
                                        value={room?.data?.iconurl}
                                        onChange={e => {
                                            const value = e.target.value;
                                            roomRating[index].data.iconurl =
                                                value;
                                            setRoomRating([...roomRating]);
                                        }}
                                    />
                                    {room?.data?.iconurl && (
                                        <Row className="m-2">
                                            <Col xs={12}>
                                                <img
                                                    src={room?.data?.iconurl}
                                                    alt={`Image ${room?.data?.iconurl}`}
                                                    style={{
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Button
                                        variant="danger"
                                        className="m-2"
                                        disabled={
                                            index === 0 &&
                                            roomRating.length === 1 &&
                                            defaultValues
                                                ? true
                                                : false
                                        }
                                        onClick={() => handleRemove(index)}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleAdd(index)}
                                    >
                                        Add More
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    ))}
            </>
        );
    };

    const confirmDelete = () => {
        return (
            <Modal
                show={showDeleteConfirmation}
                onHide={() => setShowDeleteConfirmation(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete? This action will delete
                    everything and cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteConfirmation(false)}
                    >
                        No
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const handleSubmit = async () => {
        try {
            setValidated(true);

            setIsLoading(true);

            const reqBody = {
                discussionid: discussionid?.value,
                roomRating: roomRating,
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADaddUpdateInRoomRating`,
                reqBody,
                globalURLS.config,
            );

            setIsLoading(false);

            if (resp?.data?.success === true) {
                setShowSuccessToast(true);
                resetAllStates();
                onHide();
            } else {
                setShowErrorToast(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log('--error', error);
            setShowErrorToast(true);
        }
    };

    const handleShowDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const reqBody = {
                discussionid: discussionid?.value,
                roomRating: {},
                deleteType: true,
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADaddUpdateInRoomRating`,
                reqBody,
                globalURLS.config,
            );

            if (resp?.data?.success === true) {
                resetAllStates();
                setShowSuccessToast(true);
            } else {
                setShowErrorToast(true);
            }
        } catch (error) {
            console.log('-err', error);
        }

        setShowDeleteConfirmation(false);
    };

    const resetAllStates = () => {
        setDiscussionID(initialDiscussionid);
        setRoomRating(initialValue);
    };
    const handleHide = async () => {
        resetAllStates();
        onHide();
    };
    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Row>
                    <Modal.Title>
                        Create/Update/Delete In Room Rating
                    </Modal.Title>
                    <Modal.Title style={{ fontSize: '0.8em' }}>
                        {defaultValues
                            ? 'Note: These are Default Values, Please do fill these'
                            : 'Note: These are Prefilled Values from the Actual Data'}
                    </Modal.Title>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Form.Group controlId="formSpecialties">
                        <Form.Label>Select Discussion</Form.Label>
                        <Select
                            name="discussionid"
                            options={selectDiscussions}
                            isMulti={false}
                            isSearchable
                            value={discussionid}
                            onChange={(e: any) => setDiscussionID(e)}
                        />
                    </Form.Group>

                    {discussionid?.value && !defaultValues && (
                        <Form.Group controlId="deleteBtn">
                            <Col>
                                <Button
                                    variant="danger"
                                    className="mt-4"
                                    onClick={() =>
                                        handleShowDeleteConfirmation()
                                    }
                                >
                                    Delete Type
                                </Button>
                            </Col>
                        </Form.Group>
                    )}

                    {discussionid?.value && renderRoomRating()}
                    {confirmDelete()}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    Close
                </Button>
                <Button
                    variant={isLoading ? 'warning' : 'success'}
                    type="submit"
                    onClick={handleSubmit}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddInRoomRating;
