/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { Button, Modal, Container, Form, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';

import { DiscussionContext, useGlobalContext } from '../../../Context/index';
import { globalURLS } from '../../../Global/urls.js';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import CustomInput from '../../Common/CustomInput/CustomInput';

interface AddonVideo {
    discussionid: string;
    url: string;
    videoExt: string;
    altImgUrl: string;
    position: string;
    userType: string;
    canSkip: string;
    skipUserType: string;
    skipAfterSeconds: string;
    markSeenAfterSeconds: string;
    thumbnailurl: string;
}

interface Props {}

const AddVideoAds: React.FC<Props> = () => {
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const { openAddVideoAdsModal, setAddVideoAdsModal } = useContext(
        DiscussionContext.AddVideoAdsContext,
    );

    const [discussionid, setDiscussionid] = useState<string>('');

    const [inputList, setinputList] = useState<AddonVideo[]>([
        {
            discussionid: '',
            url: '',
            videoExt: '',
            altImgUrl: '',
            position: '',
            userType: '',
            canSkip: '',
            skipUserType: 'free',
            skipAfterSeconds: '',
            markSeenAfterSeconds: '',
            thumbnailurl: '',
        },
    ]);

    const [validated, setValidated] = useState(false);

    const getSelectDiscussions = true;

    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const btnclose = () => {
        setinputList([]);
        setAddVideoAdsModal(false);
    };

    const handleInputChange = (
        name: keyof AddonVideo,
        val: string | number | boolean | any,
        index: number,
    ) => {
        const list = [...inputList];
        list[index]['discussionid'] = discussionid || '';
        list[index][name] = typeof val === 'string' ? val : val?.target.value;
        setinputList(list);
    };

    const handleRemove = (index: number) => {
        const list = [...inputList];
        list.splice(index, 1);
        setinputList(list);
    };

    const handleAddClick = () => {
        setinputList([
            ...inputList,
            {
                discussionid: '',
                url: '',
                videoExt: '',
                altImgUrl: '',
                position: '',
                userType: '',
                canSkip: '',
                skipUserType: 'free',
                skipAfterSeconds: '',
                markSeenAfterSeconds: '',
                thumbnailurl: '',
            },
        ]);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        const bodyInput = {
            discussionid: discussionid,
            addonVideos: inputList,
        };

        const resp = await axios.post(
            `${globalURLS.baseURL}ADaddAddonVideoForLive`,
            bodyInput,
            globalURLS.config,
        );

        if (resp?.data?.success === true) {
            setinputList([]);
            setShowSuccessToast(true);
            setAddVideoAdsModal(false);
        } else {
            setShowErrorToast(true);
        }
    };

    return (
        <Container>
            <Col>
                <Modal show={openAddVideoAdsModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Join Room Videos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* Discussionid */}
                            <div className="form-group col-md-6">
                                <Form.Label>Discussion</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a Discussion.
                                </Form.Control.Feedback>
                                <Select
                                    closeMenuOnSelect={true}
                                    onChange={val => setDiscussionid(val.value)}
                                    isSearchable
                                    options={selectDiscussions}
                                />
                            </div>
                            <div
                                className="row "
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {inputList &&
                                    inputList.map((input, i) => {
                                        return (
                                            <>
                                                {discussionid && (
                                                    <Row
                                                        key={i}
                                                        style={{
                                                            background:
                                                                '#F2F2F2',
                                                            borderRadius: '1em',
                                                            height: 'auto',
                                                            width: '1vw',
                                                            marginTop: '1em',
                                                        }}
                                                    >
                                                        {/* Video Format */}
                                                        <div className="form-group row-md-6">
                                                            <CustomInput
                                                                classNames={''}
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={
                                                                    'dropdown'
                                                                }
                                                                errorMessage={`Please select video format`}
                                                                label={
                                                                    'Video Format'
                                                                }
                                                                placeHolder={
                                                                    'Video Format'
                                                                }
                                                                required={true}
                                                                value={
                                                                    input?.videoExt ||
                                                                    'mp4'
                                                                }
                                                                defaultValue={
                                                                    'mp4'
                                                                }
                                                                dropDown={{
                                                                    title: 'Video Format ',
                                                                    dropDownItems:
                                                                        [
                                                                            'mp4',
                                                                            'm3u8',
                                                                        ],
                                                                }}
                                                                onSelect={e => {
                                                                    handleInputChange(
                                                                        'videoExt',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                            />
                                                            {/* URL */}
                                                            <CustomInput
                                                                label={
                                                                    'Video URL'
                                                                }
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={'text'}
                                                                required={true}
                                                                placeHolder={
                                                                    'Enter a Video URL'
                                                                }
                                                                value={
                                                                    input?.url ||
                                                                    ''
                                                                }
                                                                onChange={e => {
                                                                    handleInputChange(
                                                                        'url',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                                errorMessage={
                                                                    'Please Enter Video URL'
                                                                }
                                                            />

                                                            {/* thumbnail URL */}
                                                            <CustomInput
                                                                label={
                                                                    'ThumbnailURL'
                                                                }
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={'text'}
                                                                required={true}
                                                                placeHolder={
                                                                    'Enter a ThumbnailURL'
                                                                }
                                                                value={
                                                                    input?.thumbnailurl ||
                                                                    ''
                                                                }
                                                                onChange={e => {
                                                                    handleInputChange(
                                                                        'thumbnailurl',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                                errorMessage={
                                                                    'Please Enter ThumbnailURL'
                                                                }
                                                            />

                                                            {/* Alt Image URL */}
                                                            <CustomInput
                                                                label={
                                                                    'Alt Image URL'
                                                                }
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={'text'}
                                                                required={true}
                                                                placeHolder={
                                                                    'Enter a Alt Image URL'
                                                                }
                                                                value={
                                                                    input?.altImgUrl ||
                                                                    ''
                                                                }
                                                                onChange={e => {
                                                                    handleInputChange(
                                                                        'altImgUrl',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                                errorMessage={
                                                                    'Please Enter Alt Image URL'
                                                                }
                                                            />

                                                            {/* Ad Position */}

                                                            <CustomInput
                                                                classNames={''}
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={
                                                                    'dropdown'
                                                                }
                                                                errorMessage={`Please select Ad Position`}
                                                                label={
                                                                    'Ad Position'
                                                                }
                                                                placeHolder={
                                                                    'Ad Position'
                                                                }
                                                                required={true}
                                                                value={
                                                                    input?.position ||
                                                                    'start'
                                                                }
                                                                dropDown={{
                                                                    title: 'Ad Position ',
                                                                    dropDownItems:
                                                                        [
                                                                            'start',
                                                                            'end',
                                                                        ],
                                                                }}
                                                                onSelect={e => {
                                                                    handleInputChange(
                                                                        'position',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                            />

                                                            {/* User Type */}

                                                            <CustomInput
                                                                classNames={''}
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={
                                                                    'dropdown'
                                                                }
                                                                errorMessage={`Please select User Type`}
                                                                label={
                                                                    'User Type'
                                                                }
                                                                placeHolder={
                                                                    'User Type'
                                                                }
                                                                required={true}
                                                                value={
                                                                    input?.position ||
                                                                    'free'
                                                                }
                                                                dropDown={{
                                                                    title: 'User Type ',
                                                                    dropDownItems:
                                                                        [
                                                                            'free',
                                                                            'premium',
                                                                            'all',
                                                                        ],
                                                                }}
                                                                onSelect={e => {
                                                                    handleInputChange(
                                                                        'userType',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                            />

                                                            {/* Skip User Type */}

                                                            <CustomInput
                                                                classNames={''}
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={
                                                                    'dropdown'
                                                                }
                                                                errorMessage={`Please select Skip User Type`}
                                                                label={
                                                                    'Skip User Type'
                                                                }
                                                                placeHolder={
                                                                    'Skip User Type'
                                                                }
                                                                required={true}
                                                                value={
                                                                    input?.skipUserType
                                                                }
                                                                dropDown={{
                                                                    title: 'Skip User Type ',
                                                                    dropDownItems:
                                                                        [
                                                                            'free',
                                                                            'premium',
                                                                            'all',
                                                                        ],
                                                                }}
                                                                onSelect={e => {
                                                                    handleInputChange(
                                                                        'skipUserType',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                            />

                                                            {/* Skip After Seconds in sec */}
                                                            <CustomInput
                                                                label={
                                                                    'Skip After Seconds in sec'
                                                                }
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={'text'}
                                                                required={true}
                                                                placeHolder={
                                                                    'Enter a Skip After Seconds in sec'
                                                                }
                                                                value={
                                                                    input?.skipAfterSeconds ||
                                                                    ''
                                                                }
                                                                onChange={e => {
                                                                    handleInputChange(
                                                                        'skipAfterSeconds',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                                errorMessage={
                                                                    'Please Enter Skip After Seconds in sec'
                                                                }
                                                            />

                                                            {/* Mark Seen After Seconds in sec */}
                                                            <CustomInput
                                                                label={
                                                                    'Mark Seen After Seconds in sec'
                                                                }
                                                                textArea={
                                                                    'short'
                                                                }
                                                                type={'text'}
                                                                required={true}
                                                                placeHolder={
                                                                    'Enter a Mark Seen After Seconds in sec'
                                                                }
                                                                value={
                                                                    input?.markSeenAfterSeconds ||
                                                                    ''
                                                                }
                                                                onChange={e => {
                                                                    handleInputChange(
                                                                        'markSeenAfterSeconds',
                                                                        e,
                                                                        i,
                                                                    );
                                                                }}
                                                                errorMessage={
                                                                    'Please Enter Mark Seen After Seconds in sec'
                                                                }
                                                            />
                                                        </div>

                                                        {/* Buttons */}
                                                        <div className="form-group ">
                                                            {inputList.length !==
                                                                1 && (
                                                                <Button
                                                                    className="btn btn-danger mx-2 mb-2"
                                                                    onClick={() =>
                                                                        handleRemove(
                                                                            i,
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </Button>
                                                            )}
                                                            {inputList.length -
                                                                1 ===
                                                                i && (
                                                                <Button
                                                                    style={{
                                                                        width: 'auto',
                                                                    }}
                                                                    className="btn btn-success mx-2 mb-2"
                                                                    onClick={
                                                                        handleAddClick
                                                                    }
                                                                >
                                                                    Add More
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </Row>
                                                )}
                                            </>
                                        );
                                    })}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
};

export default AddVideoAds;
