/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';

import { appBanner } from '../interface';
import CustomButton from '../../Common/CustomButton/CustomButton';
import { useBannerContext } from '../../../Context/index';
import CustomInput from '../../Common/CustomInput/CustomInput';

import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import SuccessToast from '../../Common/Toast/SuccessToast';
import FailureToast from '../../Common/Toast/FailureToast';
import { parse } from 'papaparse';
import { zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import LoadingToast from '../../Common/Toast/LoadingToast';
interface value {
    value: string;
    label: string;
}

const AddAppBannerForum: React.FC = () => {
    const getSelectDiscussions = true;
    const getSelectClubs = true;
    const { selectDiscussions, selectClubs } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectDiscussions,
            getSelectClubs,
        });

    const { setAddBannerModal } = useBannerContext();
    const [validated, setValidated] = useState(false);
    const [forumData, setForumData] = useState<appBanner>();

    const [name, setName] = useState(forumData?.name || '');
    const [priority, setPriority] = useState(forumData?.priority || '');
    const [imageURL, setImageURL] = useState(forumData?.imageurl || '');
    const [startDate, setStartDate] = useState(forumData?.startdate || '');
    const [startTime, setStartTime] = useState(forumData?.starttime || '');

    const [endDate, setEndDate] = useState(forumData?.enddate || '');
    const [endTime, setEndTime] = useState(forumData?.endtime || '');

    const [action, setAction] = useState(forumData?.action || 'deeplink');
    const [openWebviewUrl, setOpenWebviewUrl] = useState(
        forumData?.openWebviewUrl || '',
    );
    const [value, setValue] = useState<string>();
    const [appVersionSpecific, setAppVersionSpecific] = useState<string>();
    const [minAndroidIos, setMinAndroidIos] = useState({
        ios: '',
        android: '',
    });
    const [maxAndroidIos, setMaxAndroidIos] = useState({
        ios: '',
        android: '',
    });

    const [screen, setScreen] = useState(forumData?.screen || 'Discussion');

    const [videoid, setvideoid] = useState(forumData?.screenData || '');
    const [title, setTitle] = useState(forumData?.screenData || '');
    const [showFor, setShowFor] = useState(forumData?.showfor || 'all');
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showFailureToast, setShowFailureToast] = useState(false);
    const [showLoadingToast, setShowLoadingToast] = useState(false);
    const [accountids, setAccountids] = useState(forumData?.ids || []);
    const [feedDeepLink, setFeedDeepLink] = useState({
        source: '',
        feedItemId: '',
        module: '',
        conferenceid: '',
    });

    const handleName = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, name: value });
        setName(value);
    };

    const handleImageURL = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, imageurl: value });
        setImageURL(value);
    };

    const handlePriority = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, priority: value });
        setPriority(value);
    };

    const timeHandler = (handleName: string, e: any) => {
        const { value } = e.target;

        switch (handleName) {
            case 'startDate':
                setForumData({ ...forumData, startdate: value });
                setStartDate(value);
                break;
            case 'startTime':
                setForumData({ ...forumData, starttime: value });
                setStartTime(value);
                break;
            default:
                break;
            case 'endDate':
                setForumData({ ...forumData, enddate: value });
                setEndDate(value);
                break;
            case 'endTime':
                setForumData({ ...forumData, endtime: value });
                setEndTime(value);
                break;
        }
    };

    const handleAction = (e: any) => {
        setForumData({ ...forumData, action: e, type: 'image' });
        setAction(e);
    };

    const handleOpenWebViewURL = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, openWebviewUrl: value });
        setOpenWebviewUrl(value);
    };

    const handleScreen = (e: any) => {
        setForumData({ ...forumData, screen: e });
        setScreen(e);
    };

    const handleAppVersionSpecific = (e: any) => {
        setForumData({ ...forumData, appVersionSpecific: e });
        setAppVersionSpecific(e);
    };

    const handleSetShowFor = (e: any) => {
        setForumData({ ...forumData, showfor: e });
        setShowFor(e);
    };

    const handleValue = (val: value) => {
        setValue(val?.value);
    };

    const handlesetTitle = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, title: value });
        setTitle(value);
    };

    const handlesetFeedDeepLink = (name: string, e: any) => {
        const { value } = e.target;
        setFeedDeepLink({ ...feedDeepLink, [name]: value });
    };
    const handlesetVideoId = (e: any) => {
        const { value } = e.target;
        setForumData({ ...forumData, videoid: value });
        setvideoid(value);
    };

    const handleSuccessToast = () => {
        setShowSuccessToast(false);
        window.location.reload();
    };

    const handleFailureToast = () => {
        setShowFailureToast(false);
    };

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        setShowLoadingToast(true);
        if (action === 'deeplink') {
            let openDeeplinkData: any;
            if (screen === 'Discussion') {
                openDeeplinkData = {
                    params: {
                        discussionid: value,
                    },
                    screen: screen,
                };
            }

            if (screen === 'Club') {
                openDeeplinkData = {
                    params: {
                        params: {
                            clubid: value,
                        },
                        screen: 'ClubHome',
                    },
                    screen: 'Club',
                };
            }

            if (screen === 'YoutubeEmbed') {
                openDeeplinkData = {
                    params: {
                        title: title,
                        videoid: videoid,
                        discussionid: value,
                    },
                    screen: screen,
                };
            }

            if (screen === 'Room') {
                openDeeplinkData = {
                    params: {
                        channel: {
                            id: value,
                            title: title,
                        },
                    },
                    screen: 'Room',
                };
            }

            if (screen === 'Feed') {
                openDeeplinkData = {
                    params: feedDeepLink,
                    screen: 'Feed',
                };
            }

            if (screen === 'AIAssist') {
                openDeeplinkData = {
                    params: feedDeepLink,
                    screen: 'AIAssist',
                };
            }

            if (screen === 'conference') {
                openDeeplinkData = {
                    params: feedDeepLink,
                    screen: 'conference',
                };
            }

            if (screen === 'Sessions') {
                openDeeplinkData = {
                    params: feedDeepLink,
                    screen: 'Sessions',
                };
            }
            forumData.minappversion = minAndroidIos;
            forumData.maxappversion = maxAndroidIos;
            forumData.openDeeplink = openDeeplinkData;
            // console.log('forumData.openDeeplink', forumData.openDeeplink);
        }

        if (accountids && accountids.length) {
            forumData.ids = accountids;
        }

        try {
            if (forumData.startDateUTC && forumData.endDateUTC) {
                forumData.startdate = forumData.startDateUTC;
                forumData.enddate = forumData.endDateUTC;
            }

            // console.log('forumData', forumData);
            const respData = await axios.post(
                `${globalURLS.baseURL}addBanners`,
                { banners: [forumData] },
                globalURLS.config,
            );

            setShowLoadingToast(true);

            // console.log('respData', respData);
            if (respData?.data?.success === true) {
                setShowLoadingToast(false);
                setShowSuccessToast(true);
                setAddBannerModal(false);
            }

            if (respData?.data?.success === false) {
                setShowLoadingToast(false);
                setShowFailureToast(true);
            }
        } catch (e) {
            setShowLoadingToast(false);
            setShowFailureToast(true);
        }
    };

    useEffect(() => {
        const values = forumData;
        if (startDate && startDate.length && startTime && startTime.length) {
            const startDateUTC = zonedTimeToUtc(
                `${startDate + ' ' + startTime}`,
                'Asia/Calcutta',
            );
            values.startDateUTC = String(startDateUTC);
            setForumData(values);
        }
        if (endDate && endDate.length && endTime && endTime.length) {
            const endDateUTC = zonedTimeToUtc(
                `${endDate + ' ' + endTime}`,
                'Asia/Calcutta',
            );
            values.endDateUTC = String(endDateUTC);
            setForumData(values);
        }
    }, [startDate, startTime, endDate, endTime]);

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title className="mb-0">Add App Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <InputGroup className={'row mt-1'}>
                            {/* Name */}
                            <CustomInput
                                label={'Banner Name'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                placeHolder={'Enter a Banner Name'}
                                value={name}
                                onChange={handleName}
                                errorMessage={'Please Enter Banner Name'}
                            />
                            {/* Image URL */}
                            <CustomInput
                                label={'Image URL'}
                                textArea={'short'}
                                type={'textImage'}
                                required={true}
                                placeHolder={'Enter a  Image URL'}
                                value={imageURL}
                                onChange={handleImageURL}
                                errorMessage={'Please Enter  Image URL'}
                            />
                            {/* Priority */}
                            <CustomInput
                                label={'Priority (Number from 0-9)'}
                                textArea={'short'}
                                type={'text'}
                                required={true}
                                placeHolder={'Enter a  Priority'}
                                value={priority}
                                onChange={handlePriority}
                                errorMessage={'Please Enter  Priority'}
                            />
                            {/* Start Date & Time */}
                            <InputGroup className={'row mt-1'}>
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    Start Date & Time
                                </Form.Label>
                                <CustomInput
                                    textArea="short"
                                    type={'date'}
                                    required={true}
                                    errorMessage="Please provide a start date."
                                    value={startDate}
                                    onChange={(e: any) => {
                                        timeHandler('startDate', e);
                                    }}
                                />
                                <CustomInput
                                    textArea="short"
                                    type={'time'}
                                    required={true}
                                    errorMessage="Please provide a start time."
                                    value={startTime}
                                    onChange={(e: any) => {
                                        timeHandler('startTime', e);
                                    }}
                                />
                            </InputGroup>
                            {/* End Date & Time */}
                            <InputGroup className={'row mt-1'}>
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    End Date & Time
                                </Form.Label>
                                <CustomInput
                                    textArea="short"
                                    type={'date'}
                                    required={true}
                                    errorMessage="Please provide a end date."
                                    value={endDate}
                                    onChange={(e: any) => {
                                        timeHandler('endDate', e);
                                    }}
                                />
                                <CustomInput
                                    textArea="short"
                                    type={'time'}
                                    required={true}
                                    errorMessage="Please provide a end time."
                                    value={endTime}
                                    onChange={(e: any) => {
                                        timeHandler('endTime', e);
                                    }}
                                />
                            </InputGroup>
                            <CustomInput
                                label={'Banner Action'}
                                textArea={'short'}
                                type={'dropdown'}
                                required={true}
                                placeHolder={'Enter a Banner Action'}
                                value={action}
                                dropDown={{
                                    title: 'Select Banner Action ',
                                    dropDownItems: [
                                        'deeplink',
                                        'openExternalUrl',
                                    ],
                                }}
                                onSelect={handleAction}
                                errorMessage={'Please Enter Banner Action'}
                            />
                            {action && action === 'openExternalUrl' && (
                                // openWebviewUrl
                                <CustomInput
                                    label={'Open Web View URL'}
                                    textArea={'short'}
                                    type={'text'}
                                    required={true}
                                    placeHolder={'Enter a  Open Web View URL'}
                                    value={openWebviewUrl}
                                    onChange={handleOpenWebViewURL}
                                    errorMessage={
                                        'Please Enter  Open Web View URL'
                                    }
                                />
                            )}
                            {action && action === 'deeplink' && (
                                // Screen
                                <CustomInput
                                    label={'Screen'}
                                    textArea={'short'}
                                    type={'dropdown'}
                                    required={true}
                                    value={screen}
                                    dropDown={{
                                        title: 'Select Screen ',
                                        dropDownItems: [
                                            'Club',
                                            'Discussion',
                                            'Feed',
                                            'Room',
                                            'YoutubeEmbed',
                                            'AIAssist',
                                            'conference',
                                            'Sessions',
                                            'ReplayTab',
                                        ],
                                    }}
                                    onSelect={handleScreen}
                                    errorMessage={'Please Enter Screen'}
                                />
                            )}

                            {screen === 'conference' && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Conference ID</Form.Label>
                                    <Form.Control
                                        name="conferenceid"
                                        required
                                        value={feedDeepLink.conferenceid}
                                        onChange={e =>
                                            setFeedDeepLink({
                                                ...feedDeepLink,
                                                conferenceid: e.target.value,
                                            })
                                        }
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Add Conference ID
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                            {screen === 'AIAssist' && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Module</Form.Label>
                                    <Form.Select
                                        name="module"
                                        required
                                        value={feedDeepLink.module}
                                        onChange={e =>
                                            setFeedDeepLink({
                                                ...feedDeepLink,
                                                module: e.target.value,
                                            })
                                        }
                                    >
                                        {' '}
                                        <option value="">-</option>
                                        <option value="DD">DD</option>
                                        <option value="DDX">DDX</option>
                                        <option value="DI">DI</option>
                                        <option value="AMA">AMA</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please Add Module
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}

                            {action === 'deeplink' &&
                                screen === 'Discussion' && (
                                    // Select Discussion
                                    <Select
                                        closeMenuOnSelect={true}
                                        onChange={val => handleValue(val)}
                                        isSearchable
                                        options={selectDiscussions}
                                    />
                                )}
                            {action === 'deeplink' && screen === 'Club' && (
                                // Select Club
                                <Select
                                    closeMenuOnSelect={true}
                                    onChange={(val: value) => handleValue(val)}
                                    isSearchable
                                    options={selectClubs}
                                />
                            )}
                            {action === 'deeplink' && screen === 'Room' && (
                                <>
                                    {/* Select Discussion */}
                                    <Select
                                        closeMenuOnSelect={true}
                                        onChange={(val: value) =>
                                            handleValue(val)
                                        }
                                        isSearchable
                                        options={selectDiscussions}
                                    />

                                    {/* Title Name */}
                                    <CustomInput
                                        label={'Title'}
                                        textArea={'short'}
                                        type={'text'}
                                        required={true}
                                        placeHolder={'Enter a Title'}
                                        value={title}
                                        defaultValue={name}
                                        onChange={handlesetTitle}
                                        errorMessage={'Please Enter Title'}
                                    />
                                </>
                            )}
                            {action === 'deeplink' &&
                                screen === 'YoutubeEmbed' && (
                                    <>
                                        {/* Select Discussion */}
                                        <Select
                                            closeMenuOnSelect={true}
                                            onChange={(val: value) =>
                                                handleValue(val)
                                            }
                                            isSearchable
                                            options={selectDiscussions}
                                        />

                                        {/* Title Name */}
                                        <CustomInput
                                            label={'Title'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={'Enter a Title'}
                                            value={title}
                                            defaultValue={name}
                                            onChange={handlesetTitle}
                                            errorMessage={'Please Enter Title'}
                                        />

                                        {/* VideoId */}
                                        <CustomInput
                                            label={'VideoId'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={'Enter a VideoId'}
                                            value={videoid}
                                            defaultValue={name}
                                            onChange={handlesetVideoId}
                                            errorMessage={
                                                'Please Enter VideoId'
                                            }
                                        />
                                    </>
                                )}

                            {action === 'deeplink' && (
                                // {/* Source */}
                                <CustomInput
                                    label={'Source'}
                                    textArea={'short'}
                                    type={'text'}
                                    required={true}
                                    placeHolder={'Enter a Source'}
                                    value={feedDeepLink?.source}
                                    onChange={e =>
                                        handlesetFeedDeepLink('source', e)
                                    }
                                    errorMessage={'Please Enter Source'}
                                />
                            )}
                            {action === 'deeplink' && screen === 'Feed' && (
                                <>
                                    {/* FeedItem Id */}
                                    <CustomInput
                                        label={'FeedItem'}
                                        textArea={'short'}
                                        type={'text'}
                                        required={true}
                                        placeHolder={'Enter a FeedItem'}
                                        value={feedDeepLink?.feedItemId}
                                        onChange={e =>
                                            handlesetFeedDeepLink(
                                                'feedItemId',
                                                e,
                                            )
                                        }
                                        errorMessage={'Please Enter FeedItem'}
                                    />
                                </>
                            )}
                            <CustomInput
                                label={'Show For'}
                                textArea={'short'}
                                type={'dropdown'}
                                required={true}
                                value={screen}
                                dropDown={{
                                    title: 'Select Show For ',
                                    dropDownItems: ['all', 'accountid'],
                                }}
                                onSelect={handleSetShowFor}
                                errorMessage={'Please Select'}
                            />
                            {showFor === 'accountid' && (
                                <>
                                    <Form.Group
                                        controlId="formFile"
                                        className="mb-3"
                                    >
                                        <Form.Label>
                                            Accountids CSV File
                                        </Form.Label>
                                        <Form.Control
                                            type="file"
                                            accept=".csv"
                                            onChange={async e => {
                                                const files = e.target.files;

                                                if (files) {
                                                    console.log(
                                                        'files',
                                                        files[0],
                                                    );
                                                    parse(files[0], {
                                                        header: true,
                                                        complete: function (
                                                            results,
                                                        ) {
                                                            const data =
                                                                results.data.map(
                                                                    (
                                                                        el: string,
                                                                    ) =>
                                                                        el.accountid,
                                                                );
                                                            console.log(
                                                                'data',
                                                                data,
                                                            );
                                                            setAccountids(data);
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </>
                            )}
                            {/* Min Max  App Version Specific */}
                            <CustomInput
                                label={'App Version Specific'}
                                textArea={'short'}
                                type={'dropdown'}
                                required={true}
                                value={appVersionSpecific}
                                dropDown={{
                                    title: 'Select App Version Specific ',
                                    dropDownItems: ['Yes', 'No'],
                                }}
                                onSelect={handleAppVersionSpecific}
                                errorMessage={
                                    'Please Select App Version Specific '
                                }
                            />
                            {appVersionSpecific === 'Yes' && (
                                <>
                                    <Form.Label
                                        className="m-2 mt-3"
                                        htmlFor="basic-url"
                                    >
                                        Min Version
                                    </Form.Label>
                                    <InputGroup className={'col'}>
                                        {/* ios */}
                                        <CustomInput
                                            label={'IOS'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={
                                                'Enter a  IOS Minimum Version'
                                            }
                                            value={minAndroidIos?.ios}
                                            onChange={(e: any) =>
                                                setMinAndroidIos({
                                                    ...minAndroidIos,
                                                    ios: e.target.value,
                                                })
                                            }
                                            errorMessage={
                                                'Please Enter  IOS Minimum Version'
                                            }
                                        />

                                        {/* android */}
                                        <CustomInput
                                            label={'Android'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={
                                                'Enter a  Android Minimum Version'
                                            }
                                            value={minAndroidIos?.android}
                                            onChange={(e: any) =>
                                                setMinAndroidIos({
                                                    ...minAndroidIos,
                                                    android: e.target.value,
                                                })
                                            }
                                            errorMessage={
                                                'Please Enter  Android Minimum Version'
                                            }
                                        />
                                    </InputGroup>

                                    <Form.Label
                                        className="m-2 mt-3"
                                        htmlFor="basic-url"
                                    >
                                        Max Version
                                    </Form.Label>
                                    <InputGroup className={'col'}>
                                        {/* ios */}
                                        <CustomInput
                                            label={'IOS'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={
                                                'Enter a  IOS Maximum Version'
                                            }
                                            value={maxAndroidIos?.ios}
                                            onChange={(e: any) =>
                                                setMaxAndroidIos({
                                                    ...maxAndroidIos,
                                                    ios: e.target.value,
                                                })
                                            }
                                            errorMessage={
                                                'Please Enter  IOS Maximum Version'
                                            }
                                        />

                                        {/* android */}
                                        <CustomInput
                                            label={'Android'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={
                                                'Enter a  Android Maximum Version'
                                            }
                                            value={maxAndroidIos?.android}
                                            onChange={(e: any) =>
                                                setMaxAndroidIos({
                                                    ...maxAndroidIos,
                                                    android: e.target.value,
                                                })
                                            }
                                            errorMessage={
                                                'Please Enter  Android Maximum Version'
                                            }
                                        />
                                    </InputGroup>
                                </>
                            )}
                        </InputGroup>
                    </>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                    }}
                >
                    <CustomButton
                        variant="secondary"
                        onClick={() => {
                            setAddBannerModal(false), setForumData(null);
                        }}
                    >
                        Close
                    </CustomButton>

                    <CustomButton
                        variant="primary"
                        type="submit"
                        disabled={showLoadingToast ? true : false}
                        onClick={handleSubmit}
                    >
                        Create
                    </CustomButton>
                </Modal.Footer>
            </Form>

            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={handleSuccessToast}
                    headerTitle="Success"
                />
            )}

            {showFailureToast && (
                <FailureToast
                    showModal={showFailureToast}
                    handleClose={handleFailureToast}
                    headerTitle="Failure"
                />
            )}

            {showLoadingToast && (
                <LoadingToast
                    showModal={showLoadingToast}
                    headerTitle="Processing..."
                />
            )}
        </>
    );
};

export default AddAppBannerForum;
