import React, { useEffect, useState } from 'react';
import {
    Modal,
    Form,
    Button,
    Dropdown,
    Row,
    Col,
    FormCheck,
} from 'react-bootstrap';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';

import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import { useGlobalContext } from '../../../Context';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';

interface IData {
    authorid: string;
    value: string;
    label: string;
    title: string;
    url: string;
}

const ManageAudioExt: React.FC<{
    show: boolean;
    onHide(): void;
}> = ({ show, onHide }) => {
    const initialValue = [
        { authorid: '', value: '', label: '', title: '', url: '' },
    ];

    const initialDiscussionid = {
        value: '',
        label: '',
    };

    const initialIData: IData[] = [
        { authorid: '', value: '', label: '', title: '', url: '' },
    ];

    const [discussionid, setDiscussionID] = useState<{
        value: string;
        label: string;
    }>(initialDiscussionid);
    const [defaultValues, setDefaultValues] = useState(true);

    const [data, setData] = useState<IData[]>(initialIData);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getSelectDiscussions = true;
    const getSelectSpeakers = true;

    const { selectDiscussions, selectSpeakers } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectDiscussions,
            getSelectSpeakers,
        });

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const reqBody = {
                    discussionid: discussionid?.value,
                };

                const resp = await axios.post(
                    `${globalURLS.baseURL}ADgetAudioExt`,
                    reqBody,
                    globalURLS.config,
                );

                if (resp?.data?.success === true) {
                    Array.isArray(resp?.data?.data) && resp?.data?.data?.length
                        ? setDefaultValues(false)
                        : setDefaultValues(true);
                    const finalData =
                        Array.isArray(resp?.data?.data) &&
                        resp?.data?.data?.length
                            ? resp?.data?.data
                            : initialValue;
                    setData(finalData);
                } else {
                    setData(initialValue);
                    setDefaultValues(true);
                    setShowErrorToast(true);
                }
            } catch (error) {
                console.log('--err', error);
            }
        };

        fetchData();
    }, [discussionid]);

    const handleAdd = (index: number) => {
        const updateddata = [...data];
        updateddata.splice(index + 1, 0, { ...initialValue[0] });
        setData(updateddata);
    };

    const handleRemove = (index: number) => {
        if (data.length === 1 && defaultValues === false) {
            setShowDeleteConfirmation(true);
        } else {
            const updateddata = [...data];
            updateddata.splice(index, 1);
            setData(updateddata);
        }
    };

    const BGStyle = {
        backgroundColor: '#BFBFBF',
        height: 'auto',
        margin: '4px',
        padding: '10px',
        borderRadius: '10px',
    };

    const renderdata = () => {
        return (
            <>
                {data &&
                    data.length &&
                    data?.map((info: IData, index: number) => (
                        <Row key={info.url} className="mt-3" style={BGStyle}>
                            <Col>
                                {/* Author */}
                                <Form.Group
                                    controlId={`data[${index}].authorid`}
                                >
                                    <Form.Label>Author</Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        onChange={(val: {
                                            value: string;
                                            label: string;
                                        }) => {
                                            data[index].authorid = val?.value;
                                            data[index].label = val?.label;
                                            setData([...data]);
                                        }}
                                        value={{
                                            value: info.authorid,
                                            label: info.label,
                                        }}
                                        isSearchable
                                        options={selectSpeakers}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Title */}
                                <Form.Group controlId={`data[${index}].title`}>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Title"
                                        value={info?.title}
                                        onChange={e => {
                                            const value = e.target.value;
                                            data[index].title = value;
                                            setData([...data]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Audio URL */}
                                <Form.Group controlId={`data[${index}].url`}>
                                    <Form.Label>Audio URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Audio URL"
                                        value={info?.url}
                                        onChange={e => {
                                            const value = e.target.value;
                                            data[index].url = value;
                                            setData([...data]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Button
                                        variant="danger"
                                        className="m-2"
                                        disabled={
                                            index === 0 &&
                                            data.length === 1 &&
                                            defaultValues
                                                ? true
                                                : false
                                        }
                                        onClick={() => handleRemove(index)}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleAdd(index)}
                                    >
                                        Add More
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    ))}
            </>
        );
    };

    const confirmDelete = () => {
        return (
            <Modal
                show={showDeleteConfirmation}
                onHide={() => setShowDeleteConfirmation(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete? This action will delete
                    everything and cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteConfirmation(false)}
                    >
                        No
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const handleSubmit = async () => {
        try {
            setValidated(true);

            setIsLoading(true);

            const reqBody = {
                discussionid: discussionid?.value,
                data: data,
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADUpdateAudioExt`,
                reqBody,
                globalURLS.config,
            );

            setIsLoading(false);

            if (resp?.data?.success === true) {
                setShowSuccessToast(true);
                resetAllStates();
                onHide();
            } else {
                setShowErrorToast(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log('--error', error);
            setShowErrorToast(true);
        }
    };

    const handleShowDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const reqBody = {
                discussionid: discussionid?.value,
                data: {},
                deleteType: true,
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADUpdateAudioExt`,
                reqBody,
                globalURLS.config,
            );

            if (resp?.data?.success === true) {
                resetAllStates();
                setShowSuccessToast(true);
            } else {
                setShowErrorToast(true);
            }
        } catch (error) {
            console.log('-err', error);
        }

        setShowDeleteConfirmation(false);
    };

    const resetAllStates = () => {
        setDiscussionID(initialDiscussionid);
        setData(initialValue);
    };
    const handleHide = async () => {
        resetAllStates();
        onHide();
    };
    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Row>
                    <Modal.Title>Manage in-data Audio</Modal.Title>
                    <Modal.Title style={{ fontSize: '0.8em' }}>
                        {defaultValues
                            ? 'Note: These are Default Values, Please do fill these'
                            : 'Note: These are Prefilled Values from the Actual Data'}
                    </Modal.Title>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Form.Group controlId="formSpecialties">
                        <Form.Label>Select Discussion</Form.Label>
                        <Select
                            name="discussionid"
                            options={selectDiscussions}
                            isMulti={false}
                            isSearchable
                            value={discussionid}
                            onChange={(e: any) => setDiscussionID(e)}
                        />
                    </Form.Group>

                    {discussionid?.value && !defaultValues && (
                        <Form.Group controlId="deleteBtn">
                            <Col>
                                <Button
                                    variant="danger"
                                    className="mt-4"
                                    onClick={() =>
                                        handleShowDeleteConfirmation()
                                    }
                                >
                                    Delete All
                                </Button>
                            </Col>
                        </Form.Group>
                    )}

                    {discussionid?.value && renderdata()}
                    {confirmDelete()}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    Close
                </Button>
                <Button
                    variant={isLoading ? 'warning' : 'success'}
                    type="submit"
                    onClick={handleSubmit}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManageAudioExt;
