/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import axios from 'axios';

import { globalURLS } from '../Global/urls';
import { selectDiscussion, selectClub } from '../global';

interface IInput {
    getSelectDiscussions?: boolean;
    getSelectClubs?: boolean;
    getSelectSpeakers?: boolean;
    getSelectInternalSpeakers?: boolean;
    getSelectSpecialties?: boolean;
    isDisabled?: boolean;
}

function useDiscussionsClubsHostsInternalHosts({
    getSelectDiscussions,
    isDisabled,
    getSelectClubs,
    getSelectSpeakers,
    getSelectInternalSpeakers,
    getSelectSpecialties,
}: IInput): any {
    const [selectDiscussions, setSelectDiscussions] =
        useState<selectDiscussion[]>();
    const [selectClubs, setSelectClubs] = useState<selectClub[]>();
    const [selectSpeakers, setSelectSpeakers] = useState([]);
    const [selectInternalSpeakers, setSelectInternalSpeakers] = useState([]);
    const [selectSpecialties, setSelectSpecialties] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (getSelectDiscussions) {
                const discussionsData = await axios.get(
                    globalURLS.discussionsJSONURL,
                );

                if (discussionsData?.data) {
                    setSelectDiscussions(
                        discussionsData?.data?.map((el: selectDiscussion) => ({
                            value: el.discussionid,
                            label: `${el.name}${el.startdate ? ` | ${new Date(el.startdate).toLocaleString()}` : ``}`,
                        })),
                    );
                }
            }

            if (getSelectClubs) {
                const clubsData = await axios.get(globalURLS.clubsJSONURL);

                if (clubsData?.data) {
                    setSelectClubs(
                        clubsData?.data?.map((el: selectClub) => ({
                            value: el.clubid,
                            label: el.name,
                        })),
                    );
                }
            }

            if (getSelectSpeakers) {
                const speakersData = await axios.post(
                    `${globalURLS.baseURL}ADgetAllSpeakers`,
                    { forAddDiscussion: isDisabled || false },
                    globalURLS.config,
                );

                if (
                    speakersData?.data?.success === true &&
                    speakersData?.data?.data
                ) {
                    setSelectSpeakers(
                        speakersData?.data?.data?.map((el: any) => ({
                            value: el.accountid,
                            label: `${el.name} - ${el.phone}`,
                            isDisabled: el.isDisabled,
                        })),
                    );
                }
            }

            if (getSelectInternalSpeakers) {
                const internalSpeakersData = await axios.get(
                    globalURLS.internalHostsJSONURL,
                );

                if (internalSpeakersData?.data) {
                    setSelectInternalSpeakers(
                        internalSpeakersData?.data?.map((el: any) => ({
                            value: el.accountid,
                            label: `${el.name} - ${el.phone}`,
                        })),
                    );
                }
            }

            if (getSelectSpecialties) {
                const specialties = await axios.get(
                    globalURLS.specialtiesJSONURL,
                );

                if (specialties?.data) {
                    setSelectSpecialties(
                        specialties?.data?.map((el: any) => ({
                            value: el.id,
                            label: el.name,
                        })),
                    );
                }
            }
        };
        fetchData();
    }, [
        getSelectDiscussions,
        getSelectClubs,
        getSelectSpeakers,
        getSelectInternalSpeakers,
        getSelectSpecialties,
    ]);

    return {
        selectDiscussions,
        selectClubs,
        selectSpeakers,
        selectInternalSpeakers,
        selectSpecialties,
    };
}

export default useDiscussionsClubsHostsInternalHosts;
