import React from 'react';
import AppBannerTable from '../../Banner/AppBanner/AppBannerTable';
import WebBannerTable from '../../Banner/WebBanner/WebBannerTable';
import UpcomingQuizDiscussion from '../../Quiz/UpcomingQuizDiscussions/UpcomingQuizDiscussions';
import DraftTable from '../../Quiz/DraftTable/DraftTable';

interface navItemProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    eventKey: string;
    page: string;
    children?: React.ReactNode;
    table?: React.ReactNode;
}

const navTabsData: navItemProps[] = [
    {
        page: 'banner',
        onClick: () => '',
        eventKey: 'Mobile Banners',
        children: 'Mobile Banners',
        table: <AppBannerTable />,
    },
    {
        page: 'banner',
        onClick: () => '',
        eventKey: 'Web Banners',
        children: 'Web Banners',
        table: <WebBannerTable />,
    },
    {
        page: 'quiz',
        onClick: () => '',
        eventKey: 'Quiz Upcoming',
        children: 'Quiz Upcoming',
        table: <UpcomingQuizDiscussion />,
    },
    {
        page: 'quiz',
        onClick: () => '',
        eventKey: 'Quiz Draft',
        children: 'Quiz Draft',
        table: <DraftTable />,
    },
];

export { navTabsData };
