import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, InputGroup, Stack } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios'; // Make sure you've installed Axios in your project
import TextInput from '../../Common/Inputs/TextInput';
import CheckboxInput from '../../Common/Inputs/CheckboxInput';
import YearPicker from '../../Common/Pickers/YearPicker';
import CustomSelect from '../../Common/Inputs/CustomSelect';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import useUserDropDown from '../../../hooks/useUserDropDown';
import { globalURLS } from '../../../Global/urls';
import axios from 'axios';
import { useGlobalContext } from '../../../Context';
import TextAreaInput from '../../Common/Inputs/TextAreaInput';

interface ICity {
    id: string;
    name: string;
}

interface UpdateUserProps {
    show: boolean;
    onHide: () => void;
    // userId: string;
}

interface FormValues {
    accountid: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    summary: string;
    callingcode: string;
    callingcodeid: string;
    isstudent: number;
    isspeaker: number;
    isTest: number;
    yearOfPassing: number;
    toVerify: boolean;
    profilepic: string;
    profilepicthumb: string;
    specialtyid: string;
    specialty: { value: string; label: string };
    cityid: string;
    city: { value: string; label: string };
    designationid: string;
    designation: { value: string; label: string };
    yob: number;
    instituteid: string;
    institute: { value: string; label: string };
    pursuingid: string;
    pursuing: { value: string; label: string };
    usertypeid: string;
    usertype: { value: string; label: string };
    degreeid: string;
    degree: { value: string; label: string };
    hideAttendeeCount: number;
}

const initialValuess = {
    accountid: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    summary: '',
    callingcodeid: '',
    callingcode: '91',
    isstudent: 0,
    isspeaker: 0,
    isTest: 0,
    yearOfPassing: 0,
    toVerify: false,
    profilepic: '',
    profilepicthumb: '',
    specialtyid: '',
    specialty: { value: '', label: '' },
    cityid: '',
    city: { value: '', label: '' },
    designationid: '',
    designation: { value: '', label: '' },
    yob: 0,
    instituteid: '',
    institute: { value: '', label: '' },
    pursuingid: '',
    pursuing: { value: '', label: '' },
    usertypeid: '',
    usertype: { value: '', label: '' },
    degreeid: '',
    degree: { value: '', label: '' },
    hideAttendeeCount: 0
};

const validationSchema = Yup.object().shape({
    accountid: Yup.string().required('Accountid is required'),
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    callingcode: Yup.string().required('Calling Code is required'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string().required('Email is required'),
    yearOfPassing: Yup.string().required('yearOfPassing is required'),
    specialtyid: Yup.string().required('Specialty is required'),
    cityid: Yup.string().required('City is required'),
    // designationid: Yup.string().optional(),
    // instituteid: Yup.string().required('Institute is required'),
    // usertypeid: Yup.string().required('User Type is required'),
    degreeid: Yup.string().required('Degree is required'),
});

const UpdateUser: React.FC<UpdateUserProps> = ({ show, onHide }) => {
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<FormValues | null>(
        initialValuess,
    );
    const [countryISO, setCountryISO] = useState('');
    const [selectCitiesLocal, setSelectCitiesLocal] = useState([]);

    const fetchUserDataHandler = async (accountid: string) => {
        try {
            if (accountid.length >= 30) {
                const resp = await axios.post(
                    `${globalURLS.baseURL}ADgetUserData`,
                    { accountid: accountid },
                    globalURLS.config,
                );

                if (resp?.data?.success === true && resp?.data?.data) {
                    const finalData = resp?.data?.data[0];

                    const fieldMappings = {
                        city: 'cityid',
                        callingcode: 'callingcodeid',
                        specialty: 'specialtyid',
                        institute: 'instituteid',
                        designation: 'designationid',
                        usertype: 'usertypeid',
                        pursuing: 'pursuingid',
                        degree: 'degreeid',
                    };

                    const updatedData = Object.keys(fieldMappings).reduce(
                        (result, fieldName) => {
                            const fieldId = fieldMappings[fieldName];

                            result[fieldName] = {
                                value: finalData[fieldId],
                                label: finalData[`${fieldName}Name`],
                            };
                            return result;
                        },
                        {},
                    );

                    const verifiedData = {
                        toVerify: finalData.isverified ? true : false,
                    };

                    setInitialValues({
                        ...finalData,
                        ...verifiedData,
                        ...updatedData,
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleSubmit = async (values: FormValues) => {
        try {
            setIsLoading(true);

            const finalValues = {
                ...values,
                isTest: values?.isTest ? 1 : 0,
                callingcode: values?.callingcode?.value || 91,
                hideAttendeeCount: values?.hideAttendeeCount ? 1 : 0
            };

            const bodyReq = { users: [finalValues] };

            const resp = await axios.post(
                `${globalURLS.baseURL}updateusers`,
                bodyReq,
                globalURLS.config,
            );

            setIsLoading(false);

            if (resp?.data?.success === true) {
                onHide();
                setShowSuccessToast(true);
                setInitialValues(initialValuess);
            } else {
                setShowErrorToast(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error updating user:', error);
            setShowErrorToast(true);
        }
    };

    const handleCustomSelectChange = (
        formikProps: FormikProps<FormValues>,
        fieldName: string,
        fieldName2: string,
        selectedOption: any,
    ) => {
        fieldName === 'callingcodeid'
            ? setCountryISO(selectedOption?.countryISO)
            : '';

        formikProps.setFieldValue(fieldName2, {
            value: selectedOption.value,
            label: selectedOption.label,
        });

        formikProps.setFieldValue(fieldName, selectedOption?.value || '');
    };

    const getSelectSpecialties = true;
    const { selectSpecialties } = useDiscussionsClubsHostsInternalHosts({
        getSelectSpecialties,
    });

    const getSelectCities = true;
    const getUserDropDown = true;
    const getSelectCountries = true;

    const { selectCities, selectUserDropDown, selectCountries } =
        useUserDropDown(getSelectCities, getUserDropDown, getSelectCountries);

    useEffect(() => {
        const postReq = async () => {
            const bodyReq = {
                countryISO: countryISO,
                count: 1000000,
            };
            const resp = await axios.post(
                `${globalURLS.baseURL}getCities`,
                bodyReq,
                globalURLS.config,
            );

            if (resp?.data?.success === true && resp?.data?.data) {
                const cities: ICity[] = resp?.data?.data;

                const finalCitiesData = cities.map((city: ICity) => {
                    return { value: city?.id, label: city?.name };
                });

                if (finalCitiesData && finalCitiesData?.length) {
                    setSelectCitiesLocal(finalCitiesData);
                }
            }
        };

        postReq();
    }, [countryISO]);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Update User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize // Allow reinitialization of the form with updated initialValues
                >
                    {(formikProps: FormikProps<FormValues>) => (
                        <Form>
                            <Stack
                                direction="horizontal"
                                gap={3}
                                style={{ margin: '10px 0px 30px 0px' }}
                            >
                                <TextInput label="Accountid" name="accountid" />
                                <Button
                                    variant={isLoading ? 'warning' : 'success'}
                                    style={{ marginTop: '30px' }}
                                    type="button"
                                    onClick={() =>
                                        fetchUserDataHandler(
                                            formikProps.values.accountid,
                                        )
                                    }

                                    // disabled={
                                    //     isLoading ||
                                    //     initialValues?.accountid?.length >= 36
                                    //         ? true
                                    //         : false
                                    // }
                                >
                                    Search
                                </Button>
                            </Stack>
                            {initialValues?.firstname && (
                                <>
                                    <TextInput
                                        label="First Name"
                                        name="firstname"
                                    />

                                    <TextInput
                                        label="Last Name"
                                        name="lastname"
                                    />
                                    <TextAreaInput label="Bio" name="summary" />
                                    <TextInput label="Email" name="email" />

                                    <InputGroup>
                                        {/* <TextInput
                                    label="Calling Code"
                                    name="callingcode"
                                /> */}
                                        <CustomSelect
                                            label="Calling Code"
                                            name="callingcode"
                                            options={selectCountries || []}
                                            isMulti={false}
                                            isSearchable
                                            value={
                                                formikProps.values.callingcode
                                            }
                                            onChange={(selectedOption: any) =>
                                                handleCustomSelectChange(
                                                    formikProps,
                                                    'callingcodeid',
                                                    'callingcode',
                                                    selectedOption,
                                                )
                                            }
                                        />

                                        <TextInput label="Phone" name="phone" />
                                    </InputGroup>
                                    <TextInput
                                        label={'Profile Pic'}
                                        name={'profilepic'}
                                    />
                                    <Stack
                                        direction="horizontal"
                                        gap={3}
                                        style={{ margin: '10px' }}
                                    >
                                        <YearPicker
                                            label="Year of Passing"
                                            name="yearOfPassing"
                                        />
                                        {/* <YearPicker
                                            label="Year of Birth"
                                            name="yob"
                                        /> */}
                                    </Stack>

                                    <CustomSelect
                                        label="Select Degree"
                                        name="degreeid"
                                        options={
                                            selectUserDropDown?.degree || []
                                        }
                                        isMulti={false}
                                        isSearchable
                                        value={formikProps.values.degree}
                                        onChange={(selectedOption: any) =>
                                            handleCustomSelectChange(
                                                formikProps,
                                                'degreeid',
                                                'degree',
                                                selectedOption,
                                            )
                                        }
                                    /> 

                                    <CustomSelect
                                        label="Select Cities"
                                        name="cityid"
                                        options={selectCitiesLocal}
                                        isMulti={false}
                                        isSearchable
                                        value={formikProps.values.city}
                                        onChange={(selectedOption: any) =>
                                            handleCustomSelectChange(
                                                formikProps,
                                                'cityid',
                                                'city',
                                                selectedOption,
                                            )
                                        }
                                    />
                                    <CustomSelect
                                        label="Select Specialties"
                                        name="specialtyid"
                                        options={selectSpecialties}
                                        isMulti={false}
                                        isSearchable
                                        value={formikProps?.values?.specialty}
                                        onChange={(selectedOption: any) =>
                                            handleCustomSelectChange(
                                                formikProps,
                                                'specialtyid',
                                                'specialty',
                                                selectedOption,
                                            )
                                        }
                                    />

                                    {/* <CustomSelect
                                        label="Select Designation"
                                        name="designationid"
                                        options={
                                            selectUserDropDown?.designation ||
                                            []
                                        }
                                        isMulti={false}
                                        isSearchable
                                        value={formikProps.values.designation}
                                        onChange={(selectedOption: any) =>
                                            handleCustomSelectChange(
                                                formikProps,
                                                'designationid',
                                                'designation',
                                                selectedOption,
                                            )
                                        }
                                    /> */}

                                    <CustomSelect
                                        label="Select Institute"
                                        name="instituteid"
                                        options={
                                            selectUserDropDown?.institute || []
                                        }
                                        isMulti={false}
                                        isSearchable
                                        value={formikProps.values.institute}
                                        onChange={(selectedOption: any) =>
                                            handleCustomSelectChange(
                                                formikProps,
                                                'instituteid',
                                                'institute',
                                                selectedOption,
                                            )
                                        }
                                    />

                                    <CustomSelect
                                        label="Select User Type"
                                        name="usertypeid"
                                        options={
                                            selectUserDropDown?.userType || []
                                        }
                                        isMulti={false}
                                        isSearchable
                                        value={formikProps.values.usertype}
                                        onChange={(selectedOption: any) =>
                                            handleCustomSelectChange(
                                                formikProps,
                                                'usertypeid',
                                                'usertype',
                                                selectedOption,
                                            )
                                        }
                                    />

                                    {/* <CustomSelect
                                        label="Select Pursuing"
                                        name="pursuingid"
                                        options={
                                            selectUserDropDown?.pursuing || []
                                        }
                                        isMulti={false}
                                        isSearchable
                                        value={formikProps.values.pursuing}
                                        onChange={(selectedOption: any) =>
                                            handleCustomSelectChange(
                                                formikProps,
                                                'pursuingid',
                                                'pursuing',
                                                selectedOption,
                                            )
                                        }
                                    /> */}
                                    <Stack direction="horizontal">
                                        <CheckboxInput
                                            label="Student"
                                            name="isstudent"
                                        />
                                        {/* <CheckboxInput
                                            label="Speaker"
                                            name="isspeaker"
                                        /> */}

                                        <CheckboxInput
                                            label="Test"
                                            name="isTest"
                                        />
                                        <CheckboxInput
                                            label="Verify"
                                            name="toVerify"
                                        />
                                        <CheckboxInput
                                            label="Mark For Recording"
                                            name="hideAttendeeCount"
                                        />
                                    </Stack>
                                </>
                            )}
                            {initialValues?.firstname && (
                                <Button
                                    variant={isLoading ? 'warning' : 'success'}
                                    className="mt-10"
                                    type="button"
                                    onClick={() =>
                                        handleSubmit(formikProps.values)
                                    }
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Updating...' : 'Update'}
                                </Button>
                            )}
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateUser;
