import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';

export const getActiveAds = async () => {
    const response = await axios.post(`${globalURLS.baseURL}addash1_get_ads`, {});
    return response.data?.data ?? {};
};

export const getAdStats = async (adId: string) => {
    const response = await axios.post(`${globalURLS.baseURL}addash1_get_adstats1`, { ad_id: adId });
    return response.data?.data ?? [];
};
