import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import UploadForm from './Uploader';
import { useGlobalContext } from '../../../Context';

const UploaderModal: React.FC = () => {
    const { showUploaderModal, setShowUploaderModal } = useGlobalContext();

    const handleShowModal = () => setShowUploaderModal(true);
    const handleCloseModal = () => setShowUploaderModal(false);
    return (
        <div className="container">
            <Modal show={showUploaderModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UploadForm url="https://postapi.plexusmd.com/n/ADuploadToS3Hosted" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UploaderModal;
