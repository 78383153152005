import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';
import { Button } from 'react-bootstrap';

interface DownloadButtonProps {
    url: string;
}

function DiscussionFiles() {
    const [columnDefs] = useState([
        { field: 'discussionid', sortable: true, resizable: true },
        { field: 'filename', sortable: true, resizable: true },
        { field: 'authorName', sortable: true, resizable: true },
        { field: 'discussionName', sortable: true, resizable: true },
        { field: 'url', sortable: true, resizable: true },
        {
            field: 'url',
            headerName: '',
            cellRendererFramework: (params: any) => (
                <DownloadButton url={params.value} />
            ),
        },
    ]);
    const [rowData, setRowData] = useState([]);

    function DownloadButton(props: DownloadButtonProps) {
        const downloadFile = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            const url = props.url;
            if (!url) {
                console.error('URL is undefined or empty');
                return;
            }
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank'; // add target="_blank" to open in a new tab
            link.download = url.substring(url.lastIndexOf('/') + 1);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return (
            <Button variant="success" onClick={downloadFile}>
                Download
            </Button>
        );
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${globalURLS.baseURL}ADgetDiscussionFiles`,
                globalURLS.config,
            );
            setRowData(response.data.data);
        };
        fetchData();
    }, []);

    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Discussion Files'}
            ></Table>
        </>
    );
}

export default DiscussionFiles;
