/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Modal,
    Form,
    InputGroup,
    Toast,
    ToastContainer,
    Container,
    Col,
    Image,
    Card,
    Alert,
} from 'react-bootstrap';
import { BiEdit } from 'react-icons/bi/index.esm.js';

// Components imports
import {
    useFeedContext,
    useGlobalContext,
} from '../../../../Context/index.tsx';
import { globalURLS } from '../../../../Global/urls.js';
import { CustomInput } from '../../../Common/index.js';
import FeedInput from '../Step1/FeedInput/FeedInput.js';

const feedInfo = {
    title: '',
    summary: '',
    bannersMedia: [{ cta: '', src: '', type: 'Image' }],
    isTest: 0,
    publishTs: '',
    deadline: '',
    header: '',
    headerIcon: '',
    showResult: true,
};

const shortPollInfo = {
    pollId: '',
    parentId: '',
    parentType: 'news',
    pollType: 'short',
    question: '',
    options: [
        {
            text: '',
            rank: 1,
        },
        {
            text: '',
            rank: 2,
        },
    ],
    rank: 1,
};

const Final = () => {
    const [toastShow, setToastShow] = useState(false);
    const [validated, setValidated] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [axiosRespData, setAxiosRespData] = useState({});

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const {
        showModal,
        setShowModal,
        feedType,
        step,
        setStep,
        feedData,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setFeedData,
        shortPollData,
        setShortPollData,
        longPollsData,
        setLongPollsData,
        alertModal,
        setAlertModal,
        feedTags,
        notToEditFeed,
        setNotToEditFeed,
    } = useFeedContext();

    const prevStep = () => {
        setStep(step - 1);
    };

    const btnclose = () => {
        setShowModal(false);
        setFeedData('');
        setShortPollData('');
        setStep(1);
        window.location.reload(true);
    };

    const alertModalHandler = () => {
        setAlertModal(alertModal ? false : true);
    };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        if (feedData && feedType === 'news') {
            const respNews = await axios.post(
                `${globalURLS.baseURL}ADNews`,
                feedData,
                globalURLS.config,
            );

            const parentId = respNews?.data?.data.id;

            if (feedTags.length > 0) {
                const tagParams = {
                    feedItemId: parentId,
                    addTags: feedTags.map(feedTag => {
                        const obj = {};

                        obj.id = feedTag.value;

                        return obj;
                    }),
                };

                const tagRes = await axios.post(
                    `${globalURLS.baseURL}ADtagFeedItem`,
                    tagParams,
                    globalURLS.config,
                );
            }

            if (shortPollData.question.length > 1) {
                let shortPollValues = shortPollData;
                shortPollValues.parentId = parentId;

                const resp = await axios.post(
                    `${globalURLS.baseURL}ADPoll`,
                    shortPollValues,
                    globalURLS.config,
                );

                if (resp?.data?.data && resp?.data?.success === true) {
                    const feedParams = {
                        isTest: feedData?.isTest || 0,
                        hide: feedData?.hide || 0,
                        feedItemType: 'news',
                        feedItemId: parentId,
                        publishTs: feedData.publishTs,
                    };
                    const feedRes = await axios.post(
                        `${globalURLS.baseURL}ADFeed`,
                        feedParams,
                        globalURLS.config,
                    );

                    setAxiosRespData(resp?.data?.data);
                    setShowSuccessToast(true);
                    setAlertModal(false);
                    setShowModal(false);
                } else {
                    setShowErrorToast(true);
                }
            } else {
                if (respNews?.data?.data && respNews?.data?.success === true) {
                    const feedParams = {
                        isTest: feedData?.isTest || 0,
                        feedItemType: 'news',
                        hide: feedData?.hide || 0,
                        feedItemId: parentId,
                        publishTs: feedData.publishTs,
                    };
                    const feedRes = await axios.post(
                        `${globalURLS.baseURL}ADFeed`,
                        feedParams,
                        globalURLS.config,
                    );

                    setAxiosRespData(respNews?.data?.data);
                    setFeedData(feedInfo);
                    setLongPollsData(undefined);
                    setStep(1);
                    setAlertModal(false);
                    setShowModal(false);
                    setShowSuccessToast(true);
                } else {
                    setShowErrorToast(true);
                }
            }
        } else if (
            feedData &&
            longPollsData != undefined &&
            feedType == 'quiz'
        ) {
            const quizQuesParams = [...longPollsData];
            let totalScore = 0;

            longPollsData.map((poll, i) => (totalScore += poll.positiveWeight));

            const respQuiz = await axios.post(
                `${globalURLS.baseURL}ADQuiz`,
                { ...feedData, totalScore: totalScore },
                globalURLS.config,
            );

            const parentId = respQuiz?.data?.data.id;

            if (feedTags.length > 0) {
                const tagParams = {
                    feedItemId: parentId,
                    addTags: feedTags.map(feedTag => {
                        const obj = {};

                        obj.id = feedTag.value;

                        return obj;
                    }),
                };

                const tagRes = await axios.post(
                    `${globalURLS.baseURL}ADtagFeedItem`,
                    tagParams,
                    globalURLS.config,
                );
            }
            const resp = [];
            let res = true;

            for (let index = 0; index < quizQuesParams.length; index++) {
                quizQuesParams[index].parentId = parentId;
                resp[index] = await axios.post(
                    `${globalURLS.baseURL}ADPoll`,
                    quizQuesParams[index],
                    globalURLS.config,
                );
                if (!resp[index].data) {
                    res = false;
                }
            }
            if (res) {
                const feedParams = {
                    isTest: feedData?.isTest || 0,
                    feedItemType: 'quiz',
                    feedItemId: parentId,
                    hide: feedData?.hide || 0,
                    publishTs: feedData.publishTs,
                };
                const feedRes = await axios.post(
                    `${globalURLS.baseURL}ADFeed`,
                    feedParams,
                    globalURLS.config,
                );

                if (feedRes) {
                    const response = {
                        ADQuiz: respQuiz,
                        ADPoll: resp,
                        ADFeed: feedRes,
                    };
                    setFeedData(feedInfo);
                    setLongPollsData(undefined);
                    setStep(1);
                    setAxiosRespData(response);
                    setAlertModal(false);
                    setShowModal(false);
                    setShowSuccessToast(true);
                }
            }
        } else if (
            feedData &&
            longPollsData != undefined &&
            feedType == 'ques'
        ) {
            const quesParams = [...longPollsData];

            const respQues = await axios.post(
                `${globalURLS.baseURL}ADQuestionnaire`,
                feedData,
                globalURLS.config,
            );

            const parentId = respQues?.data?.data.id;

            if (feedTags.length > 0) {
                const tagParams = {
                    feedItemId: parentId,
                    addTags: feedTags.map(feedTag => {
                        const obj = {};

                        obj.id = feedTag.value;

                        return obj;
                    }),
                };

                const tagRes = await axios.post(
                    `${globalURLS.baseURL}ADtagFeedItem`,
                    tagParams,
                    globalURLS.config,
                );
            }
            const resp = [];
            let res = true;

            for (let index = 0; index < quesParams.length; index++) {
                quesParams[index].parentId = parentId;
                resp[index] = await axios.post(
                    `${globalURLS.baseURL}ADPoll`,
                    quesParams[index],
                    globalURLS.config,
                );
                if (!resp[index].data) {
                    res = false;
                }
            }
            if (res) {
                const feedParams = {
                    isTest: feedData?.isTest || 0,
                    feedItemType: 'questionnaire',
                    feedItemId: parentId,
                    hide: feedData?.hide || 0,
                    publishTs: feedData.publishTs,
                };
                const feedRes = await axios.post(
                    `${globalURLS.baseURL}ADFeed`,
                    feedParams,
                    globalURLS.config,
                );
                // console.log(`ADFeed Res: `, feedRes);
                if (feedRes) {
                    const response = {
                        ADQues: respQues,
                        ADPoll: resp,
                        ADFeed: feedRes,
                    };

                    setFeedData(feedInfo);
                    setStep(1);
                    setAxiosRespData(response);
                    setLongPollsData(undefined);
                    setAlertModal(false);
                    setShowModal(false);
                    setShowSuccessToast(true);
                }
            }
        }
    };

    // console.log('newsData', newsData);
    const handleDelete = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        if (feedData && feedData?.feedTypeDelete) {
            let resp;
            if (feedData?.feedTypeDelete === 'news') {
                resp = await axios.delete(
                    `${globalURLS.baseURL}ADNews/${feedData?.newsid}`,
                    globalURLS.config,
                );
            }

            if (feedData?.feedTypeDelete === 'quiz') {
                resp = await axios.delete(
                    `${globalURLS.baseURL}ADQuiz/${feedData?.quizid}`,
                    globalURLS.config,
                );
            }

            if (feedData?.feedTypeDelete === 'ques') {
                resp = await axios.delete(
                    `${globalURLS.baseURL}ADQuestionnaire/${feedData?.questionnaireid}`,
                    globalURLS.config,
                );
            }

            if (resp?.data?.data && resp?.data?.success === true) {
                setAxiosRespData(resp?.data?.data);
                setShowSuccessToast(true);
                setAlertModal(false);
                setShowModal(false);
            } else {
                setShowErrorToast(true);
            }
        }
    };

    const handleUpdate = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        if (
            feedData &&
            feedData?.feedTypeDelete &&
            (feedData?.newsid || feedData?.quizid || feedData?.questionnaireid)
        ) {
            let resp;
            if (feedData?.feedTypeDelete === 'news') {
                resp = await axios.patch(
                    `${globalURLS.baseURL}ADNews/${feedData.newsid}`,
                    { ...feedData, newsId: feedData.newsid },
                    globalURLS.config,
                );
            }

            if (feedData?.feedTypeDelete === 'quiz') {
                resp = await axios.patch(
                    `${globalURLS.baseURL}ADQuiz/${feedData.quizid}`,
                    { ...feedData, quizId: feedData.quizid },
                    globalURLS.config,
                );
            }

            if (feedData?.feedTypeDelete === 'ques') {
                resp = await axios.patch(
                    `${globalURLS.baseURL}ADQuestionnaire/${feedData.questionnaireid}`,
                    {
                        ...feedData,
                        quesId: feedData.questionnaireid,
                        feedItemType:
                            feedData.feedTypeDelete === 'ques'
                                ? 'questionnaire'
                                : feedData.feedTypeDelete,
                        feedItemId: feedData.questionnaireid,
                    },
                    globalURLS.config,
                );
            }

            if (resp) {
                await axios.patch(
                    `${globalURLS.baseURL}ADfeed`,
                    {
                        ...feedData,
                        hide: feedData?.hide || 0,
                        feedItemId:
                            feedData?.newsid ||
                            feedData?.quizid ||
                            feedData?.questionnaireid,
                        feedItemType:
                            feedData.feedTypeDelete === 'ques'
                                ? 'questionnaire'
                                : feedData.feedTypeDelete,
                    },
                    globalURLS.config,
                );
            }

            if (feedData?.publishTs) {
                await axios.patch(
                    `${globalURLS.baseURL}ADFeed`,
                    {
                        ...feedData,
                        hide: feedData?.hide || 0,
                        isTest: feedData?.isTest || 0,
                        publishT: feedData?.publishTs,
                        feedItemId:
                            feedData?.newsid ||
                            feedData?.quizid ||
                            feedData?.questionnaireid,
                        feedItemType: feedData.feedTypeDelete,
                    },
                    globalURLS.config,
                );
            }

            if (shortPollData && shortPollData.pollId) {
                const respS = await axios.patch(
                    `${globalURLS.baseURL}ADPoll`,
                    { ...shortPollData, pollId: shortPollData.pollId },
                    globalURLS.config,
                );
            }

            if (resp?.data?.data && resp?.data?.success === true) {
                setAxiosRespData(resp?.data?.data);
                setShowSuccessToast(true);
                setAlertModal(false);
                setShowModal(false);
            } else {
                setShowErrorToast(true);
            }
        }
    };

    useEffect(() => {
        // console.log(`FeedData: `, feedData);
    }, []);

    const editEnable = () => setNotToEditFeed(notToEditFeed ? false : true);

    return (
        <Container className="news-container">
            <Col>
                <Modal show={showModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            {feedData?.feedDelete === true && (
                                <Modal.Title>{`Edit ${feedData?.feedTypeDelete}`}</Modal.Title>
                            )}
                            {feedData && !feedData?.feedDelete && (
                                <Modal.Title>Review Publish</Modal.Title>
                            )}
                            {step === 3 &&
                                {
                                    news: <BiEdit onClick={editEnable} />,
                                    quiz: <BiEdit onClick={editEnable} />,
                                    ques: <BiEdit onClick={editEnable} />,
                                }[feedData?.feedTypeDelete]}
                        </Modal.Header>
                        <Modal.Body>
                            {feedData && !feedData?.feedDelete && (
                                <FeedInput isPreview={true} />
                            )}
                            {feedData && feedData?.feedDelete === true && (
                                <FeedInput isPreview={false} />
                            )}
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>

                            {feedData && !feedData?.feedDelete && (
                                <>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        onClick={prevStep}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={toastShow}
                                        onClick={handleSubmit}
                                    >
                                        Publish
                                    </Button>
                                </>
                            )}

                            {feedData && feedData.feedDelete === true && (
                                <>
                                    <Button
                                        variant="danger"
                                        type="submit"
                                        disabled={false}
                                        onClick={alertModalHandler}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            step === 3 && notToEditFeed
                                                ? true
                                                : false
                                        }
                                        onClick={alertModalHandler}
                                    >
                                        Save Changes
                                    </Button>
                                </>
                            )}
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* Alert Pop-Up */}

                {alertModal === true && (
                    <>
                        <Modal show={alertModal} onHide={alertModalHandler}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {step === 3 && notToEditFeed === false
                                        ? 'Confirm Update'
                                        : 'Confirm Delete'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {step === 3 && notToEditFeed === false
                                    ? 'You are making changes. Are you sure?'
                                    : 'Woohoo, you are soft deletings this. Are you sure?'}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={alertModalHandler}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={
                                        step === 3 && notToEditFeed === false
                                            ? handleUpdate
                                            : handleDelete
                                    }
                                >
                                    {step === 3 && notToEditFeed === false
                                        ? 'Confirm Update'
                                        : 'Confirm Delete'}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Col>
        </Container>
    );
};

export default Final;
