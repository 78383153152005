import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, utcToZonedTime } from 'date-fns-tz';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';

function Questionnaire() {
    const [questionnaireData, setQuestionnaireData] = useState([]);
    const [columnDefs] = useState([
        { field: 'questionnaireid', sortable: true, resizable: true },
        { field: 'header', sortable: true, resizable: true },
        { field: 'title', sortable: true, resizable: true },
        { field: 'summary', sortable: true, resizable: true },
        { field: 'viewCount', sortable: true, resizable: true },
        { field: 'deadline', sortable: true, resizable: true },
        { field: 'publishTs', sortable: true, resizable: true },
    ]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                `${globalURLS.baseURL}ADQuestionnaire`,
                globalURLS.config,
            );
            setQuestionnaireData(response.data.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (questionnaireData && questionnaireData.length > 0) {
            // console.log("questionnaireData", questionnaireData)
            const finalquestionnaireData = questionnaireData.map(el => {
                const questionnaire = {};
                const datePublishTs = new Date(el.publishTs);
                const ISTDateTimedatePublishTs = utcToZonedTime(
                    datePublishTs,
                    'Asia/Calcutta',
                );
                const finalISTDateTimedatePublishTs = format(
                    ISTDateTimedatePublishTs,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );

                const dateDeadline = new Date(el.deadline);
                const ISTDateTimeDeadline = utcToZonedTime(
                    dateDeadline,
                    'Asia/Calcutta',
                );
                const finalISTDateTimeDeadline = format(
                    ISTDateTimeDeadline,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                (questionnaire.questionnaireid = el.id),
                    (questionnaire.header = el.header),
                    (questionnaire.title = el.title),
                    (questionnaire.summary = el.summary),
                    (questionnaire.deadline = finalISTDateTimeDeadline),
                    (questionnaire.publishTs = finalISTDateTimedatePublishTs),
                    (questionnaire.likeCount = el.likeCount),
                    (questionnaire.viewCount = el.viewCount);
                return questionnaire;
            });

            // console.log("finalquestionnaireData", finalquestionnaireData)
            setRowData(finalquestionnaireData ? finalquestionnaireData : []);
        }
    }, [questionnaireData]);

    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Questionnaire'}
            ></Table>
        </>
    );
}

export default Questionnaire;
