import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import AddUsersForm from './AddUsersForm';
import { useUserContext } from '../../../Context';

const AddUsersModal = () => {
    const { showAddUsersModal, setShowAddUsersModal } = useUserContext();
    return (
        <Modal
            show={showAddUsersModal}
            onHide={() => setShowAddUsersModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddUsersForm />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => setShowAddUsersModal(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddUsersModal;
