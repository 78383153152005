import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import PageTitle from '../../Components/PageTitle/PageTitle.js';
import { Col, Container, Row } from 'react-bootstrap';
import LiveUsersCard from '../../Components/Common/Cards/LiveUsersCard';
import LiveDiscussionCard from '../../Components/Discussion/LiveDiscussionCard/LiveDiscussionCard';
import LiveChart from '../../Components/Common/Charts/LiveChart';
import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';

interface LiveSession {
    count: number;
    status: string;
    title: string;
    isTest: number;
}

interface PlatformStats {
    android: number;
    ios: number;
    mobile_Web: number;
    desktop_Web: number;
}

interface LiveUsersData {
    totalCount: number;
    platform: PlatformStats;
    liveSessionCount: Record<string, LiveSession>;
    liveSessionAttendeeCount: Record<string, LiveSession>;
}

interface platform {
    name: string;
    count: number;
}
interface LiveUserPlatform {
    platforms?: platform[];
    totalCount?: number;
}

interface LiveDiscussions {
    discussionid: string;
    count: number;
    peak?: number;
    status: string;
    title: string;
    isTest: number;
}

const userRealTimeInitialData: LiveUserPlatform = {
    totalCount: 0,
    platforms: [
        { name: 'Android', count: 0 },
        { name: 'iOS', count: 0 },
        { name: 'Mobile Web ', count: 0 },
        { name: 'Mobile Web', count: 0 },
        { name: 'Tablet Web', count: 0 },
    ],
};

function Dashboard() {
    const [uniqueDiscussionIds, setUniqueDiscussionIds] = useState(new Set());
    const [firstLoad, setFirstLoad] = useState(false);

    const [realTimeData, setRealTimeData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [usersRealTime, setUsersRealTime] = useState<LiveUserPlatform>(
        userRealTimeInitialData,
    );

    const headers = {
        forceNew: true,
        secure: true,
        transports: ['websocket'],
        query: {
            accessid: 'aFJKj2387bc!y198',
        },
        reconnection: true,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 60,
    };

    useEffect(() => {
        const socket = io('https://socketio.medflix.app/captain', headers);

        socket.on('connect', () => {
            console.log('Connected');
        });

        socket.on('LiveData1', data => {
            // Filter discussions based on 'isTest' property
            // const filteredLiveSessionCount = Object.keys(data.liveSessionCount)
            //     .filter(
            //         uuid =>
            //             data.liveSessionCount[uuid].isTest === 0 &&
            //             data.liveSessionCount[uuid].status === 'live',
            //     )
            //     .reduce((result, uuid) => {
            //         result[uuid]['sessionCount'] = data.liveSessionCount[uuid];
            //         return result;
            //     }, {});
            const filteredLiveSessionCount = Object.keys(data.liveSessionCount)
                .filter(
                    uuid =>
                        data.liveSessionCount[uuid].isTest === 0 &&
                        data.liveSessionCount[uuid].status === 'live',
                )
                .reduce((result, uuid) => {
                    result[uuid] = {
                        sessionCount: data.liveSessionCount[uuid],
                        attendeeCount: data.liveSessionAttendeeCount[uuid], // Add attendeeCount
                    };
                    return result;
                }, {});

            const transData = Object.entries(data?.platform).map(el => ({
                name: el[0],
                count: el[1],
            }));

            const finaltransData: LiveUserPlatform = {
                platforms: transData,
                totalCount: data?.totalCount || 0,
            };

            setUsersRealTime(finaltransData);

            const liveSessions = filteredLiveSessionCount;

            const transformedData: LiveDiscussions[] = [];

            const maxPeak = realTimeData.reduce(
                (max, session) => Math.max(max, session.peak),
                0,
            );

            for (const uuid in liveSessions) {
                if (Object.prototype.hasOwnProperty.call(liveSessions, uuid)) {
                    const sessionInfo = liveSessions[uuid];
                    const transformedSession = {
                        x: new Date(),
                        y: sessionInfo.sessionCount.count,
                        discussionid: uuid,
                        count: sessionInfo.sessionCount.count,
                        status: 'live',
                        title: sessionInfo.sessionCount.title,
                        isTest: sessionInfo.sessionCount.isTest,
                        peak: maxPeak,
                        attendeesCount: sessionInfo.attendeeCount.count,
                    };
                    transformedData.push(transformedSession);
                }
            }

            // Use a Set to store the latest data based on discussionid
            const latestData = new Set<string>();
            transformedData.forEach(session => {
                latestData.add(session.discussionid);
            });

            // Convert Set back to an array of objects
            const latestDataArray = Array.from(latestData).map(discussionid => {
                return transformedData.find(
                    session => session.discussionid === discussionid,
                );
            });

            // console.log('latestDataArray', latestDataArray);

            const newDiscussionIds = new Set([
                ...uniqueDiscussionIds,
                ...latestDataArray.map(session => session.discussionid),
            ]);
            setUniqueDiscussionIds(newDiscussionIds);

            const discussionIDsArray = [...uniqueDiscussionIds];

            if (!firstLoad && discussionIDsArray && discussionIDsArray.length) {
                const fetchHistoricalData = async () => {
                    const response = await axios.post(
                        `${globalURLS.baseURL}ADgetLiveDiscussionPeaks`,
                        { discussionIds: discussionIDsArray },
                        globalURLS.config,
                    );

                    const data = response?.data?.data;
                    // console.log('response', response);
                    setHistoricalData(data);
                    // console.log('historicalData', historicalData);
                    setFirstLoad(true);
                };

                fetchHistoricalData();
            }
            // console.log('historicalData', historicalData);

            if (historicalData && historicalData.length) {
                setRealTimeData(prevData => [
                    ...historicalData,
                    ...prevData,
                    ...latestDataArray,
                ]);
            } else {
                setRealTimeData(prevData => [...prevData, ...latestDataArray]);
            }
            // console.log('realTimeData', realTimeData);
        });

        return () => {
            socket.disconnect();
        };
    }, [uniqueDiscussionIds, realTimeData]);

    return (
        <Container className="dashboard-container">
            <div className="dashboard-title">
                <PageTitle>Dashboard</PageTitle>
            </div>

            <Container>
                <Col className="w-90vw">
                    <LiveUsersCard
                        totalLiveUsers={usersRealTime?.totalCount || 0}
                        platformData={usersRealTime?.platforms || []}
                    />

                    <div
                        style={{
                            maxHeight: '400px',
                            // overflowY: 'auto',
                        }}
                    >
                        {!realTimeData || realTimeData.length === 0 ? (
                            <Row className="w-90vw p-3 border rounded mr-3">
                                <h3 className="mb-3">Live Discussion Chart</h3>

                                <div>No live discussions available.</div>
                            </Row>
                        ) : (
                            <Row className="p-3 border rounded mr-3">
                                <h3 className="mb-3">Live Discussion Chart</h3>
                                {[...uniqueDiscussionIds].map(discussionid => (
                                    <Row
                                        className="w-90vw h-400px"
                                        key={discussionid}
                                    >
                                        <LiveChart
                                            data={realTimeData.filter(
                                                discussion =>
                                                    discussion.discussionid ===
                                                    discussionid,
                                            )}
                                            discussionId={discussionid}
                                        />
                                    </Row>
                                ))}
                            </Row>
                        )}
                    </div>
                </Col>
            </Container>
        </Container>
    );
}

export default Dashboard;
