import * as React from 'react';
import { Container, Col } from 'react-bootstrap';

import PageTitle from '../../Components/PageTitle/PageTitle.js';
import './Quiz.css';
import CustomButton from '../../Components/Common/CustomButton/CustomButton';
import AddQuiz from '../../Components/Quiz/AddQuiz/AddQuiz';
import { useState } from 'react';
import FailureToast from '../../Components/Common/Toast/FailureToast';
import SuccessToast from '../../Components/Common/Toast/SuccessToast';
import { useGlobalContext } from '../../Context/index';
import UpcomingQuizDiscussion from '../../Components/Quiz/UpcomingQuizDiscussions/UpcomingQuizDiscussions';
import DownloadJSON from '../../Components/Quiz/DownloadJSON/DownloadJSON';
import { navTabsData } from '../../Components/Common/Table/TableData';
import NavTabs from '../../Components/Common/NavTabs/NavTabsWithTables/NavTabsWithTables';
const Quiz: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showJSONModal, setJSONModal] = useState(false);

    const quizNavTabsData = navTabsData.filter(
        navTab => navTab.page === 'quiz',
    );

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const openJSONModal = () => setJSONModal(true);
    const closeJSONModal = () => setJSONModal(false);

    const {
        showSuccessToast,
        showErrorToast,
        setShowSuccessToast,
        setShowErrorToast,
    } = useGlobalContext();

    return (
        <Container className="quiz-container">
            <Col>
                <Col className="quiz-title">
                    <PageTitle>Quiz</PageTitle>
                    <CustomButton onClick={openModal}>+ Quiz</CustomButton>
                    <CustomButton onClick={openJSONModal}>
                        {' '}
                        Quiz JSON
                    </CustomButton>
                </Col>
                <AddQuiz
                    show={showModal}
                    onClose={closeModal}
                    isUpdate={false}
                    isDraft={false}
                />
                <DownloadJSON show={showJSONModal} onClose={closeJSONModal} />
                <NavTabs
                    defaultActiveKey={'Quiz Upcoming'}
                    data={quizNavTabsData}
                />
            </Col>
            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={() => setShowSuccessToast(false)}
                    headerTitle="Success!"
                />
            )}
            {showErrorToast && (
                <FailureToast
                    showModal={showErrorToast}
                    handleClose={() => setShowErrorToast(false)}
                    headerTitle="Something went wrong, Please try again"
                />
            )}
        </Container>
    );
};

export default Quiz;
useGlobalContext;
