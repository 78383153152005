import React, { useContext, useState, useEffect } from 'react';
import {
    Button,
    Col,
    Form,
    Modal,
    Row,
    Image,
    Container,
} from 'react-bootstrap';
import Select from 'react-select';

import { DiscussionContext, useGlobalContext } from '../../../Context';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';

interface PollOption {
    title: string;
    rank: number;
}

interface Poll {
    question: string;
    images?: string[];
    rank: number;
    maxRunForSeconds: number;
    options: PollOption[];
}

interface discussion {
    value: string;
    label: string;
}

const AddDiscussionPoll: React.FC = () => {
    const [polls, setPolls] = useState<Poll[]>([]);
    const [validated, setValidated] = useState(false);
    const [discussion, setDiscussion] = useState<discussion>();
    const { openAddPollsModal, setAddPollsModal } = useContext(
        DiscussionContext.AddPollsContext,
    );
    const [isLoading, setIsLoading] = useState(false);

    // console.log('polls', polls);
    const getSelectDiscussions = true;
    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const fetchData = async (val : discussion) => {
        console.log('-val-', val.value);
        setPolls([])
        if (val.value?.length) {
            const resp = await axios.post(
                `${globalURLS.baseURL}getAllPolls`,
                {discussionid: val.value},
                globalURLS.config,
            );
            if (resp?.data?.success === true && resp?.data?.data?.length) {
                setPolls(resp.data.data.map(i => ({
                    id: i.id, question: i.question,
                    images: JSON.parse(i.images), maxRunForSeconds: i.maxRunForSeconds,
                    rank: i.rank, options: i.options
                })))
            }
            else handleAddPoll();
        }
    }

    const defaultPollOption: PollOption = { title: '', rank: 0 };
    const defaultPoll: Poll = {
        question: '',
        images: [''],
        maxRunForSeconds: 0,
        rank: polls?.length + 1 || 1,
        options: [defaultPollOption],
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity() === false) {
            setValidated(true);
        } else {
            try {
                const recalculatePollsRank = polls.map((poll, idx) => {
                    return {
                        ...poll,
                        rank: idx + 1,
                        options: poll.options.map((option, idx) => {
                            return {
                                ...option,
                                rank: idx + 1,
                            };
                        }),
                    };
                });
                const bodyData = {
                    discussionid: discussion?.value,
                    polls: recalculatePollsRank,
                };
                setIsLoading(true);

                const res = await axios.post(
                    `${globalURLS.baseURL}addPolls`,
                    bodyData,
                    globalURLS.config,
                );

                if (res?.data?.success === true) {
                    handleModalClose();
                    setShowSuccessToast(true);
                } else {
                    setShowErrorToast(true);
                }

                setIsLoading(false);
            } catch (e) {
                setShowErrorToast(true);
            }
        }
    };

    const handleModalClose = () => {
        setAddPollsModal(false);
        setPolls([])
    };

    const handlePollChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        pollIndex: number,
        optionIndex: number,
    ) => {
        const { name, value } = e.target;
        setPolls(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState)); // Creates a deep copy
            newState[pollIndex].options[optionIndex][name] = value;
            return newState;
        });
    };

    const handleAddPoll = () => {
        setPolls(prevState => [...prevState, { ...defaultPoll }]); // Spread operator to create a new object
    };

    const handleRemovePoll = (index: number) => {
        setPolls(prevState => prevState.filter((_, i) => i !== index));
    };

    const handleRemovePollOption = (pollIndex: number, optionIndex: number) => {
        setPolls(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState)); // Creates a deep copy
            newState[pollIndex].options = newState[pollIndex].options.filter(
                (_, i) => i !== optionIndex,
            );
            return newState;
        });
    };

    const handleInsertPoll = (index: number) => {
        setPolls(prevPolls => {
            const newPolls = [...prevPolls];
            newPolls.splice(index + 1, 0, { ...defaultPoll });
            return newPolls;
        });
    };

    const handleRemoveImages = (pollIndex: number, imageIndex: number) => {
        setPolls(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState)); // Creates a deep copy
            newState[pollIndex].images = newState[pollIndex].images.filter(
                (_, i) => i !== imageIndex,
            );
            return newState;
        });
    };

    return (
        <>
            <Modal
                show={openAddPollsModal}
                onHide={handleModalClose}
                animation={false}
            >
                <Form noValidate validated={validated} onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Poll</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Discussion</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            onChange={(val:discussion) => {
                                setDiscussion(val);
                                fetchData(val);
                            }}
                            isSearchable
                            options={selectDiscussions}
                        />
                        {/* <Button
                            className="mt-2"
                            disabled={discussion?.value ? false : true}
                            onClick={handleAddPoll}
                        >
                            Add Poll
                        </Button> */}

                        {discussion?.value &&
                            polls.map((poll, pollIndex) => (
                                <div
                                    key={pollIndex}
                                    style={{
                                        backgroundColor: '#E9ECEF',
                                        height: 'auto',
                                        width: 'auto',
                                        padding: '1em',
                                        borderRadius: '1em',
                                        marginTop: '5em',
                                    }}
                                >
                                    <Form.Group>
                                        <Form.Label>Question</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Question"
                                            required
                                            value={poll.question}
                                            onChange={e =>
                                                setPolls(prevState => {
                                                    const newState = JSON.parse(
                                                        JSON.stringify(
                                                            prevState,
                                                        ),
                                                    ); // Creates a deep copy
                                                    newState[
                                                        pollIndex
                                                    ].question = e.target.value;
                                                    return newState;
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        {poll?.images.map((img, idx) => {
                                            return (
                                                <>
                                                    <Form.Label>
                                                        {`Image URL ${idx}`}
                                                    </Form.Label>
                                                    <Row
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'flex-start',
                                                            alignItems:
                                                                'center',
                                                            width: 'auto',
                                                            marginLeft: '2em',
                                                        }}
                                                    >
                                                        <Form.Control
                                                            style={{
                                                                width: 'auto',
                                                            }}
                                                            type="text"
                                                            placeholder={`Enter Image URL ${idx}`}
                                                            // required
                                                            value={
                                                                poll?.images[
                                                                    idx
                                                                ].length
                                                                    ? poll
                                                                          ?.images[
                                                                          idx
                                                                      ]
                                                                    : ''
                                                            }
                                                            onChange={e =>
                                                                setPolls(
                                                                    prevState => {
                                                                        const newState =
                                                                            JSON.parse(
                                                                                JSON.stringify(
                                                                                    prevState,
                                                                                ),
                                                                            ); // Creates a deep copy
                                                                        newState[
                                                                            pollIndex
                                                                        ].images[
                                                                            idx
                                                                        ] =
                                                                            e.target.value.trim();
                                                                        return newState;
                                                                    },
                                                                )
                                                            }
                                                        />
                                                        <Button
                                                            style={{
                                                                fontSize: '1em',
                                                                margin: '0.2em',

                                                                height: '2em',
                                                                width: '2em',
                                                            }}
                                                            variant="danger"
                                                            onClick={() =>
                                                                handleRemoveImages(
                                                                    pollIndex,
                                                                    idx,
                                                                )
                                                            }
                                                        >
                                                            -
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '1em',
                                                                margin: '0.2em',

                                                                height: '2em',
                                                                width: '2em',
                                                            }}
                                                            onClick={() =>
                                                                setPolls(
                                                                    prevState => {
                                                                        const newState =
                                                                            JSON.parse(
                                                                                JSON.stringify(
                                                                                    prevState,
                                                                                ),
                                                                            ); // Creates a deep copy
                                                                        newState[
                                                                            pollIndex
                                                                        ].images.push(
                                                                            {
                                                                                ...defaultPoll?.images,
                                                                            },
                                                                        );
                                                                        return newState;
                                                                    },
                                                                )
                                                            }
                                                        >
                                                            +
                                                        </Button>
                                                    </Row>
                                                    {img && img.length && (
                                                        <Image
                                                            src={img ? img : ''}
                                                            fluid={true}
                                                            style={{
                                                                width: '80vw',
                                                                height: '45vh',
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            );
                                        })}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>
                                            Max Run For Seconds
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Max Run For Seconds"
                                            required
                                            value={poll?.maxRunForSeconds}
                                            onChange={e =>
                                                setPolls(prevState => {
                                                    const newState = JSON.parse(
                                                        JSON.stringify(
                                                            prevState,
                                                        ),
                                                    ); // Creates a deep copy
                                                    newState[
                                                        pollIndex
                                                    ].maxRunForSeconds =
                                                        e.target.value;
                                                    return newState;
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <Row>
                                        {poll.options.map((x, i) => {
                                            return (
                                                <div key={i}>
                                                    <Col
                                                        style={{
                                                            margin: '1em',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Poll Option
                                                                Title
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter Title"
                                                                name="title"
                                                                required
                                                                value={x.title}
                                                                onChange={e =>
                                                                    handlePollChange(
                                                                        e,
                                                                        pollIndex,
                                                                        i,
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group>
                                                        {/* <Form.Group>
                                                            <Form.Label>
                                                                Poll Option Rank
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Enter Rank"
                                                                name="rank"
                                                                required
                                                                value={x.rank}
                                                                onChange={e =>
                                                                    handlePollChange(
                                                                        e,
                                                                        pollIndex,
                                                                        i,
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group> */}
                                                        <Button
                                                            style={{
                                                                fontSize: '1em',
                                                                margin: '0.2em',
                                                                marginTop:
                                                                    '2em',
                                                                height: '2em',
                                                                width: '2em',
                                                            }}
                                                            variant="danger"
                                                            onClick={() =>
                                                                handleRemovePollOption(
                                                                    pollIndex,
                                                                    i,
                                                                )
                                                            }
                                                        >
                                                            -
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '1em',
                                                                margin: '0.2em',
                                                                marginTop:
                                                                    '2em',
                                                                height: '2em',
                                                                width: '2em',
                                                            }}
                                                            onClick={() =>
                                                                setPolls(
                                                                    prevState => {
                                                                        const newState =
                                                                            JSON.parse(
                                                                                JSON.stringify(
                                                                                    prevState,
                                                                                ),
                                                                            ); // Creates a deep copy
                                                                        newState[
                                                                            pollIndex
                                                                        ].options.push(
                                                                            {
                                                                                ...defaultPollOption,
                                                                            },
                                                                        );
                                                                        return newState;
                                                                    },
                                                                )
                                                            }
                                                        >
                                                            +
                                                        </Button>
                                                    </Col>
                                                </div>
                                            );
                                        })}
                                    </Row>

                                    <Button
                                        variant="danger"
                                        onClick={() =>
                                            handleRemovePoll(pollIndex)
                                        }
                                        style={{ marginRight: '10px' }}
                                    >
                                        Remove Poll
                                    </Button>
                                    <Button
                                        disabled={!discussion?.value}
                                        onClick={() =>
                                            handleInsertPoll(pollIndex)
                                        }
                                    >
                                        Add Poll
                                    </Button>
                                </div>
                            ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleModalClose}
                            style={{ marginRight: '10px' }}
                        >
                            Close
                        </Button>
                        {discussion?.value && (
                            <Button
                                variant={isLoading ? 'warning' : 'success'}
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Adding Polls...' : 'Add Polls'}
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default AddDiscussionPoll;
