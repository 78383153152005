/* eslint-disable react/prop-types */
import React from 'react';

import { useFeedContext } from '../../../Context/index.tsx';
import StepOne from '../Steps/Step1/StepOne.js';
import Final from '../Steps/Step3/Final.js';
import QuestionnaireForm from '../Steps/Step2/QuestionForm/QuestionForm.js';

function AdQues() {
    const { step } = useFeedContext();

    switch (step) {
        case 1:
            return (
                <>
                    <StepOne />
                </>
            );
        case 2:
            return (
                <>
                    <QuestionnaireForm feedtype="questionnaire" />
                    {/* <LongPoll type="quiz" /> */}
                </>
            );
        case 3:
            return (
                <>
                    <Final />
                </>
            );
        default:
            return <></>;
    }
}

export default AdQues;
