import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';

function PastDiscussion() {
    const [pastDiscussionData, setPastDiscussionData] = useState([]);
    const [columnDefs] = useState([
        { field: 'discussionid', sortable: true, resizable: true },
        { field: 'title', sortable: true, resizable: true },
        { field: 'startdate', sortable: true, resizable: true },
        { field: 'views', sortable: true, resizable: true },
        { field: 'peak', sortable: true, resizable: true },
        { field: 'ratings', sortable: true, resizable: true },
        { field: 'ratingavg', sortable: true, resizable: true },
        { field: 'hasrecording', sortable: true, resizable: true },
    ]);
    const [rowData, setRowData] = useState([]);

    const adminData = {
        accountid: 'a237aec7-5fe0-4177-99b2-d0b590af26db',
        count: 1000,
        orderby: 'date',
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${globalURLS.baseURL}getWebPastDiscussions`,
                adminData,
            );
            setPastDiscussionData(response.data.data.discussions);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (pastDiscussionData && pastDiscussionData.length > 0) {
            const finalPastDiscussionData = pastDiscussionData.map(el => {
                const past = {};
                const date = new Date(el.istdate);
                const ISTDateTime = utcToZonedTime(date, 'Asia/Calcutta');
                const finalISTDateTime = format(
                    ISTDateTime,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                (past.discussionid = el.discussionid),
                    (past.title = el.title),
                    (past.startdate = finalISTDateTime),
                    (past.views = el.viewcount),
                    (past.peak = el.peak),
                    (past.ratings = el.ratingcount),
                    (past.ratingavg = el.ratingavg),
                    (past.hasrecording = el.hasRecording);
                return past;
            });

            setRowData(finalPastDiscussionData ? finalPastDiscussionData : []);
        }
    }, [pastDiscussionData]);
    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Past Discussions'}
            ></Table>
        </>
    );
}

export default PastDiscussion;
