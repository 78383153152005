import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../Common/Table/Table.js';
import { convertUTCtoIST } from '../../../Helper/TimeZone';

interface IProps {
    adminnotificationid: string;
    header: string;
    subheader: string;
    screen: string;
    ctatext: string;
    starttime: string;
    endtime: string;
    broadcast: boolean;
}

const columnDefs = [
    { field: 'adminnotificationid', sortable: true, resizable: true },
    { field: 'header', sortable: true, resizable: true },
    { field: 'subheader', sortable: true, resizable: true },
    { field: 'screen', sortable: true, resizable: true },
    { field: 'ctatext', sortable: true, resizable: true },
    { field: 'starttime', sortable: true, resizable: true },
    { field: 'endtime', sortable: true, resizable: true },
    { field: 'broadcast', sortable: true, resizable: true },
];

const adminData = {
    accountid: 'a237aec7-5fe0-4177-99b2-d0b590af26db',
    count: 1000,
    orderby: 'date',
};

function InAppBellTable() {
    const [data, setData] = useState<IProps[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${globalURLS.baseURL}getAdminNotifications`,
                    adminData,
                );

                if (response?.data?.data) {
                    const copyData = response?.data?.data;

                    const finalData = await Promise.all(
                        copyData.map(async el => {
                            return {
                                ...el,
                                starttime: await convertUTCtoIST(el.starttime),
                                endtime: await convertUTCtoIST(el.endtime),
                            };
                        }),
                    );

                    setData(finalData || []);
                }
            } catch (err) {
                console.error('Failed to fetch data: ', err);
            }
        };
        fetchData();
    }, []);

    return (
        <Table
            rowData={data}
            columnDefs={columnDefs}
            title={'Admin Notification (Past 30 Days)'}
        />
    );
}

export default InAppBellTable;
