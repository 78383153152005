import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { parse } from 'date-fns';
import 'chartjs-adapter-date-fns';

interface HistoricalChartProps {
    historicalData: any[];
}

const HistoricalChart: React.FC<HistoricalChartProps> = ({
    historicalData,
}) => {
    if (historicalData?.length === 0) {
        return <div>No data available.</div>;
    }

    const processedData = historicalData.map(entry => {
        // const entryTime = parse(entry.time, 'h:mm a', new Date());
        return {
            x: entry.time,
            y: entry.peak,
        };
    });

    const chartData = {
        datasets: [
            {
                label: 'Historical Peaks',
                data: processedData,
                fill: false, // Set fill to false to show just the line
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    const maxPeak = Math.max(...processedData.map(entry => entry.y));

    const chartOptions = {
        animation: {
            duration: 1000, // Animation duration in milliseconds
            easing: 'easeInOutQuad', // Animation easing function
        },
        interaction: {
            intersect: false, // Add this line to prevent multiple data points in tooltip
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: () => '', // Remove the title
                    label: context => {
                        const dataPoint = context.parsed.x;
                        const formattedTime = new Intl.DateTimeFormat('en-IN', {
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZone: 'Asia/Kolkata',
                        }).format(dataPoint);

                        return `${formattedTime}: ${context.parsed.y}`;
                    },
                    afterLabel: () => `Max Peak: ${maxPeak}`, // Display max peak after the label
                },
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    unitStepSize: 1, // Display each hour
                    displayFormats: {
                        hour: 'HH:mm',
                    },
                    timeZone: 'Asia/Kolkata',
                },
                title: {
                    display: true,
                    text: 'Time',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Peak Value',
                },
            },
        },
    };

    return (
        <div>
            <h2>Historical Data Chart</h2>
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    );
};

export default HistoricalChart;
