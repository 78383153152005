/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

interface NotificationContextProps {
    //Modal State
    sendBNModal?: boolean;
    setSendBNModal?: (sendBNModal: boolean) => void;
    sendInAppModal?: boolean;
    setSendInAppModal?: (sendInAppModal: boolean) => void;
    updateInAppModal?: boolean;
    setUpdateInAppModal?: (updateInAppModal: boolean) => void;
}

export const NotificationContext =
    React.createContext<NotificationContextProps>({
        //Modal State
        sendBNModal: false,
        setSendBNModal: () => {},
        sendInAppModal: false,
        setSendInAppModal: () => {},
        updateInAppModal: false,
        setUpdateInAppModal: () => {},
    });

export const NotificationContextProvider = (props: any) => {
    const [sendBNModal, setSendBNModal] = useState<boolean>();
    const [sendInAppModal, setSendInAppModal] = useState<boolean>();
    const [updateInAppModal, setUpdateInAppModal] = useState<boolean>();

    return (
        <NotificationContext.Provider
            value={{
                sendBNModal,
                setSendBNModal,
                sendInAppModal,
                setSendInAppModal,
                updateInAppModal,
                setUpdateInAppModal,
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    );
};
