/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Nav } from 'react-bootstrap';
import { useGlobalContext } from '../../../../Context/index.tsx';
interface navItemProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    eventKey: string;
    children?: React.ReactNode;
    table?: React.ReactNode;
}

interface Props {
    data: navItemProps[];
}

const NavTabsList: React.FC<Props> = ({ data }) => {
    const { setSelectedTable } = useGlobalContext();

    const tableSelectHandler = (e: any) => {
        const input = e.target as HTMLElement;
        setSelectedTable({ name: input.innerText });
    };
    return (
        <>
            {data
                ? data?.map(navBar => {
                      return (
                          <Nav.Item key={navBar.eventKey}>
                              <Nav.Link
                                  onClick={tableSelectHandler}
                                  eventKey={navBar.eventKey}
                              >
                                  {navBar.children}
                              </Nav.Link>
                          </Nav.Item>
                      );
                  })
                : ''}
        </>
    );
};

export default NavTabsList;
