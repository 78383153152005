import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import { useGlobalContext, useUserContext } from '../../Context/index';

import PageTitle from '../../Components/PageTitle/PageTitle.js';
import CustomButton from '../../Components/Common/CustomButton/CustomButton';
import './User.css';

import SuccessToast from '../../Components/Common/Toast/SuccessToast';
import FailureToast from '../../Components/Common/Toast/FailureToast';
import AddUsersModal from '../../Components/User/AddUsers/AddUsersModal';
import RemoveUserModal from '../../Components/User/RemoveUser/RemoveUserModal';
import MakeSpeakerModal from '../../Components/User/MakeSpeaker/MakeSpeakerModal';
import SpeakersTable from '../../Components/User/SpeakersTable/SpeakersTable';
import AddUser from '../../Components/User/AddUser/AddUser';
import UpdateUser from '../../Components/User/UpdateUser';
const User: React.FC = () => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);

    const handleShowAddUserModal = () => {
        setShowAddUserModal(showAddUserModal ? false : true);
    };

    const handleShowUpdateUserModal = () => {
        setShowUpdateUserModal(showUpdateUserModal ? false : true);
    };

    const {
        showSuccessToast,
        showErrorToast,
        setShowSuccessToast,
        setShowErrorToast,
    } = useGlobalContext();

    const {
        setShowAddUsersModal,
        setShowRemoveUserModal,
        setShowMakeSpeakerModal,
    } = useUserContext();
    return (
        <Container className="user-container">
            <Col>
                <Col className="user-title">
                    <PageTitle>User</PageTitle>
                    <CustomButton onClick={handleShowAddUserModal}>
                        Add User
                    </CustomButton>
                    <CustomButton onClick={handleShowUpdateUserModal}>
                        Update User
                    </CustomButton>
                    <CustomButton onClick={() => setShowAddUsersModal(true)}>
                        Add Users
                    </CustomButton>
                    <CustomButton onClick={() => setShowRemoveUserModal(true)}>
                        Remove
                    </CustomButton>
                    <CustomButton onClick={() => setShowMakeSpeakerModal(true)}>
                        Make Speaker
                    </CustomButton>
                </Col>

                <AddUser
                    show={showAddUserModal}
                    onHide={handleShowAddUserModal}
                />

                <AddUsersModal />
                <UpdateUser
                    show={showUpdateUserModal}
                    onHide={handleShowUpdateUserModal}
                />
                <RemoveUserModal />
                <MakeSpeakerModal />
                <SpeakersTable />
            </Col>
            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={() => setShowSuccessToast(false)}
                    headerTitle="Success!"
                />
            )}
            {showErrorToast && (
                <FailureToast
                    showModal={showErrorToast}
                    handleClose={() => setShowErrorToast(false)}
                    headerTitle="Something went wrong, Please try again"
                />
            )}
        </Container>
    );
};

export default User;
