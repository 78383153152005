import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, utcToZonedTime } from 'date-fns-tz';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';

function News() {
    const [newsData, setNewsData] = useState([]);
    const [columnDefs] = useState([
        { field: 'newsid', sortable: true, resizable: true },
        { field: 'title', sortable: true, resizable: true },
        { field: 'summary', sortable: true, resizable: true },
        { field: 'viewCount', sortable: true, resizable: true },
        { field: 'publishTs', sortable: true, resizable: true },
    ]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                `${globalURLS.baseURL}ADNews`,
                globalURLS.config,
            );
            setNewsData(response.data.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (newsData && newsData.length > 0) {
            const finalNewsData = newsData.map(el => {
                const news = {};
                const date = new Date(el.publishTs);
                const ISTDateTime = utcToZonedTime(date, 'Asia/Calcutta');
                const finalISTDateTime = format(
                    ISTDateTime,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                (news.newsid = el.id),
                    (news.title = el.title),
                    (news.summary = el.summary),
                    (news.publishTs = finalISTDateTime),
                    (news.likeCount = el.likeCount),
                    (news.viewCount = el.viewCount);
                return news;
            });

            setRowData(finalNewsData ? finalNewsData : []);
        }
    }, [newsData]);

    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'News'}
            ></Table>
        </>
    );
}

export default News;
