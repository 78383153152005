import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import {
    FaApple,
    FaAndroid,
    FaDesktop,
    FaMobile,
    FaTabletAlt,
} from 'react-icons/fa';

interface PlatformCardProps {
    platform: 'ios' | 'android' | 'desktop_Web' | 'mobile_Web' | 'tablet_Web';
    userCount: number;
}

const platformNames = Object.freeze({
    ios: 'iOS',
    android: 'Android',
    desktop_Web: 'Desktop Web',
    mobile_Web: 'Mobile Web',
    tablet_Web: 'Tablet Web',
});

const PlatformCard: React.FC<PlatformCardProps> = ({ platform, userCount }) => {
    const getPlatformIcon = () => {
        switch (platform) {
            case 'ios':
                return <FaApple fontSize={30} />;
            case 'android':
                return <FaAndroid fontSize={30} />;
            case 'desktop_Web':
                return <FaDesktop fontSize={30} />;
            case 'mobile_Web':
                return <FaMobile fontSize={30} />;
            case 'tablet_Web':
                return <FaTabletAlt fontSize={30} />;
            default:
                return null;
        }
    };

    const platformNameStyle: React.CSSProperties = {
        fontSize: '0.8rem', // Adjust the font size as needed
        fontWeight: 'bold', // You can also adjust other styles
    };

    const userCountStyle: React.CSSProperties = {
        fontSize: '1rem', // Adjust the font size as needed
    };

    return (
        <Card style={{ width: '240px', height: '70px', marginTop: '1em' }}>
            <Card.Body>
                <Row>
                    <Col className="icon text-center mb-3">
                        {getPlatformIcon()}
                    </Col>
                    <Col className="platform-info ">
                        <h5
                            className="card-title platform-name "
                            style={platformNameStyle}
                        >
                            {platformNames[platform]}
                        </h5>
                        <p
                            className="card-text user-count"
                            style={userCountStyle}
                        >
                            {userCount} {userCount <= 1 ? 'User' : 'Users'}
                        </p>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default PlatformCard;
