import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

interface IProp {
    showModal: boolean;
    handleClose: () => void;
    headerTitle: string;
}

const FailureToast: React.FC<IProp> = ({
    showModal,
    handleClose,
    headerTitle,
}) => {
    return (
        <>
            <ToastContainer
                style={{ width: '10em' }}
                position={'middle-center'}
            >
                <Toast onClose={handleClose} show={showModal}>
                    <Toast.Header>
                        <strong
                            style={{
                                fontSize: '1em',
                                padding: '0.5em',
                                color: 'red',
                            }}
                        >
                            {headerTitle
                                ? headerTitle
                                : `Some random error, please try again with proper inputs.`}
                        </strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </>
    );
};

export default FailureToast;
