import React from 'react';
import OptionTypeBase from 'react-select';
import Select, { Props as SelectProps } from 'react-select';
import { Field, FieldProps } from 'formik';

interface CustomSelectProps extends SelectProps<OptionTypeBase> {
    label: string;
    name: string;
    options: OptionTypeBase[];
}

const CustomSelect: React.FC<CustomSelectProps> = ({
    label,
    name,
    options,
    ...selectProps
}) => {
    return (
        <div className="form-group">
            <label>{label}</label>
            <Field name={name}>
                {({ field, form }: FieldProps) => (
                    <Select
                        {...field}
                        {...selectProps}
                        options={options}
                        onChange={selectedOption => {
                            form.setFieldValue(name, selectedOption);
                            if (selectProps.onChange) {
                                selectProps.onChange(selectedOption);
                            }
                        }}
                    />
                )}
            </Field>
        </div>
    );
};

export default CustomSelect;
