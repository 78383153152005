/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import { useBannerContext } from '../../Context/index';
import CustomInput from '../Common/CustomInput/CustomInput';
import { useState } from 'react';
import AddAppBannerForum from './AppBanner/AddAppBannerForum';
import CustomButton from '../Common/CustomButton/CustomButton';
import AddWebBannerForum from './WebBanner/AddWebBannerForum';

const BannerAddModal: React.FC = () => {
    const { addBannerModal, setAddBannerModal } = useBannerContext();
    const [platform, setPlatform] = useState('');
    const handlePlatform = (e: any) => {
        setPlatform(e);
    };

    return (
        <Modal show={addBannerModal} animation={false}>
            <Container>
                <CustomInput
                    label={'Platform'}
                    textArea={'short'}
                    type={'dropdown'}
                    required={true}
                    placeHolder={'Enter a Platform'}
                    dropDown={{
                        title: 'Select Platform ',
                        dropDownItems: ['Mobile App', 'Desktop Web'],
                    }}
                    onSelect={handlePlatform}
                    errorMessage={'Please Enter Platform'}
                />
            </Container>
            {!platform && (
                <Container
                    style={{
                        height: '50vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'flex-start',
                    }}
                >
                    Please Select A Platform
                    <CustomButton onClick={() => setAddBannerModal(false)}>
                        Close
                    </CustomButton>
                </Container>
            )}
            {platform && platform === 'Mobile App' && <AddAppBannerForum />}
            {platform && platform === 'Desktop Web' && <AddWebBannerForum />}
        </Modal>
    );
};

export default BannerAddModal;
