import axios from 'axios';
import React, { useState, ChangeEvent, FormEvent } from 'react';
import Select from 'react-select';
import { Button, Modal, Form, Image } from 'react-bootstrap';
import { globalURLS } from '../../../Global/urls';
import { useClubContext, useGlobalContext } from '../../../Context';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';

interface Club {
    name: string;
    image: string;
    logo: string;
    description: string;
    handle: string;
    clubAdmin: string[];
    specialities: string[];
    isPrivate: boolean;
}

const initialClub: Club = {
    name: '',
    image: '',
    logo: '',
    description: '',
    handle: '',
    clubAdmin: [''],
    specialities: [''],
    isPrivate: false,
};

const AddClub: React.FC = () => {
    const [club, setClub] = useState<Club>(initialClub);
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const { addClubModal, setAddClubModal } = useClubContext();

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const getSelectSpeakers = true;
    const getSelectSpecialties = true;
    const { selectSpeakers, selectSpecialties } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectSpeakers,
            getSelectSpecialties,
        });

    const handleClose = () => {
        setAddClubModal(false);
        setClub(initialClub);
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setClub({
            ...club,
            [e.target.name]:
                e.target.name === 'image' || e.target.name === 'logo'
                    ? e.target.value.trim()
                    : e.target.value,
        });
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setClub({ ...club, [e.target.name]: e.target.checked });
    };

    const handleClubAdmins = val => {
        const mappedAdminIDs = val.map(el => el.value);
        setClub({ ...club, clubAdmin: mappedAdminIDs });
    };

    const handleSpecialties = val => {
        const mappedSpecialtiesIDs = val.map(el => el.value);
        setClub({ ...club, specialities: mappedSpecialtiesIDs });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(club);

        setIsLoading(true);

        handleClose();

        if (e.currentTarget.checkValidity() === false) {
            setValidated(true);
        } else {
            try {
                const bodyData = {
                    ...club,
                    image: [club?.image],
                    isPrivate: club?.isPrivate ? 1 : 0,
                };
                const res = await axios.post(
                    `${globalURLS.baseURL}addClubs`,
                    { clubs: [bodyData] },
                    globalURLS.config,
                );

                if (res?.data?.success === true) {
                    setClub(initialClub);
                    setAddClubModal(false);
                    setShowSuccessToast(true);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    setShowErrorToast(true);
                }
            } catch (e) {
                setShowErrorToast(true);
            }
        }
    };

    return (
        <>
            <Modal show={addClubModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Club</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Form.Group controlId="formClubName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={club.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formClubImage">
                            <Form.Label>Image URL</Form.Label>
                            <Form.Control
                                type="url"
                                name="image"
                                value={club.image}
                                onChange={handleChange}
                                required
                            />
                            {club.image && (
                                <Image
                                    src={club.image}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}
                        </Form.Group>

                        <Form.Group controlId="formClubLogo">
                            <Form.Label>Logo URL</Form.Label>
                            <Form.Control
                                type="url"
                                name="logo"
                                value={club.logo}
                                onChange={handleChange}
                                required
                            />
                            {club.logo && (
                                <Image
                                    src={club.logo}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}
                        </Form.Group>

                        <Form.Group controlId="formClubDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={club.description}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formClubHandle">
                            <Form.Label>Handle</Form.Label>
                            <Form.Control
                                type="text"
                                name="handle"
                                value={club.handle}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formClubAdmin">
                            <Form.Label>Club Admin</Form.Label>

                            <Select
                                closeMenuOnSelect={true}
                                onChange={val => handleClubAdmins(val)}
                                isSearchable
                                isMulti={true}
                                options={selectSpeakers || []}
                            />
                        </Form.Group>

                        <Form.Group controlId="formSpecialties">
                            <Form.Label>Specialties</Form.Label>

                            <Select
                                closeMenuOnSelect={true}
                                onChange={val => handleSpecialties(val)}
                                isSearchable
                                isMulti={true}
                                options={selectSpecialties}
                            />
                        </Form.Group>

                        <Form.Group controlId="formClubPrivate">
                            <Form.Check
                                type="checkbox"
                                name="isPrivate"
                                checked={club.isPrivate}
                                onChange={handleCheckboxChange}
                                label="Is Private"
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isLoading}
                            >
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddClub;
