import React from 'react';
import {
    Container,
    Form,
    InputGroup,
    Col,
    Button,
    Row,
    Image,
} from 'react-bootstrap';

import { useFeedContext } from '../../../../../Context/index.tsx';

const LPPreview = () => {
    const { notToEditFeed, longPollsData, feedType } = useFeedContext();
    return (
        <Container className="mt-2">
            {longPollsData &&
                longPollsData.map((poll, index) => (
                    <Container key={index}>
                        <InputGroup className="mb-0">
                            <InputGroup.Text>{`Q${index + 1}`}</InputGroup.Text>
                            <Form.Control
                                as={'input'}
                                type={'text'}
                                placeholder={`Question ${index + 1}`}
                                name={`question${index + 1}`}
                                value={poll.question}
                                readOnly={true}
                            />
                        </InputGroup>
                        {poll?.options?.map((option, ind) => (
                            <InputGroup className="mt-0 mb-2" key={ind}>
                                <InputGroup.Text id="option__no">
                                    {ind + 1}
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder={`Option ${ind + 1}`}
                                    name={`option${ind + 1}`}
                                    readOnly={true}
                                    value={option.text}
                                />
                            </InputGroup>
                        ))}
                        {longPollsData[index].bannersMedia.map(
                            (banner, bIndex) => (
                                <>
                                    <Row>
                                        <Col>
                                            <InputGroup.Text>{`Url`}</InputGroup.Text>
                                            <Form.Control
                                                placeholder={`Banner_Q${
                                                    bIndex + 1
                                                }`}
                                                name={`banner${bIndex + 1}`}
                                                readOnly={true}
                                                value={
                                                    poll?.bannersMedia[bIndex]
                                                        ?.src
                                                }
                                                aria-label="Option with + and -"
                                            />
                                        </Col>
                                        <Row>
                                            {poll?.bannersMedia[bIndex]
                                                ?.src && (
                                                <Image
                                                    src={
                                                        poll.bannersMedia[
                                                            bIndex
                                                        ].src
                                                    }
                                                    thumbnail
                                                    style={{
                                                        marginTop: '0.5em',
                                                        width: 'auto',
                                                        height: 'auto',
                                                    }}
                                                />
                                            )}
                                        </Row>
                                    </Row>
                                </>
                            ),
                        )}
                        <Col>
                            {poll.isCorrectOption != null && (
                                <Button
                                    variant={'success'}
                                    className="me-2 mb-3"
                                    style={{ border: 'none' }}
                                >
                                    {`Option ${poll.isCorrectOption + 1}`}
                                </Button>
                            )}
                            {(feedType == 'quiz' ||
                                poll.positiveWeight != null) && (
                                <Button
                                    variant={'success'}
                                    className="me-2 mb-3"
                                    style={{ border: 'none' }}
                                >
                                    {poll.positiveWeight}
                                </Button>
                            )}
                            {(feedType == 'quiz' ||
                                poll.negativeWeight != null) && (
                                <Button
                                    variant={'danger'}
                                    className="me-2 mb-3"
                                    style={{ border: 'none' }}
                                >
                                    {poll.negativeWeight}
                                </Button>
                            )}
                        </Col>
                    </Container>
                ))}
        </Container>
    );
};

export default LPPreview;
