// import * as DiscussionContext from './DiscussionContext.js'
// export {
//     DiscussionContext
// }

import { useContext } from 'react';
import { FeedContext } from './FeedContext.js';
import { ReportContext } from './ReportContext';
import { GlobalContext } from './GlobalContext';
import { BannerContext } from './BannerContext';
import { PmdContext } from './PmdContext';
import { UserContext } from './UserContext';
import { ClubContext } from './ClubContext';
import { NotificationContext } from './NotificationContext';
export * as DiscussionContext from './DiscussionContext.js';

export const useGlobalContext = () => useContext(GlobalContext);
export const useFeedContext = () => useContext(FeedContext);
export const useReportContext = () => useContext(ReportContext);
export const useBannerContext = () => useContext(BannerContext);
export const usePmdContext = () => useContext(PmdContext);
export const useUserContext = () => useContext(UserContext);
export const useClubContext = () => useContext(ClubContext);
export const useNotificationContext = () => useContext(NotificationContext);
