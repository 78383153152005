import React, { useState } from 'react';
import Dropdown from './Dropdown';
import StatsDisplay from './StatsDisplay';
import { getAdStats } from './api';
import './AdsDash.css';

const Dashboard: React.FC = () => {
    const [stats, setStats] = useState<any[]>([]);

    const handleSelect = async (adId: string) => {
        const statsData = await getAdStats(adId);
        setStats(statsData);
    };

    return (
        <div className="dashboard">
            <Dropdown onSelect={handleSelect} />
            {stats.length > 0 && <StatsDisplay stats={stats} />}
        </div>
    );
};

export default Dashboard;
