/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,
    InputGroup,
    Container,
    Col,
    Image,
    Row,
} from 'react-bootstrap';
import axios from 'axios';
import { format, zonedTimeToUtc } from 'date-fns-tz';

// Components imports
import { CustomInput } from '../../../../Common/index.js';
import { useFeedContext } from '../../../../../Context/index.tsx';
import SPPreview from '../../Step3/Preview/SPPreview.js';
import LPPreview from '../../Step3/Preview/LPPreview.js';
import NewsFeedsTags from '../../../NewsFeedsTags/NewsFeedsTags.js';
import { globalURLS } from '../../../../../Global/urls.js';

const FeedInput = ({ isPreview = false }) => {
    const {
        step,
        feedType,
        feedData,
        setFeedData,
        shortPollData,
        setShortPollData,
        longPollsData,
        setLongPollsData,
        feedTags,
        setFeedTags,
        notToEditFeed,
        setNotToEditFeed,
    } = useFeedContext();

    const [title, setTitle] = useState(feedData.title || '');
    const [synopsis, setSynopsis] = useState(feedData.summary || '');
    const [sessionHeader, setSessionHeader] = useState(feedData.header || '');
    const [sessionHeaderURL, setSessionHeaderURL] = useState(
        feedData.headerIcon || '',
    );
    const [showRes, setShowRes] = useState(feedData.showResult ? 1 : 0);
    const [test, setTest] = useState(feedData.isTest || 0);
    const [hide, setHide] = useState(feedData.hide || 0);
    const [date, setDate] = useState(feedData.date || '');
    const [time, setTime] = useState(feedData.time || '');
    const [publishTs, setPublishTs] = useState(feedData.publishTs || '');
    const [endDate, setEndDate] = useState(feedData.endDate);
    const [endTime, setEndTime] = useState(feedData.endTime);
    const [bannersMedia, setBannersMedia] = useState(
        feedData.bannersMedia || [{ src: '', cta: '', type: '' }],
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    useEffect(() => {
        const fetchData = async () => {
            let resp = null;
            let respShortPoll = null;
            let respLongPoll = null;

            if (feedData?.feedTypeDelete === 'news') {
                resp = await axios.get(
                    `${globalURLS.baseURL}ADNews/${feedData?.newsid}`,
                    globalURLS.config,
                );

                respShortPoll = await axios.get(
                    `${globalURLS.baseURL}ADPoll/${feedData?.newsid}`,
                    globalURLS.config,
                );
            }

            if (feedData?.feedTypeDelete === 'quiz') {
                resp = await axios.get(
                    `${globalURLS.baseURL}ADQuiz/${feedData?.quizid}`,
                    globalURLS.config,
                );

                respLongPoll = await axios.get(
                    `${globalURLS.baseURL}ADPoll/${feedData?.quizid}`,
                    globalURLS.config,
                );
            }

            if (feedData?.feedTypeDelete === 'ques') {
                resp = await axios.get(
                    `${globalURLS.baseURL}ADQuestionnaire/${feedData?.questionnaireid}`,
                    globalURLS.config,
                );

                respLongPoll = await axios.get(
                    `${globalURLS.baseURL}ADPoll/${feedData?.questionnaireid}`,
                    globalURLS.config,
                );
            }

            let finalResp = {
                ...feedData,
                ...resp?.data?.data,
            };
            if (resp?.data?.data) {
                const finalDate = format(
                    new Date(finalResp.publishTs),
                    'yyyy-MM-dd',
                    { timeZone: 'Asia/Calcutta' },
                );

                setDate(finalDate);
                const finalTime = format(
                    new Date(finalResp.publishTs),
                    'HH:mm',
                    {
                        timeZone: 'Asia/Calcutta',
                    },
                );
                //  new Date(epochstartdate).toLocaleTimeString('it-IT', { timeZone: 'Asia/Calcutta' });
                setTime(finalTime);
                const finalEndDate = format(
                    new Date(finalResp.deadline),
                    'yyyy-MM-dd',
                    { timeZone: 'Asia/Calcutta' },
                );
                setEndDate(finalEndDate);

                const finalEndTime = format(
                    new Date(finalResp.deadline),
                    'HH:mm',
                    {
                        timeZone: 'Asia/Calcutta',
                    },
                );
                setEndTime(finalEndTime);

                setFeedData(finalResp);
                setBannersMedia(
                    finalResp?.bannersMedia ? finalResp?.bannersMedia : [],
                );
                setHide(finalResp?.hide);
                setTest(finalResp?.isTest);

                setFeedData(finalResp);
            }

            if (respShortPoll?.data?.data && respShortPoll?.data?.data[0]) {
                setShortPollData(respShortPoll?.data?.data[0] || {});
            }

            if (respLongPoll?.data?.data && respLongPoll?.data?.data[0]) {
                setLongPollsData(respLongPoll?.data?.data || []);
            }
        };
        fetchData();
    }, [feedData?.feedTypeDelete, feedData?.newsid]);

    const timeHandler = (handleName, e) => {
        const data = e.target.value;
        let feedValues = feedData;

        switch (handleName) {
            case 'endDate':
                feedValues.endDate = data;
                setFeedData(feedValues);
                setEndDate(data);
                break;
            case 'endTime':
                feedValues.endTime = data;
                setFeedData(feedValues);
                setEndTime(data);
                break;
            default:
                break;
        }
    };

    const handleTitle = e => {
        const { value } = e.target;
        let feedValues = feedData;
        feedValues.title = value;
        setFeedData(feedValues);
        setTitle(e.target.value);
    };

    const handleSynopsis = e => {
        const { value } = e.target;
        let feedValues = feedData;
        feedValues.summary = value;
        setFeedData(feedValues);
        setSynopsis(e.target.value);
    };

    const handleSessionHeader = e => {
        const { value } = e.target;
        let feedValues = feedData;
        feedValues.header = value;
        setFeedData(feedValues);
        setSessionHeader(e.target.value);
    };

    const handleSessionHeaderURL = e => {
        const { value } = e.target;
        setSessionHeaderURL(value.trim());
        let feedValues = feedData;
        feedValues.headerIcon = value;
        setFeedData(feedValues);
    };

    const handleShowRes = () => {
        const newRes = showRes ? 0 : 1;
        setShowRes(newRes);
        let feedValues = feedData;
        feedValues.showResult = newRes == 1 ? true : false;
        setFeedData(feedValues);
    };

    const handleTest = () => {
        let feedValues = feedData;
        feedValues.isTest = test === 1 ? 0 : 1;
        setTest(feedValues.isTest);
        setFeedData(feedValues);
    };

    const handleHide = () => {
        let feedValues = feedData;
        feedValues.hide = hide === 1 ? 0 : 1;
        setHide(feedValues.hide);
        setFeedData(feedValues);
    };

    const handleDate = e => {
        setDate(e.target.value);
        let feedValues = feedData;
        feedValues.date = e.target.value;
        setFeedData(feedValues);
    };

    const handleTime = e => {
        setTime(e.target.value);
        let feedValues = feedData;
        feedValues.time = e.target.value;
        setFeedData(feedValues);
    };

    const handleBannersMedia = (e, index) => {
        const { value } = e.target;
        let values = [...bannersMedia];
        values[index].src = value.trim();
        setBannersMedia(values);
        let feedValues = feedData;
        feedValues.bannersMedia = values;
        setFeedData(feedValues);
    };

    const handleType = (e, index) => {
        let values = [...bannersMedia];
        values[index].type = e === 'Video' ? 'video' : e;
        setBannersMedia(values);
        let feedValues = feedData;
        feedValues.bannersMedia = values;
        setFeedData(feedValues);
    };

    const handleFeedTags = (e, index) => {
        const { value } = e.target;
        let values = [...feedTags];
        values[index] = `${value}`;
        setFeedTags(values);
    };

    const handleAddTags = () => {
        let values = [...feedTags];
        values.push('');
        setFeedTags(values);
    };

    const handleRemoveTags = () => {
        let values = [...feedTags];
        values.pop();
        setFeedTags(values);
    };

    const handleRemoveImgUrl = () => {
        let values = [...bannersMedia];
        values.pop();
        setBannersMedia(values);
    };
    const handleAddImgUrl = () => {
        let values = [...bannersMedia];
        values.push({ src: '' });
        setBannersMedia(values);
    };

    useEffect(() => {
        let values = feedData;
        if (date && date.length && time && time.length) {
            let utcPublishTs = zonedTimeToUtc(
                `${date + ' ' + time}`,
                'Asia/Calcutta',
            );
            values.publishTs = utcPublishTs;
            setFeedData(values);
        }
        if (endDate && endDate.length && endTime && endTime.length) {
            let utcEndDate = zonedTimeToUtc(
                `${endDate + ' ' + endTime}`,
                'Asia/Calcutta',
            );
            values.deadline = utcEndDate;
            setFeedData(values);
        }
    }, [date, time, endDate, endTime]);

    return (
        <Container>
            <>
                {feedData?.feedTypeDelete === 'news' && (
                    <>
                        {/* News ID */}
                        <CustomInput
                            textArea="short"
                            type={'text'}
                            label="News ID"
                            value={feedData.newsid}
                            disabled={true}
                        />
                    </>
                )}

                {feedData?.feedTypeDelete === 'quiz' && (
                    <>
                        {/* Quiz ID */}
                        <CustomInput
                            textArea="short"
                            type={'text'}
                            label="Quiz ID"
                            value={feedData.quizid}
                            disabled={true}
                        />
                    </>
                )}

                {feedData?.feedTypeDelete === 'ques' && (
                    <>
                        {/* Questionnaire ID */}
                        <CustomInput
                            textArea="short"
                            type={'text'}
                            label="Questionnaire ID"
                            value={feedData.questionnaireid}
                            disabled={true}
                        />
                    </>
                )}
                {/* Session Header */}
                {feedData?.feedTypeDelete !== 'news' && feedType != 'news' && (
                    <CustomInput
                        textArea="short"
                        type={'text'}
                        required={false}
                        label="Header"
                        placeHolder={`Enter Header for the ${feedType}`}
                        errorMessage="Please provide a feed header."
                        value={sessionHeader}
                        onChange={handleSessionHeader}
                        disabled={step === 3 ? notToEditFeed : false}
                    />
                )}
                {/* Session Header Icon URL*/}
                {feedData?.feedTypeDelete !== 'news' && feedType != 'news' && (
                    <CustomInput
                        textArea="short"
                        type={'text'}
                        required={false}
                        label="Header Icon URL"
                        placeHolder={`Enter Header Icon Url for the ${feedType}`}
                        errorMessage="Please provide a feed header icon url."
                        value={sessionHeaderURL}
                        onChange={handleSessionHeaderURL}
                        disabled={step === 3 ? notToEditFeed : false}
                    />
                )}
                {/* Title */}
                <CustomInput
                    textArea="short"
                    type={'text'}
                    required={true}
                    errorMessage="Please provide a news title."
                    label="Title"
                    placeHolder={`Enter ${feedType} title`}
                    value={title}
                    onChange={handleTitle}
                    disabled={step === 3 ? notToEditFeed : false}
                />
                {/* Synopsis */}
                <CustomInput
                    textArea="long"
                    required={true}
                    errorMessage="Please provide a Summay."
                    label="Synopsis"
                    placeHolder="Synopsis"
                    value={synopsis}
                    onChange={handleSynopsis}
                    disabled={step === 3 ? notToEditFeed : false}
                />

                {/* Date and Time */}
                {/* Publish Date and Time */}
                <InputGroup className="row">
                    <Form.Label className="m-2 mt-3" htmlFor="basic-url">
                        Publish
                    </Form.Label>
                    <Container
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <CustomInput
                            textArea="short"
                            type={'date'}
                            required={true}
                            errorMessage="Please provide a publish date."
                            value={date}
                            onChange={handleDate}
                            disabled={step === 3 ? notToEditFeed : false}
                        />
                        <CustomInput
                            textArea="short"
                            type={'time'}
                            required={true}
                            errorMessage="Please provide a publish time."
                            value={time}
                            onChange={handleTime}
                            disabled={step === 3 ? notToEditFeed : false}
                        />
                    </Container>
                </InputGroup>

                {/* Deadline Date and Time */}
                {feedData?.feedTypeDelete !== 'news' && feedType != 'news' && (
                    <InputGroup className="row">
                        <Form.Label className="m-2 mt-3" htmlFor="basic-url">
                            Deadline
                        </Form.Label>
                        <Container
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <CustomInput
                                textArea="short"
                                type={'date'}
                                required={true}
                                errorMessage="Please provide a deadline date."
                                value={endDate}
                                onChange={e => {
                                    timeHandler('endDate', e);
                                }}
                                disabled={step === 3 ? notToEditFeed : false}
                            />
                            <CustomInput
                                textArea="short"
                                type={'time'}
                                required={true}
                                errorMessage="Please provide a deadline time."
                                value={endTime}
                                onChange={e => {
                                    timeHandler('endTime', e);
                                }}
                                disabled={step === 3 ? notToEditFeed : false}
                            />
                        </Container>
                    </InputGroup>
                )}

                {/* Banner Url */}
                {(feedType === 'news' || feedData?.feedTypeDelete === 'news') &&
                    bannersMedia.map((banner, index) => (
                        <>
                            <CustomInput
                                label={'Select Type'}
                                textArea={'short'}
                                type={'dropdown'}
                                required={true}
                                value={banner.type}
                                dropDown={{
                                    title: 'Select Type',
                                    dropDownItems: ['Image', 'Video'],
                                }}
                                defaultValue={'Image'}
                                onSelect={e => handleType(e, index)}
                                errorMessage={'Please Type'}
                            />
                            <InputGroup className="row" key={index}>
                                <Form.Label
                                    className="m-2 mt-2"
                                    htmlFor="basic-url"
                                >
                                    {`${banner.type}${index + 1} Url:`}
                                </Form.Label>
                                <Form.Control
                                    className="mx-3 mb-2"
                                    placeholder={`Enter ${banner.type}${
                                        index + 1
                                    } Url`}
                                    name={`${banner.type}${index + 1}`}
                                    onChange={e => handleBannersMedia(e, index)}
                                    value={banner.src}
                                    disabled={
                                        step === 3 ? notToEditFeed : false
                                    }
                                />
                                {banner.src && banner.type === 'Image' && (
                                    <Image
                                        src={banner.src}
                                        fluid={true}
                                        style={{
                                            width: '80vw',
                                            height: '45vh',
                                        }}
                                    />
                                )}
                            </InputGroup>
                        </>
                    ))}
                {(feedType === 'news' ||
                    feedData?.feedTypeDelete === 'news') && (
                    <Col className="mb-2 mx-1">
                        <Button
                            className="me-2"
                            variant="outline-secondary"
                            onClick={() => handleRemoveImgUrl()}
                            disabled={
                                bannersMedia.length == 1 || step === 3
                                    ? notToEditFeed
                                    : false
                            }
                        >
                            - Image/Video
                        </Button>

                        <Button
                            className="me-2"
                            variant="outline-secondary"
                            onClick={() => handleAddImgUrl()}
                            disabled={step === 3 ? notToEditFeed : false}
                        >
                            + Image/Video
                        </Button>
                    </Col>
                )}

                {/* Feed Tags */}
                {!feedData?.feedTypeDelete && (
                    <>
                        <Form.Label className="m-2 mt-2" htmlFor="basic-url">
                            Add Tags
                        </Form.Label>
                        <NewsFeedsTags />
                    </>
                )}

                {((feedType === 'news' && step === 2) ||
                    (feedType === 'news' && step === 3 && shortPollData) ||
                    (feedData?.feedTypeDelete === 'news' &&
                        shortPollData &&
                        shortPollData.pollOptions)) && <SPPreview />}

                {/* {isPreview && longPollsData && feedType != 'news' && (
                    <LPPreview />
                )} */}
                {longPollsData && feedType !== 'news' && <LPPreview />}

                <Row>
                    {/* ShowResult */}
                    {feedType == 'ques' && (
                        <InputGroup className="mt-3 ml-5">
                            <CustomInput
                                textArea="short"
                                type="switches"
                                id="custom-switch"
                                label="Show Result"
                                value={showRes}
                                onChange={() => handleShowRes()}
                                disabled={step === 3 ? notToEditFeed : false}
                            />
                        </InputGroup>
                    )}

                    {/* IsTest */}
                    <InputGroup className="mt-3 ml-5">
                        <CustomInput
                            textArea="short"
                            type="switches"
                            id="custom-switch"
                            label="Test"
                            value={test}
                            onChange={handleTest}
                            disabled={step === 3 ? notToEditFeed : false}
                        />
                    </InputGroup>

                    {/* Hide */}
                    <InputGroup className="mt-3 ml-5">
                        <CustomInput
                            textArea="short"
                            type="switches"
                            id="custom-switch"
                            label="Hide"
                            value={hide}
                            onChange={handleHide}
                            disabled={step === 3 ? notToEditFeed : false}
                        />
                    </InputGroup>
                </Row>
            </>
        </Container>
    );
};

export default FeedInput;
