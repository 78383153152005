import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

interface IProp {
    showModal: boolean;
    headerTitle: string;
}

const LoadingToast: React.FC<IProp> = ({ showModal, headerTitle }) => {
    return (
        <>
            <ToastContainer
                style={{ width: '10em' }}
                position={'middle-center'}
            >
                <Toast show={showModal}>
                    <Toast.Header>
                        <strong
                            style={{
                                fontSize: '1em',
                                padding: '0.5em',
                                color: 'orange',
                            }}
                        >
                            {headerTitle ? headerTitle : `Loading...`}
                        </strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </>
    );
};

export default LoadingToast;
