/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

interface UserContextProps {
    showAddUsersModal?: boolean;
    setShowAddUsersModal?: (showAddUsersModal: boolean) => void;
    showRemoveUserModal?: boolean;
    setShowRemoveUserModal?: (showRemoveUserModal: boolean) => void;
    showMakeSpeakerModal?: boolean;
    setShowMakeSpeakerModal?: (showMakeSpeakerModal: boolean) => void;
}

export const UserContext = React.createContext<UserContextProps>({
    showAddUsersModal: false,
    setShowAddUsersModal: () => {},
    showRemoveUserModal: false,
    setShowRemoveUserModal: () => {},
    showMakeSpeakerModal: false,
    setShowMakeSpeakerModal: () => {},
});

export const UserContextProvider = (props: any) => {
    const [showAddUsersModal, setShowAddUsersModal] = useState<boolean>();
    const [showRemoveUserModal, setShowRemoveUserModal] = useState<boolean>();
    const [showMakeSpeakerModal, setShowMakeSpeakerModal] = useState<boolean>();

    return (
        <UserContext.Provider
            value={{
                showAddUsersModal,
                setShowAddUsersModal,
                showRemoveUserModal,
                setShowRemoveUserModal,
                showMakeSpeakerModal,
                setShowMakeSpeakerModal,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
