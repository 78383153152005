import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';

const convertUTCtoIST = async (date: string) => {
    const ist = utcToZonedTime(date, 'Asia/Kolkata');
    return format(ist, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'Asia/Kolkata' });
};

const convertIstToUtc = async (dateString: string) => {
    // Define the timezone for the provided date
    const timeZone = 'Asia/Kolkata';

    // Create a Date object in the desired timezone
    const zonedDate = zonedTimeToUtc(dateString, timeZone);

    // Format the date in the desired format
    const pattern = 'yyyy-MM-dd HH:mm:ss';
    const formattedUtcDate = format(zonedDate, pattern);

    return formattedUtcDate;
};

const convertLocalToIst = async (dateString: string) => {
    return new Promise(resolve => {
        // Convert local time to UTC
        const utcDate = zonedTimeToUtc(
            dateString,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
        );

        // Convert UTC to IST
        const istTimeZone = 'Asia/Kolkata';
        const istDate = utcToZonedTime(utcDate, istTimeZone);

        // Format IST date to the format accepted by datetime-local input
        const pattern = "yyyy-MM-dd'T'HH:mm";
        const istDateString = format(istDate, pattern);

        resolve(istDateString);
    });
};

export { convertUTCtoIST, convertIstToUtc, convertLocalToIst };
