import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

interface TextInputProps {
    label: string;
    name: string;
}

const TextInput: React.FC<TextInputProps> = ({ label, name }) => {
    return (
        <Form.Group as={Col}>
            <Form.Label>{label}</Form.Label>
            <Field type="text" name={name} className="form-control" />
            <ErrorMessage name={name} component="div" className="text-danger" />
        </Form.Group>
    );
};

export default TextInput;
