import React from 'react';
import YearPickerInput from 'react-year-picker';
import { Field, FieldProps } from 'formik';
import { Form, Stack } from 'react-bootstrap';

interface YearPickerProps {
    label: string;
    name: string;
}

const YearPicker: React.FC<YearPickerProps> = ({ label, name }) => {
    return (
        <Form.Group
            style={{
                zIndex: 1000,
            }}
        >
            {/* <Stack gap={3} style={{ margin: '10px' }}> */}
            <Form.Label>{label}</Form.Label>
            <Field name={name}>
                {({ field, form }: FieldProps) => (
                    <YearPickerInput
                        style={{
                            zIndex: 1000,
                        }}
                        className="form-control"
                        {...field}
                        year={field.value}
                        onChange={(year: number) =>
                            form.setFieldValue(field.name, year)
                        }
                        max={new Date().getFullYear()}
                    />
                )}
            </Field>
            {/* </Stack> */}
        </Form.Group>
    );
};

export default YearPicker;
