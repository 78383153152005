import React from 'react';
import { Button } from 'react-bootstrap';
import PageTitle from '../../Components/PageTitle/PageTitle';

import './Club.css';
import { useClubContext, useGlobalContext } from '../../Context';
import AddClub from '../../Components/Club/AddClub/AddClub';
import Clubs from '../../Components/Club/Clubs/Clubs';
import FailureToast from '../../Components/Common/Toast/FailureToast';
import SuccessToast from '../../Components/Common/Toast/SuccessToast';

const Club: React.FC = () => {
    const { setAddClubModal } = useClubContext();
    const {
        showSuccessToast,
        showErrorToast,
        setShowSuccessToast,
        setShowErrorToast,
    } = useGlobalContext();
    return (
        <div className="club-container">
            <div className="club-title">
                <PageTitle>Club</PageTitle>
                <Button
                    className="btn-add"
                    variant="primary"
                    onClick={() => setAddClubModal(true)}
                >
                    + Club
                </Button>
                <AddClub />
            </div>
            <Clubs />
            {showSuccessToast && (
                <SuccessToast
                    showModal={showSuccessToast}
                    handleClose={() => setShowSuccessToast(false)}
                    headerTitle="Success!"
                />
            )}
            {showErrorToast && (
                <FailureToast
                    showModal={showErrorToast}
                    handleClose={() => setShowErrorToast(false)}
                    headerTitle="Something went wrong, Please try again"
                />
            )}
        </div>
    );
};

export default Club;
