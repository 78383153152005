/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { Button, Modal, Container, Form, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';

import './AddFiles.css';
import {
    DiscussionContext,
    useGlobalContext,
} from '../../../Context/index.tsx';
import { globalURLS } from '../../../Global/urls.js';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts.tsx';

function AddFiles() {
    const { openAddFilesModal, setAddFilesModal } = useContext(
        DiscussionContext.AddDiscussionFilesContext,
    );

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const [inputList, setinputList] = useState([
        { discussionid: '', name: '', url: '', authorid: '' },
    ]);
    const [validated, setValidated] = useState(false);

    const getSelectDiscussions = true;
    const getSelectSpeakers = true;

    const { selectDiscussions, selectSpeakers } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectDiscussions,
            getSelectSpeakers,
        });

    const mergeSpeakerAndInternalHost = selectSpeakers;

    const handleinputchange = (name, val, index) => {
        const list = [...inputList];
        list[index][name] = val?.value ? val?.value : val?.target?.value;
        setinputList(list);
    };

    const handleremove = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setinputList(list);
    };

    const handleaddclick = () => {
        setinputList([
            ...inputList,
            { discussionid: '', name: '', url: '', authorid: '' },
        ]);
    };

    const btnclose = () => {
        setAddFilesModal(false);
    };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        const resp = await axios.post(
            `${globalURLS.baseURL}addDiscussionFile`,
            { inputList },
            globalURLS.config,
        );

        if (resp?.data?.success === true) {
            setShowSuccessToast(true);
            setAddFilesModal(false);
        } else {
            setShowErrorToast(true);
        }
    };

    return (
        <Container>
            <Col>
                <Modal show={openAddFilesModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Discussion Files</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                {inputList.map((x, i) => {
                                    return (
                                        <Row key={i}>
                                            <Row>
                                                {/* Discussion */}
                                                <div className="form-group col-md-6">
                                                    <Form.Label>
                                                        Discussion
                                                    </Form.Label>

                                                    <Select
                                                        closeMenuOnSelect={true}
                                                        onChange={val =>
                                                            handleinputchange(
                                                                'discussionid',
                                                                val,
                                                                i,
                                                            )
                                                        }
                                                        isSearchable
                                                        options={
                                                            selectDiscussions
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a
                                                        Discussionid.
                                                    </Form.Control.Feedback>
                                                </div>

                                                {/* Name */}
                                                <div className="form-group col-md-6">
                                                    <Form.Label>
                                                        File Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        onChange={e =>
                                                            handleinputchange(
                                                                'name',
                                                                e,
                                                                i,
                                                            )
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a Name.
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Row>
                                            <Row>
                                                {/* AuthorID */}
                                                <div className="form-group col-md-6">
                                                    <Form.Label>
                                                        Author
                                                    </Form.Label>
                                                    <Select
                                                        closeMenuOnSelect={true}
                                                        onChange={val =>
                                                            handleinputchange(
                                                                'authorid',
                                                                val,
                                                                i,
                                                            )
                                                        }
                                                        isSearchable
                                                        options={
                                                            mergeSpeakerAndInternalHost
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a
                                                        AuthorID.
                                                    </Form.Control.Feedback>
                                                </div>
                                                {/* URL */}
                                                <div className="form-group col-md-6">
                                                    <Form.Label>URL</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="url"
                                                        className="form-control"
                                                        onChange={e =>
                                                            handleinputchange(
                                                                'url',
                                                                e,
                                                                i,
                                                            )
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a URL.
                                                    </Form.Control.Feedback>
                                                </div>

                                                {/* Buttons */}
                                                <div className="form-group ">
                                                    <Col>
                                                        {inputList.length !==
                                                            1 && (
                                                            <Button
                                                                className="btn btn-danger mx-2 mb-2"
                                                                onClick={() =>
                                                                    handleremove(
                                                                        i,
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </Button>
                                                        )}
                                                        {inputList.length -
                                                            1 ===
                                                            i && (
                                                            <Button
                                                                style={{
                                                                    width: 'auto',
                                                                }}
                                                                className="btn btn-success mx-2 mb-2"
                                                                onClick={
                                                                    handleaddclick
                                                                }
                                                            >
                                                                Add More
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </div>
                                            </Row>
                                        </Row>
                                    );
                                })}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Add Files
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
}

export default AddFiles;
