/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

interface ClubContextProps {
    //Modal State
    addClubModal?: boolean;
    setAddClubModal?: (addClubModal: boolean) => void;
}

export const ClubContext = React.createContext<ClubContextProps>({
    //Modal State
    addClubModal: false,
    setAddClubModal: () => {},
});

export const ClubContextProvider = (props: any) => {
    const [addClubModal, setAddClubModal] = useState<boolean>();
    return (
        <ClubContext.Provider
            value={{
                addClubModal,
                setAddClubModal,
            }}
        >
            {props.children}
        </ClubContext.Provider>
    );
};
