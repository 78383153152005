/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Modal, Form, Container, Col } from 'react-bootstrap';

// Components imports
import { useFeedContext } from '../../../../Context/index.tsx';
import FeedInput from './FeedInput/FeedInput.js';

const StepOne = () => {
    const {
        showModal,
        setShowModal,
        feedType,
        step,
        setStep,
        feedData,
        setFeedData,
    } = useFeedContext();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState(true);
    const [validated, setValidated] = useState(false);

    const finalStepHandler = () => {
        setStep(3);
    };

    const handleSubmit = async (event, stepAssign) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        if (feedData) setError(false);

        if (feedType == 'news') {
            if (
                feedData?.title &&
                feedData?.summary &&
                feedData?.publishTs &&
                stepAssign === 'nextStep'
            ) {
                nextStep();
            }
            if (
                feedData?.title &&
                feedData?.summary &&
                feedData?.publishTs &&
                stepAssign === 'finalStep'
            ) {
                finalStepHandler();
            }
        } else if (feedType == 'quiz' || feedType == 'ques') {
            if (
                feedData?.title &&
                feedData?.summary &&
                feedData?.publishTs &&
                feedData?.deadline &&
                stepAssign === 'nextStep'
            ) {
                nextStep();
            }
            if (
                feedData?.title &&
                feedData?.summary &&
                feedData?.publishTs &&
                feedData?.deadline &&
                stepAssign === 'finalStep'
            ) {
                finalStepHandler();
            }
        }

        event.preventDefault();
        event.stopPropagation();
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const btnclose = () => {
        setShowModal(false);
        setStep(1);
        setFeedData('');
        // window.location.reload(true);
    };

    return (
        <Container className="news-container">
            <Col>
                <Modal show={showModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title className="mb-0">
                                {`Add ${feedType}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FeedInput isPreview={false} />
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>

                            <Button
                                variant="primary"
                                type="submit"
                                // disabled={toastShow ? false : true}
                                onClick={event =>
                                    handleSubmit(event, 'nextStep')
                                }
                            >
                                Add Poll
                            </Button>

                            {feedType == 'news' && (
                                <Button
                                    variant="primary"
                                    type="button"
                                    disabled={false}
                                    onClick={event =>
                                        handleSubmit(event, 'finalStep')
                                    }
                                    // onClick={finalStepHandler}
                                >
                                    Preview
                                </Button>
                            )}
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
};

export default StepOne;
