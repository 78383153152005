import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    Form,
    Button,
    Dropdown,
    Row,
    Col,
    FormCheck,
} from 'react-bootstrap';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';

import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import { useGlobalContext } from '../../../Context';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';

interface CTA {
    type: string;
    default: {
        text: string;
        showClickCount: boolean;
    };
    success: {
        text: string;
        subtext: string;
    };
    externalRedirectUrl?: string;
}

interface Author {
    imageurl: string;
    title?: string;
    iconurl?: string;
}

interface AdSpot {
    id: string;
    cta: CTA;
    author: Author;
    displayname: string;
    headingText: string;
    overrideSubmit: boolean;
    subHeadingText?: string;
    additionalImages?: string[];
    fromDB?: boolean;
}

interface Video {
    id: string;
    url: string;
    displayname: string;
    durationinsec: number;
    overrideSubmit: boolean;
    skip_after_seconds: number;
    fromDB?: boolean;
}

interface Bottom extends AdSpot {
    headingText: string;
    fullImage: string;
}

interface Comment extends AdSpot {
    images: string[];
}

interface Sidepanel extends AdSpot {
    images: string[];
}

interface AdSpotsData {
    video: Video[];
    bottom: Bottom[];
    comment: Comment[];
    sidepannel: Sidepanel[];
}

const generateUniqueUUID = () => {
    return uuid();
};

const initialFormValues = {
    video: 1,
    bottom: 1,
    comment: 1,
    sidepannel: 1,
};
const initialVideo = [
    {
        id: generateUniqueUUID(),
        url: '',
        displayname: '',
        durationinsec: 0,
        overrideSubmit: true,
        skip_after_seconds: 0,
        fromDB: false,
    },
];

const initialBottom = [
    {
        id: generateUniqueUUID(),
        cta: {
            type: 'default',
            default: {
                text: 'Get in touch',
                showClickCount: true,
            },
            success: {
                text: '👍',
                subtext: 'Thank You',
            },
        },
        author: {
            imageurl: '',
        },
        displayname: '',
        headingText: '',
        overrideSubmit: true,
        subHeadingText: '',
        fromDB: false,
        fullImage: ''
    },
];

const initialComment = [
    {
        id: generateUniqueUUID(),
        cta: {
            type: 'default',
            default: {
                text: 'Get in touch',
                showClickCount: true,
            },
            success: {
                text: 'Thank You',
                subtext: 'A representative will reach out',
            },
            externalRedirectUrl: '',
        },
        author: {
            title: '',
            iconurl:
                'https://plexusmd.gumlet.io/Verifieddoctorbadge_1699420746483.png',
            imageurl: '',
        },
        images: [''],
        displayname: '',
        headingText: '',
        overrideSubmit: true,
        subHeadingText: '',
        fromDB: false,
    },
];

const initalSidepannel = [
    {
        id: generateUniqueUUID(),
        cta: {
            type: 'default',
            default: {
                text: 'Get in touch',
                showClickCount: true,
            },
            success: {
                text: '👍',
                subtext: 'Thank You',
            },
        },
        author: {
            imageurl: '',
        },
        images: [''],
        displayname: '',
        headingText: '',
        overrideSubmit: true,
        fromDB: false,
    },
];

const AddAdSpots: React.FC<{
    show: boolean;
    onHide(): void;
}> = ({ show, onHide }) => {
    const [discussionid, setDiscussionID] = useState<{
        value: string;
        label: string;
    }>();
    const [videos, setVideos] = useState<Video[]>(initialVideo);
    const [bottoms, setBottoms] = useState<Bottom[]>(initialBottom);
    const [comments, setComments] = useState<Comment[]>(initialComment);
    const [sidepannels, setSidePanels] =
        useState<Sidepanel[]>(initalSidepannel);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedType, setSelectedType] = useState<string | null>('');
    const [defaultValues, setDefaultValues] = useState(true);

    const formRef = useRef(null);

    const getSelectDiscussions = true;
    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const fetchData = async (setState: React.SetStateAction<any>) => {
        try {
            const reqBody = {
                selectedType: selectedType,
                discussionid: discussionid?.value,
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADgetAdSpots`,
                reqBody,
                globalURLS.config,
            );

            const initialD = {
                video: initialVideo,
                bottom: initialBottom,
                comment: initialComment,
                sidepannel: initalSidepannel,
            };
            if (resp?.data?.success === true) {
                resp?.data?.data
                    ? setDefaultValues(false)
                    : setDefaultValues(true);
                const finalData = resp?.data?.data || initialD[selectedType];
                setState(
                    finalData.map(data => {
                        return { ...data, fromDB: true,
                            // ['cta']: ['none',null,undefined].includes(data?.cta?.type) ? initialD[selectedType]['cta'] : data.cta
                        };
                    }),
                );
            } else {
                setDefaultValues(true);
                setState(initialD[selectedType]);
                setShowErrorToast(true);
            }
        } catch (error) {
            console.log('--err', error);
        }
    };

    useEffect(() => {
        if (selectedType) {
            switch (selectedType) {
                case 'video':
                    try {
                        fetchData(setVideos);
                    } catch (error) {
                        console.log('--error', error);
                        setShowErrorToast(true);
                    }
                    break;
                case 'bottom':
                    try {
                        fetchData(setBottoms);
                    } catch (error) {
                        console.log('--error', error);
                        setShowErrorToast(true);
                    }
                    break;
                case 'comment':
                    try {
                        fetchData(setComments);
                    } catch (error) {
                        console.log('--error', error);
                        setShowErrorToast(true);
                    }
                    break;
                case 'sidepannel':
                    try {
                        fetchData(setSidePanels);
                    } catch (error) {
                        console.log('--error', error);
                        setShowErrorToast(true);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [selectedType, discussionid]);

    const handleShowDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const reqBody = {
                selectedType: selectedType,
                discussionid: discussionid?.value,
                adSpots: {},
                deleteType: true,
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADaddUpdateAdSpots`,
                reqBody,
                globalURLS.config,
            );

            if (resp?.data?.success === true) {
                resetAllStates();
                setShowSuccessToast(true);
            } else {
                setShowErrorToast(true);
            }
        } catch (error) {
            console.log('-err', error);
        }

        setShowDeleteConfirmation(false);
    };

    const handleAddVideo = (index: number) => {
        const updatedVideos = [...videos];

        const newVideo = {
            id: generateUniqueUUID(),
            url: '',
            displayname: '',
            durationinsec: 0,
            overrideSubmit: true,
            skip_after_seconds: 0,
            fromDB: false,
        };

        updatedVideos.splice(index + 1, 0, newVideo);

        setVideos(updatedVideos);
    };

    const handleRemoveVideo = (index: number) => {
        if (videos.length === 1 && defaultValues === false) {
            setShowDeleteConfirmation(true);
        } else {
            const updatedVideos = [...videos];
            updatedVideos.splice(index, 1);
            setVideos(updatedVideos);
        }
    };

    const handleAddBottom = (index: number) => {
        const updatedBottoms = [...bottoms];
        updatedBottoms.splice(index + 1, 0, {
            id: generateUniqueUUID(),
            cta: {
                type: 'default',
                default: {
                    text: 'Get in touch',
                    showClickCount: true,
                },
                success: {
                    text: '👍',
                    subtext: 'Thank You',
                },
            },
            author: {
                imageurl: '',
            },
            displayname: '',
            headingText: '',
            overrideSubmit: true,
            subHeadingText: '',
            fromDB: false,
            fullImage: ''
        });
        setBottoms(updatedBottoms);
    };

    const handleRemoveBottom = (index: number) => {
        if (bottoms.length === 1 && defaultValues === false) {
            setShowDeleteConfirmation(true);
        } else {
            const updatedBottoms = [...bottoms];
            updatedBottoms.splice(index, 1);
            setBottoms(updatedBottoms);
        }
    };

    const handleAddComment = (index: number) => {
        const updatedComments = [...comments];
        const initalVal = initialComment[0];
        updatedComments.splice(index + 1, 0, {
            id: generateUniqueUUID(),
            cta: {
                type: 'default',
                default: {
                    text: 'Get in touch',
                    showClickCount: true,
                },
                success: {
                    text: 'Thank You',
                    subtext: 'A representative will reach out',
                },
                externalRedirectUrl: '',
            },
            author: {
                title: '',
                iconurl:
                    'https://plexusmd.gumlet.io/Verifieddoctorbadge_1699420746483.png',
                imageurl: '',
            },
            images: [''],
            displayname: '',
            headingText: '',
            overrideSubmit: true,
            subHeadingText: '',
            fromDB: false,
        });
        setComments(updatedComments);
    };

    const handleRemoveComment = (index: number) => {
        if (comments.length === 1 && defaultValues === false) {
            setShowDeleteConfirmation(true);
        } else {
            const updatedComments = [...comments];
            updatedComments.splice(index, 1);
            setComments(updatedComments);
        }
    };

    const handleAddSidepanel = (index: number) => {
        const updatedSidePanels = [...sidepannels];
        updatedSidePanels.splice(index + 1, 0, {
            id: generateUniqueUUID(),
            cta: {
                type: 'default',
                default: {
                    text: 'Get in touch',
                    showClickCount: true,
                },
                success: {
                    text: '👍',
                    subtext: 'Thank You',
                },
            },
            author: {
                imageurl: '',
            },
            images: [''],
            displayname: '',
            headingText: '',
            overrideSubmit: true,
            fromDB: false,
        });
        setSidePanels(updatedSidePanels);
    };

    const handleRemoveSidepanel = (index: number) => {
        if (sidepannels.length === 1 && defaultValues === false) {
            setShowDeleteConfirmation(true);
        } else {
            const updatedSidepanels = [...sidepannels];
            updatedSidepanels.splice(index, 1);
            setSidePanels(updatedSidepanels);
        }
    };

    const handleAddAdditionalImage = (
        index: number,
        forType: 'comment' | 'sidepannel',
    ) => {
        const defaultAdditionalImage = '';

        if (forType === 'comment') {
            setComments(prevComments => {
                const updatedComments = [...prevComments];
                const commentImages = [
                    ...updatedComments[index].images,
                    defaultAdditionalImage,
                ];
                updatedComments[index] = {
                    ...updatedComments[index],
                    images: commentImages,
                };
                return updatedComments;
            });
        }

        if (forType === 'sidepannel') {
            setSidePanels(prevSidePanels => {
                const updatedSidePanels = [...prevSidePanels];
                const sidepanelImages = [
                    ...updatedSidePanels[index].images,
                    defaultAdditionalImage,
                ];
                updatedSidePanels[index] = {
                    ...updatedSidePanels[index],
                    images: sidepanelImages,
                };
                return updatedSidePanels;
            });
        }
    };

    const handleRemoveAdditionalImage = (
        index: number,
        imageIndex: number,
        forType: 'comment' | 'sidepannel',
    ) => {
        if (forType === 'comment') {
            setComments(prevComments => {
                const updatedComments = [...prevComments];
                updatedComments[index] = {
                    ...updatedComments[index],
                    images: [
                        ...updatedComments[index].images.slice(0, imageIndex),
                        ...updatedComments[index].images.slice(imageIndex + 1),
                    ],
                };
                return updatedComments;
            });
        }

        if (forType === 'sidepannel') {
            setSidePanels(prevSidePanels => {
                const updatedSidePanels = [...prevSidePanels];
                updatedSidePanels[index] = {
                    ...updatedSidePanels[index],
                    images: [
                        ...updatedSidePanels[index].images.slice(0, imageIndex),
                        ...updatedSidePanels[index].images.slice(
                            imageIndex + 1,
                        ),
                    ],
                };
                return updatedSidePanels;
            });
        }
    };

    const BGStyle = {
        backgroundColor: '#BFBFBF',
        height: 'auto',
        margin: '4px',
        padding: '10px',
        borderRadius: '10px',
    };

    const renderVideoFields = () => {
        return (
            <>
                {videos &&
                    videos?.map((video, index: number) => (
                        <Row key={video.id} className="mt-3" style={BGStyle}>
                            <Col>
                                {/* Display Name */}
                                <Form.Group
                                    controlId={`video[${index}].displayname`}
                                >
                                    <Form.Label>Display Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Display Name"
                                        required
                                        value={video.displayname}
                                        onChange={e => {
                                            const value = e.target.value;
                                            videos[index].displayname = value;
                                            setVideos([...videos]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Video URL */}
                                <Form.Group controlId={`video[${index}].url`}>
                                    <Form.Label>Video URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter video URL"
                                        value={video?.url}
                                        onChange={e => {
                                            const value = e.target.value;
                                            videos[index].url = value;
                                            setVideos([...videos]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row>
                                    <Col>
                                        {/* Duration (in seconds)*/}
                                        <Form.Group
                                            className="mt-3"
                                            controlId={`video[${index}].durationinsec`}
                                        >
                                            <Form.Label>
                                                Duration (in sec)
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter duration"
                                                required
                                                value={video.durationinsec}
                                                onChange={e => {
                                                    const value =
                                                        e.target.value;
                                                    videos[
                                                        index
                                                    ].durationinsec =
                                                        Number(value);
                                                    setVideos([...videos]);
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {/* Skip AFter (in seconds)*/}
                                    <Col>
                                        <Form.Group
                                            className="mt-3"
                                            controlId={`video[${index}].skip_after_seconds`}
                                        >
                                            <Form.Label>
                                                Skip AFter (in sec)
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter duration"
                                                required
                                                value={video.skip_after_seconds}
                                                onChange={e => {
                                                    const value =
                                                        e.target.value;
                                                    videos[
                                                        index
                                                    ].skip_after_seconds =
                                                        Number(value);
                                                    setVideos([...videos]);
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* Override Submit */}
                                <Col>
                                    <Form.Group
                                        className="mt-3"
                                        controlId={`video[${index}].overrideSubmit`}
                                    >
                                        <FormCheck
                                            type="switch"
                                            label="Override Submit"
                                            checked={video.overrideSubmit}
                                            onChange={e => {
                                                const value = e.target.value;
                                                videos[index].overrideSubmit =
                                                    Boolean(
                                                        video.overrideSubmit
                                                            ? false
                                                            : true,
                                                    );
                                                setVideos([...videos]);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <>
                                    <Form.Group>
                                        <Button
                                            variant="danger"
                                            className="m-2"
                                            disabled={
                                                index === 0 &&
                                                videos.length === 1 &&
                                                defaultValues
                                                    ? true
                                                    : false
                                            }
                                            onClick={() =>
                                                handleRemoveVideo(index)
                                            }
                                        >
                                            Remove Video
                                        </Button>

                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                handleAddVideo(index)
                                            }
                                        >
                                            Add Video
                                        </Button>
                                    </Form.Group>
                                </>
                                {/* // )} */}
                            </Col>
                        </Row>
                    ))}
            </>
        );
    };

    const renderBottomFields = () => {
        return (
            <>
                {bottoms.map((bottom: Bottom, index: number) => (
                    <Row key={bottom.id} className="mt-3" style={BGStyle}>
                        <Col>
                            {/* Display Name */}
                            <Form.Group
                                controlId={`bottom[${index}].displayname`}
                            >
                                <Form.Label>Display Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Display Name"
                                    // required
                                    value={bottom?.displayname}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].displayname = value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Heading Text */}
                            <Form.Group
                                controlId={`bottom[${index}].headingText`}
                            >
                                <Form.Label>Heading Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Heading Text"
                                    // required
                                    value={bottom?.headingText}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].headingText = value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Subheading Text */}
                            <Form.Group
                                controlId={`bottom[${index}].subHeadingText`}
                            >
                                <Form.Label>Subheading Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Subheading Text"
                                    value={bottom?.subHeadingText}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].subHeadingText = value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                            </Form.Group>

                            {/* CTA Type */}
                            <Form.Group controlId={`bottom[${index}].cta.type`}>
                                <Form.Label>CTA Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    defaultValue={'default'}
                                    value={bottom?.cta?.type ?? 'none'}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].cta.type = value;
                                        setBottoms([...bottoms]);
                                    }}
                                >
                                    <option value="default">Default</option>
                                    <option value="externalRedirect">
                                        External Redirect
                                    </option>
                                    <option value="none">None</option>
                                </Form.Control>
                            </Form.Group>
                            {/* External Redirect URL */}
                            {bottom?.cta?.type === 'externalRedirect' && (
                                <Form.Group
                                    controlId={`bottom[${index}].cta.externalRedirectUrl`}
                                >
                                    <Form.Label>
                                        External Redirect URL
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter External Redirect URL"
                                        required
                                        value={bottom?.cta?.externalRedirectUrl}
                                        onChange={e => {
                                            const value = e.target.value;
                                            bottoms[
                                                index
                                            ].cta.externalRedirectUrl = value;
                                            setBottoms([...bottoms]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}

                            {/* CTA Text */}
                            {!['none',undefined,null].includes(bottom?.cta?.type) && (<Form.Group
                                controlId={`bottom[${index}].cta.default.text`}
                            >
                                <Form.Label>CTA Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CTA Text"
                                    required
                                    value={bottom?.cta?.default?.text}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].cta.default.text = value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}

                            {/* CTA Success Text */}
                            {!['none',undefined,null].includes(bottom?.cta?.type) && (<Form.Group
                                controlId={`bottom[${index}].cta.success.text`}
                            >
                                <Form.Label>CTA Success Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Success Text"
                                    required
                                    value={bottom?.cta?.success?.text}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].cta.success.text = value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}
                            {/* CTA Success Subtext */}
                           {!['none',undefined,null].includes(bottom?.cta?.type) && (<Form.Group
                                controlId={`bottom[${index}].cta.success.subtext`}
                            >
                                <Form.Label>CTA Success Subtext</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Success Subtext"
                                    value={bottom?.cta?.success?.subtext}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].cta.success.subtext =
                                            value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                            </Form.Group>)}
                            {/* Author Image URL */}
                            <Form.Group
                                controlId={`bottom[${index}].author.imageurl`}
                            >
                                <Form.Label>Author Image URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Image URL"
                                    required={false}
                                    value={bottom?.author?.imageurl}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].author.imageurl = value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                                {bottom?.author?.imageurl && (
                                    <Col xs={12}>
                                        <img
                                            src={bottom?.author?.imageurl}
                                            alt={`Image ${bottom?.author?.imageurl}`}
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    </Col>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Full Image URL */}
                            <Form.Group
                                controlId={`bottom[${index}].fullImage`}
                            >
                                <Form.Label>Full Image URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Image URL"
                                    required={false}
                                    value={bottom?.fullImage}
                                    onChange={e => {
                                        const value = e.target.value;
                                        bottoms[index].fullImage = value;
                                        setBottoms([...bottoms]);
                                    }}
                                />
                                {!!bottom?.fullImage && (
                                    <Col xs={12}>
                                        <img
                                            src={bottom?.fullImage}
                                            alt={`Image ${bottom?.fullImage}`}
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    </Col>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Row className="m-2">
                                {!['none',undefined,null].includes(bottom?.cta?.type) && (<Col>
                                    {/* CTA Show Click Count */}
                                    <Form.Group
                                        controlId={`bottom[${index}].cta.default.showClickCount`}
                                    >
                                        <Form.Check
                                            type="switch"
                                            label="Show Click Count"
                                            checked={
                                                bottom.cta.default
                                                    .showClickCount
                                            }
                                            onChange={e => {
                                                const value = e.target.value;
                                                bottoms[
                                                    index
                                                ].cta.default.showClickCount =
                                                    Boolean(
                                                        bottom.cta.default
                                                            .showClickCount
                                                            ? false
                                                            : true,
                                                    );
                                                setBottoms([...bottoms]);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>)}

                                <Col>
                                    {/* Override Submit */}
                                    <Form.Group
                                        controlId={`bottom[${index}].overrideSubmit`}
                                    >
                                        <Form.Check
                                            type="switch"
                                            label="Override Submit"
                                            checked={bottom.overrideSubmit}
                                            onChange={e => {
                                                const value = e.target.value;
                                                bottoms[index].overrideSubmit =
                                                    Boolean(
                                                        bottom.overrideSubmit
                                                            ? false
                                                            : true,
                                                    );
                                                setBottoms([...bottoms]);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Remove Bottom Button */}
                            <Form.Group>
                                <Button
                                    variant="danger"
                                    className="m-2"
                                    disabled={
                                        index === 0 &&
                                        bottoms.length === 1 &&
                                        defaultValues
                                            ? true
                                            : false
                                    }
                                    onClick={() => handleRemoveBottom(index)}
                                >
                                    Remove Bottom
                                </Button>

                                <Button
                                    variant="primary"
                                    onClick={() => handleAddBottom(index)}
                                >
                                    Add Bottom
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                ))}
            </>
        );
    };

    const renderCommentFields = () => {
        return (
            <>
                {comments.map((comment: Comment, index: number) => (
                    <Row key={comment.id} className="mt-3" style={BGStyle}>
                        <Col>
                            {/* Display Name */}
                            <Form.Group
                                controlId={`comments[${index}].displayname`}
                            >
                                <Form.Label>Display Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Display Name"
                                    // required
                                    value={comment?.displayname}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].displayname = value;
                                        setComments([...comments]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Heading Text */}
                            <Form.Group
                                controlId={`comments[${index}].headingText`}
                            >
                                <Form.Label>Heading Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Heading Text"
                                    // required
                                    value={comment?.headingText}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].headingText = value;
                                        setComments([...comments]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Subheading Text */}
                            <Form.Group
                                controlId={`comments[${index}].subHeadingText`}
                            >
                                <Form.Label>Subheading Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Subheading Text"
                                    required
                                    value={comment?.subHeadingText}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].subHeadingText = value;
                                        setComments([...comments]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required{' '}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* CTA Type */}
                            <Form.Group
                                controlId={`comments[${index}].cta.type`}
                            >
                                <Form.Label>CTA Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    defaultValue={'default'}
                                    value={comment?.cta?.type ?? 'none'}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].cta.type = value;
                                        setComments([...comments]);
                                    }}
                                >
                                    <option value="default">Default</option>
                                    <option value="externalRedirect">
                                        External Redirect
                                    </option>
                                    <option value="none">None</option>
                                </Form.Control>
                            </Form.Group>

                            {/* External Redirect URL */}
                            {comment?.cta?.type === 'externalRedirect' && (
                                <Form.Group
                                    controlId={`comment[${index}].cta.externalRedirectUrl`}
                                >
                                    <Form.Label>
                                        External Redirect URL
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter External Redirect URL"
                                        required
                                        value={
                                            comment?.cta?.externalRedirectUrl
                                        }
                                        onChange={e => {
                                            const value = e.target.value;
                                            comments[
                                                index
                                            ].cta.externalRedirectUrl = value;
                                            setComments([...comments]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                            {/* CTA Text */}
                            {!['none',undefined,null].includes(comment?.cta?.type) && (<Form.Group
                                controlId={`comments[${index}].cta.default.text`}
                            >
                                <Form.Label>CTA Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CTA Text"
                                    required
                                    value={comment?.cta?.default.text}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].cta.default.text =
                                            value;
                                        setComments([...comments]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}

                            {/* CTA Success Text */}
                            {!['none',undefined,null].includes(comment?.cta?.type) && (<Form.Group
                                controlId={`comments[${index}].cta.success.text`}
                            >
                                <Form.Label>CTA Success Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CTA Success Text"
                                    required
                                    value={comment?.cta.success.text}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].cta.success.text =
                                            value;
                                        setComments([...comments]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}

                            {/* CTA Success Subtext */}
                            {!['none',undefined,null].includes(comment?.cta?.type) && (<Form.Group
                                controlId={`comments[${index}].cta.success.subtext`}
                            >
                                <Form.Label>CTA Success Subtext</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CTA Success Subtext"
                                    required
                                    value={comment?.cta.success.subtext}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].cta.success.subtext =
                                            value;
                                        setComments([...comments]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}
                            {/* Author Image URL */}
                            <Form.Group
                                controlId={`comments[${index}].author.imageurl`}
                            >
                                <Form.Label>Author Image URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Image URL"
                                    required
                                    value={comment?.author.imageurl}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].author.imageurl = value;
                                        setComments([...comments]);
                                    }}
                                />
                                {comment?.author?.imageurl && (
                                    <Col xs={12}>
                                        <img
                                            src={comment?.author?.imageurl}
                                            alt={`Image ${comment?.author?.imageurl}`}
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    </Col>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Author Title */}
                            <Form.Group
                                controlId={`comments[${index}].author.title`}
                            >
                                <Form.Label>Author Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Title"
                                    required
                                    value={comment?.author.title}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].author.title = value;
                                        setComments([...comments]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Author Icon URL */}
                            <Form.Group
                                controlId={`comments[${index}].author.iconurl`}
                            >
                                <Form.Label>Author Icon URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Icon URL"
                                    required
                                    value={comment?.author.iconurl}
                                    onChange={e => {
                                        const value = e.target.value;
                                        comments[index].author.iconurl = value;
                                        setComments([...comments]);
                                    }}
                                />
                                {comment?.author?.iconurl && (
                                    <Col xs={12}>
                                        <img
                                            src={comment?.author?.iconurl}
                                            alt={`Image ${comment?.author?.iconurl}`}
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    </Col>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Images */}
                            <Form.Group controlId={`comments[${index}].images`}>
                                <Form.Label>Images</Form.Label>
                                {comments[index]?.images.map(
                                    (image: string, imageIndex: number) => (
                                        <div key={imageIndex}>
                                            <Row className="m-2">
                                                <Col xs={8}>
                                                    <Form.Group>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Image URL"
                                                            value={
                                                                comment?.images[
                                                                    imageIndex
                                                                ]
                                                            }
                                                            onChange={e => {
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                comments[
                                                                    index
                                                                ].images[
                                                                    imageIndex
                                                                ] = value;
                                                                setComments([
                                                                    ...comments,
                                                                ]);
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                {imageIndex ===
                                                    comment?.images?.length -
                                                        1 && (
                                                    <>
                                                        <Col xs={2}>
                                                            <Button
                                                                variant="danger"
                                                                disabled={
                                                                    imageIndex ===
                                                                        0 &&
                                                                    comment
                                                                        ?.images
                                                                        .length ===
                                                                        1
                                                                        ? true
                                                                        : false
                                                                }
                                                                // className="ml-2"
                                                                onClick={() =>
                                                                    handleRemoveAdditionalImage(
                                                                        index,
                                                                        imageIndex,
                                                                        'comment',
                                                                    )
                                                                }
                                                            >
                                                                -
                                                            </Button>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <Button
                                                                variant="primary"
                                                                onClick={() =>
                                                                    handleAddAdditionalImage(
                                                                        index,
                                                                        'comment',
                                                                    )
                                                                }
                                                            >
                                                                +
                                                            </Button>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                            {comment?.images[imageIndex] && (
                                                <Row className="m-2">
                                                    <Col xs={12}>
                                                        <img
                                                            src={
                                                                comment?.images[
                                                                    imageIndex
                                                                ]
                                                            }
                                                            alt={`Image ${
                                                                imageIndex + 1
                                                            }`}
                                                            style={{
                                                                maxWidth:
                                                                    '100%',
                                                                height: 'auto',
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    ),
                                )}
                            </Form.Group>

                            <Row className="m-2">
                                {!['none',undefined,null].includes(comment?.cta?.type) && (<Col>
                                    {/* CTA Show Click Count */}
                                    <Form.Group
                                        controlId={`comments[${index}].cta.default.showClickCount`}
                                    >
                                        <Form.Check
                                            type="switch"
                                            label="Show Click Count"
                                            checked={
                                                comment.cta.default
                                                    .showClickCount
                                            }
                                            onChange={e => {
                                                comments[
                                                    index
                                                ].cta.default.showClickCount =
                                                    Boolean(
                                                        comment.cta.default
                                                            .showClickCount
                                                            ? false
                                                            : true,
                                                    );
                                                setComments([...comments]);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
)}
                                <Col>
                                    {/* Override Submit */}
                                    <Form.Group
                                        controlId={`comments[${index}].overrideSubmit`}
                                    >
                                        <Form.Check
                                            type="switch"
                                            label="Override Submit"
                                            checked={comment.overrideSubmit}
                                            onChange={e => {
                                                comments[index].overrideSubmit =
                                                    Boolean(
                                                        comment.overrideSubmit
                                                            ? false
                                                            : true,
                                                    );
                                                setComments([...comments]);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Remove Bottom Button */}
                            <Form.Group>
                                <Button
                                    variant="danger"
                                    className="m-2"
                                    disabled={
                                        index === 0 &&
                                        comments.length === 1 &&
                                        defaultValues
                                            ? true
                                            : false
                                    }
                                    onClick={() => handleRemoveComment(index)}
                                >
                                    Remove Comment
                                </Button>

                                <Button
                                    variant="primary"
                                    onClick={() => handleAddComment(index)}
                                >
                                    Add Comment
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                ))}
            </>
        );
    };

    const renderSidepanelFields = () => {
        return (
            <>
                {sidepannels.map((sidepannel: Sidepanel, index: number) => (
                    <Row key={sidepannel.id} className="mt-3" style={BGStyle}>
                        <Col>
                            {/* Display Name */}
                            <Form.Group
                                controlId={`sidepannels[${index}].displayname`}
                            >
                                <Form.Label>Display Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Display Name"
                                    // required
                                    value={sidepannel?.displayname}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].displayname = value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Heading Text */}
                            <Form.Group
                                controlId={`sidepannels[${index}].headingText`}
                            >
                                <Form.Label>Heading Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Heading Text"
                                    // required
                                    value={sidepannel?.headingText}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].headingText = value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Subheading Text */}
                            {/* <Form.Group
                                controlId={`sidepannels[${index}].subHeadingText`}
                            >
                                <Form.Label>Subheading Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Subheading Text"
                                    required
                                    value={sidepannel?.subHeadingText}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].subHeadingText =
                                            value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required{' '}
                                </Form.Control.Feedback>
                            </Form.Group> */}

                            {/* CTA Type */}
                            <Form.Group
                                controlId={`sidepannels[${index}].cta.type`}
                            >
                                <Form.Label>CTA Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    defaultValue={'default'}
                                    value={sidepannel?.cta?.type ?? 'none'}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].cta.type = value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                >
                                    <option value="default">Default</option>
                                    <option value="externalRedirect">
                                        External Redirect
                                    </option>
                                    <option value="none">None</option>
                                </Form.Control>
                            </Form.Group>

                            {/* External Redirect URL */}
                            {sidepannel?.cta?.type === 'externalRedirect' && (
                                <Form.Group
                                    controlId={`sidepannel[${index}].cta.externalRedirectUrl`}
                                >
                                    <Form.Label>
                                        External Redirect URL
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter External Redirect URL"
                                        required
                                        value={
                                            sidepannel?.cta?.externalRedirectUrl
                                        }
                                        onChange={e => {
                                            const value = e.target.value;
                                            sidepannels[
                                                index
                                            ].cta.externalRedirectUrl = value;
                                            setSidePanels([...sidepannels]);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}

                            {/* CTA Text */}
                            {!['none',undefined,null].includes(sidepannel?.cta?.type) && (<Form.Group
                                controlId={`sidepannels[${index}].cta.default.text`}
                            >
                                <Form.Label>CTA Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CTA Text"
                                    required
                                    value={sidepannel?.cta?.default.text}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].cta.default.text =
                                            value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}

                            {/* CTA Success Text */}
                            {!['none',undefined,null].includes(sidepannel?.cta?.type) && (<Form.Group
                                controlId={`sidepannels[${index}].cta.success.text`}
                            >
                                <Form.Label>CTA Success Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CTA Success Text"
                                    required
                                    value={sidepannel?.cta.success.text}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].cta.success.text =
                                            value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}

                            {/* CTA Success Subtext */}
                            {!['none',undefined,null].includes(sidepannel?.cta?.type) && (<Form.Group
                                controlId={`sidepannels[${index}].cta.success.subtext`}
                            >
                                <Form.Label>CTA Success Subtext</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CTA Success Subtext"
                                    required
                                    value={sidepannel?.cta.success.subtext}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].cta.success.subtext =
                                            value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>)}
                            {/* Author Image URL */}
                            <Form.Group
                                controlId={`sidepannels[${index}].author.imageurl`}
                            >
                                <Form.Label>Author Image URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Image URL"
                                    required
                                    value={sidepannel?.author.imageurl}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].author.imageurl =
                                            value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                {sidepannel?.author?.imageurl && (
                                    <Col xs={12}>
                                        <img
                                            src={sidepannel?.author?.imageurl}
                                            alt={`Image ${sidepannel?.author?.imageurl}`}
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    </Col>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Author Title */}
                            {/* <Form.Group
                                controlId={`sidepannels[${index}].author.title`}
                            >
                                <Form.Label>Author Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Title"
                                    required
                                    value={sidepannel?.author.title}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].author.title = value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group> */}
                            {/* Author Icon URL */}
                            {/* <Form.Group
                                controlId={`sidepannels[${index}].author.iconurl`}
                            >
                                <Form.Label>Author Icon URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Author Icon URL"
                                    required
                                    value={sidepannel?.author.iconurl}
                                    onChange={e => {
                                        const value = e.target.value;
                                        sidepannels[index].author.iconurl =
                                            value;
                                        setSidePanels([...sidepannels]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group> */}
                            {/* Images */}
                            <Form.Group
                                controlId={`sidepannels[${index}].images`}
                            >
                                <Form.Label>Images</Form.Label>
                                {sidepannels[index]?.images.map(
                                    (image: string, imageIndex: number) => (
                                        <div key={imageIndex}>
                                            <Row className="m-2">
                                                <Col xs={8}>
                                                    <Form.Group>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Image URL"
                                                            value={
                                                                sidepannel
                                                                    ?.images[
                                                                    imageIndex
                                                                ]
                                                            }
                                                            onChange={e => {
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                sidepannels[
                                                                    index
                                                                ].images[
                                                                    imageIndex
                                                                ] = value;
                                                                setSidePanels([
                                                                    ...sidepannels,
                                                                ]);
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={2}>
                                                    <Button
                                                        variant="danger"
                                                        // className="ml-2"
                                                        disabled={
                                                            imageIndex === 0 &&
                                                            sidepannel?.images
                                                                .length === 1
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() =>
                                                            handleRemoveAdditionalImage(
                                                                index,
                                                                imageIndex,
                                                                'sidepannel',
                                                            )
                                                        }
                                                    >
                                                        -
                                                    </Button>
                                                </Col>
                                                <Col xs={2}>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() =>
                                                            handleAddAdditionalImage(
                                                                index,
                                                                'sidepannel',
                                                            )
                                                        }
                                                    >
                                                        +
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {sidepannel?.images[imageIndex] && (
                                                <Row className="m-2">
                                                    <Col xs={12}>
                                                        <img
                                                            src={
                                                                sidepannel
                                                                    ?.images[
                                                                    imageIndex
                                                                ]
                                                            }
                                                            alt={`Image ${
                                                                imageIndex + 1
                                                            }`}
                                                            style={{
                                                                maxWidth:
                                                                    '100%',
                                                                height: 'auto',
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    ),
                                )}
                            </Form.Group>

                            <Row className="m-2">
                                {!['none',undefined,null].includes(sidepannel?.cta?.type) && (<Col>
                                    {/* CTA Show Click Count */}
                                    <Form.Group
                                        controlId={`sidepannels[${index}].cta.default.showClickCount`}
                                    >
                                        <Form.Check
                                            type="switch"
                                            label="Show Click Count"
                                            checked={
                                                sidepannel.cta.default
                                                    .showClickCount
                                            }
                                            onChange={e => {
                                                sidepannels[
                                                    index
                                                ].cta.default.showClickCount =
                                                    Boolean(
                                                        sidepannel.cta.default
                                                            .showClickCount
                                                            ? false
                                                            : true,
                                                    );
                                                setSidePanels([...sidepannels]);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>)}

                                <Col>
                                    {/* Override Submit */}
                                    <Form.Group
                                        controlId={`sidepannels[${index}].overrideSubmit`}
                                    >
                                        <Form.Check
                                            type="switch"
                                            label="Override Submit"
                                            checked={sidepannel.overrideSubmit}
                                            onChange={e => {
                                                sidepannels[
                                                    index
                                                ].overrideSubmit = Boolean(
                                                    sidepannel.overrideSubmit
                                                        ? false
                                                        : true,
                                                );
                                                setSidePanels([...sidepannels]);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Remove Sidepannel Button */}
                            <Form.Group>
                                <Button
                                    variant="danger"
                                    className="m-2"
                                    disabled={
                                        index === 0 &&
                                        sidepannels.length === 1 &&
                                        defaultValues
                                            ? true
                                            : false
                                    }
                                    onClick={() => handleRemoveSidepanel(index)}
                                >
                                    Remove Sidepanel
                                </Button>

                                <Button
                                    variant="primary"
                                    onClick={() => handleAddSidepanel(index)}
                                >
                                    Add Sidepanel
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                ))}
            </>
        );
    };

    const confirmDelete = () => {
        return (
            <Modal
                show={showDeleteConfirmation}
                onHide={() => setShowDeleteConfirmation(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the selected type? This
                    action will delete everything regarding this type and cannot
                    be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteConfirmation(false)}
                    >
                        No
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const renderType = () => {
        return (
            <Form.Group controlId="type">
                <Row>
                    <Col>
                        <Form.Label>Select Type</Form.Label>
                        <Dropdown
                            onSelect={(type: string | null) =>
                                setSelectedType(type)
                            }
                        >
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                                {selectedType || 'Select Type'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {Object.keys(initialFormValues).map(type => (
                                    <Dropdown.Item key={type} eventKey={type}>
                                        {type}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    {discussionid?.value && selectedType && !defaultValues && (
                        <Col>
                            <Button
                                variant="danger"
                                className="mt-4"
                                onClick={() => handleShowDeleteConfirmation()}
                            >
                                Delete Type
                            </Button>
                        </Col>
                    )}
                </Row>
            </Form.Group>
        );
    };

    const renderFormFields = () => {
        switch (selectedType) {
            case 'video':
                return renderVideoFields();
            case 'bottom':
                return renderBottomFields();
            case 'comment':
                return renderCommentFields();
            case 'sidepannel':
                return renderSidepanelFields();
            default:
                return null;
        }
    };

    const handleSubmit = async () => {
        try {
            setValidated(true);

            if (!formRef.current.checkValidity()) {
                // Handle invalid form (you can display an error message, etc.)
                return;
            }

            const states = {
                video: videos,
                bottom: bottoms,
                comment: comments,
                sidepannel: sidepannels,
            };

            const adSpots: Video[] | Bottom[] | Comment[] | Sidepanel[] =
                states[selectedType];

            setIsLoading(true);

            const reqBody = {
                selectedType: selectedType,
                discussionid: discussionid?.value,
                adSpots: adSpots.map(adSpot => {
                    return {
                        ...adSpot,
                        id: generateUniqueUUID(),
                    };
                }),
            };

            const resp = await axios.post(
                `${globalURLS.baseURL}ADaddUpdateAdSpots`,
                reqBody,
                globalURLS.config,
            );

            setIsLoading(false);

            if (resp?.data?.success === true) {
                setShowSuccessToast(true);
                resetAllStates();
                onHide();
            } else {
                setShowErrorToast(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log('--error', error);
            setShowErrorToast(true);
        }
    };

    const resetAllStates = () => {
        setDiscussionID({ value: '', label: '' });
        setSelectedType('');
        setIsLoading(false);
        setVideos(initialVideo);
        setBottoms(initialBottom);
        setSidePanels(initalSidepannel);
        setComments(initialComment);
    };

    const handleHide = async () => {
        resetAllStates();
        onHide();
    };

    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Row>
                    <Modal.Title>Create/Update/Delete Ad-Spots</Modal.Title>

                    <Modal.Title style={{ fontSize: '0.8em' }}>
                        {defaultValues
                            ? 'Note: These are Default Values, Please do fill these'
                            : 'Note: These are Prefilled Values from the Actual Data'}
                    </Modal.Title>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Form
                    ref={formRef}
                    noValidate
                    validated={validated}
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Form.Group controlId="formSpecialties">
                        <Form.Label>Select Discussion</Form.Label>
                        <Select
                            name="discussionid"
                            options={selectDiscussions}
                            isMulti={false}
                            isSearchable
                            value={discussionid}
                            onChange={(e: any) => setDiscussionID(e)}
                        />
                    </Form.Group>
                    {discussionid && renderType()}
                    {discussionid && renderFormFields()}
                    {confirmDelete()}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    Close
                </Button>
                <Button
                    variant={isLoading ? 'warning' : 'success'}
                    type="submit"
                    onClick={handleSubmit}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddAdSpots;
