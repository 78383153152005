import React, { useState } from 'react';

export interface PostInfo {
    postid: string;
    title: string;
    description: string;
    createdon: Date;
    deleted: boolean;
    lastmodifiedon: Date;
    previewdata: Previewdata;
    specialties: Specialty[];
    genres: Genre[];
    partners: Partner[];
    imagepath: string;
    images: Image[];
    files: null;
    publisher: string;
    source: string;
    authorname: null;
    authordescription: null;
    newspublishdate: Date;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    partnerSpecialty: any[];
}

export interface Genre {
    genreId: number;
    genreName: string;
    genreType: string;
}

export interface Image {
    fullImageUrl: string;
    thumbnailUrl: string;
}

export interface Partner {
    isbn: number;
    name: string;
    partnerid: number;
    importance: number;
}

export interface Previewdata {
    title: string;
    description: string;
    image: string;
    url: string;
}

export interface Specialty {
    specialtyId: number;
    specialtyName: string;
}

interface PmdContextProps {
    //Modal State
    addStoryModal: boolean;
    setAddStoryModal: (addStoryModal: boolean) => void;
    updateStoryModal: boolean;
    setUpdateStoryModal: (updateStoryModal: boolean) => void;
    updateStoryId: string;
    setUpdateStoryId: (updateStoryId: string) => void;
    allStories?: PostInfo[];
    setAllStories?: (allStories: PostInfo[]) => void;
    updateStory?: PostInfo | undefined;
    setUpdateStory?: (updateStory: PostInfo) => void;
    isVerified: boolean;
    setIsVerified: (isVerified: boolean) => void;
}

export const PmdContext = React.createContext<PmdContextProps>({
    //Modal State
    addStoryModal: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAddStoryModal: () => {},
    updateStoryModal: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUpdateStoryModal: () => {},
    updateStoryId: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUpdateStoryId: () => {},
    allStories: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAllStories: () => {},
    updateStory: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUpdateStory: () => {},
    isVerified: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsVerified: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PmdContextProvider = (props: any) => {
    const [addStoryModal, setAddStoryModal] = useState(false);
    const [updateStoryModal, setUpdateStoryModal] = useState(false);
    const [updateStoryId, setUpdateStoryId] = useState('');
    const [allStories, setAllStories] = useState([]);
    const [updateStory, setUpdateStory] = useState(undefined);
    const [isVerified, setIsVerified] = useState(false);

    return (
        <PmdContext.Provider
            value={{
                addStoryModal,
                setAddStoryModal,
                updateStoryModal,
                setUpdateStoryModal,
                updateStoryId,
                setUpdateStoryId,
                allStories,
                setAllStories,
                updateStory,
                setUpdateStory,
                isVerified,
                setIsVerified,
            }}
        >
            {props.children}
        </PmdContext.Provider>
    );
};
