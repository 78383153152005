import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
    Modal,
    Button,
    Form,
    InputGroup,
    FormControl,
    DropdownButton,
    Col,
    Row,
} from 'react-bootstrap';
import { useGlobalContext } from '../../../Context';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import CustomSelect from '../Inputs/CustomSelect';
import useUserDropDown from '../../../hooks/useUserDropDown';
import Select from 'react-select';

type CountryCode = {
    code: string;
    name: string;
};

interface MessengerProps {
    sendAction?: (to: string, content: string) => void;
}

const Messenger: React.FC<MessengerProps> = () => {
    const [countryCode, setCountryCode] = useState<string>('91');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [messageContent, setMessageContent] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const countryCodes: CountryCode[] = [
        { code: '61', name: 'Australia' },
        { code: '43', name: 'Austria' },
        { code: '32', name: 'Belgium' },
        { code: '55', name: 'Brazil' },
        { code: '1', name: 'Canada' },
        { code: '86', name: 'China' },
        { code: '420', name: 'Czech Republic' },
        { code: '20', name: 'Egypt' },
        { code: '33', name: 'France' },
        { code: '49', name: 'Germany' },
        { code: '30', name: 'Greece' },
        { code: '36', name: 'Hungary' },
        { code: '91', name: 'India' },
        { code: '62', name: 'Indonesia' },
        { code: '98', name: 'Iran' },
        { code: '972', name: 'Israel' },
        { code: '39', name: 'Italy' },
        { code: '81', name: 'Japan' },
        { code: '60', name: 'Malaysia' },
        { code: '52', name: 'Mexico' },
        { code: '31', name: 'Netherlands' },
        { code: '64', name: 'New Zealand' },
        { code: '47', name: 'Norway' },
        { code: '63', name: 'Philippines' },
        { code: '48', name: 'Poland' },
        { code: '351', name: 'Portugal' },
        { code: '7', name: 'Russia' },
        { code: '27', name: 'South Africa' },
        { code: '82', name: 'South Korea' },
        { code: '34', name: 'Spain' },
        { code: '94', name: 'Sri Lanka' },
        { code: '46', name: 'Sweden' },
        { code: '41', name: 'Switzerland' },
        { code: '66', name: 'Thailand' },
        { code: '90', name: 'Turkey' },
        { code: '380', name: 'Ukraine' },
        { code: '44', name: 'United Kingdom' },
        { code: '1', name: 'United States' },
        { code: '58', name: 'Venezuela' },
        { code: '84', name: 'Vietnam' },
    ];

    const getSelectCities = false;
    const getUserDropDown = true;
    const getSelectCountries = true;
    const { selectCountries } = useUserDropDown(
        getSelectCities,
        getUserDropDown,
        getSelectCountries,
    );

    const {
        setShowSuccessToast,
        setShowErrorToast,
        showMessengerModal,
        setShowMessengerModal,
    } = useGlobalContext();

    const clearnData = () => {
        setCountryCode('91');
        setPhoneNumber('');
        setMessageContent('');
    };
    const handleInputChange = (
        event: ChangeEvent<HTMLInputElement>,
        setter: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        setter(event.target.value);
    };

    const handleDropdownSelect = (eventKey: string | null) => {
        if (eventKey) {
            setCountryCode(eventKey);
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const sendTo = `${countryCode}${phoneNumber}`;

        // console.log('sendTo, messageContent', sendTo, messageContent);
        const handleFunc = async () => {
            setIsLoading(true);
            try {
                const resp = await axios.post(
                    `${globalURLS.baseURL}sendSinchSingleWAMessage`,
                    { sendTo: sendTo, messageContent: messageContent },
                    globalURLS.config,
                );

                // console.log('resp', resp);

                if (resp?.data?.success === true) {
                    setShowSuccessToast(true);
                    setIsLoading(false);
                } else {
                    setShowErrorToast(true);
                    setIsLoading(false);
                }
            } catch (error) {
                setShowErrorToast(true);
                console.log('error', error);
            }
        };

        handleFunc();
    };

    return (
        <>
            <Modal
                show={showMessengerModal}
                onHide={() => {
                    setShowMessengerModal(false);
                    clearnData();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={5}>
                                <InputGroup className="mb-4 mt-3">
                                    <Form.Label>Calling Code</Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        onChange={(val: any) =>
                                            setCountryCode(val?.value)
                                        }
                                        defaultValue={{
                                            label: 'IN-91',
                                            value: '91',
                                        }}
                                        isSearchable
                                        options={selectCountries || []}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={7}>
                                <InputGroup className="mt-5">
                                    <FormControl
                                        placeholder="Phone number"
                                        aria-label="Phone number"
                                        aria-describedby="basic-addon1"
                                        value={phoneNumber}
                                        onChange={e =>
                                            handleInputChange(e, setPhoneNumber)
                                        }
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Message Content</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={messageContent}
                                onChange={e =>
                                    handleInputChange(
                                        e as ChangeEvent<HTMLInputElement>,
                                        setMessageContent,
                                    )
                                }
                            />
                        </Form.Group>

                        <Button
                            variant={isLoading === true ? 'warning' : 'primary'}
                            type="submit"
                        >
                            {isLoading === true ? 'Sending' : 'Send Message'}
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        disabled={isLoading === true ? true : false}
                        onClick={() => {
                            setShowMessengerModal(false);
                            clearnData();
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Messenger;
