import React, { useEffect, useState } from 'react';

// Components imports
import Table from '../../Common/Table/Table.js';
import { appBanner } from '../interface';
import { format, utcToZonedTime } from 'date-fns-tz';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls.js';

const AppBannerTable: React.FC = () => {
    const [appBannersData, setAppBannersData] = useState<appBanner[]>();
    const [appBanners, setAppBanners] = useState<appBanner[]>();

    useEffect(() => {
        const fetchBannersData = async () => {
            const respAppBanners = await axios.post(
                `${globalURLS.baseURL}ADgetAppBanners`,
                globalURLS.config,
            );

            if (
                respAppBanners?.data?.data &&
                respAppBanners?.data?.success === true
            ) {
                setAppBannersData(respAppBanners.data.data);
            }
        };
        fetchBannersData();
    }, []);

    useEffect(() => {
        if (appBannersData && appBannersData.length > 0) {
            const transformedData = appBannersData.map(el => {
                const banner: appBanner = {
                    bannerid: '',
                    isactive: 0,
                    name: '',
                    imageurl: '',
                    type: 'image',
                };
                const startDate = new Date(el.startdate);
                const ISTStartDateTime = utcToZonedTime(
                    startDate,
                    'Asia/Calcutta',
                );
                const finalISTStartDateTime = format(
                    ISTStartDateTime,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                const endtDate = new Date(el.enddate);
                const ISTEndDateTime = utcToZonedTime(
                    endtDate,
                    'Asia/Calcutta',
                );
                const finalISTEndDateTime = format(
                    ISTEndDateTime,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                banner.bannerid = el.bannerid;
                banner.isactive = el.isactive;
                banner.name = el.name;
                banner.imageurl = el.imageurl;
                banner.priority = el.priority;
                banner.startdate = finalISTStartDateTime;
                banner.enddate = finalISTEndDateTime;
                banner.action = el.action;
                banner.type = el.type;
                return banner;
            });

            setAppBanners(transformedData ? transformedData : []);
        }
    }, [appBannersData]);

    const [columnDefs] = useState([
        { field: 'bannerid', sortable: true, resizable: true },
        { field: 'name', sortable: true, resizable: true },
        { field: 'type', sortable: true, resizable: true },
        { field: 'startdate', sortable: true, resizable: true },
        { field: 'enddate', sortable: true, resizable: true },
    ]);

    return (
        <>
            <Table
                rowData={appBanners || []}
                columnDefs={columnDefs}
                title={'Mobile Banners'}
            ></Table>
        </>
    );
};

export default AppBannerTable;
