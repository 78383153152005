/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import {
    Button,
    Modal,
    Container,
    Form,
    Col,
    Row,
    Toast,
    ToastContainer,
} from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';

// import './AddVideoExt.css'
import { DiscussionContext } from '../../../Context/index.tsx';
import { globalURLS } from '../../../Global/urls.js';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts.tsx';

function AddVideoExt() {
    const { openAddVideoFilesModal, setAddVideoFilesModal } = useContext(
        DiscussionContext.AddVideoExContext,
    );

    const [inputList, setinputList] = useState([
        {
            discussionid: '',
            title: '',
            durationinms: 0,
            thumbnailurl: '',
            url: '',
            authorid: '',
        },
    ]);
    const [validated, setValidated] = useState(false);
    const [errorToastShow, setErrorToastShow] = useState(false);
    const [toastShow, setToastShow] = useState(false);

    const getSelectDiscussions = true;
    const getSelectSpeakers = true;

    const { selectDiscussions, selectSpeakers } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectDiscussions,
            getSelectSpeakers,
        });

    const handleinputchange = (val, index, name) => {
        const list = [...inputList];
        list[index][name || val?.target?.name] = val?.value
            ? val?.value
            : val?.target?.value;
        setinputList(list);
    };

    const handleremove = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setinputList(list);
    };

    const handleaddclick = () => {
        setinputList([
            ...inputList,
            {
                discussionid: '',
                title: '',
                url: '',
                authorid: '',
                durationinms: 0,
                thumbnailurl: '',
            },
        ]);
    };

    const btnclose = () => {
        setAddVideoFilesModal(false);
    };

    const handleToastShow = () => {
        setToastShow(toastShow ? false : true);
    };

    const handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        const Inputs = {
            discussionid: inputList[0].discussionid,
            authorid: inputList[0].authorid,
            files: inputList,
        };

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        axios
            .post(`${globalURLS.baseURL}addVideoext`, Inputs, globalURLS.config)
            .then(res =>
                res?.data?.success === true
                    ? (setToastShow(true),
                      setAddVideoFilesModal(false),
                      setErrorToastShow(false))
                    : (setToastShow(false),
                      setAddVideoFilesModal(true),
                      setErrorToastShow(true)),
            )
            .catch(err => console.log(err, setErrorToastShow(true)));
    };

    return (
        <Container>
            <Col>
                <Modal show={openAddVideoFilesModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Add In Room Video</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                {inputList.map((x, i) => {
                                    return (
                                        <>
                                            <Row key={i}>
                                                <Row>
                                                    {/* Discussionid */}
                                                    <div className="form-group col-md-6">
                                                        <Form.Label>
                                                            Discussionid
                                                        </Form.Label>
                                                        <Select
                                                            closeMenuOnSelect={
                                                                true
                                                            }
                                                            onChange={val =>
                                                                handleinputchange(
                                                                    val,
                                                                    i,
                                                                    'discussionid',
                                                                )
                                                            }
                                                            isSearchable
                                                            options={
                                                                selectDiscussions
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            Discussionid.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                    {/* AuthorID */}
                                                    <div className="form-group col-md-6">
                                                        <Form.Label>
                                                            Author
                                                        </Form.Label>
                                                        <Select
                                                            closeMenuOnSelect={
                                                                true
                                                            }
                                                            onChange={val =>
                                                                handleinputchange(
                                                                    val,
                                                                    i,
                                                                    'authorid',
                                                                )
                                                            }
                                                            isSearchable
                                                            options={
                                                                selectSpeakers
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            AuthorID.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                    {/* Name */}
                                                    <div className="form-group col-md-6">
                                                        <Form.Label>
                                                            Name
                                                        </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            name="title"
                                                            className="form-control"
                                                            onChange={e =>
                                                                handleinputchange(
                                                                    e,
                                                                    i,
                                                                )
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            Name.
                                                        </Form.Control.Feedback>
                                                    </div>

                                                    {/* URL */}
                                                    <div className="form-group col-md-6">
                                                        <Form.Label>
                                                            URL
                                                        </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            name="url"
                                                            className="form-control"
                                                            onChange={e =>
                                                                handleinputchange(
                                                                    e,
                                                                    i,
                                                                )
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            URL.
                                                        </Form.Control.Feedback>
                                                    </div>

                                                    {/* Thumbnail URL */}
                                                    <div className="form-group col-md-6">
                                                        <Form.Label>
                                                            Thumbnail URL
                                                        </Form.Label>
                                                        <Form.Control
                                                            required={false}
                                                            type="text"
                                                            name="thumbnailurl"
                                                            className="form-control"
                                                            onChange={e =>
                                                                handleinputchange(
                                                                    e,
                                                                    i,
                                                                )
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            URL.
                                                        </Form.Control.Feedback>
                                                    </div>

                                                    {/* Duration in ms */}
                                                    <div className="form-group col-md-6">
                                                        <Form.Label>
                                                            Duration in ms
                                                        </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            name="durationinms"
                                                            className="form-control"
                                                            onChange={e =>
                                                                handleinputchange(
                                                                    e,
                                                                    i,
                                                                )
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            Duration in ms.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    {/* Buttons */}
                                                    <div className="form-group col-md-2 mt-4 ml=4">
                                                        {inputList.length !==
                                                            1 && (
                                                            <Button
                                                                className="btn btn-danger mx-2 mb-2"
                                                                onClick={() =>
                                                                    handleremove(
                                                                        i,
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </Button>
                                                        )}
                                                        {inputList.length -
                                                            1 ===
                                                            i && (
                                                            <Button
                                                                style={{
                                                                    width: '5em',
                                                                }}
                                                                className="btn btn-success mx-2 mb-2"
                                                                onClick={
                                                                    handleaddclick
                                                                }
                                                            >
                                                                Add More
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Row>
                                            </Row>
                                        </>
                                    );
                                })}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Add Files
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* Toast */}

                {toastShow && (
                    <ToastContainer
                        style={{ width: '15em' }}
                        position={'middle-center'}
                    >
                        <Toast onClose={handleToastShow} show={toastShow}>
                            <Toast.Header>
                                <strong
                                    style={{
                                        fontSize: '1em',
                                        padding: '0.5em',
                                        color: 'green',
                                    }}
                                >
                                    Successfully Added
                                </strong>
                            </Toast.Header>
                        </Toast>
                    </ToastContainer>
                )}

                {errorToastShow && (
                    <ToastContainer className="p-3" position={'middle-center'}>
                        <Toast onClose={handleToastShow} show={toastShow}>
                            <Toast.Header>
                                <strong className="me-auto">Error</strong>
                            </Toast.Header>
                            <Toast.Body>
                                Some random error, please try again.
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>
                )}
            </Col>
        </Container>
    );
}

export default AddVideoExt;
