import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

interface IProp {
    showModal: boolean;
    handleClose: () => void;
    headerTitle: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    headerStyle?: React.CSSProperties;
}

const SuccessToast: React.FC<IProp> = ({
    showModal,
    handleClose,
    headerTitle,
    children,
    style,
    headerStyle,
}) => {
    return (
        <>
            <ToastContainer
                style={style ? style : { width: '10em' }}
                position={'middle-center'}
            >
                <Toast onClose={handleClose} show={showModal}>
                    <Toast.Header>
                        <strong
                            style={
                                headerStyle
                                    ? headerStyle
                                    : {
                                          fontSize: '1em',
                                          padding: '0.5em',
                                          color: 'green',
                                      }
                            }
                        >
                            {headerTitle ? headerTitle : 'Success'}
                        </strong>
                    </Toast.Header>
                    {children ?? children}
                </Toast>
            </ToastContainer>
        </>
    );
};

export default SuccessToast;
