import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useGlobalContext, useUserContext } from '../../../Context';
import CustomInput from '../../Common/CustomInput/CustomInput';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';

const RemoveUserModal = () => {
    const { showRemoveUserModal, setShowRemoveUserModal } = useUserContext();
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const [accountid, setAccountid] = useState();
    const [loading, setLoading] = useState(false);
    const [callingCode, setCallingCode] = useState();
    const [phone, setPhone] = useState();

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);

        try {
            let forumData = null;

            if (accountid && !phone && !callingCode) {
                forumData = { accountid: accountid };
            } else if (!accountid && phone && callingCode) {
                forumData = { callingCode: callingCode, phone: phone };
            }
            const respData = await axios.post(
                `${globalURLS.baseURL}deleteUsers`,
                { users: [forumData] },
                globalURLS.config,
            );

            if (respData?.data?.success === true) {
                setLoading(false);
                setShowSuccessToast(true);
                setShowRemoveUserModal(false);
            }

            if (respData?.data?.success === false) {
                setLoading(false);
                setShowErrorToast(true);
            }
        } catch (e) {
            setLoading(false);
            setShowErrorToast(true);
        }
    };

    return (
        <Modal
            show={showRemoveUserModal}
            onHide={() => setShowRemoveUserModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Remove User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomInput
                    label={'Accountid'}
                    textArea={'short'}
                    type={'text'}
                    placeHolder={'Enter a Accountid'}
                    value={accountid}
                    onChange={e => setAccountid(e.target.value)}
                    errorMessage={'Please Enter Accountid'}
                />

                <Form.Label className="m-2">
                    {' '}
                    <strong>Or</strong>
                </Form.Label>
                <CustomInput
                    label={'CallingCode'}
                    textArea={'short'}
                    type={'text'}
                    placeHolder={'Enter a CallingCode'}
                    value={callingCode}
                    onChange={e => setCallingCode(e.target.value)}
                    errorMessage={'Please Enter CallingCode'}
                />
                <CustomInput
                    label={'Phone'}
                    textArea={'short'}
                    type={'text'}
                    placeHolder={'Enter a Phone'}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    errorMessage={'Please Enter Phone'}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => setShowRemoveUserModal(false)}
                >
                    Close
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={handleSubmit}
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RemoveUserModal;
