import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Image,
    Form,
    InputGroup,
    FormControl,
    Button,
} from 'react-bootstrap';
import { MdClear } from 'react-icons/md';

interface PredefinedOption {
    url: string;
    name: string;
}

interface BackGroundInputSelectorProps {
    predefinedOptions: PredefinedOption[];
    onChange: (selectedImages: string[]) => void;
    selectedImages?: string[];
    initBackgroundImages? : string[];
}

const BackGroundInputSelector: React.FC<BackGroundInputSelectorProps> = ({
    predefinedOptions,
    onChange,
    selectedImages,
    initBackgroundImages
}) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [customImageUrls, setCustomImageUrls] = useState<string[]>([]);
    const [customImageUrl, setCustomImageUrl] = useState<string>('');

    useEffect(() => {
        if (initBackgroundImages?.length) setCustomImageUrls(initBackgroundImages);
    }, [initBackgroundImages])

    const handleImageSelect = (image: string) => {
        if (selectedImage === image) {
            setSelectedImage(null);
        } else {
            setSelectedImage(image);
            setCustomImageUrls([]);
        }
    };

    const handleCustomImageUrlChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCustomImageUrl(event.target.value);
    };

    const addCustomImage = () => {
        if (customImageUrl) {
            setCustomImageUrls([...customImageUrls, customImageUrl]);
            setCustomImageUrl('');
            setSelectedImage(null);
        }
    };

    const removeCustomImage = (image: string) => {
        const updatedCustomUrls = customImageUrls.filter(url => url !== image);
        setCustomImageUrls(updatedCustomUrls);
    };

    const removeSelectedImage = () => {
        setSelectedImage(null);
    };

    useEffect(() => {
        const selectedImages = selectedImage
            ? [selectedImage, ...customImageUrls]
            : customImageUrls;
        onChange(selectedImages);
    }, [selectedImage, customImageUrls]);

    return (
        <Container>
            <Row>
                <Col>
                    <Form.Label>Default Options</Form.Label>
                    <Form>
                        <Form.Group>
                            {predefinedOptions?.map((image, index) => (
                                <Form.Check
                                    type="radio"
                                    label={`${image.name}`}
                                    name="backgroundImageSelector"
                                    id={`image${index}`}
                                    key={`image${index}`}
                                    checked={selectedImage === image.url}
                                    onClick={() => handleImageSelect(image.url)}
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    <div>
                        <Form.Label>Add custom images</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Enter custom image URL"
                                value={customImageUrl}
                                onChange={handleCustomImageUrlChange}
                            />
                            <Button onClick={addCustomImage}>Add</Button>
                        </InputGroup>
                    </div>
                    <div>
                        {selectedImage && (
                            <Form.Label>Selected Image</Form.Label>
                        )}

                        {selectedImages && selectedImages.length > 0
                            ? selectedImages.map((image, index) => (
                                  <div
                                      key={`selected${index}`}
                                      className="position-relative"
                                      style={{
                                          marginBottom: '10px',
                                          width: '400px',
                                      }}
                                  >
                                      <Image
                                          src={image}
                                          alt={`Selected ${index + 1}`}
                                          thumbnail
                                          fluid={true}
                                          style={{
                                              width: '80vw',
                                              height: '45vh',
                                          }}
                                      />
                                      {predefinedOptions.some(
                                          option => option.url === image,
                                      ) && (
                                          <Button
                                              variant="danger"
                                              size="sm"
                                              className="remove-button position-absolute top-0 end-0"
                                              onClick={() =>
                                                  removeSelectedImage()
                                              }
                                              style={{
                                                  background:
                                                      'rgba(255, 0, 0, 0.7)',
                                                  borderRadius: '50%',
                                                  padding: '4px',
                                                  height: '40px',
                                                  width: '40px',
                                                  opacity: '1',
                                              }}
                                          >
                                              <MdClear
                                                  style={{ color: 'white' }}
                                              />
                                          </Button>
                                      )}
                                      {!predefinedOptions.some(
                                          option => option.url === image,
                                      ) && (
                                          <Button
                                              variant="danger"
                                              size="sm"
                                              className="remove-button position-absolute top-0 end-0"
                                              onClick={() =>
                                                  removeCustomImage(image)
                                              }
                                              style={{
                                                  background:
                                                      'rgba(255, 0, 0, 0.7)',
                                                  borderRadius: '50%',
                                                  padding: '4px',
                                                  height: '40px',
                                                  width: '40px',
                                                  opacity: '1',
                                              }}
                                          >
                                              <MdClear
                                                  style={{ color: 'white' }}
                                              />
                                          </Button>
                                      )}
                                  </div>
                              ))
                            : customImageUrls.map((image, index) => (
                                  <div
                                      key={`selected${index}`}
                                      className="position-relative"
                                      style={{
                                          marginBottom: '10px',
                                          width: '400px',
                                      }}
                                  >
                                      <Image
                                          src={image}
                                          alt={`Selected ${index + 1}`}
                                          thumbnail
                                          fluid={true}
                                          style={{
                                              width: '80vw',
                                              height: '45vh',
                                          }}
                                      />
                                      <Button
                                          variant="danger"
                                          size="sm"
                                          className="remove-button position-absolute top-0 end-0"
                                          onClick={() =>
                                              removeCustomImage(image)
                                          }
                                          style={{
                                              background:
                                                  'rgba(255, 0, 0, 0.7)',
                                              borderRadius: '50%',
                                              padding: '4px',
                                              height: '40px',
                                              width: '40px',
                                              opacity: '1',
                                          }}
                                      >
                                          <MdClear style={{ color: 'white' }} />
                                      </Button>
                                  </div>
                              ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default BackGroundInputSelector;
