/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi/index.esm.js';

// Reguarding AG-Grid Imports
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Container, Row, Form, InputGroup } from 'react-bootstrap';
import './Table.css';
import UpdateDiscussion from '../../Discussion/UpdateDiscussion/UpdateDiscussion.js';
import UpdateVideoAds from '../../Discussion/UpdateVideoAds/UpdateVideoAds.tsx';

import {
    DiscussionContext,
    useBannerContext,
    useFeedContext,
    useNotificationContext,
    usePmdContext,
    useReportContext,
} from '../../../Context/index.tsx';
import Final from '../../NewsFeed/Steps/Step3/Final.js';
import ReportModal from '../../Report/ReportModal/ReportModal.tsx';
import UpdateAppBannerForum from '../../Banner/AppBanner/UpdateAppBannerForum.tsx';
import UpdateWebBannerForum from '../../Banner/WebBanner/UpdateWebBannerForum.tsx';
import UpdateInAppModal from '../../Notification/InAppBell/UpdateInAppBell.tsx';
import AddQuiz from '../../Quiz/AddQuiz/AddQuiz.tsx';

function Table({ rowData, columnDefs, title }) {
    const [showModal, setShowModal] = useState(false);
    const [showUpdateQuizModal, setShowUpdatQuizeModal] = useState({
        isUpdate: false,
        discussionid: '',
    });
    const [showDraftQuizModal, setShowDraftQuizeModal] = useState({
        isDraft: false,
        discussionid: '',
    });
    const [IDdata, seIDtData] = useState('');
    const [discussionid, setDiscussionid] = useState();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [openUpdateDiscussionModal, setUpdateDiscussionModal] = useState({
        isUpdate: false,
        discussionid: '',
    });
    const [openUpdateVideoAdsModal, setUpdateVideoAdsModal] = useState(false);
    const [VideoAdsData, setVideoAdsData] = useState({});
    const {
        showModal: showFeedModal,
        setShowModal: setShowFeedModal,
        step,
        setStep,
        setFeedData,
        selectTable,
    } = useFeedContext();

    const closeUpdateQuizModal = () => {
        setShowUpdatQuizeModal({ isUpdate: false, discussionid: '' });
    };

    const closeUpdateDiscussionModal = () => {
        setUpdateDiscussionModal({ isUpdate: false, discussionid: '' });
    };
    const closeDraftQuizModal = () => {
        setShowDraftQuizeModal({ isDraft: false, discussionid: '' });
    };

    const { updateInAppModal, setUpdateInAppModal } = useNotificationContext();

    const { reportData, setReportData, reportModal, setReportModal } =
        useReportContext();
    const { setUpdateStoryId } = usePmdContext();

    const UpdateVideoAdsModal = () => setUpdateVideoAdsModal(true);
    const {
        updateAppBannerModal,
        setUpdateAppBannerModal,
        setAppBannerData,
        updateWebBannerModal,
        setWebBannerData,
        setUpdateWebBannerModal,
    } = useBannerContext();

    const btnopen = () => {
        setShowModal(true);
    };

    const valueFormatter = function (params) {
        return params.value
            .map(function (host) {
                return host.firstname;
            })
            .join(', ');
    };
    const keyCreator = function (params) {
        return params.value.map(function (host) {
            return host.accountid;
        });
    };

    const cellClickedHandler = e => {
        // console.log('#E', e?.data);
        // To open quiz, news and questionnaire modal
        e.data.Postid && setUpdateStoryId(e.data.Postid);
        if (e?.data?.newsid) e.data.feedTypeDelete = 'news';
        if (e?.data?.quizid && !e?.data?.discussionid)
            e.data.feedTypeDelete = 'quiz';
        if (e?.data?.questionnaireid) e.data.feedTypeDelete = 'ques';

        if (e?.data?.newsid || e?.data?.quizid || e?.data?.questionnaireid) {
            setStep(3);
            setFeedData(e.data, (e.data.feedDelete = true));
            setShowFeedModal(true);
        }

        // To Open Edit Quiz Modal
        if (e?.data?.quizid && e?.data?.discussionid) {
            setShowUpdatQuizeModal({
                isUpdate: true,
                discussionid: e?.data?.discussionid,
            });
        }

        if (e?.data?.isDraft && e?.data?.discussionid) {
            setShowDraftQuizeModal({
                isDraft: true,
                discussionid: e?.data?.discussionid,
            });
        }

        // To Open Discussion Update Modal
        if (
            e?.data?.discussionid &&
            !e?.data.filename &&
            !e?.data.videoExt &&
            !e?.data.quizid &&
            !e?.data?.isDraft
        ) {
            setUpdateDiscussionModal({
                isUpdate: true,
                discussionid: e?.data?.discussionid,
            });
        }

        if (e?.data?.id && e?.data?.videoExt) {
            setVideoAdsData(e?.data), UpdateVideoAdsModal();
        }

        // Update In App Notification Modal
        if (e?.data?.adminnotificationid) {
            seIDtData(e?.data?.adminnotificationid);
            setUpdateInAppModal(true);
        }

        // To Open Report Modal
        e?.data?.name && e?.data?.endPoint && e?.data?.averageTime
            ? (setReportData({
                  name: e?.data?.name,
                  description: e?.data?.description,
                  averageTime: e?.data?.averageTime,
                  endPoint: e?.data?.endPoint,
              }),
              setReportModal(true))
            : '';

        if (e?.data?.bannerid) {
            setUpdateAppBannerModal(true);
            setAppBannerData(e.data);
        }

        if (e?.data?.webbannerid) {
            setUpdateWebBannerModal(true);
            setWebBannerData(e.data);
        }
    };

    function onGridReady(params) {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }
    const onFilterTextChange = e => {
        setSearchValue(e.target.value);
        gridApi.setQuickFilter(e.target.value);
    };
    return (
        <>
            <Container className="searchDivStyle">
                <Form>
                    <Row>
                        <Form.Group>
                            <InputGroup>
                                <InputGroup.Text>
                                    <BiSearchAlt2 />
                                </InputGroup.Text>
                                <Form.Control
                                    name={'Find the thing you are looking for!'}
                                    className="searchStyle"
                                    type={'text'}
                                    placeholder={
                                        'search by name, title, description, id, date etc...'
                                    }
                                    value={searchValue}
                                    onChange={onFilterTextChange}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
            </Container>
            <Container className="bg-table">
                <Row>
                    <div className="ag-theme-alpine">
                        <h2>{title}</h2>

                        <AgGridReact
                            onGridReady={onGridReady}
                            onCellClicked={cellClickedHandler}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={10}
                            paginationAutoPageSize={true}
                            enableCellTextSelection={true}
                        >
                            <AgGridColumn
                                headerName="Hosts"
                                field="hosts"
                                filter="agSetColumnFilter"
                                valueFormatter={valueFormatter}
                                keyCreator={keyCreator}
                            />
                        </AgGridReact>
                    </div>
                </Row>

                {openUpdateDiscussionModal?.isUpdate && (
                    <UpdateDiscussion
                        show={openUpdateDiscussionModal?.isUpdate}
                        discussionid={openUpdateDiscussionModal?.discussionid}
                        onClose={closeUpdateDiscussionModal}
                    />
                )}

                <DiscussionContext.UpdateVideoAdsContext.Provider
                    value={{
                        openUpdateVideoAdsModal,
                        setUpdateVideoAdsModal,
                    }}
                >
                    {openUpdateVideoAdsModal && (
                        <UpdateVideoAds data={VideoAdsData} />
                    )}
                </DiscussionContext.UpdateVideoAdsContext.Provider>

                {
                    (selectTable && showFeedModal === true,
                    step === 3 && <Final />)
                }
                {reportModal === true && <ReportModal />}

                {updateAppBannerModal && <UpdateAppBannerForum />}
                {updateWebBannerModal && <UpdateWebBannerForum />}
                {updateInAppModal && (
                    <UpdateInAppModal adminnotificationid={IDdata} />
                )}
                {showUpdateQuizModal.isUpdate && (
                    <AddQuiz
                        show={!!showUpdateQuizModal?.isUpdate}
                        onClose={closeUpdateQuizModal}
                        cellData={showUpdateQuizModal}
                    />
                )}
                {showDraftQuizModal.isDraft && (
                    <AddQuiz
                        show={showDraftQuizModal.isDraft}
                        onClose={closeDraftQuizModal}
                        cellData={showDraftQuizModal}
                    />
                )}
            </Container>
        </>
    );
}

export default Table;
