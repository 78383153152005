import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, utcToZonedTime } from 'date-fns-tz';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../../Components/Common/Table/Table.js';
import RemoveQuizButton from '../RemoveQuizBtn/RemoveQuizBtn';
import { Button } from 'react-bootstrap';

function UpcomingQuizDiscussion() {
    const [upcomingQuizDiscussionData, setUpcomingQuizDiscussionData] =
        useState([]);

    const [columnDefs] = useState([
        { field: 'quizid', sortable: true, resizable: true },
        { field: 'discussionid', sortable: true, resizable: true },
        { field: 'title', sortable: true, resizable: true },
        { field: 'club', sortable: true, resizable: true },
        { field: 'status', sortable: true, resizable: true },
        { field: 'startdate', sortable: true, resizable: true },
        { field: 'attendeecount', sortable: true, resizable: true },
        { field: 'hosts', sortable: true, resizable: true },
        {
            field: 'discussionid',
            headerName: '',
            cellRendererFramework: (params: any) => (
                // setDiscussionRemoveID()
                <Button
                    size="sm"
                    onClick={() => handleRemoveQuiz(params.value)}
                >
                    <RemoveQuizButton discussionid={params.value} />
                </Button>
            ),
        },
    ]);
    const [rowData, setRowData] = useState([]);

    const handleRemoveQuiz = (discussionIdToRemove: any) => {
        // Filter the rowData to exclude the row with the matching discussionid
        setRowData(prevRowData =>
            prevRowData.filter(
                row => row.discussionid !== discussionIdToRemove,
            ),
        );
    };

    const adminData = {
        accountid: 'a237aec7-5fe0-4177-99b2-d0b590af26db',
        count: 100,
        getOnlyQuizDiscussions: true,
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${globalURLS.baseURL}getWebUpcomingDiscussions`,
                adminData,
            );

            setUpcomingQuizDiscussionData(response.data.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (
            upcomingQuizDiscussionData &&
            upcomingQuizDiscussionData.length > 0
        ) {
            const finalUcomingDiscussionData = upcomingQuizDiscussionData.map(
                el => {
                    const upcoming = {};
                    const date = new Date(el.istdate);
                    const ISTDateTime = utcToZonedTime(date, 'Asia/Calcutta');
                    const finalISTDateTime = format(
                        ISTDateTime,
                        'yyyy-MM-dd HH:mm:ss',
                        { timeZone: 'Asia/Calcutta' },
                    );
                    // const finalISTDateTime = format(ISTDateTime, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'Asia/Calcutta'});
                    const finalHosts = el.hosts
                        .map(host => {
                            return `${host.firstname} ${host.lastname}`;
                        })
                        .join('| ');
                    (upcoming.quizid = el.quizid),
                        (upcoming.discussionid = el.discussionid),
                        (upcoming.title = el.title),
                        (upcoming.club = el.clubinfo.name),
                        (upcoming.status = el.status),
                        (upcoming.startdate = finalISTDateTime),
                        (upcoming.attendeecount = el.attendeecount),
                        (upcoming.hosts = finalHosts);
                    return upcoming;
                },
            );

            setRowData(
                finalUcomingDiscussionData ? finalUcomingDiscussionData : [],
            );
        }
    }, [upcomingQuizDiscussionData]);
    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Upcoming Quiz Discussions'}
            ></Table>
        </>
    );
}

export default UpcomingQuizDiscussion;
