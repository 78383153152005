import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import './index.css'; // Import the CSS file for the animation
import PlatformCard from '../PlatformCards';

interface platformData {
    name: string;
    count: number;
}
interface LiveUsersCardProps {
    totalLiveUsers: number;
    platformData: platformData;
}

const LiveUsersCard: React.FC<LiveUsersCardProps> = ({
    totalLiveUsers,
    platformData,
}) => {
    return (
        <Card style={{ width: '75vw' }} className="live-users-card mb-4 w-90vw">
            <Card.Body>
                <Row className="mb-4">
                    <Col>
                        <h5 className="card-title">Total Live Users</h5>
                        <p className="card-text h1">{totalLiveUsers}</p>
                    </Col>
                </Row>

                <Row>
                    {platformData &&
                        Array.isArray(platformData) &&
                        platformData.length &&
                        platformData.map(platform => {
                            return (
                                <Col key={platform?.name}>
                                    <PlatformCard
                                        platform={platform.name}
                                        userCount={platform.count}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default LiveUsersCard;
