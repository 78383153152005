import React, { useEffect, useState } from 'react';

// Components imports
import Table from '../../Common/Table/Table.js';
import { format, utcToZonedTime } from 'date-fns-tz';
import { globalURLS } from '../../../Global/urls.js';
import { webBanner } from '../interface';
import axios from 'axios';

const WebBannerTable: React.FC = () => {
    const [webBannersData, setWebBannersData] = useState<webBanner[]>();
    const [wabBanners, setWebBanners] = useState();
    useEffect(() => {
        const fetchBannersData = async () => {
            const respWebBanners = await axios.post(
                `${globalURLS.baseURL}getWebBanners`,
                globalURLS.config,
            );

            if (
                respWebBanners?.data?.data &&
                respWebBanners?.data?.success === true
            ) {
                setWebBannersData(respWebBanners.data.data);
            }
        };
        fetchBannersData();
    }, []);

    useEffect(() => {
        if (webBannersData && webBannersData.length > 0) {
            const transformedData = webBannersData.map(el => {
                const banner: webBanner = {
                    webbannerid: '',
                    name: '',
                    imageurl: '',
                    platform: '',
                    isactive: 1,
                };

                const startDate = new Date(el.startdate);
                const ISTStartDateTime = utcToZonedTime(
                    startDate,
                    'Asia/Calcutta',
                );
                const finalISTStartDateTime = format(
                    ISTStartDateTime,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                const endtDate = new Date(el.enddate);
                const ISTEndDateTime = utcToZonedTime(
                    endtDate,
                    'Asia/Calcutta',
                );
                const finalISTEndDateTime = format(
                    ISTEndDateTime,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );

                banner.webbannerid = el.webbannerid;
                banner.name = el.name;
                banner.imageurl = el.imageurl;
                banner.platform = el.platform;
                banner.startdate = finalISTStartDateTime;
                banner.enddate = finalISTEndDateTime;
                banner.isactive = el.isactive;

                return banner;
            });

            setWebBanners(transformedData ? transformedData : []);
        }
    }, [webBannersData]);

    const [columnDefs] = useState([
        { field: 'webbannerid', sortable: true, resizable: true },
        { field: 'name', sortable: true, resizable: true },
        { field: 'platform', sortable: true, resizable: true },
        { field: 'startdate', sortable: true, resizable: true },
        { field: 'enddate', sortable: true, resizable: true },
        { field: 'isactive', sortable: true, resizable: true },
    ]);

    return (
        <>
            <Table
                rowData={wabBanners || []}
                columnDefs={columnDefs}
                title={'Web Banners'}
            ></Table>
        </>
    );
};

export default WebBannerTable;
