/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Modal, Form, Container, Col } from 'react-bootstrap';

// Components imports
import { useFeedContext } from '../../../../../Context/index.tsx';
import { CustomInput } from '../../../../Common/index.js';

const StepTwo = () => {
    const {
        showModal,
        setShowModal,
        step,
        setStep,
        feedData,
        setFeedData,
        shortPollData,
        setShortPollData,
    } = useFeedContext();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [question, setQuestion] = useState(shortPollData.question);
    const [optionOne, setOptionOne] = useState(shortPollData.options[0].text);
    const [optionTwo, setOptionTwo] = useState(shortPollData.options[1].text);
    const [validated, setValidated] = useState(false);

    const prevStep = () => {
        setStep(step - 1);
    };
    const btnclose = () => {
        setShowModal(false);
        setFeedData('');
        setShortPollData('');
        setStep(1);
        // window.location.reload(true);
    };

    const handleQuestion = e => {
        const { value } = e.target;
        setQuestion(value);
        let pollValues = shortPollData;
        pollValues.question = value;
        setShortPollData(pollValues);
    };

    const handleOptionOne = e => {
        const { value } = e.target;
        setOptionOne(value);
        let pollValues = shortPollData;
        pollValues.options[0].text = value;
        setShortPollData(pollValues);
    };

    const handleOptionTwo = e => {
        const { value } = e.target;
        setOptionTwo(value);
        let pollValues = shortPollData;
        pollValues.options[1].text = value;
        setShortPollData(pollValues);
    };

    const finalStepHandler = () => {
        setStep(3);
    };

    const handleSubmit = event => {
        if (
            question.length > 0 &&
            optionOne.length > 0 &&
            optionTwo.length > 0
        ) {
            setValidated(true);
        } else {
            setValidated(false);
            setShortPollData('');
        }

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        question && optionOne && optionTwo && feedData && finalStepHandler();
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Container className="news-container">
            <Col>
                <Modal show={showModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Poll</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* Question */}
                            <CustomInput
                                textArea="short"
                                type={'text'}
                                required={true}
                                errorMessage="Please provide a question."
                                label="Question"
                                placeHolder="Question"
                                value={question}
                                onChange={e => handleQuestion(e)}
                            />

                            {/* Option 1 */}
                            <CustomInput
                                textArea="short"
                                type={'text'}
                                required={true}
                                errorMessage="Please provide option 1."
                                label="Option 1"
                                placeHolder="Option 1"
                                value={optionOne}
                                onChange={e => handleOptionOne(e)}
                            />

                            {/* Option 2 */}
                            <CustomInput
                                textArea="short"
                                type={'text'}
                                required={true}
                                errorMessage="Please provide option 2."
                                label="Option 2"
                                placeHolder="Option 2"
                                value={optionTwo}
                                onChange={e => handleOptionTwo(e)}
                            />
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>

                            <Button
                                variant="primary"
                                type="button"
                                onClick={prevStep}
                            >
                                Back
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Preview
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
};

export default StepTwo;
