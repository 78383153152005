import React, { useState } from 'react';
import { Modal, Button, Form, Col, Row, Image } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.cjs';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import { useGlobalContext } from '../../../Context';
import FailureToast from '../../Common/Toast/FailureToast';

interface CertificateData {
    discussionid: string;
    xAxis: number;
    yAxis: number;
    templateURL: string;
    fontColor: 'black' | 'white';
    certthreshold: number;
}

interface CertificateModalProps {
    show: boolean;
    onHide(): void;
}

const animatedComponents = makeAnimated();

const initialData: CertificateData = {
    discussionid: '',
    xAxis: 450,
    yAxis: 525,
    templateURL: '',
    fontColor: 'black',
    certthreshold: 75,
};

const CertificateModal: React.FC<CertificateModalProps> = ({
    show,
    onHide,
}) => {
    const [certificateData, setCertificateData] =
        useState<CertificateData>(initialData);

    const [certificateUrl, setCertificateUrl] = useState('');
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();
    const [isTestLoading, setIsTestLoading] = useState(false);
    const [addingCertificate, setAddingCertificate] = useState(false);
    const [certificateAlreadyExists, setCertificateAlreadyExists] =
        useState(false);

    const getSelectDiscussions = true;

    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'fontColor' && (value === 'black' || value === 'white')) {
            setCertificateData(prevData => ({ ...prevData, [name]: value }));
        }

        setCertificateData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTestSubmit = async () => {
        try {
            setIsTestLoading(true);
            const res = await axios.post(
                `${globalURLS.baseURL}generateCertTest`,
                certificateData,
                globalURLS.config,
            );

            if (
                res?.data?.success === true &&
                res?.data?.data?.certificateurl
            ) {
                setCertificateUrl(res?.data?.data?.certificateurl);
            } else {
                setShowErrorToast(true);
            }

            setIsTestLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAddCertificate = async () => {
        try {
            setAddingCertificate(true);
            const res = await axios.post(
                `${globalURLS.baseURL}addCertForDiscussion`,
                certificateData,
                globalURLS.config,
            );

            if (
                res?.data?.success === true &&
                res?.data?.data?.certificateExists
            ) {
                setCertificateAlreadyExists(true);
            } else if (
                res?.data?.success === true &&
                res?.data?.data?.discussioncertconfigid
            ) {
                onHide();
                setCertificateData(initialData);
                setCertificateUrl('');
                setShowSuccessToast(true);
            } else {
                setShowErrorToast(true);
            }

            setAddingCertificate(false);
        } catch (error) {
            console.log(error);
            setShowErrorToast(true);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add Certificate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="discussionid">
                        <Form.Label>Discussion ID</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            onChange={(val: any) =>
                                setCertificateData({
                                    ...certificateData,
                                    discussionid: val.value,
                                })
                            }
                            isSearchable
                            options={JSON.parse(
                                JSON.stringify(
                                    selectDiscussions ? selectDiscussions : [],
                                ),
                            )}
                        />
                    </Form.Group>
                    <Row className="m-2">
                        <Col xs={5}>
                            <Form.Group controlId="xAxis">
                                <Form.Label>X Axis</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="xAxis"
                                    value={certificateData.xAxis}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={5}>
                            <Form.Group controlId="yAxis">
                                <Form.Label>Y Axis</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="yAxis"
                                    value={certificateData.yAxis}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={5}>
                            <Form.Group controlId="fontColor">
                                <Form.Label>Font Color</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="fontColor"
                                    value={certificateData.fontColor}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="black">Black</option>
                                    <option value="white">White</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={5}>
                            <Form.Group controlId="certthreshold">
                                <Form.Label>Certificate Threshold</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="certthreshold"
                                    value={certificateData.certthreshold}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="templateURL">
                        <Form.Label>Template URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="templateURL"
                            value={certificateData.templateURL}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Form>
                <Button
                    className="mt-2 mb-2"
                    variant={isTestLoading ? 'warning' : 'success'}
                    disabled={
                        isTestLoading ||
                        (!!certificateData?.discussionid &&
                            !!certificateData?.templateURL)
                            ? false
                            : true
                    }
                    onClick={handleTestSubmit}
                >
                    {isTestLoading ? 'Testing, please wait' : 'Test'}
                </Button>
                {!!certificateUrl && (
                    <Image
                        src={certificateUrl}
                        fluid={true}
                        style={{
                            width: '70vw',
                            height: '70vh',
                        }}
                    />
                )}
                {certificateAlreadyExists && (
                    <FailureToast
                        showModal={certificateAlreadyExists}
                        handleClose={() => setCertificateAlreadyExists(false)}
                        headerTitle="Certificate Already Exists For This Discussion"
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="success"
                    disabled={addingCertificate || !certificateUrl}
                    onClick={handleAddCertificate}
                >
                    Add Certificate
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CertificateModal;
