import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Image } from 'react-bootstrap';
import { useGlobalContext, useNotificationContext } from '../../../Context';
import { format, parseISO, parse as parsed } from 'date-fns';
import { parse } from 'papaparse';
import Select from 'react-select';

import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import { zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import { convertUTCtoIST } from '../../../Helper/TimeZone';

// interface DeepLink {
//     params: {
//         feedItemId?: string;
//         discussionid?: string;
//     };
//     screen: 'Discussion' | 'Feed';
// }

interface NotificationData {
    adminnotificationid: string;
    header: string;
    screen: string;
    subheader: string;
    thumbnailurl: string;
    banners: string[];
    ctatext: string;
    starttime: string;
    endtime: string;
    broadcast: boolean;
}

const initialState: NotificationData = {
    adminnotificationid: '',
    header: '',
    screen: '',
    subheader: '',
    thumbnailurl: '',
    banners: [''],
    ctatext: '',
    starttime: '',
    endtime: '',
    broadcast: true,
};
const UpdateInAppBell: React.FC<{
    adminnotificationid: string;
}> = ({ adminnotificationid }) => {
    const [notification, setNotification] =
        useState<NotificationData>(initialState);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const getSelectDiscussions = true;
    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const { setUpdateInAppModal, updateInAppModal } = useNotificationContext();

    const handleInputChange = (event: any) => {
        setNotification({
            ...notification,
            [event.target.name]:
                event.target.name === 'starttime' ||
                event.target.name === 'endtime'
                    ? format(
                          parseISO(event.target.value),
                          'yyyy-MM-dd HH:mm:ss',
                      )
                    : event?.target?.value,
        });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        // Here, we assume IST refers to Indian Standard Time (UTC+5:30)
        const startDateIST = parsed(
            notification.starttime,
            'yyyy-MM-dd HH:mm:ss',
            new Date(),
        );

        const endDateIST = parsed(
            notification.endtime,
            'yyyy-MM-dd HH:mm:ss',
            new Date(),
        );

        // Convert the date to UTC
        const startDateUTC = zonedTimeToUtc(startDateIST, 'Asia/Kolkata');
        const endDateUTC = zonedTimeToUtc(endDateIST, 'Asia/Kolkata');

        // Format the date in UTC
        const formattedStartDateUTC = format(
            startDateUTC,
            'yyyy-MM-dd HH:mm:ssXXX',
        );

        const formattedEndDateUTC = format(
            endDateUTC,
            'yyyy-MM-dd HH:mm:ssXXX',
        );

        notification.starttime = formattedStartDateUTC;
        notification.endtime = formattedEndDateUTC;

        try {
            setIsLoading(true);
            const respData = await axios.post(
                `${globalURLS.baseURL}ADaddAdminNotification`,
                notification,
                globalURLS.config,
            );

            if (respData?.data?.success === true) {
                setIsLoading(false);
                setShowSuccessToast(true);
                setUpdateInAppModal(false);
            }

            if (respData?.data?.success === false) {
                setIsLoading(false);
                setShowSuccessToast(false);
                setShowErrorToast(true);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setShowSuccessToast(false);
            setShowErrorToast(true);
        }
    };

    const handleDelete = async (event: React.FormEvent) => {
        async function deleteFunc() {
            try {
                const deleteID = notification.adminnotificationid;
                const resp = await axios.delete(
                    `${globalURLS.baseURL}getAdminNotifications/${deleteID}`,
                    globalURLS.config,
                );

                if (resp?.data?.data && resp?.data?.success === true) {
                    setShowSuccessToast(true);
                    setUpdateInAppModal(false);
                    setNotification(initialState);
                } else {
                    setShowErrorToast(true);
                }
            } catch (error) {
                console.log(error);
                setShowErrorToast(true);
            }
        }
        deleteFunc();
    };

    useEffect(() => {
        async function fetchInAppBellNotification() {
            const respData = await axios.post(
                `${globalURLS.baseURL}getAdminNotifications`,
                { id: adminnotificationid },
                globalURLS.config,
            );

            if (respData?.data?.success === true) {
                setNotification(respData?.data?.data[0]);
            }
        }
        fetchInAppBellNotification();
    }, [adminnotificationid]);

    return (
        <>
            <Modal
                show={updateInAppModal}
                animation={false}
                onHide={() => setUpdateInAppModal(false)}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header>
                        <Modal.Title className="mb-0">
                            Send In-App Notification
                        </Modal.Title>
                    </Modal.Header>
                    {notification && (
                        <Modal.Body>
                            {/* Header */}
                            <Form.Group className="mb-3">
                                <Form.Label>Header</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="header"
                                    required
                                    // defaultValue = {notification.header}
                                    defaultValue={notification.header}
                                    onChange={handleInputChange}
                                    readOnly
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter the Header
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* SubHeader */}
                            <Form.Group className="mb-3">
                                <Form.Label>Subheader</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="subheader"
                                    required
                                    defaultValue={notification.subheader}
                                    onChange={handleInputChange}
                                    readOnly
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter the Subheader
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* ThumbnailURL */}
                            <Form.Group className="mb-3">
                                <Form.Label>ThumbnailURL</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="thumbnailurl"
                                    required
                                    defaultValue={notification.thumbnailurl}
                                    onChange={handleInputChange}
                                    readOnly
                                />
                                {notification.thumbnailurl && (
                                    <Image
                                        src={notification.thumbnailurl}
                                        thumbnail
                                        style={{
                                            marginTop: '0.5em',
                                            width: '55vw',
                                            height: '35vh',
                                        }}
                                    />
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Please Enter the ThumbnailURL
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Banner */}
                            <Form.Group className="mb-3">
                                <Form.Label>Banner URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="banners"
                                    required
                                    readOnly
                                    defaultValue={notification.banners[0]}
                                    onChange={e =>
                                        setNotification({
                                            ...notification,
                                            banners: [e.target.value.trim()],
                                        })
                                    }
                                />
                                {notification.banners && (
                                    <Image
                                        src={notification.banners[0]}
                                        thumbnail
                                        style={{
                                            marginTop: '0.5em',
                                            width: '55vw',
                                            height: '35vh',
                                        }}
                                    />
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Please Enter the Banner URL
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* CTA Text */}
                            <Form.Group className="mb-3">
                                <Form.Label>CTA Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="ctatext"
                                    required
                                    readOnly
                                    defaultValue={notification.ctatext}
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter the CTA Text
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* CTA Icon URL */}
                            {/* <Form.Group className="mb-3">
                            <Form.Label>CTA Icon URL</Form.Label>
                            <Form.Control
                                type="text"
                                name="ctaiconurl"
                                defaultValue = {notification.ctaiconurl}
                                onChange={handleInputChange}
                            />
                            {notification.ctaiconurl && (
                                <Image
                                    src={notification.ctaiconurl}
                                    thumbnail
                                    style={{
                                        marginTop: '0.5em',
                                        width: '55vw',
                                        height: '35vh',
                                    }}
                                />
                            )}
                        </Form.Group> */}

                            {/* Action */}
                            {/* <Form.Group className="mb-3">
                            <Form.Label>Action</Form.Label>
                            <Form.Control
                                type="text"
                                name="action"
                                defaultValue = {notification.action}
                                readOnly
                            />
                        </Form.Group> */}

                            {/* OpenWebViewURL */}
                            {/* <Form.Group className="mb-3">
                            <Form.Label>OpenWebviewUrl</Form.Label>
                            <Form.Control
                                type="text"
                                name="openWebviewUrl"
                                defaultValue = {notification.openWebviewUrl}
                                readOnly
                            />
                        </Form.Group> */}

                            {/* Broadcast */}
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Broadcast (By making false, can add
                                    accountids.)
                                </Form.Label>
                                <Form.Select
                                    name="broadcast"
                                    defaultValue={
                                        notification.broadcast === true
                                            ? 'true'
                                            : 'false'
                                    }
                                    disabled
                                    onChange={e =>
                                        setNotification({
                                            ...notification,
                                            broadcast:
                                                e.target.value === 'true'
                                                    ? true
                                                    : false,
                                        })
                                    }
                                    required
                                >
                                    <option value="true">true</option>
                                    <option value="false">false</option>
                                </Form.Select>
                            </Form.Group>

                            {/* target IDS */}
                            {/* {notification.broadcast === false && (
                            <Form.Group className="mb-3">
                                <Form.Label>Account IDs (CSV File)</Form.Label>
                                <Form.Control
                                    required
                                    type="file"
                                    accept=".csv"
                                    name="accountids"
                                    onChange={async e => {
                                        const files = e.target.files;

                                        if (files) {
                                            // console.log('files', files[0]);
                                            parse(files[0], {
                                                header: true,
                                                complete: function (results) {
                                                    const data =
                                                        results.data.map(
                                                            (el: string) =>
                                                                el.accountid,
                                                        );
                                                    // console.log('data', data);
                                                    setNotification({
                                                        ...notification,
                                                        targedids: data,
                                                    });
                                                },
                                            });
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide accountids CSV file
                                </Form.Control.Feedback>
                            </Form.Group>
                        )} */}

                            {/* Screen */}
                            <Form.Group className="mb-3">
                                <Form.Label>Screen</Form.Label>
                                <Form.Select
                                    name="screen"
                                    defaultValue={notification.screen}
                                    // onChange={e =>
                                    //     setNotification({
                                    //         ...notification,
                                    //         openDeeplink: {
                                    //             ...notification.openDeeplink,
                                    //             screen:
                                    //                 e.target.value === 'Discussion'
                                    //                     ? 'Discussion'
                                    //                     : 'Feed',
                                    //         },
                                    //     })
                                    // }
                                    required
                                    disabled
                                >
                                    <option value="Discussion">
                                        Discussion
                                    </option>
                                    <option value="Feed">Feed</option>
                                </Form.Select>
                            </Form.Group>

                            {/* {notification.openDeeplink.screen === 'Discussion' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Discussion</Form.Label>

                                <Select
                                    closeMenuOnSelect={true}
                                    onChange={val =>
                                        setNotification({
                                            ...notification,
                                            openDeeplink: {
                                                ...notification.openDeeplink,
                                                params: {
                                                    ...notification.openDeeplink
                                                        .params,
                                                    discussionid: val.value,
                                                },
                                            },
                                        })
                                    }
                                    isSearchable
                                    options={selectDiscussions}
                                />
                            </Form.Group>
                        )}

                        {notification.openDeeplink.screen === 'Feed' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Feed Item ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    name="title"
                                    defaultValue = {
                                        notification.openDeeplink.params
                                            .feedItemId
                                    }
                                    onChange={val =>
                                        setNotification({
                                            ...notification,
                                            openDeeplink: {
                                                ...notification.openDeeplink,
                                                params: {
                                                    ...notification.openDeeplink
                                                        .params,
                                                    feedItemId: val.value,
                                                },
                                            },
                                        })
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter Feed Item ID
                                </Form.Control.Feedback>
                            </Form.Group>
                        )} */}

                            {/* Start Time */}
                            {/* <Form.Group className="mb-3">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    required
                                    name="starttime"
                                    defaultValue={notification.starttime}
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter the Start Time
                                </Form.Control.Feedback>
                            </Form.Group> */}

                            {/* End Time */}
                            {/* <Form.Group className="mb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                required
                                name="endtime"
                                defaultValue = {notification.endtime}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please Enter the End Time
                            </Form.Control.Feedback>
                        </Form.Group> */}
                        </Modal.Body>
                    )}
                    <Modal.Footer
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setUpdateInAppModal(false),
                                    setNotification(initialState);
                            }}
                        >
                            Close
                        </Button>

                        {/* <Button
                            variant={isLoading ? 'warning' : 'primary'}
                            type="submit"
                            disabled={isLoading ? true : false}
                            onClick={handleSubmit}
                        >
                            {isLoading ? 'Please Wait' : 'Create'}
                        </Button> */}
                        <Button variant="danger" onClick={handleDelete}>
                            {isLoading ? 'Please Wait' : 'Delete'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default UpdateInAppBell;
