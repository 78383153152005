import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Field, FieldProps } from 'formik';

interface CheckboxInputProps {
    label: string;
    name: string;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({ label, name }) => {
    return (
        <Form.Group as={Col}>
            <Form.Check>
                <Field name={name}>
                    {({ field }: FieldProps) => (
                        <Form.Check.Input
                            type="checkbox"
                            checked={field.value}
                            onChange={() =>
                                field.onChange({
                                    target: { name, value: !field.value },
                                })
                            }
                        />
                    )}
                </Field>
                <Form.Check.Label>{label}</Form.Check.Label>
            </Form.Check>
        </Form.Group>
    );
};

export default CheckboxInput;
