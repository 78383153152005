import React, { useState, useEffect } from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Col, Container, Row } from 'react-bootstrap';

interface Data {
    discussionid?: string;
    x: Date;
    y: number;
    count: number;
    attendeesCount: number;
    status: 'live' | 'scheduled' | 'ended';
    title: string;
}

interface LiveChartProps {
    data: Data[];
    discussionId: string;
}

const LiveChart: React.FC<LiveChartProps> = ({ data, discussionId }) => {
    const [chartData, setChartData] = useState<any>({
        datasets: [
            {
                label: '',
                data: [],
                fill: false,
                borderColor: 'rgba(75, 192, 192, 1)',
            },
        ],
    });

    const [maxPeak, setMaxPeak] = useState<number>(0);
    const [latestDataPoint, setLatestDataPoint] = useState<Data | undefined>(
        undefined,
    );

    const updateChartData = () => {
        const discussionData = data.filter(
            item => item.discussionid === discussionId,
        );
        const newMaxPeak = Math.max(...discussionData.map(entry => entry.y));
        const newLatestDataPoint = discussionData[discussionData.length - 1];

        setMaxPeak(newMaxPeak);
        setLatestDataPoint(newLatestDataPoint);

        setChartData({
            datasets: [
                {
                    label: `Discussion: ${discussionData[0]?.title || ''}`,
                    data: discussionData.map(item => ({
                        x: item.x,
                        y: item.y,
                    })),
                    fill: false,
                    borderColor: 'rgba(75, 192, 192, 1)',
                },
            ],
        });
    };

    useEffect(() => {
        updateChartData();

        // Update chart every 5 seconds
        const intervalId = setInterval(() => {
            updateChartData();
        }, 5000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [discussionId, data]);

    const chartOptions = {
        animation: {
            duration: 500,
            easing: 'linear',
        },
        interaction: {
            intersect: false,
        },
        plugins: {
            tooltip: {
                mode: 'index',
                callbacks: {
                    title: () => '',
                    label: context => {
                        const dataPoint = context.parsed.x;
                        const formattedTime = new Intl.DateTimeFormat('en-IN', {
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZone: 'Asia/Kolkata',
                        }).format(dataPoint);

                        return `${formattedTime}: ${Math.round(
                            context.parsed.y,
                        )}`;
                    },
                    afterLabel: () => `Max Peak: ${maxPeak}`,
                },
            },
        },
        elements: {
            point: {
                radius: 0, // Set point radius to 0 to hide data points
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'minute',
                    unitStepSize: 5,
                    displayFormats: {
                        minute: 'HH:mm',
                    },
                    timeZone: 'Asia/Kolkata',
                },
                title: {
                    display: true,
                    text: 'Time',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Peak Value',
                },
                ticks: {
                    stepSize: 10,
                },
            },
        },
    };

    return (
        <Container className="live-chart-container">
            <Row>
                <Col sm={8} className="chart-wrapper">
                    <Chart
                        type="line"
                        data={chartData}
                        options={chartOptions}
                    />
                </Col>
                <Col sm={4} className="info-wrapper">
                    <p>Max Peak: {maxPeak}</p>
                    <p>Current: {latestDataPoint?.y}</p>
                    <p>
                        Total Attendee Count: {latestDataPoint?.attendeesCount}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default LiveChart;
