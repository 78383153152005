import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Button, Modal, Form, Image, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.cjs';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import { useGlobalContext } from '../../../Context';

interface Resp {
    filename: string;
    size: number;
    path: string;
    url: string;
    mimetype?: any;
    uploadedon: number;
}

const initialState: Resp = {
    filename: '',
    size: 0,
    path: '',
    url: '',
    uploadedon: 0,
};
const animatedComponents = makeAnimated();

const DownloadJSON: React.FC<{
    show: boolean;
    onClose: () => void;
}> = ({ show, onClose }) => {
    const [discussionID, setDiscussionID] = useState('');
    const [selectDiscussions, setSelectDiscussions] = useState([]);
    const [downloadURL, setDownloadURL] = useState<Resp>(initialState);
    const [isLoading, setIsLoading] = useState(false);

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    useEffect(() => {
        const fetchDiscussion = async () => {
            const discussionsData = await axios.get(
                globalURLS.quizOnlyDiscussionsJSONURL,
            );

            const finalData = discussionsData?.data?.map((el: any) => ({
                value: el.discussionid,
                label: el.name,
            }));

            setSelectDiscussions(finalData);
        };

        fetchDiscussion();
    }, []);

    useEffect(() => {
        const fetchDiscussion = async () => {
            try {
                setIsLoading(true);
                const res = await axios.post(
                    `${globalURLS.baseURL}ADDgetQuizJSON`,
                    { discussionid: discussionID },
                    globalURLS.config,
                );
                console.log('res', res);

                if (res?.data?.success === true) {
                    setDownloadURL(res?.data?.data);
                } else {
                    setShowErrorToast(true);
                }
                setIsLoading(false);
            } catch (e) {
                setShowErrorToast(true);
            }
        };

        if (discussionID) fetchDiscussion();
    }, [discussionID]);

    return (
        <Modal show={show} onHide={onClose} size="lg" className="p-5">
            <Modal.Header closeButton>
                <Modal.Title>Download Quiz JSON</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="discussionid">
                        <Form.Label>Discussion</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            onChange={(val: any) => setDiscussionID(val.value)}
                            isSearchable
                            options={JSON.parse(
                                JSON.stringify(
                                    selectDiscussions ? selectDiscussions : [],
                                ),
                            )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    disabled={downloadURL?.url ? false : true}
                    href={downloadURL?.url || null}
                    variant={isLoading ? 'warning' : 'success'}
                    target={downloadURL?.url ? '_blank' : null}
                >
                    {isLoading ? 'Fetching...' : 'Download'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DownloadJSON;
