import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, InputGroup, Stack } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios'; // Make sure you've installed Axios in your project
import TextInput from '../../Common/Inputs/TextInput';
import CheckboxInput from '../../Common/Inputs/CheckboxInput';
import YearPicker from '../../Common/Pickers/YearPicker';
import CustomSelect from '../../Common/Inputs/CustomSelect';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import useUserDropDown from '../../../hooks/useUserDropDown';
import { globalURLS } from '../../../Global/urls';
import axios from 'axios';
import { useGlobalContext } from '../../../Context';
import TextAreaInput from '../../Common/Inputs/TextAreaInput';

interface AddUserProps {
    show: boolean;
    onHide: () => void;
}

interface ICity {
    id: string;
    name: string;
}
interface SelectCity {
    value: string;
    label: string;
}

interface FormValues {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    summary: string;
    callingcode: string;
    isstudent: number;
    isspeaker: number;
    isTest: number;
    yearOfPassing: number;
    toVerify: boolean;
    profilepic: string;
    profilepicthumb: string;
    specialtyid: string;
    cityid: string;
    designationid: string;
    yob: string;
    instituteid: string;
    pursuingid: string;
    usertypeid: string;
    degreeid: string;
    hideAttendeeCount: number;
}

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    callingcode: Yup.string().required('Calling Code is required'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string().required('Email is required'),
    yearOfPassing: Yup.string().required('yearOfPassing is required'),
    specialtyid: Yup.string().required('Specialty is required'),
    cityid: Yup.string().required('City is required'),
    // designationid: Yup.string().optional(),
    instituteid: Yup.string().required('Institute is required'),
    // usertypeid: Yup.string().required('User Type is required'),
    degreeid: Yup.string().required('Degree is required'),
});

Yup;
const AddUser: React.FC<AddUserProps> = ({ show, onHide }) => {
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();
    const [isLoading, setIsLoading] = useState(false);
    const [countryISO, setCountryISO] = useState('');
    const [selectCitiesLocal, setSelectCitiesLocal] = useState([]);

    function removeEmptyFields(obj: any) {
        const result = {};

        for (const key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (
                    value !== null &&
                    value !== undefined &&
                    value !== '' &&
                    value !== false &&
                    value !== 0
                ) {
                    if (typeof value === 'object' && !Array.isArray(value)) {
                        const cleanedValue = removeEmptyFields(value);
                        if (Object.keys(cleanedValue).length > 0) {
                            result[key] = cleanedValue;
                        }
                    } else {
                        result[key] = value;
                    }
                }
            }
        }

        return result;
    }

    const handleSubmit = async (values: any) => {
        try {
            if (values) {
                const bodyReq = { users: [removeEmptyFields(values)] };

                setIsLoading(true);

                const resp = await axios.post(
                    `${globalURLS.baseURL}addUsers`,
                    bodyReq,
                    globalURLS.config,
                );

                setIsLoading(false);

                if (resp?.data?.success === true) {
                    onHide();
                    setShowSuccessToast(true);
                } else {
                    setShowErrorToast(true);
                    setIsLoading(false);
                }
            }

            onHide();
        } catch (error) {
            console.error('Error adding user:', error);
            setShowErrorToast(true);
        }
    };

    const handleCustomSelectChange = async (
        formikProps: FormikProps<FormValues>,
        fieldName: string,
        selectedOption: any,
    ) => {
        fieldName === 'callingcode'
            ? setCountryISO(selectedOption?.countryISO)
            : '';

        formikProps.setFieldValue(fieldName, selectedOption?.value || '');
    };

    useEffect(() => {
        const postReq = async () => {
            const bodyReq = {
                countryISO: countryISO,
                count: 1000000,
            };
            const resp = await axios.post(
                `${globalURLS.baseURL}getCities`,
                bodyReq,
                globalURLS.config,
            );

            if (resp?.data?.success === true && resp?.data?.data) {
                const cities: ICity[] = resp?.data?.data;

                const finalCitiesData = cities.map((city: ICity) => {
                    return { value: city?.id, label: city?.name };
                });

                if (finalCitiesData && finalCitiesData?.length) {
                    setSelectCitiesLocal(finalCitiesData);
                }
            }
        };

        postReq();
    }, [countryISO]);

    const getSelectSpecialties = true;
    const { selectSpecialties } = useDiscussionsClubsHostsInternalHosts({
        getSelectSpecialties,
    });

    const getSelectCities = false;
    const getUserDropDown = true;
    const getSelectCountries = true;
    const { selectUserDropDown, selectCountries } = useUserDropDown(
        getSelectCities,
        getUserDropDown,
        getSelectCountries,
    );

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        firstname: '',
                        lastname: '',
                        summary: '',
                        yob: '',
                        email: '',
                        phone: '',
                        callingcode: '91',
                        isstudent: 0,
                        isspeaker: 0,
                        isTest: 0,
                        yearOfPassing: 0,

                        // sendSms: 0,
                        // sendEmail: 0,
                        toVerify: false,
                        hideAttendeeCount: 0,
                        profilepic: '',
                        profilepicthumb: '',
                        // smsTemplateName: '',
                        // emailTemplateName: '',
                        specialtyid: '',
                        cityid: '',
                        designationid: '',
                        instituteid: '',
                        pursuingid: '',
                        usertypeid: '',
                        degreeid: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <Form>
                            <TextInput label="First Name" name="firstname" />
                            <TextInput label="Last Name" name="lastname" />
                            <TextAreaInput label="Bio" name="summary" />
                            {/* <TextInput label="Bio" name="summary" /> */}

                            <TextInput label="Email" name="email" />
                            <Stack
                                direction="horizontal"
                                gap={3}
                                style={{ margin: '10px' }}
                            >
                                <YearPicker
                                    label="Year of Passing"
                                    name="yearOfPassing"
                                />
                                {/* <YearPicker label="Year of Birth" name="yob" /> */}
                            </Stack>
                            <InputGroup>
                                {/* <TextInput
                                    label="Calling Code"
                                    name="callingcode"
                                /> */}
                                <CustomSelect
                                    label="Calling Code"
                                    name="callingcode"
                                    options={selectCountries || []}
                                    isMulti={false}
                                    isSearchable
                                    value={formikProps.values.institute}
                                    onChange={(selectedOption: any) =>
                                        handleCustomSelectChange(
                                            formikProps,
                                            'callingcode',
                                            selectedOption,
                                        )
                                    }
                                />
                                <TextInput label="Phone" name="phone" />
                            </InputGroup>

                            <TextInput
                                label={'Profile Pic'}
                                name={'profilepic'}
                            />

                            <CustomSelect
                                label="Select Degree"
                                name="degreeid"
                                options={selectUserDropDown?.degree || []}
                                isMulti={false}
                                isSearchable
                                value={formikProps.values.degree}
                                onChange={(selectedOption: any) =>
                                    handleCustomSelectChange(
                                        formikProps,
                                        'degreeid',
                                        selectedOption,
                                    )
                                }
                            />

                            <CustomSelect
                                label="Select Cities"
                                name="cityid"
                                options={selectCitiesLocal}
                                isMulti={false}
                                isSearchable
                                value={formikProps.values.city}
                                onChange={(selectedOption: any) =>
                                    handleCustomSelectChange(
                                        formikProps,
                                        'cityid',
                                        selectedOption,
                                    )
                                }
                            />
                            <CustomSelect
                                label="Select Specialties"
                                name="specialtyid"
                                options={selectSpecialties}
                                isMulti={false}
                                isSearchable
                                value={formikProps.values.specialty}
                                onChange={(selectedOption: any) =>
                                    handleCustomSelectChange(
                                        formikProps,
                                        'specialtyid',
                                        selectedOption,
                                    )
                                }
                            />

                            {/* <CustomSelect
                                label="Select Designation"
                                name="designationid"
                                options={selectUserDropDown?.designation || []}
                                isMulti={false}
                                isSearchable
                                value={formikProps.values.designation}
                                onChange={(selectedOption: any) =>
                                    handleCustomSelectChange(
                                        formikProps,
                                        'designationid',
                                        selectedOption,
                                    )
                                }
                            /> */}

                            <CustomSelect
                                label="Select Institute"
                                name="instituteid"
                                options={selectUserDropDown?.institute || []}
                                isMulti={false}
                                isSearchable
                                value={formikProps.values.institute}
                                onChange={(selectedOption: any) =>
                                    handleCustomSelectChange(
                                        formikProps,
                                        'instituteid',
                                        selectedOption,
                                    )
                                }
                            />

                            <CustomSelect
                                label="Select User Type"
                                name="usertypeid"
                                options={selectUserDropDown?.userType || []}
                                isMulti={false}
                                isSearchable
                                value={formikProps.values.usertype}
                                onChange={(selectedOption: any) =>
                                    handleCustomSelectChange(
                                        formikProps,
                                        'usertypeid',
                                        selectedOption,
                                    )
                                }
                            />

                            {/* <CustomSelect
                                label="Select Pursuing"
                                name="pursuingid"
                                options={selectUserDropDown?.pursuing || []}
                                isMulti={false}
                                isSearchable
                                value={formikProps.values.pursuing}
                                onChange={(selectedOption: any) =>
                                    handleCustomSelectChange(
                                        formikProps,
                                        'pursuingid',
                                        selectedOption,
                                    )
                                }
                            /> */}

                            <Stack direction="horizontal">
                                <CheckboxInput
                                    label="Student"
                                    name="isstudent"
                                />
                                {/* <CheckboxInput
                                    label="Speaker"
                                    name="isspeaker"
                                /> */}

                                <CheckboxInput label="Test" name="isTest" />
                                <CheckboxInput label="Verify" name="toVerify" />
                                <CheckboxInput label="Mark For Recording" name="hideAttendeeCount" />
                            </Stack>

                            <Button
                                variant={isLoading ? 'warning' : 'success'}
                                className="mt-10"
                                type="button"
                                onClick={() => {
                                    handleSubmit(formikProps.values);
                                }}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Creating...' : 'Create'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default AddUser;
