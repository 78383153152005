import React from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import './AdsDash.css';

type timestats = [{ date: string; totalViews: number; uniqueViews: number; totalClicks: number; uniqueClicks: number }] | [];

interface StatsProps {
    stats: {
        title: string;
        totalViews: number;
        totalClicks: number;
        uniqueViews: number;
        uniqueClicks: number;
        timestats: timestats;
    }[];
}

const StatsDisplay: React.FC<StatsProps> = ({ stats }) => {
    const formatChartData = (label: string, totalKey: string, uniqueKey: string, timestats: timestats): ChartData<'line'> => {
        return {
            labels: timestats.map((entry) => entry.date) || [],
            datasets: [
                {
                    label: `Total ${label}`,
                    data: timestats.map((entry) => (entry[totalKey])) || [],
                    borderColor: 'blue',
                    fill: false,
                },
                {
                    label: `Unique ${label}`,
                    data: timestats.map((entry) => entry[uniqueKey]) || [],
                    borderColor: 'green',
                    fill: false,
                },
            ],
        };
    };

    return (
        <div className="stats-display">
            {stats.map((stat) => (
                <div key={stat.title} className="stat-item">
                    <h5>{stat.title}</h5>
                    <p>Total Views: {stat.totalViews}, Unique Views: {stat.uniqueViews}</p>
                    <p>Total Clicks: {stat.totalClicks}, Unique Clicks: {stat.uniqueClicks}</p>

                    <div className="charts">
                        <div style={{flex: 1}}><Line data={formatChartData('Views', 'totalViews', 'uniqueViews', stat.timestats ?? [])} /></div>
                        <div style={{flex: 1}}> <Line data={formatChartData('Clicks', 'totalClicks', 'uniqueClicks', stat.timestats ?? [])} /></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default StatsDisplay;
