import { createContext } from 'react';

const AddDiscussionContext = createContext({
    showModal: false,
});

const AddDiscussionFilesContext = createContext({
    openAddFilesModal: false,
});

const AddVideoExContext = createContext({
    openAddVideoFilesModal: false,
});

const AddDiscussionDocumentContext = createContext({
    openAddDocumentModal: false,
});

const AddVideoAdsContext = createContext({
    openAddVideoAdsModal: false,
});

const UpdateVideoAdsContext = createContext({
    openUpdateVideoAdsModal: false,
});

const AddPollsContext = createContext({
    openAddPollsModal: false,
});

export {
    AddDiscussionContext,
    AddDiscussionFilesContext,
    AddDiscussionDocumentContext,
    AddVideoAdsContext,
    UpdateVideoAdsContext,
    AddPollsContext,
    AddVideoExContext,
};
