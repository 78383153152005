/* eslint-disable react/prop-types */
import React from 'react';
import {
    Form,
    Row,
    InputGroup,
    Image,
    ButtonGroup,
    Button,
    Dropdown,
    Container,
} from 'react-bootstrap';

function CustomInput({
    classNames = undefined,
    textArea = undefined,
    type = undefined,
    errorMessage = undefined,
    label = undefined,
    placeHolder = undefined,
    value = undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    synopsis = undefined,
    onChange = undefined,
    required = undefined,
    disabled = undefined,
    readOnly = undefined,
    defaultValue = undefined,
    dropDown = undefined,
    onSelect = undefined,
    min = undefined,
}) {
    switch (textArea) {
        case 'short':
            switch (type) {
                case 'text':
                    return (
                        <>
                            <InputGroup
                                className={classNames ? classNames : 'row mt-1'}
                            >
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    {label}
                                </Form.Label>
                                <Form.Control
                                    name={label}
                                    className="mx-3"
                                    required={required}
                                    type={type}
                                    defaultValue={defaultValue}
                                    placeholder={placeHolder}
                                    value={value}
                                    onChange={onChange}
                                    disabled={disabled || false}
                                    readOnly={readOnly || false}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errorMessage}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </>
                    );
                case 'textImage':
                    return (
                        <>
                            <InputGroup
                                className={classNames ? classNames : 'row mt-3'}
                            >
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    {label}
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    required={required}
                                    type={type}
                                    defaultValue={defaultValue}
                                    placeholder={placeHolder}
                                    value={value}
                                    onChange={onChange}
                                    disabled={disabled || false}
                                />
                                {value && value.length && (
                                    <Image
                                        src={value}
                                        thumbnail
                                        style={{
                                            marginTop: '0.5em',
                                            width: '55vw',
                                            height: '35vh',
                                        }}
                                    />
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {errorMessage}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </>
                    );
                case 'datetime':
                    return (
                        <>
                            {label && (
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    {label}
                                </Form.Label>
                            )}
                            <Form.Control
                                className="m-2"
                                type="datetime-local"
                                required
                                min={min}
                                defaultValue={defaultValue}
                                value={value}
                                onChange={onChange}
                                disabled={disabled || false}
                            />
                        </>
                    );
                case 'date':
                    return (
                        <>
                            {label && (
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    {label}
                                </Form.Label>
                            )}
                            <Form.Control
                                className="m-2"
                                type="date"
                                required
                                min={min}
                                defaultValue={defaultValue}
                                value={value}
                                onChange={onChange}
                                disabled={disabled || false}
                            />
                        </>
                    );
                case 'time':
                    return (
                        <>
                            {label && (
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    {label}
                                </Form.Label>
                            )}
                            <Form.Control
                                className="m-2"
                                type="time"
                                required
                                defaultValue={defaultValue}
                                value={value}
                                onChange={onChange}
                                disabled={disabled || false}
                            />
                        </>
                    );
                case 'switches':
                    return (
                        <>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={label}
                                checked={value === 1 || value === true}
                                value={value}
                                defaultValue={defaultValue}
                                onChange={onChange}
                                disabled={disabled || false}
                            />
                        </>
                    );
                case 'dropdown':
                    return (
                        <>
                            {dropDown && (
                                <Container
                                    style={{
                                        display: 'flex',
                                        width: '18em',
                                        margin: '0.3em',
                                    }}
                                >
                                    <Dropdown
                                        as={ButtonGroup}
                                        className="mt-2"
                                        style={{ width: '10em' }}
                                        onSelect={onSelect}
                                    >
                                        <Button variant="success">
                                            {dropDown.title}
                                        </Button>

                                        <Dropdown.Toggle
                                            split
                                            variant="success"
                                            id="dropdown-split-basic"
                                        />

                                        <Dropdown.Menu>
                                            {dropDown &&
                                                dropDown?.dropDownItems &&
                                                dropDown?.dropDownItems.map(
                                                    (dropDownItem, idx) => {
                                                        return (
                                                            <Row
                                                                key={
                                                                    dropDownItem
                                                                }
                                                            >
                                                                <Dropdown.Item
                                                                    value={
                                                                        dropDownItem
                                                                    }
                                                                    key={idx}
                                                                    eventKey={
                                                                        dropDownItem
                                                                    }
                                                                >
                                                                    {
                                                                        dropDownItem
                                                                    }
                                                                </Dropdown.Item>
                                                            </Row>
                                                        );
                                                    },
                                                )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Container>
                            )}
                        </>
                    );
            }
            break;
        case 'long':
            return (
                <>
                    <Form.Label className="mt-3">{label}</Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        aria-label="With textarea"
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        readOnly={readOnly}
                        rows={10}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errorMessage}
                    </Form.Control.Feedback>
                </>
            );
        case 'file':
            return (
                <>
                    <InputGroup
                        className={classNames ? classNames : 'row m-10'}
                    >
                        <Form.Label className="mt-3">{label}</Form.Label>
                        <Form.Control
                            accept=".csv"
                            required
                            type="file"
                            aria-label="With textarea"
                            value={value}
                            defaultValue={defaultValue}
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorMessage}
                        </Form.Control.Feedback>
                    </InputGroup>
                </>
            );
    }
}

export default CustomInput;
