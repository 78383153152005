/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { Button, Modal, Container, Form, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';

import {
    DiscussionContext,
    useGlobalContext,
} from '../../../Context/index.tsx';
import { globalURLS } from '../../../Global/urls.js';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts.tsx';

function AddDocument() {
    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const { openAddDocumentModal, setAddDocumentModal } = useContext(
        DiscussionContext.AddDiscussionDocumentContext,
    );

    const [inputList, setinputList] = useState([
        { discussionid: '', name: '', url: '' },
    ]);

    const [validated, setValidated] = useState(false);

    const getSelectDiscussions = true;

    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const btnclose = () => {
        setAddDocumentModal(false);
    };

    const handleinputchange = (name, val, index) => {
        const list = [...inputList];
        list[index][name] = val?.value ? val?.value : val?.target?.value;
        setinputList(list);
    };

    const handleremove = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setinputList(list);
    };

    const handleaddclick = () => {
        setinputList([...inputList, { discussionid: '', name: '', url: '' }]);
    };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        const resp = await axios.post(
            `${globalURLS.baseURL}addDiscussionDownloads`,
            { inputList },
            globalURLS.config,
        );

        if (resp?.data?.success === true) {
            setShowSuccessToast(true);
            setAddDocumentModal(false);
        } else {
            setShowErrorToast(true);
        }
    };

    return (
        <Container>
            <Col>
                <Modal show={openAddDocumentModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Discussion Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                {inputList.map((x, i) => {
                                    return (
                                        <Row key={i}>
                                            {/* Discussionid */}
                                            <div className="form-group col-md-6">
                                                <Form.Label>
                                                    Discussion
                                                </Form.Label>

                                                <Select
                                                    closeMenuOnSelect={true}
                                                    onChange={val =>
                                                        handleinputchange(
                                                            'discussionid',
                                                            val,
                                                            i,
                                                        )
                                                    }
                                                    isSearchable
                                                    options={selectDiscussions}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a Discussion.
                                                </Form.Control.Feedback>
                                            </div>

                                            {/* Name */}
                                            <div className="form-group col-md-6">
                                                <Form.Label>
                                                    File Name
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    onChange={e =>
                                                        handleinputchange(
                                                            'name',
                                                            e,
                                                            i,
                                                        )
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a Name.
                                                </Form.Control.Feedback>
                                            </div>

                                            {/* URL */}
                                            <div className="form-group col-md-6">
                                                <Form.Label>URL</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="url"
                                                    name="url"
                                                    className="form-control"
                                                    onChange={e =>
                                                        handleinputchange(
                                                            'url',
                                                            e,
                                                            i,
                                                        )
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a URL.
                                                </Form.Control.Feedback>
                                            </div>

                                            {/* Thumbnail URL */}
                                            <div className="form-group col-md-6">
                                                <Form.Label>
                                                    Thumbnail URL
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    type="thumbnailurl"
                                                    name="thumbnailurl"
                                                    className="form-control"
                                                    onChange={e =>
                                                        handleinputchange(
                                                            'thumbnailurl',
                                                            e,
                                                            i,
                                                        )
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a
                                                    thumbnailurl.
                                                </Form.Control.Feedback>
                                            </div>

                                            {/* Buttons */}
                                            <div className="form-group ">
                                                {inputList.length !== 1 && (
                                                    <Button
                                                        className="btn btn-danger mx-2 mb-2"
                                                        onClick={() =>
                                                            handleremove(i)
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                                {inputList.length - 1 === i && (
                                                    <Button
                                                        style={{
                                                            width: 'auto',
                                                        }}
                                                        className="btn btn-success mx-2 mb-2"
                                                        onClick={handleaddclick}
                                                    >
                                                        Add More
                                                    </Button>
                                                )}
                                            </div>
                                        </Row>
                                    );
                                })}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Add Document
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
}

export default AddDocument;
