import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Toast,
    Card,
    ToastContainer,
    Container,
    Col,
} from 'react-bootstrap';
import axios from 'axios';

import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.esm.js';
import { BiEdit } from 'react-icons/bi/index.esm.js';

import { globalURLS } from '../../../Global/urls.js';
import './FeedTags.css';

const FeedTags = () => {
    // const {} = useFeedContext();
    // const [submitted, setSubmitted] = useState(false);
    const [allFeedTags, setAllFeedTags] = useState([]);
    const [shownFeedTags, setShownFeedTags] = useState([]);
    const [editDisable, setEditDisable] = useState(true);
    const [toastShow, setToastShow] = useState(false);
    const [arraySelectedFeedTags, setArraySelectedFeedTags] = useState([]);
    const animatedComponents = makeAnimated();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const AllFeedTagsData = await axios.get(
                    globalURLS.allFeedTagsJSONURL,
                );

                const ShownFeedTagsData = await axios.get(
                    globalURLS.feedShownTagsJSONURL,
                );

                if (AllFeedTagsData?.data && ShownFeedTagsData?.data) {
                    setAllFeedTags(
                        AllFeedTagsData.data.map(el => ({
                            value: el.id,
                            label: el.title,
                        })),
                    );
                    setShownFeedTags(
                        ShownFeedTagsData.data.map(el => ({
                            value: el.id,
                            label: el.title,
                        })),
                    );
                }
            } catch (e) {
                console.log('Error', e);
            }
        };
        fetchData();
    }, []);

    const handleShowFeedTags = val => {
        setArraySelectedFeedTags([]);
        setArraySelectedFeedTags(val);
    };

    const submitHandler = async type => {
        const data = arraySelectedFeedTags.map(el => el.value);

        if (type === 'orderFeedTags') {
            const respData = await axios.post(
                `${globalURLS.baseURL}ADsetFeedDisplayPills`,
                { feedTagIds: data },
                globalURLS.config,
            );

            if (respData?.data?.success === true) {
                const AllFeedTagsData = await axios.get(
                    globalURLS.allFeedTagsJSONURL,
                );

                const ShownFeedTagsData = await axios.get(
                    globalURLS.feedShownTagsJSONURL,
                );
                console.log('ShownFeedTagsData2', ShownFeedTagsData);
                if (AllFeedTagsData?.data && ShownFeedTagsData?.data) {
                    setAllFeedTags(
                        AllFeedTagsData.data.map(el => ({
                            value: el.id,
                            label: el.title,
                        })),
                    );
                    setShownFeedTags(
                        ShownFeedTagsData.data.map(el => ({
                            value: el.id,
                            label: el.title,
                        })),
                    );
                }
                setToastShow(true);
                setEditDisable(true);
            }
        }

        // if (type === 'addFeedTag') {
        //     const respData = await axios.post(
        //         `${globalURLS.baseURL}ADFeedTag`,
        //         { title: newFeedTagTitle },
        //         globalURLS.config,
        //     );

        //     if (respData?.data?.success === true) {
        //         setToastShow(true);
        //         setSubmitted(true);
        //         setEditDisable(true);
        //     }
        // }
    };
    return (
        <Card style={{ width: '80vw' }}>
            <Container className="mb-4">
                <Container
                    className="d-flex row justify-content-between"
                    style={{ width: '78vw' }}
                >
                    <Card.Title>
                        <Col className="d-flex col justify-content-between">
                            <Form.Label className="mt-5 px-2">
                                {editDisable
                                    ? 'Feed Tags To Be Shown on App'
                                    : `Edit Display FeedTags`}
                            </Form.Label>

                            <Button
                                className="btn-add"
                                onClick={() => {
                                    setEditDisable(editDisable ? false : true);
                                }}
                                style={{ marginRight: '2em' }}
                            >
                                <BiEdit />
                                {editDisable === true
                                    ? `Update Display Tags Order`
                                    : 'Close'}
                            </Button>

                            {editDisable === false && (
                                <Button
                                    className="btn-add"
                                    variant="primary"
                                    type="submit"
                                    onClick={() =>
                                        submitHandler('orderFeedTags')
                                    }
                                >
                                    Save
                                </Button>
                            )}
                            {/* </Container> */}
                        </Col>
                    </Card.Title>
                </Container>
                <Card.Body>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        value={
                            editDisable === false
                                ? arraySelectedFeedTags
                                : shownFeedTags
                        }
                        isDisabled={editDisable}
                        onChange={val => handleShowFeedTags(val)}
                        options={allFeedTags}
                    />
                </Card.Body>
                {toastShow && (
                    <ToastContainer
                        style={{ width: '15em' }}
                        position={'middle-center'}
                    >
                        <Toast
                            onClose={() => setToastShow(false)}
                            show={toastShow}
                        >
                            <Toast.Header>
                                <strong
                                    style={{
                                        fontSize: '1em',
                                        padding: '0.5em',
                                        color: 'green',
                                    }}
                                >
                                    Successfully Updated
                                </strong>
                            </Toast.Header>
                        </Toast>
                    </ToastContainer>
                )}
            </Container>
        </Card>
    );
};

export default FeedTags;
