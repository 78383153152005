/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Container, Form, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';

import { DiscussionContext, useGlobalContext } from '../../../Context/index';
import { globalURLS } from '../../../Global/urls.js';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import CustomInput from '../../Common/CustomInput/CustomInput';
import { BiEdit } from 'react-icons/bi';

interface AddonVideo {
    id: string;
    discussionName: string;
    discussionid: string;
    url: string;
    videoExt: string;
    altImgUrl: string;
    position: string;
    userType: string;
    canSkip: string;
    skipUserType: string;
    skipAfterSeconds: number;
    markSeenAfterSeconds: number;
    thumbnailurl: string;
}
interface IProps {
    data: AddonVideo;
}

const UpdateVideoAds: React.FC<IProps> = ({ data }) => {
    const {
        id,
        discussionName,
        discussionid,
        url,
        videoExt,
        altImgUrl,
        position,
        userType,
        canSkip,
        skipUserType,
        skipAfterSeconds,
        markSeenAfterSeconds,
        thumbnailurl,
    } = data;

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const { openUpdateVideoAdsModal, setUpdateVideoAdsModal } = useContext(
        DiscussionContext.UpdateVideoAdsContext,
    );

    const [alertModal, setAlertModal] = useState(false);

    const [editDisable, setEditDisable] = useState(true);
    const [inputList, setinputList] = useState<AddonVideo[]>({
        id: id || '',
        discussionName: discussionName || '',
        discussionid: discussionid || '',
        url: url || '',
        videoExt: videoExt || '',
        altImgUrl: altImgUrl || '',
        position: position || '',
        userType: userType || '',
        canSkip: canSkip || '',
        skipUserType: skipAfterSeconds || 0,
        skipAfterSeconds: skipAfterSeconds || 0,
        markSeenAfterSeconds: markSeenAfterSeconds || '',
        thumbnailurl: thumbnailurl || '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const respData = await axios.post(
                        `${globalURLS.baseURL}ADgetVideosAds`,
                        { id: id },
                        globalURLS.config,
                    );

                    // console.log('respData', respData);
                    if (respData?.data?.data[0]) {
                        setinputList(respData?.data?.data[0]);
                        // console.log('inputList', inputList);
                    }
                }
            } catch (e) {
                console.log('Error', e);
            }
        };
        fetchData();
    }, [id]);

    console.log('input', inputList);
    const [validated, setValidated] = useState(false);

    const getSelectDiscussions = true;

    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const btnclose = () => {
        setinputList(null);
        setUpdateVideoAdsModal(false);
    };

    const alertModalHandler = () => {
        setAlertModal(alertModal ? false : true);
    };

    const handleInputChange = (
        name: keyof AddonVideo,
        val: string | number | boolean | any,
    ) => {
        console.log('val, name', `${val.value}, ${name}`);

        if (name === 'discussionid') {
            const copyObj = {
                ...inputList,
                discussionid: val?.value,
                discussionName: val?.label,
            };

            setinputList(copyObj);
        } else {
            if (
                name === 'skipAfterSeconds' ||
                name === 'markSeenAfterSeconds'
            ) {
                const copyObj = {
                    ...inputList,
                    [name]: Number(val?.target?.value),
                };
                setinputList(copyObj);
            } else {
                const copyObj = {
                    ...inputList,
                    [name]: val?.target?.value,
                };
                setinputList(copyObj);
            }
        }
    };

    const editEnable = () => setEditDisable(editDisable ? false : true);

    const handleDelete = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        const resp = await axios.post(
            `${globalURLS.baseURL}ADdeleteOrUpdateVideosAds`,
            { ...inputList, apiType: 'delete' },
            globalURLS.config,
        );

        if (resp?.data?.data && resp?.data?.success === true) {
            setShowSuccessToast(true);
            setUpdateVideoAdsModal(false);
        } else {
            setShowErrorToast(true);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        const resp = await axios.post(
            `${globalURLS.baseURL}ADdeleteOrUpdateVideosAds`,
            { ...inputList, apiType: 'update' },
            globalURLS.config,
        );

        console.log('inputList', inputList);

        if (resp?.data?.success === true) {
            setinputList(null);
            setShowSuccessToast(true);
            setUpdateVideoAdsModal(false);
        } else {
            setShowErrorToast(true);
        }
    };

    return (
        <Container>
            <Col>
                <Modal show={openUpdateVideoAdsModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Join Room Videos</Modal.Title>
                            <BiEdit onClick={editEnable} />
                        </Modal.Header>
                        <Modal.Body>
                            {/* Discussionid */}
                            <div className="form-group col-md-6">
                                <Form.Label>Discussion</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a Discussion.
                                </Form.Control.Feedback>
                                <Select
                                    closeMenuOnSelect={true}
                                    onChange={val =>
                                        handleInputChange('discussionid', val)
                                    }
                                    value={[
                                        {
                                            value: inputList?.discussionid,
                                            label: inputList?.discussionName,
                                        },
                                    ]}
                                    isDisabled={editDisable}
                                    isSearchable
                                    options={selectDiscussions}
                                />
                            </div>
                            <div
                                className="row "
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Row
                                    style={{
                                        background: '#F2F2F2',
                                        borderRadius: '1em',
                                        height: 'auto',
                                        width: '1vw',
                                        marginTop: '1em',
                                    }}
                                >
                                    {/* Video Format */}
                                    <div className="form-group row-md-6">
                                        <CustomInput
                                            classNames={''}
                                            textArea={'short'}
                                            type={'dropdown'}
                                            errorMessage={`Please select video format`}
                                            label={'Video Format'}
                                            placeHolder={'Video Format'}
                                            required={true}
                                            value={inputList?.videoExt || 'mp4'}
                                            defaultValue={'mp4'}
                                            disabled={editDisable}
                                            dropDown={{
                                                title: 'Video Format ',
                                                dropDownItems: ['mp4', 'm3u8'],
                                            }}
                                            onSelect={e => {
                                                //
                                                handleInputChange(
                                                    'videoExt',
                                                    e,
                                                );
                                            }}
                                        />
                                        {/* URL */}
                                        <CustomInput
                                            disabled={editDisable}
                                            label={'Video URL'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={'Enter a Video URL'}
                                            value={inputList?.url || ''}
                                            onChange={e => {
                                                //
                                                handleInputChange('url', e);
                                            }}
                                            errorMessage={
                                                'Please Enter Video URL'
                                            }
                                        />

                                        {/* thumbnail URL */}
                                        <CustomInput
                                            disabled={editDisable}
                                            label={'ThumbnailURL'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={false}
                                            placeHolder={'Enter a ThumbnailURL'}
                                            value={
                                                inputList?.thumbnailurl || ''
                                            }
                                            onChange={e => {
                                                //
                                                handleInputChange(
                                                    'thumbnailurl',
                                                    e,
                                                );
                                            }}
                                            errorMessage={
                                                'Please Enter ThumbnailURL'
                                            }
                                        />

                                        {/* Alt Image URL */}
                                        <CustomInput
                                            disabled={editDisable}
                                            label={'Alt Image URL'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={false}
                                            placeHolder={
                                                'Enter a Alt Image URL'
                                            }
                                            value={inputList?.altImgUrl || ''}
                                            onChange={e => {
                                                //
                                                handleInputChange(
                                                    'altImgUrl',
                                                    e,
                                                );
                                            }}
                                            errorMessage={
                                                'Please Enter Alt Image URL'
                                            }
                                        />

                                        {/* Ad Position */}

                                        <CustomInput
                                            classNames={''}
                                            textArea={'short'}
                                            type={'dropdown'}
                                            errorMessage={`Please select Ad Position`}
                                            disabled={editDisable}
                                            label={'Ad Position'}
                                            placeHolder={'Ad Position'}
                                            required={true}
                                            value={
                                                inputList?.position || 'start'
                                            }
                                            dropDown={{
                                                title: 'Ad Position ',
                                                dropDownItems: ['start', 'end'],
                                            }}
                                            onSelect={e => {
                                                //
                                                handleInputChange(
                                                    'position',
                                                    e,
                                                );
                                            }}
                                        />

                                        {/* User Type */}

                                        <CustomInput
                                            classNames={''}
                                            textArea={'short'}
                                            type={'dropdown'}
                                            errorMessage={`Please select User Type`}
                                            disabled={editDisable}
                                            label={'User Type'}
                                            placeHolder={'User Type'}
                                            required={true}
                                            value={inputList?.user || 'free'}
                                            dropDown={{
                                                title: 'User Type ',
                                                dropDownItems: [
                                                    'free',
                                                    'premium',
                                                    'all',
                                                ],
                                            }}
                                            onSelect={e => {
                                                //
                                                handleInputChange(
                                                    'userType',
                                                    e,
                                                );
                                            }}
                                        />

                                        {/* Skip User Type */}

                                        <CustomInput
                                            classNames={''}
                                            textArea={'short'}
                                            type={'dropdown'}
                                            errorMessage={`Please select Skip User Type`}
                                            disabled={editDisable}
                                            label={'Skip User Type'}
                                            placeHolder={'Skip User Type'}
                                            required={true}
                                            value={
                                                inputList?.skipUserType ||
                                                skipUserType
                                            }
                                            dropDown={{
                                                title: 'Skip User Type ',
                                                dropDownItems: [
                                                    'free',
                                                    'premium',
                                                    'all',
                                                ],
                                            }}
                                            onSelect={e => {
                                                handleInputChange(
                                                    'skipUserType',
                                                    e,
                                                );
                                            }}
                                        />

                                        {/* Skip After Seconds in sec */}
                                        <CustomInput
                                            disabled={editDisable}
                                            label={'Skip After Seconds in sec'}
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={
                                                'Enter a Skip After Seconds in sec'
                                            }
                                            value={
                                                inputList?.skipAfterSeconds ||
                                                ''
                                            }
                                            onChange={e => {
                                                handleInputChange(
                                                    'skipAfterSeconds',
                                                    e,
                                                );
                                            }}
                                            errorMessage={
                                                'Please Enter Skip After Seconds in sec'
                                            }
                                        />

                                        {/* Mark Seen After Seconds in sec */}
                                        <CustomInput
                                            disabled={editDisable}
                                            label={
                                                'Mark Seen After Seconds in sec'
                                            }
                                            textArea={'short'}
                                            type={'text'}
                                            required={true}
                                            placeHolder={
                                                'Enter a Mark Seen After Seconds in sec'
                                            }
                                            value={
                                                inputList?.markSeenAfterSeconds ||
                                                ''
                                            }
                                            onChange={e => {
                                                handleInputChange(
                                                    'markSeenAfterSeconds',
                                                    e,
                                                );
                                            }}
                                            errorMessage={
                                                'Please Enter Mark Seen After Seconds in sec'
                                            }
                                        />
                                    </div>
                                </Row>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>
                            <Button variant="danger" onClick={handleDelete}>
                                Delete
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={editDisable}
                            >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
            {alertModal === true && (
                <>
                    <Modal show={alertModal} onHide={alertModalHandler}>
                        <Modal.Header closeButton>
                            <Modal.Title>{'Confirm Delete'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {'Woohoo, you are  deletings this. Are you sure?'}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={alertModalHandler}
                            >
                                Close
                            </Button>
                            <Button variant="danger" onClick={handleDelete}>
                                {'Confirm Delete'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </Container>
    );
};

export default UpdateVideoAds;
