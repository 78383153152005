import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, ListGroup, ProgressBar } from 'react-bootstrap';
import axios, { AxiosRequestConfig } from 'axios';
const X_FUNCTIONS_KEY = process.env.REACT_APP_X_FUNCTIONS_KEY_UPLOADER;

interface Props {
    url: string;
}

interface ResponseData {
    data: {
        [key: string]: string;
    };
    message: string;
    success: boolean;
}

const UploadForm: React.FC<Props> = ({ url }) => {
    const [progress, setProgress] = useState<number>(0);
    const [files, setFiles] = useState<File[]>([]);
    const [respData, setRespData] = useState<ResponseData>();

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setFiles([...files, ...acceptedFiles]);
        },
        [files],
    );

    const removeFile = (fileToRemove: File) => {
        setFiles(files.filter(file => file !== fileToRemove));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData();
        const totalFiles = files.length;

        for (let i = 0; i < totalFiles; i++) {
            formData.append(`file${i}`, files[i]);
        }

        const config: AxiosRequestConfig = {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                );
                setProgress(percentCompleted);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-functions-key': X_FUNCTIONS_KEY,
            },
        };

        try {
            const response = await axios.post(url, formData, config);
            // Handle response from server
            if (response?.data && response?.data?.success) {
                setRespData(response.data);
            }
            console.log(response.data);
        } catch (error) {
            // Handle error
            console.log(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div
                style={{
                    border: '1px solid grey',
                    background: '#E9ECEF',
                    padding: '1em',
                    borderRadius: '0.6em',
                }}
                className="mb-3"
                {...getRootProps()}
            >
                <input type="file" {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : (
                    <p>
                        Drag and drop some files here, or click to select files
                    </p>
                )}
            </div>

            <div className="mb-3">
                {files.length > 0 && (
                    <ListGroup>
                        {files.map((file, index) => (
                            <ListGroup.Item key={index}>
                                <div
                                    style={{
                                        textDecoration: 'none',
                                        fontSize: '0.65em',
                                    }}
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    {file.name}
                                    <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        onClick={() => removeFile(file)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
            </div>

            <div className="mb-3">
                <ProgressBar now={progress} label={`${progress}%`} />
            </div>

            {respData && respData.success && (
                <ListGroup style={{ width: '100%' }} className="mt-3">
                    {Object.entries(respData.data).map(([key, value]) => (
                        <ListGroup.Item key={key}>
                            <div className="d-flex justify-content-between align-items-center">
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        fontSize: '0.65em',
                                    }}
                                    href={value}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    File Name:{' '}
                                    {value.split('/').pop()?.split('_').shift()}
                                </a>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() =>
                                        navigator.clipboard.writeText(value)
                                    }
                                >
                                    Copy URL
                                </Button>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}

            {respData && !respData.success && (
                <p>Error uploading files. Please try again.</p>
            )}

            <button type="submit" className="btn btn-primary mt-3">
                Upload
            </button>
        </form>
    );
};

export default UploadForm;
