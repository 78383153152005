/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';
import { ReportsData, axiosRespData } from '../Components/Report/interface';

interface ReportContextProps {
    reportModal?: boolean;
    setReportModal?: (reportModal: boolean) => void;
    reportData?: ReportsData;
    setReportData?: (reportsData: ReportsData) => void;
    selectedReport: ReportsData;
    setSelectedReport?: (reportsData: ReportsData) => void;
    toastShow?: boolean;
    setToastShow?: (toastShow: boolean) => void;
    errorToastShow?: boolean;
    setErrorToastShow?: (errorToastShow: boolean) => void;
    axiosRespData?: axiosRespData;
    setAxiosRespData?: (axiosRespData: axiosRespData) => void;
}

export const ReportContext = React.createContext<ReportContextProps>({
    reportModal: false,
    setReportModal: () => {},
    reportData: {
        name: '',
        description: '',
        averageTime: 0,
        endPoint: '',
        inputs: [{ type: '', reqBody: {} }],
    },
    setReportData: () => {},
    selectedReport: {
        name: '',
        description: '',
        averageTime: 0,
        endPoint: '',
        inputs: [{ type: '', reqBody: {} }],
    },
    setSelectedReport: () => {},
    toastShow: false,
    setToastShow: () => {},
    errorToastShow: false,
    setErrorToastShow: () => {},
    axiosRespData: {
        url: '',
    },
    setAxiosRespData: () => {},
});

export const ReportContextProvider = (props: any) => {
    const [reportData, setReportData] = useState<ReportsData>();
    const [reportModal, setReportModal] = useState<boolean>();
    const [selectedReport, setSelectedReport] = useState<ReportsData>();
    const [toastShow, setToastShow] = useState(false);
    const [errorToastShow, setErrorToastShow] = useState(false);
    const [axiosRespData, setAxiosRespData] = useState<axiosRespData>();

    return (
        <ReportContext.Provider
            value={{
                reportModal,
                setReportModal,
                reportData,
                setReportData,
                selectedReport,
                setSelectedReport,
                toastShow,
                setToastShow,
                errorToastShow,
                setErrorToastShow,
                axiosRespData,
                setAxiosRespData,
            }}
        >
            {props.children}
        </ReportContext.Provider>
    );
};
