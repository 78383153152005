import React, { useState } from 'react';
import { Form, Button, Modal, Image } from 'react-bootstrap';
import { useGlobalContext, useNotificationContext } from '../../../Context';
import { format, parseISO } from 'date-fns';
import { parse } from 'papaparse';
import Select from 'react-select';

import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import {
    convertIstToUtc,
    convertLocalToIst,
    convertUtcToIst,
} from '../../../Helper/TimeZone';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

interface Params {
    source: string;
    module: 'DD' | 'DDX' | 'DI' | 'AMA';
    channel?: {
        id: string;
        title: string;
    };
    conferenceid?: string;
    title?: string;
    videoid?: string;
    clubid?: string;
    discussionid?: string;
    feedItemId?: string;
}

interface Notification {
    openType: 'app' | 'externalLink';
    targetType: 'accountids';
    screen:
        | 'Sessions'
        | 'AIAssist'
        | 'ConferenceTicketPage'
        | 'conferenceCheckout'
        | 'conference'
        | 'offer'
        | 'ReplayTab'
        | 'Club'
        | 'welcome'
        | 'VerificationPathWay'
        | 'Room'
        | 'YoutubeEmbed'
        | 'Feed'
        | 'Discussion'
        | undefined;
    params: Params;
    url: string;
    accountids: string[];
    specialties?: string[];
    clubid?: string;
    discussionid?: string;
    soundid?: string;
    title: string;
    subtitle: string;
    imageurl: string;
    scheduledAt?: string;
}

const initialState: Notification = {
    openType: 'app',
    targetType: 'accountids',
    screen: undefined,
    params: {
        source: '',
        channel: {
            id: '',
            title: '',
        },
        module: 'DD',
        title: '',
        videoid: '',
        discussionid: '',
        feedItemId: '',
        conferenceid: '',
    },
    url: '',
    accountids: [],
    clubid: '',
    discussionid: '',
    title: '',
    subtitle: '',
    imageurl: '',
};

const conferenceIDFor = [
    'conference',
    'ConferenceTicketPage',
    'conferenceCheckout',
];
const SendBN: React.FC = () => {
    const [notification, setNotification] =
        useState<Notification>(initialState);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getSelectDiscussions = true;
    const getSelectClubs = true;
    const { selectDiscussions, selectClubs } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectDiscussions,
            getSelectClubs,
        });

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();

    const { setSendBNModal, sendBNModal } = useNotificationContext();

    const handleInputChange = async (event: any) => {
        let { value } = event.target;
        const { name } = event.target;

        if (name === 'scheduledAt' && value) {
            value = await convertLocalToIst(value);
        }

        setNotification({
            ...notification,
            [name]: value,
        });
    };

    const handleParamsInputChange = (event: any) => {
        const { name, value } = event.target;
        setNotification({
            ...notification,
            params: {
                ...notification.params,
                [name]: value,
            },
        });
    };

    const handleChannelInputChange = (event: any) => {
        const { name, value } = event.target;
        setNotification({
            ...notification,
            params: {
                ...notification.params,
                channel: {
                    ...notification.params.channel,
                    [name]: value,
                },
            },
        });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        try {
            setIsLoading(true);

            notification.scheduledAt = `${notification.scheduledAt} IST`;
            const respData = await axios.post(
                `${globalURLS.baseURL}sendTargetedNotification`,
                notification,
                globalURLS.config,
            );

            console.log('notification', notification);
            if (respData?.data?.success === true) {
                setIsLoading(false);
                setShowSuccessToast(true);
                setSendBNModal(false);
            }

            if (respData?.data?.success === false) {
                setIsLoading(false);
                setShowSuccessToast(false);
                setShowErrorToast(true);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setShowSuccessToast(false);
            setShowErrorToast(true);
        }
    };

    return (
        <>
            <Modal
                show={sendBNModal}
                animation={false}
                onHide={() => setSendBNModal(false)}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header>
                        <Modal.Title className="mb-0">Send BN</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Open Type</Form.Label>
                            <Form.Select
                                name="openType"
                                value={notification.openType}
                                onChange={handleInputChange}
                            >
                                <option value="app">App</option>
                                <option value="externalLink">
                                    External Link
                                </option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Target Type</Form.Label>
                            <Form.Control
                                type="text"
                                name="targetType"
                                value={notification.targetType}
                                readOnly
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Screen</Form.Label>
                            <Form.Select
                                name="screen"
                                value={notification.screen || ''}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">-</option>
                                <option value="Room">Room</option>
                                <option value="YoutubeEmbed">
                                    YoutubeEmbed
                                </option>
                                <option value="AIAssist">AIAssist</option>
                                <option value="Sessions">Sessions</option>
                                <option value="Feed">Feed</option>
                                <option value="Discussion">Discussion</option>
                                <option value="conference">Conference</option>
                                <option value="ConferenceTicketPage">
                                    Conference Ticket Page
                                </option>
                                <option value="conferenceCheckout">
                                    Conference Checkout
                                </option>
                                <option value="offer">Conference Offer</option>
                                <option value="ReplayTab">Replay Tab</option>
                                <option value="welcome">Welcome</option>
                                <option value="VerificationPathWay">
                                    Verification Path Way
                                </option>
                                <option value="Club">Club</option>
                            </Form.Select>
                        </Form.Group>

                        {notification?.screen === 'Club' && (
                            <Form.Group className="mb-3">
                                <Form.Label>For Club</Form.Label>

                                <Select
                                    closeMenuOnSelect={true}
                                    onChange={val =>
                                        setNotification({
                                            ...notification,
                                            params: {
                                                ...notification.params,
                                                clubid: val.value,
                                            },
                                        })
                                    }
                                    isSearchable
                                    options={selectClubs}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter discussion id
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                        {conferenceIDFor.includes(notification?.screen) && (
                            <Form.Group className="mb-3">
                                <Form.Label>Conference ID</Form.Label>
                                <Form.Control
                                    name="conferenceid"
                                    required
                                    value={notification.params.conferenceid}
                                    onChange={handleParamsInputChange}
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please Add Conference ID
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        {notification.openType === 'app' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Params Source</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="source"
                                    required
                                    value={notification.params.source}
                                    onChange={handleParamsInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Add Source
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        {notification?.screen === 'AIAssist' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Module</Form.Label>
                                <Form.Select
                                    name="module"
                                    required
                                    value={notification.params.module}
                                    onChange={handleParamsInputChange}
                                >
                                    {' '}
                                    <option value="">-</option>
                                    <option value="DD">DD</option>
                                    <option value="DDX">DDX</option>
                                    <option value="DI">DI</option>
                                    <option value="AMA">AMA</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please Add Module
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        {notification.openType === 'app' &&
                            notification.screen === 'Room' && (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>ChannelID</Form.Label>

                                        <Select
                                            closeMenuOnSelect={true}
                                            onChange={val =>
                                                setNotification({
                                                    ...notification,
                                                    params: {
                                                        ...notification.params,
                                                        channel: {
                                                            ...notification
                                                                .params.channel,
                                                            id: val.value,
                                                            title: val.label,
                                                        },
                                                    },
                                                })
                                            }
                                            isSearchable
                                            options={selectDiscussions}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please fill Channel ID
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>ChannelTitle</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="title"
                                            value={
                                                notification?.params?.channel
                                                    ?.title
                                                    ? notification?.params
                                                          ?.channel?.title
                                                    : ''
                                            }
                                            onChange={handleChannelInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter Channel Title
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )}

                        {notification.openType === 'app' &&
                            notification.screen === 'YoutubeEmbed' && (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="title"
                                            value={notification.params.title}
                                            onChange={handleParamsInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter title
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>VideoID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="videoid"
                                            value={notification.params.videoid}
                                            onChange={handleParamsInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter videoid
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>For Discussion</Form.Label>

                                        <Select
                                            closeMenuOnSelect={true}
                                            onChange={val =>
                                                setNotification({
                                                    ...notification,
                                                    params: {
                                                        ...notification.params,
                                                        discussionid: val.value,
                                                    },
                                                })
                                            }
                                            isSearchable
                                            options={selectDiscussions}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter discussion id
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )}

                        {notification.openType === 'app' &&
                            notification.screen === 'Feed' && (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Feed Item ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="feedItemId"
                                            value={
                                                notification.params.feedItemId
                                            }
                                            onChange={handleParamsInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter feed item id
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )}

                        {notification.openType === 'app' &&
                            notification.screen === 'Discussion' && (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Discussion ID</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            onChange={val =>
                                                setNotification({
                                                    ...notification,
                                                    params: {
                                                        ...notification.params,
                                                        discussionid:
                                                            val?.value,
                                                    },
                                                })
                                            }
                                            isSearchable
                                            options={selectDiscussions}
                                        />
                                    </Form.Group>
                                </>
                            )}
                        {notification.openType === 'externalLink' && (
                            <Form.Group className="mb-3">
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="url"
                                    required
                                    value={notification.url}
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter the URL
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        <Form.Group className="mb-3">
                            <Form.Label>Account IDs (CSV File)</Form.Label>
                            <Form.Control
                                required
                                type="file"
                                accept=".csv"
                                name="accountids"
                                onChange={async e => {
                                    const files = e.target.files;

                                    if (files) {
                                        parse(files[0], {
                                            header: true,
                                            complete: function (results) {
                                                const data = results.data.map(
                                                    (el: string) =>
                                                        el.accountid,
                                                );

                                                // Filter out non-UUIDs
                                                const validUUIDs = data.filter(
                                                    id =>
                                                        /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                                                            id,
                                                        ),
                                                );

                                                setNotification({
                                                    ...notification,
                                                    accountids: validUUIDs,
                                                });
                                            },
                                        });
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide accountids CSV file
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Sound ID (optional)</Form.Label>
                            <Form.Control
                                type="text"
                                name="soundid"
                                value={notification.soundid || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                name="title"
                                value={notification.title}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please Provide Title
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Subtitle</Form.Label>
                            <Form.Control
                                type="text"
                                name="subtitle"
                                value={notification.subtitle}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Image URL</Form.Label>
                            <Form.Control
                                type="text"
                                name="imageurl"
                                value={notification.imageurl}
                                onChange={handleInputChange}
                            />
                            {notification.imageurl && (
                                <Image
                                    src={notification.imageurl}
                                    thumbnail
                                    style={{
                                        marginTop: '0.5em',
                                        width: '55vw',
                                        height: '35vh',
                                    }}
                                />
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Scheduled At (optional)</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="scheduledAt"
                                value={notification.scheduledAt || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setSendBNModal(false),
                                    setNotification(initialState);
                            }}
                        >
                            Close
                        </Button>

                        <Button
                            variant={isLoading ? 'warning' : 'primary'}
                            type="submit"
                            disabled={isLoading ? true : false}
                            onClick={handleSubmit}
                        >
                            {isLoading ? 'Please Wait' : 'Create'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default SendBN;
