import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.cjs';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts';
import axios from 'axios';
import { globalURLS } from '../../../Global/urls';
import { useGlobalContext } from '../../../Context';
import { discussionsData } from '../../Report/interface';

interface Discussion {
    discussionid: string;
}

interface RecordingLiveNotifyProps {
    show: boolean;
    onHide(): void;
}

const animatedComponents = makeAnimated();

const initialData: Discussion = {
    discussionid: '',
};

const RecordingLiveNotify: React.FC<RecordingLiveNotifyProps> = ({
    show,
    onHide,
}) => {
    const [Discussion, setDiscussion] = useState<Discussion>(initialData);

    const { setShowSuccessToast, setShowErrorToast } = useGlobalContext();
    const [isLoading, setIsLoading] = useState(false);

    const getSelectDiscussions = true;

    const { selectDiscussions } = useDiscussionsClubsHostsInternalHosts({
        getSelectDiscussions,
    });

    const handlerSubmit = async () => {
        try {
            setIsLoading(true);
            const res = await axios.post(
                `${globalURLS.baseURL}recordingAvalNotify`,
                {
                    discussionid: Discussion?.discussionid,
                    toSendEmail: 1,
                },
                globalURLS.config,
            );

            setIsLoading(false);

            if (res?.data?.success === true) {
                onHide();
                setDiscussion(initialData);
                setShowSuccessToast(true);
            } else {
                setShowErrorToast(true);
            }
        } catch (error) {
            console.log(error);
            setShowErrorToast(true);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Send Recording Live Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="discussionid">
                        <Form.Label>Discussion</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            onChange={(val: any) =>
                                setDiscussion({
                                    ...Discussion,
                                    discussionid: val.value,
                                })
                            }
                            isSearchable
                            options={JSON.parse(
                                JSON.stringify(
                                    selectDiscussions ? selectDiscussions : [],
                                ),
                            )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="success"
                    disabled={isLoading}
                    onClick={handlerSubmit}
                >
                    Send Notification
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RecordingLiveNotify;
