import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, utcToZonedTime } from 'date-fns-tz';

// Components imports
import { globalURLS } from '../../../Global/urls.js';
import Table from '../../Common/Table/Table.js';

function DraftTable() {
    const [draftDiscussionData, setDraftDiscussionData] = useState([]);

    const [columnDefs] = useState([
        { field: 'isDraft', sortable: true, resizable: true },
        { field: 'discussionid', sortable: true, resizable: true },
        { field: 'title', sortable: true, resizable: true },
        { field: 'club', sortable: true, resizable: true },
        { field: 'status', sortable: true, resizable: true },
        { field: 'startdate', sortable: true, resizable: true },
        { field: 'attendeecount', sortable: true, resizable: true },
        { field: 'hosts', sortable: true, resizable: true },
    ]);

    const [rowData, setRowData] = useState([]);

    const adminData = {
        accountid: 'a237aec7-5fe0-4177-99b2-d0b590af26db',
        count: 100,
        getOnlyQuizDraftDiscussions: true,
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${globalURLS.baseURL}getWebUpcomingDiscussions`,
                adminData,
            );

            setDraftDiscussionData(response.data.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (draftDiscussionData && draftDiscussionData.length > 0) {
            const finalUcomingDiscussionData = draftDiscussionData.map(el => {
                const upcoming = {};
                const date = new Date(el.istdate);
                const ISTDateTime = utcToZonedTime(date, 'Asia/Calcutta');
                const finalISTDateTime = format(
                    ISTDateTime,
                    'yyyy-MM-dd HH:mm:ss',
                    { timeZone: 'Asia/Calcutta' },
                );
                // const finalISTDateTime = format(ISTDateTime, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'Asia/Calcutta'});
                const finalHosts = el.hosts
                    .map(host => {
                        return `${host.firstname} ${host.lastname}`;
                    })
                    .join('| ');
                (upcoming.isDraft = el.isDraft),
                    (upcoming.discussionid = el.discussionid),
                    (upcoming.title = el.title),
                    (upcoming.club = el.clubinfo.name),
                    (upcoming.status = el.status),
                    (upcoming.startdate = finalISTDateTime),
                    (upcoming.attendeecount = el.attendeecount),
                    (upcoming.hosts = finalHosts);
                return upcoming;
            });

            setRowData(
                finalUcomingDiscussionData ? finalUcomingDiscussionData : [],
            );
        }
    }, [draftDiscussionData]);
    return (
        <>
            <Table
                rowData={rowData}
                columnDefs={columnDefs}
                title={'Quiz Draft'}
            ></Table>
        </>
    );
}

export default DraftTable;
