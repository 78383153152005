import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, InputGroup, Container, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';
import Dashboard from './Dashboard'; // Assuming the Dashboard component is imported
// import { useGlobalContext } from '../../Context';

const isValidUrl = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
};


const ShortenerPage: React.FC = () => {
  const [activeView, setActiveView] = useState<string>('create');
  const [longUrl, setLongUrl] = useState<string>('');
  const [customLink, setCustomLink] = useState<string>('');
  const [isCustomAvailable, setIsCustomAvailable] = useState<boolean | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [shortUrl, setShortUrl] = useState<string>('');
  const [isloading, setisloading] = useState<{checkavailability: boolean, shorten: boolean}>({checkavailability: false, shorten: false});
  const [showBulkModal, setShowBulkModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});

  // const { authDetails } = useGlobalContext();
  // console.log({user})

  useEffect(() => {
    try {
      const d1 = localStorage.getItem('user');
      if (d1?.length) setUser(JSON.parse(d1));
    }
    catch(err) {console.log('setUser error', err)}
  }, []);

  const handleCheckAvailability = async () => {
    if (isloading?.checkavailability) return;
    setisloading(val => ({...val, checkavailability: true}));
    try {
      const response = await axios.post(`${globalURLS.baseURL3}ad_check_availability`, { url: encodeURI(customLink.trim()) });
      if (response.data.success) {
        setIsCustomAvailable(true);
      } else {
        setIsCustomAvailable(false);
        setModalMessage(response.data.message);
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error checking availability:', error);
    } finally { setisloading(val => ({...val, checkavailability: false})); }
  };

  const handleCreateLink = async () => {
    // console.log('-user', user)
    if (isloading?.shorten) return;
    if (!isValidUrl(longUrl)) {
      setModalMessage('The provided URL is not valid. Please enter a valid URL.');
      setShowModal(true);
      return;
    }
    setisloading(val => ({...val, shorten: true}));
    try {
      const response = await axios.post(`${globalURLS.baseURL3}ad_create_link_single`, {
        long_url: longUrl,
        custom_link: isCustomAvailable && customLink?.length && customLink.trim().length ? encodeURI(customLink.trim()) : null,
        creator: user?.email
      });
      if (response.data.success) {
        setShortUrl(response.data.data.short_url);
        setModalMessage(``);
        setShowModal(true);
        setCustomLink(''); setIsCustomAvailable(false); setLongUrl('');
      } else {
        setModalMessage(response.data.message);
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error creating link:', error);
    } finally { setisloading(val => ({...val, shorten: false})); }
  };

  const handleBulkSubmit = async () => {
    if (!selectedFile) {
      setModalMessage('Please select a CSV file.');
      setShowModal(true);
      return;
    }
  
    setIsLoading(true);
  
    const reader = new FileReader();
  
    reader.onload = async (event) => {
      const text = event.target?.result as string;
  
      // Split the CSV content by new lines to get rows
      const rows = text.split('\n').filter(row => row.trim() !== '');
  
      // Extract headers and remove any extra spaces
      const headers = rows[0].split(',').map(header => header.trim());
  
      // Ensure the headers match the expected structure
      if (headers[0] !== 'long_url' || headers[1] !== 'custom_link') {
        setModalMessage('Invalid CSV format. Please ensure the columns are "long_url" and "custom_link".');
        setShowModal(true);
        setIsLoading(false);
        return;
      }
  
      // Parse the rows into an array of objects
      const data = rows.slice(1).map(row => {
        const values = row.split(',').map(value => value.trim());
        return {
          long_url: values[0],
          custom_link: values[1] || null,
        };
      });
  
      try {
        const response = await axios.post(`${globalURLS.baseURL3}ad_create_link_bulk`, { data, creator: user?.email });
  
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'shortened_links.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Error generating bulk links:', error);
        setModalMessage('Failed to generate bulk links. Please try again.');
        setShowModal(true);
      } finally {
        setIsLoading(false);
        setShowBulkModal(false);
      }
    };
  
    reader.onerror = () => {
      console.error('Error reading file');
      setModalMessage('Error reading the file. Please try again.');
      setShowModal(true);
      setIsLoading(false);
    };
  
    reader.readAsText(selectedFile);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shortUrl);
    alert('Short URL copied to clipboard!');
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };
  

  return (
    <Container fluid className="shortener-page d-flex flex-column align-items-center">
      <div className="buttons-container mb-4">
        <Button
          variant={activeView === 'create' ? 'primary' : 'outline-primary'}
          onClick={() => setActiveView('create')}
          className="me-2"
        >
          Create Links
        </Button>
        <Button
          variant={activeView === 'analytics' ? 'primary' : 'outline-primary'}
          onClick={() => setActiveView('analytics')}
        >
          View Analytics
        </Button>
      </div>

      {activeView === 'create' && (
        <div className="create-link-tab">
          <Button
            variant="primary"
            onClick={() => setShowBulkModal(true)}
            className="ms-2"
            style={{marginBottom: 40}}
          >
            Generate Bulk Links
          </Button>

          <Form.Group controlId="longUrl">
            <Form.Label>Enter URL to Shorten</Form.Label>
            <Form.Control
              type="text"
              value={longUrl}
              onChange={(e) => setLongUrl(e.target.value)}
              placeholder="Enter the URL here..."
              className="mb-3"
            />
            <div className="custom-link-section mt-3" style={{width: '40%', marginBottom: 30}}>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={customLink}
                  onChange={(e) => setCustomLink(e.target.value)}
                  placeholder="Custom short link (leave blank to auto-generate)"
                />
                <Button onClick={handleCheckAvailability} variant="outline-primary" disabled={!customLink?.length || isloading.checkavailability}>
                  {isloading.checkavailability ? 'Checking...' : 'Check Availability'}
                </Button>
              </InputGroup>
              {isCustomAvailable === true && (
                <span className="availability-check ms-2">✔️ Available</span>
              )}
            </div>
          </Form.Group>
          <Button
            onClick={handleCreateLink}
            disabled={!longUrl?.length || isloading.shorten}
            variant="primary"
            className="me-2"
          >
            {isloading.shorten ? 'Creating...' : 'Shorten'}
          </Button>
        </div>
      )}

      {activeView === 'analytics' && (
        <div className="analytics-tab w-100">
          <Dashboard />
        </div>
      )}

      {/* Bulk Upload Modal */}
      <Modal show={showBulkModal} onHide={() => setShowBulkModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Generate Bulk Links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Only CSV files are accepted with two columns:</p>
          <ul>
            <li><strong>long_url</strong> - URL to be shortened</li>
            <li><strong>custom_link</strong> - Custom short link (optional)</li>
          </ul>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Select or Drag & Drop CSV File</Form.Label>
            <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBulkModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleBulkSubmit} disabled={!selectedFile || isLoading}>
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for displaying messages */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMessage}
          {shortUrl && (
            <div className="mt-3">
              <p>Short URL: {shortUrl}</p>
              <Button onClick={copyToClipboard} variant="outline-success">
                Copy to Clipboard
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModal(false);  setShortUrl(''); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ShortenerPage;
